import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, ToggleButton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import Config from "../../Utils/Config";
import useCustomMemo from "../../Hooks/useCustomMemo";
import StatusPanel from "../Orders/StatusPanel";
import { OrderStatusModel } from "../../Utils/Models";
import { updateData } from "../../Services/updateData";
import {
  ContactOrderProps,
  CreateAndUpdateOrderResponse,
} from "../../Utils/Types";
import { localUrlEnum, endpoints } from "../../Utils/UrlEnum";
import genericStyles from "../../Styles/genericStyles.module.css";
import styles from "../../Styles/dashboardContacts.module.css";
import { postData } from "../../Services/postData";
import NoDataFound from "../NoDataFound";
import { ContactCRUDContext } from "./ContactModal";

import CustomPanel from "../CustomPanel";
import EditIcon from "@mui/icons-material/Edit";
import { AxiosResponse } from "axios";

export default function ContactOrder(props: ContactOrderProps) {
  const { setContextValue, disabled } = props;
  const memo = useCustomMemo();
  const orderStatuses = memo?.storage?.orderStatuses;
  const contactCrudContext = useContext(ContactCRUDContext);

  /**
   *
   * @param status
   * @returns
   */
  const handleChangeOrderStatus = (status: OrderStatusModel) => {
    if (!contactCrudContext.order) return;
    contactCrudContext.order.status = status;
    const url = `${endpoints.ordersEndpoint}/${contactCrudContext?.order?._id}`;
    updateData(url, contactCrudContext.order).then(
      (res: CreateAndUpdateOrderResponse) => {
        if (res && res.data)
          setContextValue({ ...contactCrudContext, order: res?.data });
      }
    );
  };

  /**
   *
   */
  const createNewOrderForContact = () => {
    postData(`${endpoints.contactEndpoint}/${contactCrudContext._id}`, {}).then(
      (res: AxiosResponse) => {
        if (res?.data) {
          setContextValue(res.data);
        }
      }
    );
  };

  /**
   *
   * @returns
   */
  const renderOrderSummary = () => {
    return contactCrudContext.order?.line_items.length ? (
      <Fragment>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            className={styles.orderStatusContactSummaryTypography}
            variant="h6"
          >
            {Vocabulary.orderSummary}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography>{Vocabulary.productName} </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Typography>{Vocabulary.quantity} </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Typography>{Vocabulary.price}</Typography>
        </Grid>
        {contactCrudContext.order?.line_items.map((line: any, index: any) => {
          return (
            <Fragment key={index}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography> {line.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Typography> {line.quantity}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Typography>
                  {`${
                    line?.total
                      ? parseFloat(line?.total)?.toFixed(2)?.toString()
                      : ""
                  } ${Config.currencySymbol}`}
                </Typography>
              </Grid>
            </Fragment>
          );
        })}
      </Fragment>
    ) : (
      <NoDataFound />
    );
  };

  return (
    <CustomPanel
      title={`${Vocabulary.order} ${
        contactCrudContext.order?.number
          ? contactCrudContext.order?.number
          : Vocabulary.new
      }`}
      actionButtons={
        <>
          {contactCrudContext.order?.number ? (
            <Link
              className={styles.contactOrderLink}
              to={`${localUrlEnum.order}/${contactCrudContext.order?._id}`}
            >
              <ToggleButton
                value="center"
                aria-label="centered"
                size="small"
                style={{ margin: 10 }}
              >
                <EditIcon fontSize="small" />
              </ToggleButton>
            </Link>
          ) : null}
        </>
      }
    >
      <div className={styles.contactOrderContainer}>
        {contactCrudContext.order ? (
          <Grid container spacing={0.5}>
            <Grid item xs={12} sm={12} md={12}>
              <StatusPanel
                selectedOption={contactCrudContext.order?.status}
                handleChange={handleChangeOrderStatus}
                allOptions={orderStatuses}
                disabled={disabled}
              />
            </Grid>
            {renderOrderSummary()}
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                className={styles.orderStatusContactSummaryTypography}
                variant="h6"
              >{`${Vocabulary.total}: ${parseFloat(
                contactCrudContext?.order?.total || Config.emptyOrderTotal
              )
                .toFixed(2)
                .toString()} ${Config.currencySymbol}`}</Typography>
            </Grid>
          </Grid>
        ) : (
          <div className={genericStyles.centeredElementColumn}>
            <Typography>{Vocabulary.order}</Typography>
            <Button
              disabled={disabled}
              onClick={createNewOrderForContact}
              color="blueGrey"
              variant="contained"
              startIcon={<Add />}
            >
              {Vocabulary.createOrder}
            </Button>
          </div>
        )}
      </div>
    </CustomPanel>
  );
}
