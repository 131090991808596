import noData from "../Images/noData.svg";
import genericStyles from "../Styles/genericStyles.module.css";
import { NoDataFoundProps } from "../Utils/Types";

function NoDataFound(props: NoDataFoundProps) {
  return (
    <div
      className={`${genericStyles.noDataFoundContainer} ${props.classNames}`}
    >
      <img
        alt="noData"
        src={noData}
        className={genericStyles.noDataFoundImage}
      />
      <p>{props.text}</p>
    </div>
  );
}

export default NoDataFound;
