import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import styles from "../../Styles/chat.module.css";
import { Add } from "@mui/icons-material";
import { useRef, useState } from "react";
import { MessagesAttachmentsOptions } from "../../Utils/Constants";
import { EWhatsAppMessageType } from "../../Utils/Enums";
import { MessagesAttachmentsProps } from "../../Utils/Types";

export default function MessagesAttachments(props: MessagesAttachmentsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const imageInputFile: any = useRef(null);
  const documentInputFile: any = useRef(null);

  /**
   *
   * @param e
   * @param type
   */
  const handleFileUpload = (e: any, type: string) => {
    const { files } = e.target;
    if (files && files.length) {
      props.setFile({ data: files[0], messageType: type });
    }
  };

  /**
   *
   * @param type
   */
  const onButtonClick = (type: string) => {
    if (type === EWhatsAppMessageType.IMAGE) imageInputFile.current.click();
    else if (type === EWhatsAppMessageType.DOCUMENT)
      documentInputFile.current.click();
  };

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    setAnchorEl(event?.currentTarget || null);
  };

  return (
    <div className={styles.addTemplateChatButtonContainer}>
      <IconButton
        onClick={handleClick}
        className={styles.addAttachmentButton}
      >
        <Add />
      </IconButton>
      <Popover
        sx={{ zIndex: 9999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClick(null)}
        id={id}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List className={styles.chatExtraActionList}>
          {MessagesAttachmentsOptions.map((option: any, index) => (
            <ListItem
              key={index}
              onClick={() => {
                onButtonClick(option.type);
              }}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText primary={option.label} />
              <input
                accept={option.accept}
                style={{ display: "none" }}
                ref={
                  option.type === EWhatsAppMessageType.IMAGE
                    ? imageInputFile
                    : documentInputFile
                }
                id={`contained-button-file-${index}`}
                onChange={(event: any) => handleFileUpload(event, option.type)}
                type="file"
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
}
