import { useEffect, useState } from "react";
import { MessageModel } from "../../Utils/Models";
import { Avatar, IconButton, Tooltip, Typography } from "@mui/material";
import { formatTimestamp, stringAvatar } from "../../Utils/Utils";
import Config from "../../Utils/Config";
import styles from "../../Styles/chat.module.css";
import { Reply } from "@mui/icons-material";
import { WhatsappMessageStatuses } from "../../Utils/Constants";
import { MessagesListProps } from "../../Utils/Types";
import { Vocabulary } from "../../Utils/Vocabulary";
import FileMessageExplorer from "./FileMessageExplorer";

export default function MessagesList(props: MessagesListProps) {
  const {
    messages,
    shouldScrollPage,
    replyToMessage,
    renderMessageContext,
    disabled,
  } = props;
  const [showReplyButton, setShowReplyButton] = useState<number | undefined>(
    undefined
  );

  /**
   *
   */
  useEffect(() => {
    if (!shouldScrollPage) {
      const messageBody: any = document.querySelector("#messagesContainer");
      if (messageBody)
        messageBody.scrollTop =
          messageBody?.scrollHeight - messageBody?.clientHeight;
    }
  }, [messages, shouldScrollPage]);

  /**
   *
   * @param message
   * @param index
   * @returns
   */
  const renderReplyButton = (message: MessageModel, index: any) => {
    return (
      showReplyButton === index && (
        <Tooltip title={Vocabulary.reply}>
          <IconButton
            disabled={disabled}
            onClick={() => replyToMessage(message)}
          >
            <Reply />
          </IconButton>
        </Tooltip>
      )
    );
  };

  return (
    <div className={`${styles.messageContainerPadding}`}>
      {messages.length &&
        messages?.map((message: MessageModel, index: number) =>
          !message.isUser ? (
            <div
              key={index}
              className={`${styles.messageItem} ${styles.authenticatedUserMessageContainer}`}
            >
              <div
                className={styles.replyMessageContainer}
                onMouseEnter={() => setShowReplyButton(index)}
                onMouseLeave={() => setShowReplyButton(undefined)}
              >
                {renderReplyButton(message, index)}
                <div className={`${styles.authenticatedUserMessages}`}>
                  {renderMessageContext(message?.context?.id)}
                  <div className={styles.messageContent}>
                    <Typography variant="caption">{`${
                      message?.sender || ""
                    }`}</Typography>
                    <FileMessageExplorer message={message} />

                    <div className={styles.replyMessageContainer}>
                      <Typography align="right" variant="caption">
                        {formatTimestamp(
                          message.timestamp,
                          Config.momentEUDateTimeFormat
                        )}
                      </Typography>
                    </div>
                  </div>
                  <Tooltip
                    className={styles.messageStatus}
                    title={WhatsappMessageStatuses[message?.status]?.name}
                  >
                    {WhatsappMessageStatuses[message?.status]?.icon}
                  </Tooltip>
                </div>
              </div>
              <Tooltip title={`${message?.sender || ""}`}>
                <Avatar {...stringAvatar(message.sender || "")} />
              </Tooltip>
            </div>
          ) : (
            <div
              key={index}
              className={`${styles.messageItem} ${styles.otherUsersMessageContainer}`}
            >
              <Tooltip title={`${message?.sender || ""}`}>
                <Avatar {...stringAvatar(message.sender || "")} />
              </Tooltip>
              <div
                className={styles.replyMessageContainer}
                onMouseEnter={() => setShowReplyButton(index)}
                onMouseLeave={() => setShowReplyButton(undefined)}
              >
                <div className={`${styles.otherUsersMessages}`}>
                  {renderMessageContext(message?.context?.id)}
                  <div className={styles.messageContent}>
                    <Typography variant="caption">{`${
                      message.sender || ""
                    }`}</Typography>
                    <FileMessageExplorer message={message} />
                    <Typography align="right" variant="caption">
                      {formatTimestamp(
                        message.timestamp,
                        Config.momentEUDateTimeFormat
                      )}
                    </Typography>
                  </div>
                </div>
                {renderReplyButton(message, index)}
              </div>
            </div>
          )
        )}
    </div>
  );
}
