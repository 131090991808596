import { Delete, ExpandMore, Tune } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useState } from "react";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Filter, FiltersProps } from "../../Utils/Types";
import { EComponentTypes } from "../../Utils/Enums";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Config from "../../Utils/Config";
import styles from "../../Styles/order.module.css";

export default function Filters(props: FiltersProps) {
  const { filters } = props;
  const [accordionState, setAccordionState] = useState<boolean>(true);
  const customSearchParams = useCustomSearchParam();

  /**
   *
   * @returns
   */
  const createValidationSchema = () => {
    const schemaFields = filters.reduce(
      (acc: any, { property, validation }) => {
        if (validation) {
          acc[property] = validation;
        }
        return acc;
      },
      {}
    );
    return yup.object().shape(schemaFields);
  };
  const filtersValidationSchema = createValidationSchema();
  const methods = useForm({
    resolver: yupResolver(filtersValidationSchema),
    defaultValues: filters.reduce((acc: any, { property, defaultValue }) => {
      acc[property] = defaultValue;
      return acc;
    }, {}),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  // Extract URL parameters to set initial form values
  const initialValues = filters.reduce(
    (acc: any, { property, defaultValue }) => {
      let paramValue = customSearchParams.searchParams.get(property);
      //if paramValue search for filter in filters
      if (paramValue && paramValue !== "") {
        const filter = filters?.find((filter) => filter.property === property);
        if (filter) {
          if (filter?.type === EComponentTypes.AUTOCOMPLETE) {
            //search for the value in the options array
            const option = filter?.options.find(
              (option: any) =>
                option[filter.autocompleteFilterProperty || "name"] ===
                paramValue
            );
            if (option) {
              paramValue = option;
            }
          }
        }
      }
      acc[property] = paramValue || defaultValue || "";
      return acc;
    },
    {}
  );

  useEffect(() => {
    methods.reset(initialValues);
  }, []);

  /**
   *
   * @returns
   */
  const renderFilterProps = () => {
    return filters.map((filter: Filter, index: number) => (
      <Grid item xs={12} md={6} lg={3} key={`${index}_filter`}>
        <Controller
          key={index}
          name={filter.property}
          control={methods.control}
          render={({ field: { ref, ...field }, fieldState: { error } }) =>
            renderComponentBasedOnType(filter, field, error)
          }
        />
      </Grid>
    ));
  };

  /**
   *
   * @param filter
   * @returns
   */
  const renderComponentBasedOnType = (
    filter: Filter,
    field: any,
    error: any
  ) => {
    switch (filter.type) {
      case EComponentTypes.DATE_PICKER:
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={filter.label}
              value={
                methods.getValues(filter.property)
                  ? moment(methods.getValues(filter.property))
                  : null
              }
              slotProps={{ field: { clearable: true } }}
              sx={{ width: "100%" }}
              onChange={(date: any) => {
                let newDate;
                if (!date) {
                  newDate = null;
                  field.onChange(newDate);
                } else {
                  newDate = moment(date).format(Config.momentUSDateFormat);
                  field.onChange(moment(date));
                }
                customSearchParams.addParamToQueryString(
                  filter.property,
                  newDate
                );
              }}
              // minDate={
              //   filter.property === "end" ? methods.getValues("start") : null
              // }
              // maxDate={
              //   filter.property === "start" ? methods.getValues("end") : null
              // }
              format={Config.momentEUDateFormat}
            />
          </LocalizationProvider>
        );
      case EComponentTypes.AUTOCOMPLETE:
        return (
          <Autocomplete
            freeSolo={false}
            fullWidth
            isOptionEqualToValue={(option, value) =>
              option?.name === value?.name || option === value
            }
            onChange={(event, value) => {
              field.onChange(value);
              customSearchParams.addParamToQueryString(
                filter.property,
                filter.autocompleteFilterProperty
                  ? value
                    ? value[filter.autocompleteFilterProperty] || value
                    : null
                  : null
              );
            }}
            value={methods.getValues(filter.property) || ""}
            getOptionLabel={(option: any) => {
              return option?.name || Vocabulary[option] || "";
            }}
            id={filter.property}
            options={filter.options || []}
            renderInput={(params) => (
              <TextField
                value={field?.value || " "}
                error={!!error}
                helperText={error?.message}
                label={filter.label}
                name={filter.property}
                {...params}
              />
            )}
          />
        );
      default:
        return <></>;
    }
  };

  /**
   *
   */
  const resetFilters = () => {
    // Extract the properties that need to be reset
    const filterKeys = filters.map(({ property }) => property);
    // Remove the filter parameters from the query string
    customSearchParams.removeParamFromQueryString(filterKeys);
    // Create an object to hold the reset values
    const resetValues = filters.reduce(
      (acc: any, { property, defaultValue }) => {
        acc[property] = defaultValue || ""; // Use default value or empty string if not provided
        return acc;
      },
      {}
    );
    //delete search param as well
    customSearchParams.removeParamFromQueryString(["search"]);
    // Reset the form values with the reset values
    methods.reset(resetValues);
  };

  return (
    <Accordion
      onChange={() => {
        setAccordionState(!accordionState);
      }}
      expanded={accordionState}
      style={{ margin: "0px 0px 20px 0px", width: "100%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Tune style={{ marginRight: 10 }}></Tune>
        <Typography style={{ marginTop: 1 }}>{Vocabulary.filters}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} style={{ marginBottom: 10 }}>
          {renderFilterProps()}
          <div className={styles.deleteButton}>
            <Tooltip title={Vocabulary.reset}>
              <IconButton className={styles.deleteIcon} onClick={resetFilters}>
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
