import { Button, Grid, Switch, Typography } from "@mui/material";
import Config from "../../Utils/Config";
import { ClientSettingsValidationSchema } from "../../Utils/Models";
import { Controller, useForm } from "react-hook-form";
import { useContext, useEffect } from "react";
import { WebSocketContext } from "../../Context/WebSocketContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { getData } from "../../Services/getData";
import { AxiosResponse } from "axios";
import { endpoints } from "../../Utils/UrlEnum";
import { updateData } from "../../Services/updateData";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Save } from "@mui/icons-material";
import styles from "../../Styles/settings.module.css";

export default function ClientsSettings() {
  const websocketContext = useContext(WebSocketContext);
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(ClientSettingsValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    const newMethod = Object.assign({}, data);
    const url = `${endpoints.setting.updateClientsSettings}${newMethod?._id}`;
    updateData(url, newMethod).then((res: AxiosResponse) => {
      if (res?.data) {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        reset(res.data);
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    getData(endpoints.setting.clients).then((res: AxiosResponse) => {
      if (res?.data) {
        reset(res.data);
      }
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.settingsForm}>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12} lg={10.9}>
          <Typography>{Vocabulary.shouldSendEmailToAllClients}</Typography>
          <Controller
            name={`value.shouldSendEmailToAllClients`}
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <Switch onChange={onChange} checked={value} inputRef={ref} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={1} className={styles.oblioSettingsButtonContainer}>
          <Button
            type="submit"
            fullWidth
            startIcon={<Save />}
            variant="contained"
          >
            {Vocabulary.save}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
