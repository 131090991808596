import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { TemplateHeaderComponentProps } from "../../Utils/Types";
import { Controller } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import { HeaderTemplateMenuOptions } from "../../Utils/Constants";
import { EHeaderTemplateMenuOptions } from "../../Utils/Enums";
import DropzoneComponent from "../DropzoneComponent";
import WhatsAppTagManagementComponent from "./WhatsAppTagManagementComponent";
import { endpoints } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import styles from "../../Styles/whatsApp.module.css";
import { Visibility } from "@mui/icons-material";
import Config from "../../Utils/Config";

export default function TemplateHeaderComponent(
  props: TemplateHeaderComponentProps
) {
  const { methods } = props;

  /**
   *
   * @param file
   */
  const uploadMedia = (file: any, field: any) => {
    const formData = new FormData() as any;
    formData.append("file", file, file.name);
    postData(endpoints.whatsApp.uploadMedia, formData).then((response) => {
      if (response && response.data) {
        field.onChange(response.data.h);
      }
    });
  };

  const renderHeaderOptions = () => {
    const headerFormat = methods.watch("components.0.format");
    switch (headerFormat) {
      case EHeaderTemplateMenuOptions.TEXT:
        return (
          <Grid container spacing={Config.standardGridSpacing}>
            <WhatsAppTagManagementComponent
              methods={methods}
              maxNrOfTags={1}
              maxlengthMainString={60}
              mainProperty="components.0.text"
              examplePropertyName={`components.0.example.header_text`}
              children={
                <TextField
                  {...methods.register("components.0.text")}
                  name="components.0.text"
                  id="components.0.text"
                  inputProps={{
                    maxLength: "60",
                  }}
                  label={Vocabulary.enterTextInSelectedLanguage}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{`${
                        methods.getValues("components.0.text")?.length
                      }/60`}</InputAdornment>
                    ),
                  }}
                  error={
                    typeof methods.formState.errors.components?.[0] === "object"
                  }
                  helperText={
                    methods.formState.errors.components?.[0]?.text?.message?.toString() ||
                    ""
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              }
            />
          </Grid>
        );
      case EHeaderTemplateMenuOptions.IMAGE:
        return (
          <Controller
            name="components.0.example.header_handle[0]"
            defaultValue={""}
            control={methods.control}
            render={({ field }) => (
              <DropzoneComponent
                altText={EHeaderTemplateMenuOptions.IMAGE}
                acceptedFileTypes={{
                  "image/jpeg": [],
                  "image/png": [],
                  "image/jpg": [],
                }}
                onSave={(file: any) => uploadMedia(file, field)}
                path={field.value}
              />
            )}
          />
        );
      case EHeaderTemplateMenuOptions.DOCUMENT:
        return (
          <Controller
            name="components.0.example.header_handle[0]"
            defaultValue={""}
            control={methods.control}
            render={({ field }) => (
              <Fragment>
                <DropzoneComponent
                  altText={EHeaderTemplateMenuOptions.DOCUMENT}
                  acceptedFileTypes={{
                    "application/pdf": [],
                  }}
                  onSave={(file: any) => uploadMedia(file, field)}
                  path={field.value}
                />
                {field.value && (
                  <div className={styles.previewDocumentButtonContainer}>
                    <Button
                      className={styles.previewDocumentButton}
                      variant="contained"
                      color="secondary"
                      disabled={!field.value}
                      startIcon={<Visibility />}
                    >
                      <Link
                        className={styles.previewDocumentLink}
                        to={field.value}
                        target="_blank"
                      >
                        {Vocabulary.previewDocument}
                      </Link>
                    </Button>
                  </div>
                )}
              </Fragment>
            )}
          />
        );
      case EHeaderTemplateMenuOptions.VIDEO:
        return (
          <Controller
            name="components.0.example.header_handle[0]"
            defaultValue={""}
            control={methods.control}
            render={({ field }) => (
              <Fragment>
                <DropzoneComponent
                  altText={EHeaderTemplateMenuOptions.VIDEO}
                  acceptedFileTypes={{
                    "video/mp4": [],
                  }}
                  onSave={(file: any) => uploadMedia(file, field)}
                  path={field.value}
                />
                <div className={styles.previewDocumentButtonContainer}>
                  <Button
                    className={styles.previewDocumentButton}
                    variant="contained"
                    color="secondary"
                    startIcon={<Visibility />}
                  >
                    <Link
                      className={styles.previewDocumentLink}
                      to={field.value}
                      target="_blank"
                    >
                      {Vocabulary.previewVideo}
                    </Link>
                  </Button>
                </div>
              </Fragment>
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={Config.standardGridSpacing}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Controller
          name="components.0.format"
          defaultValue={"NONE"}
          control={methods.control}
          render={({ field }) => (
            <Select labelId="level-label" {...field}>
              {HeaderTemplateMenuOptions.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {renderHeaderOptions()}
      </Grid>
    </Grid>
  );
}
