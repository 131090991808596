import MenuIcon from "@mui/icons-material/Menu";
import {
  AutocompleteChangeReason,
  Button,
  Fab,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useState, createContext } from "react";
import IconButton from "@mui/material/IconButton";
import styles from "../Styles/mainHeader.module.css";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { localUrlEnum, endpoints } from "../Utils/UrlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import { logout } from "../Utils/Utils";
import useNoActivity from "../Hooks/useNoActivity";
import GenericModal from "./GenericModal";
import { UserModel } from "../Utils/Models";
import UserForm from "./Users/UserForm";
import { getData } from "../Services/getData";
import genericStyles from "../Styles/genericStyles.module.css";
import { EWidths } from "../Utils/Enums";
import SearchComponent from "./SearchComponent";
import { MainHeaderProps } from "../Utils/Types";
import { FormsIds } from "../Utils/Constants";
import NewMessageAnimated from "./Messages/NewMessageAnimated";
import { Info } from "@mui/icons-material";

export const CRUDContext = createContext(new UserModel());

let logoutTimeout: any;

export default function MainHeader(props: MainHeaderProps) {
  const [contextValue, setContextValue] = useState(new UserModel());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const hasActivity = useNoActivity();
  const open = Boolean(anchorEl);
  const [openModalUser, setOpenModalUser] = useState(false);
  const userId = localStorage.getItem("userId");

  /**
   *
   */
  useEffect(() => {
    if (!hasActivity) {
      logoutTimeout = setTimeout(() => {
        handleLogout();
      }, 1000 * 60 * 5);
    } else {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }
    }
  }, [hasActivity]);

  /**
   *
   * @returns
   */
  async function getUserInfo() {
    const url = `${endpoints.usersEndpoint}/${userId}`;
    const res = (await getData(url)) as any;
    if (!res || res?.error) {
      return;
    }
    setContextValue(res?.data);
  }

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   */
  const handleLogout = async () => {
    setAnchorEl(null);
    logout();
    navigate(localUrlEnum.login);
  };

  /**
   *
   */
  const redirectToSettings = () => {
    setAnchorEl(null);
    setOpenModalUser(true);
  };

  const userName = localStorage.getItem("userName");

  return (
    <CRUDContext.Provider value={contextValue}>
      <div className={styles.container}>
        <div className={styles.secondContainer}>
          <div className={styles.iconMenu}>
            <IconButton
              style={{ backgroundColor: "#fff" }}
              aria-label="add"
              onClick={() => props.setHideSlider()}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div style={{ marginLeft: 10 }}>
            <a href="/">
              <img src={"/logo.png"} alt="EcoPack" width={"34%"} />
            </a>
          </div>
        </div>
        {props.shouldRenderSearchBar && (
          <div className={genericStyles.searchField}>
            <SearchComponent />
          </div>
        )}
        <div className={styles.secondContainer}>
          <Typography className={styles.username}>{userName}</Typography>
          <Fab
            onClick={(event: any) => {
              getUserInfo();
              handleClick(event);
            }}
            size="small"
            className={styles.userSettingsAvatar}
            aria-label="add"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AccountCircleIcon />
          </Fab>
          <NewMessageAnimated />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={redirectToSettings}>
              <Avatar />
              {Vocabulary.myAccount}
            </MenuItem>
            <Divider />

            <MenuItem>
              <ListItemIcon>
                <Info fontSize="small" />
              </ListItemIcon>
              <a
                style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.80" }}
                href="intro.html"
                target="_blank"
              >
                {Vocabulary.userManual}
              </a>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {Vocabulary.logout}
            </MenuItem>
          </Menu>
          <GenericModal
            onClose={() => {
              setOpenModalUser(false);
              setContextValue(new UserModel());
            }}
            maxWidth={EWidths.MD}
            open={openModalUser}
            actions={
              <div className={genericStyles.modalActions}>
                <Button
                  color="secondary"
                  onClick={() => {
                    setOpenModalUser(false);
                    setContextValue(new UserModel());
                  }}
                  variant="outlined"
                  fullWidth
                >
                  {Vocabulary.cancel}
                </Button>
                <Button
                  fullWidth
                  type="submit"
                  form={FormsIds.usersForm}
                  variant="contained"
                >
                  {Vocabulary.save}
                </Button>
              </div>
            }
            title={Vocabulary.editModalTitle}
          >
            <UserForm
              setContextValue={(
                e: any,
                client: UserModel | null,
                reason: AutocompleteChangeReason,
                details?: any
              ) => ({})}
              selectedUser={CRUDContext}
              onClose={() => {
                setOpenModalUser(false);

                setContextValue(new UserModel());
              }}
            />
          </GenericModal>
        </div>
      </div>
    </CRUDContext.Provider>
  );
}
