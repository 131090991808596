import { Button, Drawer, Typography } from "@mui/material";
import { CustomDrawerProps } from "../Utils/Types";
import { Close } from "@mui/icons-material";
import { Vocabulary } from "../Utils/Vocabulary";

import styles from "../Styles/customDrawer.module.css";

export default function CustomDrawer(props: CustomDrawerProps) {
  const {
    open,
    handleDrawerClose,
    children,
    title,
    formId,
    okText,
    cancelText,
    className,
  } = props;
  return (
    <Drawer
      className={className}
      anchor={"right"}
      open={open}
      onClose={handleDrawerClose}
    >
      <Close
        className={styles.customDrawerCloseIcon}
        onClick={handleDrawerClose}
      />
      <div className={styles.customDrawerContainer}>
        <div className={styles.customDrawerTitle}>
          <Typography variant="h4">{title}</Typography>
        </div>
        <br />
        {children}
        <br />
      </div>
      <div className={styles.customDrawerModalActions}>
        <Button
          variant="contained"
          className={styles.customDrawerCancelButton}
          fullWidth
          color="secondary"
          onClick={handleDrawerClose}
        >
          {cancelText ? cancelText : Vocabulary.cancel}
        </Button>
        <Button
          type="submit"
          form={formId}
          variant="contained"
          className={styles.customDrawerSaveButton}
          fullWidth
          color="primary"
        >
          {okText ? okText : Vocabulary.save}
        </Button>
      </div>
    </Drawer>
  );
}
