import { useState, createContext } from "react";
import genericStyles from "../../Styles/genericStyles.module.css";
import MUIDataTableCustomStyle from "../../Components/MUIDataTableCustomStyle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { endpoints } from "../../Utils/UrlEnum";
import { ClientModel } from "../../Utils/Models";
import { Button, Chip } from "@mui/material";
import { Address, Filter } from "../../Utils/Types";
import ClientForm from "./ClientForm";
import UploadIcon from "@mui/icons-material/Upload";
import ImportExcelModal from "../ImportExcelModal";
import { ClientExcelHeader, FormsIds, TablesIds } from "../../Utils/Constants";
import { postData } from "../../Services/postData";
import { EComponentTypes, ETableEditAction, EWidths } from "../../Utils/Enums";
import GenericModal from "../GenericModal";
import { checkIfStringContainsOnlyOneChar } from "../../Utils/Utils";
import { AxiosResponse } from "axios";
import Filters from "../Widgets/Filters";
import * as yup from "yup";
import useCustomMemo from "../../Hooks/useCustomMemo";

export const CRUDContext = createContext(new ClientModel());

export default function ClientsTable() {
  const [contextValue, setContextValue] = useState(new ClientModel());
  const [openImportModal, setOpenImportModal] = useState(false);
  const [triggerReload, setTriggerReload] = useState<undefined | boolean>();
  const [open, setOpen] = useState(false);
  const memo = useCustomMemo();
  const clientCategories = memo?.storage.clientCategories || [];
  const filters: Filter[] = [
    {
      property: "category",
      label: Vocabulary.category,
      type: EComponentTypes.AUTOCOMPLETE,
      validation: yup.mixed().nullable(),
      defaultValue: null,
      options: clientCategories,
      autocompleteFilterProperty: "_id",
    },
    {
      property: "contactDate",
      label: Vocabulary.contactDate,
      type: EComponentTypes.DATE_PICKER,
      validation: yup.string().required(Vocabulary.requiredField),
      defaultValue: null,
    },
  ];
  const clientsColumns = [
    {
      label: `${Vocabulary?.name}`,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.email}`,
      name: "email",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.address}`,
      name: "address",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (address: Address) => {
          return <div>{address.city}</div>;
        },
      },
    },
    {
      label: `${Vocabulary.phone}`,
      name: "phone",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
          minHeight: 100,
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (phones: []) => {
          return (
            <>
              {phones?.map((phone: string) =>
                phone ? (
                  <Chip
                    color="primary"
                    key={phone}
                    label={phone}
                    style={{
                      margin: 5,
                    }}
                  />
                ) : null
              )}
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.category}`,
      name: "category",
      options: {
        sort: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
          minHeight: 100,
        }),
        filter: false,
        customBodyRender: (categories: []) => {
          return (
            <>
              {categories?.map((category: any) =>
                category ? (
                  <Chip
                    key={category.name}
                    label={category.name}
                    style={{
                      margin: 5,
                      backgroundColor: "#88aa91",
                    }}
                  />
                ) : null
              )}
            </>
          );
        },
      },
    },
  ];

  /**
   *
   * @param data
   */
  const importClients = (data: any) => {
    postData(endpoints.importClients, data).then((res: AxiosResponse) => {
      if (res) {
        setOpenImportModal(false);
        setTriggerReload(triggerReload !== undefined ? !triggerReload : false);
      }
    });
  };

  /**
   *
   * @param row
   * @returns
   */
  function getNewClientRow(row: any) {
    return {
      name: row.CLIENT,
      category: row.CATEGORIE,
      periodicity: row.SAPTAMANA,
      email: row.EMAIL,
      fiscalCode: row.COD_FISCAL,
      tradeRegisterNo: checkIfStringContainsOnlyOneChar(
        row.NR_REGISTRU_COMERT,
        "-"
      ),
      observations: row.OBSERVATII,
      addressDetails: row.ADRESA,
      city: row.ORAS,
      county: row.JUDET,
      country: row.TARA,
      bank: row.BANCA,
      iban: row.CONT_BANCAR,
      contactPerson: row.PERSOANA_DE_CONTACT,
      phone: row.TELEFON,
    };
  }

  return (
    <div className={genericStyles.mainContainer}>
      <Filters filters={filters} />
      <CRUDContext.Provider value={contextValue}>
        <MUIDataTableCustomStyle
          onEditAction={ETableEditAction.Modal}
          buttons={
            <Button
              onClick={() => setOpenImportModal(true)}
              style={{ marginLeft: 10 }}
              variant="contained"
            >
              <UploadIcon />
              <div style={{ marginLeft: 5 }}>{Vocabulary.import}</div>
            </Button>
          }
          title={Vocabulary.clients}
          columns={clientsColumns}
          shouldTriggerStorageUpdate={true}
          CRUDEndPoint={endpoints.clientsEndpoint}
          handleModalForm={setOpen}
          id={TablesIds.clients}
          addOrEditForm={
            <GenericModal
              onClose={() => {
                setOpen(false);
                setContextValue(new ClientModel());
              }}
              maxWidth={EWidths.MD}
              open={open}
              actions={
                <div className={genericStyles.modalActions}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setOpen(false);
                      setContextValue(new ClientModel());
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {Vocabulary.cancel}
                  </Button>
                  <Button
                    fullWidth
                    type="submit"
                    form={FormsIds.clients}
                    variant="contained"
                  >
                    {Vocabulary.save}
                  </Button>
                </div>
              }
              title={Vocabulary.editModalTitle}
            >
              <ClientForm
                selectedClient={new ClientModel()}
                onClose={() => {
                  setOpen(false);
                  setTriggerReload(
                    triggerReload !== undefined ? !triggerReload : false
                  );
                  setContextValue(new ClientModel());
                }}
              />
            </GenericModal>
          }
          setContextValue={setContextValue}
          triggerReload={triggerReload}
        ></MUIDataTableCustomStyle>
        <ImportExcelModal
          headers={ClientExcelHeader}
          title={`${Vocabulary.import} ${Vocabulary.clients}`}
          openImportModal={openImportModal}
          setOpenImportModal={setOpenImportModal}
          getNewRow={getNewClientRow}
          getRowsAsArrayData={(data: any) => importClients(data)}
        />
      </CRUDContext.Provider>
    </div>
  );
}
