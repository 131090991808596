/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */

import { Button, InputLabel, Menu, MenuItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { Vocabulary } from "../../Utils/Vocabulary";
import { isMobile, setFontColorBasedOnBackground } from "../../Utils/Utils";
import { StatusPanelType } from "../../Utils/Types";

export default function StatusPanel(props: StatusPanelType) {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const [statusArray, setStatusArray] = useState<any>([]);
  const [status, setStatus]: any = useState({
    id: "",
    name: "",
    color: "",
  });

  //set statuses
  useEffect(() => {
    if (props.allOptions) {
      const array = props.allOptions.filter((status: any) => status);
      setStatusArray(array);
    }
  }, []);

  //set statuses
  useEffect(() => {
    if (props.selectedOption) setStatus(props.selectedOption);
  }, [props.selectedOption]);

  /**
   *
   * @param event
   */
  function handleClick(
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  }

  /**
   *
   * @param st
   */
  function handleChange(st?: any) {
    if (st) {
      setStatus(st);
      props.handleChange(st);
    }
    setAnchorEl(null);
  }

  return (
    <>
      <Tooltip title={Vocabulary.pressTocChangeStatus} placement="top">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: status?.color,
            width: "auto",
            cursor: "pointer",
            boxShadow:
              "#4444 1px 1px 6px 1px inset, #4444 1px 1px 1px 1px inset",
            // boxShadow: " rgba(0, 0, 0, 0.5) 0px 3px 12px",
            padding: 10,

            borderRadius: 5,
          }}
          onClick={(e) => (props.disabled ? null : handleClick(e))}
        >
          <InputLabel
            style={{
              marginRight: 20,
              fontSize: 15,
              color: setFontColorBasedOnBackground(status?.color),
              // textShadow: "1px 1px 2px rgb(0,0,0), 0 1px 10px rgb(0,0,0)",
              fontWeight: 800,
              padding: 10,
            }}
          >
            {Vocabulary.status.toUpperCase()}
          </InputLabel>
          {status.name ? (
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: status?.color,
                color: setFontColorBasedOnBackground(status?.color),
                boxShadow: "none",
                // textShadow: "1px 1px 2px rgb(0,0,0), 0 1px 10px rgb(0,0,0)",
                fontWeight: 800,
              }}
            >
              {status.name}
            </Button>
          ) : null}

          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={() => handleChange()}
            PaperProps={{
              style: {
                width: isMobile() ? "100%" : "25%",
              },
            }}
          >
            {statusArray?.map((stEnum: any, index: number) => (
              <MenuItem
                key={`${stEnum.id}-${stEnum.name}`}
                onClick={() => handleChange(stEnum)}
              >
                {stEnum?.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Tooltip>
    </>
  );
}
