import { Divider, Grid, Typography } from "@mui/material";
import OrderSettings from "../Components/Settings/OrderSettings";
import { Vocabulary } from "../Utils/Vocabulary";
import styles from "../Styles/settings.module.css";
import ShippingSettings from "../Components/Settings/TransportSettings";
import OblioSettings from "../Components/Settings/OblioSettings";
import WhatsAppSettings from "../Components/Settings/WhatsAppSettings";
import ContactSettings from "../Components/Settings/ContactSettings";
import ClientsSettings from "../Components/Settings/ClientsSettings";

export default function Settings() {
  const handleSettingTitle = (title: string) => {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5" sx={{ fontWeight: 300 }}>
          {title}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={styles.settingContainerGrid}
        >
          <Typography variant="h4">{Vocabulary.settings}</Typography>
        </Grid>
        {/** Order Settings */}
        {handleSettingTitle(Vocabulary.order)}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={styles.settingContainerGrid}
        >
          <OrderSettings />
        </Grid>
        {/** Shipping Settings */}
        {handleSettingTitle(Vocabulary.shipping)}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={styles.settingContainerGrid}
        >
          <ShippingSettings />
        </Grid>
        {/** Contact Settings */}
        {handleSettingTitle(Vocabulary.contacts)}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={styles.settingContainerGrid}
        >
          <ContactSettings />
        </Grid>
        {/** Clients Settings */}
        {handleSettingTitle(Vocabulary.clients)}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={styles.settingContainerGrid}
        >
          <ClientsSettings />
        </Grid>
        {/** WhatsApp Settings */}
        {handleSettingTitle(Vocabulary.whatsApp)}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={styles.settingContainerGrid}
        >
          <WhatsAppSettings />
        </Grid>
        {/** Oblio Settings */}
        {handleSettingTitle(Vocabulary.oblio)}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={styles.settingContainerGrid}
        >
          <OblioSettings />
        </Grid>
      </Grid>
    </div>
  );
}
