import Config from "./Config";

export const endpoints = {
  login: `${Config.apiUrl}/auth/login`,
  logout: `${Config.apiUrl}/auth/signout`,
  usersEndpoint: `${Config.apiUrl}/user`,
  ordersEndpoint: `${Config.apiUrl}/order`,
  clientsEndpoint: `${Config.apiUrl}/client`,
  clientSearchEndpoint: `${Config.apiUrl}/client/search/`,
  getProductsSearch: `${Config.apiUrl}/product/search`,
  orderOfferEndpoint: `${Config.apiUrl}/orderOffer`,
  getPublicProductsSearch: `${Config.apiUrl}/orderOffer/searchProducts`,
  getPublicPaymentMethods: `${Config.apiUrl}/orderOffer/paymentMethods`,
  getOrderOfferForClient: `${Config.apiUrl}/orderOffer/order`,
  getPublicTransportOptions: `${Config.apiUrl}/orderOffer/transportOptions`,
  media: `${Config.apiUrl}/media`,
  rolesEndpoint: `${Config.apiUrl}/role`,
  periodicityNamesEndpoint: `${Config.apiUrl}/client/periodicityNames`,
  getClientCategory: `${Config.apiUrl}/clientCategory`,
  orderStatus: `${Config.apiUrl}/orderStatus`,
  importClients: `${Config.apiUrl}/client/import`,
  getProducts: `${Config.apiUrl}/product`,
  syncWordPressData: `${Config.apiUrl}/product/sync`,
  syncWordPressDataOrders: `${Config.apiUrl}/order/sync`,
  emailTemplatesEndpoint: `${Config.apiUrl}/emailTemplate`,
  duplicateOrder: `${Config.apiUrl}/order/duplicate/`,
  contactEndpoint: `${Config.apiUrl}/contact`,
  getDashboardSummaryContacts: `${Config.apiUrl}/contact/dashboard`,
  getAvailableContacts: `${Config.apiUrl}/contact/available`,
  getTakenContacts: `${Config.apiUrl}/contact/taken`,
  associateAgentWithContact: `${Config.apiUrl}/contact/associateAgent`,
  updateOrderNotes: `${Config.apiUrl}/order/notes`,
  updateContactNotes: `${Config.apiUrl}/contact/notes`,
  transportOption: `${Config.apiUrl}/transportOption`,
  mediaEndpoint: `${Config.apiUrl}/media`,
  getANAFDataAboutClient: `${Config.apiUrl}/client/anaf`,
  whatsAppMetaUrl: "https://graph.facebook.com/v19.0/",
  audit: {
    main: `${Config.apiUrl}/audit`,
  },
  oblio: {
    main: `${Config.apiUrl}/oblio`,
    syncProducts: `${Config.apiUrl}/oblio/syncProducts`,
    managementNomenclator: `${Config.apiUrl}/oblio/managementNomenclator`,
    cancel: `${Config.apiUrl}/oblio/cancel`,
  },
  whatsApp: {
    main: `${Config.apiUrl}/whatsApp`,
    waba: `${Config.apiUrl}/whatsApp/waba`,
    createPhoneNumber: `${Config.apiUrl}/whatsApp/createPhoneNumber`,
    registerPhoneNumber: `${Config.apiUrl}/whatsApp/registerPhoneNumber`,
    template: `${Config.apiUrl}/whatsApp/template`,
    uploadMedia: `${Config.apiUrl}/whatsApp/uploadMediaContentForHeader`,
    message: `${Config.apiUrl}/whatsApp/message`,
    templateMessage: `${Config.apiUrl}/whatsApp/templateMessage`,
    image: `${Config.apiUrl}/whatsApp/image`,
    sendFileMessage: `${Config.apiUrl}/whatsApp/sendFileMessage`,
  },
  conversation: {
    main: `${Config.apiUrl}/conversation`,
    markConversationAsRead: `${Config.apiUrl}/conversation/markConversationAsRead`,
    unreadConversations: `${Config.apiUrl}/conversation/unreadConversations`,
    associate: `${Config.apiUrl}/conversation/associate`,
  },
  setting: {
    main: `${Config.apiUrl}/setting/`,
    orderSettings: `${Config.apiUrl}/setting/orderSettings/`,
    orderSettingsNumber: `${Config.apiUrl}/setting/orderSettingsNumber/`,
    oblio: `${Config.apiUrl}/setting/oblioSettings/`,
    contact: `${Config.apiUrl}/setting/contactSettings/`,
    clients: `${Config.apiUrl}/setting/clientsSettings/`,
    updateClientsSettings: `${Config.apiUrl}/setting/clients/`,
  },
  documentation: {
    main: `${Config.apiUrl}/documentation`,
  },
};

export const localUrlEnum: any = {
  login: "/login",
  users: "/users?",
  clients: "/clients?",
  reports: "/reports",
  orders: "/orders?",
  settings: "/settings",
  messages: "/messages",
  orderStatus: "/orderStatus?",
  order: "/orders/order",
  client: "/clients/client",
  product: "/products/product",
  mainRoute: "/",
  dashboard: "/dashboard",
  whatsAppPhoneNumbers: "/whatsApp/phoneNumbers",
  whatsAppTemplates: "/whatsApp/templates",
  whatsAppTemplate: "/whatsApp/template",
  pageNotFound: "*",
  renderChildren: "",
  logs: "/logs",
  products: "/products?",
  contacts: "/contacts",
  emailTemplates: "/emailTemplates?",
  orderOfferPasswordCheck: "/orderOfferPasswordCheck",
  orderOffer: "/orderOffer",
  orderOfferConfirmation: "/orderOfferConfirmation",
};

export const basicOperationsEnum = {
  create: "/create",
  update: "/update",
  delete: "/delete",
  import: "/import",
  export: "/export",
};

export const pathParams = {
  optionalId: "/:id?",
  orderId: "/:orderId",
  page: "/:page",
};
