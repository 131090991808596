import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid, TextField, Typography } from "@mui/material";
import { FormsIds } from "../../Utils/Constants";
import { postData } from "../../Services/postData";
import { endpoints } from "../../Utils/UrlEnum";
import Config from "../../Utils/Config";
import { TwoFactorAuthenticationSetupSchema } from "../../Utils/Models";

export default function TwoFactorAuthenticationSetupStep() {
  const methods = useForm({
    resolver: yupResolver(TwoFactorAuthenticationSetupSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   * @param data
   */
  const handleSubmit = (data: any) => {
    postData(endpoints.whatsApp.registerPhoneNumber, data).then((response) => {
      if (response && response.data) {
        // console.log(response.data);
      }
    });
  };

  /**
   *
   * @param error
   */
  const handleError = (error: any) => {
    // console.log(error);
  };

  return (
    <form
      id={FormsIds.twoFactorAuthentication}
      onSubmit={methods.handleSubmit(handleSubmit, handleError)}
    >
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body2">
            {Vocabulary.twoStepVerificationPinHelperText}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            {...methods.register("pin")}
            id="pin"
            name="pin"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            label={Vocabulary.pin}
            error={typeof methods.formState.errors.pin === "object"}
            helperText={methods.formState.errors.pin?.message?.toString()}
          />
        </Grid>
      </Grid>
    </form>
  );
}
