/* eslint-disable no-prototype-builtins */
import moment, { Moment } from "moment";
import "moment/locale/ro";
import { ResolutionBreakPoints } from "./Constants";
import { EWordpressStockStatus } from "./Enums";
import { Vocabulary } from "./Vocabulary";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import axios, { AxiosResponse } from "axios";
moment.locale("ro");
/**
 *
 * @param model
 * @param propertiesArray
 * @returns
 */
export function generateFormData(model: any) {
  const formData = new FormData();

  for (const property in model) {
    if (typeof model[property] === "boolean")
      formData.append(property, model[property]);
    else formData.append(property, model[property] ? model[property] : null);
  }

  return formData;
}

/**
 *
 */
export function logout() {
  localStorage.removeItem("userId");
  localStorage.removeItem("fullName");
  localStorage.removeItem("access_token");
  localStorage.removeItem("storage");
  localStorage.removeItem("userName");
  localStorage.removeItem("userRoles");
  localStorage.removeItem("managements");
  localStorage.removeItem("orderOfferPassword");
}

/**
 *
 * @param model Main model (object)
 * @param completedData The object that is completed and can have undefined props
 * @returns New Filtered Object (Return is present to be easier to understand what the function will return)
 */
export const mergeObjects = (model: any, completedData: any) => {
  for (const idx in completedData) {
    if (completedData[idx] !== undefined) model[idx] = completedData[idx];
  }
  return model;
};

/**
 *
 * @param discount
 * @param quantity
 * @param unitPrice
 * @returns
 */
export const calcValue = (discount: any, quantity: any, unitPrice: any) => {
  const totalPrice = parseFloat(quantity) * parseFloat(unitPrice);
  const totalPriceWithDiscount =
    (parseFloat(quantity) * parseFloat(unitPrice) * parseFloat(discount)) / 100;
  const total = totalPrice - totalPriceWithDiscount;

  return isNaN(total) ? 0 : total;
};

/**
 *
 * @param wordpressPrice
 * @param tva
 * @returns
 */
export const calcPriceWithoutTVA = (wordpressPrice: any, tva: any) => {
  const priceWithoutTVA =
    (parseFloat(wordpressPrice) * 10000) /
    ((1 + parseFloat(tva) / 100) * 10000);
  return isNaN(priceWithoutTVA) ? 0 : priceWithoutTVA;
};

/**
 *
 * @param wordpressPrice
 * @param tva
 * @returns
 */
export const calcTVAValue = (wordpressPrice: any, tva: any) => {
  const priceWithoutTVA =
    wordpressPrice - parseFloat(wordpressPrice) / (1 + parseFloat(tva) / 100);
  return isNaN(priceWithoutTVA) ? 0 : priceWithoutTVA;
};

/**
 *
 * @param priceWithoutTVA
 * @param tva
 * @returns
 */
export const calcTVAValueFromPriceWithoutTVA = (
  priceWithoutTVA: any,
  tva: any
) => {
  const newPriceWithoutTVA = (tva / 100) * priceWithoutTVA;
  return isNaN(newPriceWithoutTVA) ? 0 : newPriceWithoutTVA;
};
/**
 *
 * @param value
 * @param tva
 * @returns
 */
export const calcValueWithTVA = (value: any, tva: any) => {
  const valueWithTVA =
    parseFloat(value) + parseFloat(value) * (parseFloat(tva) / 100);
  return isNaN(valueWithTVA) ? 0 : valueWithTVA;
};

/**
 *
 * @param price
 * @param tva
 * @returns
 */
export const calculatePriceWithTVA = (price: any, tva: any) => {
  const valueWithTVA =
    parseFloat(price) + parseFloat(price) * (parseFloat(tva) / 100);
  if (isNaN(valueWithTVA)) return 0;
  //  check number of decimals in valueWithTVA
  const decimals = valueWithTVA.toString().split(".")[1]?.length;
  //if the number of decimals is greater than 2, round the value
  return decimals > 2 ? getRoundedValue(valueWithTVA, 2) : valueWithTVA;
  // return valueWithTVA;
};

/**
 *
 * @param value
 * @param decimals
 * @returns
 */
const getRoundedValue = (value: any, decimals: number) => {
  if (isNaN(value)) return 0;
  return (Math.round((value + Number.EPSILON) * 100) / 100).toFixed(decimals);
};

/**
 *
 * @param unitPrice
 * @param quantity
 * @returns
 */
export const calculateTotal = (unitPrice: any, quantity: any) => {
  //parse values to float
  const unitPriceFloat = parseFloat(unitPrice);
  const quantityFloat = parseFloat(quantity);
  //calculate total
  const total = unitPriceFloat * quantityFloat;
  //return total
  return isNaN(total) ? 0 : total;
};

/**
 *
 * @param discount
 * @param quantity
 * @param unitPrice
 * @returns
 */
export const calcDiscount = (discount: any, quantity: any, unitPrice: any) => {
  const subTotal =
    (parseFloat(quantity) * parseFloat(unitPrice) * parseFloat(discount)) / 100;
  return isNaN(subTotal) ? 0 : subTotal;
};

/**
 *
 * @param file
 * @returns
 */
export function resizeImage(file: any) {
  const promise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (readerEvent) {
        const image = new Image();
        image.src = readerEvent.target?.result as string;
        image.onload = function (imageEvent) {
          const imageFile = resize(image, file.name);
          resolve(imageFile);
        };
        image.onerror = function (err) {
          reject(err);
        };
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
  return promise;
}

/**
 *
 * @param image
 * @param fileName
 * @returns
 */
function resize(image: any, fileName: any) {
  const canvas = document.createElement("canvas");
  let { width } = image,
    { height } = image;
  const maxWidth = width > 200 ? 200 : width;
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxWidth) {
      width *= maxWidth / height;
      height = maxWidth;
    }
  }
  canvas.width = width;
  canvas.height = height;
  canvas?.getContext("2d")?.drawImage(image, 0, 0, width, height);
  const dataUrl = canvas.toDataURL("image/png");
  const resizedImage = dataURLToBlob(dataUrl);
  return blobToFile(resizedImage, fileName);
}

/**
 *
 * @param {*} dataURL
 * @param {*} fileName
 * @returns
 */
export function dataURLToBlob(dataURL: any) {
  const BASE64_MARKER = ";base64,";

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  const rawLength = raw.length;

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = parts[1];
    return new Blob([raw], { type: contentType });
  }
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const blob = new Blob([uInt8Array], { type: contentType });
  return blob;
}

/**
 *
 * @param {*} ab
 * @param {*} fileName
 * @returns
 */
function blobToFile(ab: any, fileName: any) {
  const f = new File([ab], fileName, {
    type: "image/jpeg",
    lastModified: new Date().getTime(),
  });
  return f;
}

/**
 *
 * @param file
 * @returns
 */
export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 *
 * @param routePath
 * @param currentPath
 * @returns
 */
export const mainPathMatches = (routePath: string, currentPath: any) => {
  if (routePath.endsWith("?")) {
    // If the route path ends with '?', check only the part before the '?'
    return currentPath.startsWith(routePath.slice(0, -1));
  }
  //id the route contains / check only the first part of the path
  if (
    routePath.includes("/") &&
    routePath?.length > 3 &&
    routePath.split("/").length > 2
  ) {
    return currentPath.startsWith(`/${routePath.split("/")[1]}`);
  }
  // Otherwise, check the whole path
  return currentPath === routePath;
};

/**
 *
 * @param routePath
 * @param currentPath
 * @returns
 */
export const checkSecondaryPathsMatch = (
  routePath: string,
  currentPath: any
) => {
  if (routePath.endsWith("?")) {
    // If the route path ends with '?', check only the part before the '?'
    return currentPath.startsWith(routePath.slice(0, -1));
  }
  // Otherwise, check the whole path
  return currentPath === routePath;
};

/**
 *
 * @param date
 * @param format
 * @returns
 */
export const formatStringDate = (
  date: string | Date | Moment | null | undefined,
  format: any
) => {
  if (!date) return null;
  return moment(date).format(format);
};

/**
 *
 * @param url
 */
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/**
 *
 * @param timestamp
 * @param format
 * @returns
 */
export const formatTimestamp = (timestamp: any, format: any) => {
  const timestampToDate = new Date(timestamp * 1000);
  const response = moment(timestampToDate).format(format);
  return response && response !== "Invalid date" ? response : "";
};

/**
 * checks if device is mobile
 * @returns
 */
export const isMobile = () => {
  return document.body.offsetWidth <= ResolutionBreakPoints.sm;
};

/**
 * check if device is tablet
 * @returns
 */
export const isTablet = () => {
  return document.body.offsetWidth <= ResolutionBreakPoints.lg;
};

/**
 *
 * @param backgroundColor
 * @returns
 */
export const setFontColorBasedOnBackground = (backgroundColor: string) => {
  let sum = 0;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    backgroundColor
  );

  sum = result
    ? parseInt(result[1], 16) / 2 +
      parseInt(result[2], 16) / 2 +
      parseInt(result[3], 16) / 2
    : 0;
  if (sum > 127 || sum === 0) {
    return "#000";
  }
  return "#fff";
};
/**
 *
 * @param str
 * @returns
 */
export const replaceAllUnescapedChars = (str: string) => {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/ /g, "&nbsp;");
};

/**
 *
 * @param str
 * @returns
 */
export const restoreAllEscapedChars = (str: string) => {
  return str
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&nbsp;/g, " ");
};

/**
 *
 * @param html
 * @returns
 */
export const decodeHtml = (html: string) => {
  const e = document.createElement("div");
  e.innerHTML = html;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

/**
 *
 * @param html
 * @returns
 */
export const extractStringFromHtml = (html: string): string => {
  // Create a temporary container element
  const container = document.createElement("div");
  // Set the HTML content of the container
  container.innerHTML = html;

  // Function to recursively traverse through the DOM nodes
  const getText = (node: any) => {
    let text = "";
    // Iterate over child nodes
    node.childNodes.forEach((child: any) => {
      // If it's a text node, append its content
      if (child.nodeType === Node.TEXT_NODE) {
        text += child.textContent;
      } else if (
        child.nodeType === Node.ELEMENT_NODE &&
        child.tagName.toLowerCase() !== "style"
      ) {
        // If it's an element node and not a <style> tag, recursively get its text content
        text += getText(child);
      }
    });
    return text;
  };

  // Call the getText function starting from the container element
  return getText(container);
};

/**
 *
 * @param str
 * @returns
 */
export const isStringOnlyHTML = (str: string) => {
  const fragment = document.createRange().createContextualFragment(str);

  // remove all non text nodes from fragment
  fragment.querySelectorAll("*").forEach((el: any) => {
    if (el.childNodes.length === 0) {
      el.parentNode.removeChild(el);
    }
  });

  // if there is textContent, then not a pure HTML
  return !(fragment.textContent || "").trim();
};

/**
 *
 * @param html
 * @returns
 */
export const getHtml = (html: string) => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  return EditorState.createWithContent(contentState);
};

/**
 *
 * @param html
 * @returns
 */
export const sanitizeHtml = (html: string): string => {
  // Create a DOMParser to parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Remove script elements
  const scriptElements = doc.querySelectorAll("script");
  scriptElements.forEach((script) => {
    script.parentNode?.removeChild(script);
  });

  // Remove link elements (including their attributes)
  // const linkElements = doc.querySelectorAll("link");
  // linkElements.forEach((link) => {
  //   link.parentNode?.removeChild(link);
  // });

  // Serialize the cleaned DOM back to a string
  const cleanedHtml = new XMLSerializer().serializeToString(doc);

  return cleanedHtml;
};

/**
 *
 * @param users
 * @returns
 */
export const mapUserListToChatSuggestions = (users: any) => {
  return users?.map((participant: any) => {
    return {
      text: `${participant.user.lastName || ""} ${
        participant.user.firstName || ""
      }`,
      value: `${participant.user.lastName || ""} ${
        participant.user.firstName || ""
      }`,
      url: "",
    };
  });
};

export const checkIfStringContainsOnlyOneChar = (str: string, char: string) => {
  if (!str) return null;
  //remove spaces from string
  str = str.replace(/\s/g, "");
  //check if string contains only one char
  return str.length === 1 && str === char ? null : str;
};

/**
 *
 * @param stockStatus
 * @returns
 */
export const renderStockMessage = (stockStatus: string | null) => {
  switch (stockStatus) {
    case EWordpressStockStatus.OUT_OF_STOCK:
      return Vocabulary.outOfStock;
    case EWordpressStockStatus.IN_STOCK:
      return Vocabulary.inStock;
    case EWordpressStockStatus.ON_BACKORDER:
      return Vocabulary.onBackOrder;
    default:
      return Vocabulary.inStock;
  }
};

/**
 * Make an HTTP request using Axios.
 * @param url The URL to send the request to.
 * @param method The HTTP method to use (e.g., 'GET', 'POST', etc.).
 * @param token The authorization token to include in the request headers.
 * @param body The request body (optional).
 * @returns A promise that resolves to the AxiosResponse.
 */
export const makeRequest = async (
  url: string,
  method: string,
  token: string,
  body?: any
): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios({
      url,
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });
    return response;
  } catch (error) {
    // Handle error (e.g., log it or throw a custom error)
    console.error("Request failed:", error);
    throw error; // Rethrow the error to propagate it up the call stack
  }
};

/**
 *
 * @param name
 * @returns
 */
export const stringAvatar = (name: string) => {
  if (!name) return null;
  const nameSplit = name?.split(" ");
  return {
    children: `${!nameSplit.length ? "" : nameSplit?.[0]?.[0] || ""}${
      nameSplit?.[1]?.[0] || ""
    }`,
  };
};

/**
 *
 * @param obj
 */
export const removeNullProperties = (obj: { [key: string]: any }) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        obj[key] === null ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];
      } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        // Recursively call the function for nested objects
        removeNullProperties(obj[key]);
        // After recursive call, delete the property if the nested object is empty
        if (checkIfObjectIsEmpty(obj[key])) {
          delete obj[key];
        }
      }
    }
  }
};

/**
 *
 * @param obj
 * @returns
 */
export const checkIfObjectIsEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};

/**
 *
 * @param chat
 * @returns
 */
export const checkIfChatHasUnreadMessages = (chat: any) => {
  //check if chat has messages and lastRead is smaller than the curent timestamp
  const lastRead = chat?.lastRead;
  //get last message timestamp
  const lastMessageTimestamp =
    chat?.messages?.[chat?.messages?.length - 1]?.timestamp;
  return chat?.messages?.length > 0 && lastRead < lastMessageTimestamp;
};

/**
 *
 * @param property
 * @returns
 */
export const checkIfPropertyIsString = (property: any) => {
  return typeof property === "string" || property instanceof String;
};
