import { Typography } from "@mui/material";
import { Fragment } from "react";
import { Vocabulary } from "../Utils/Vocabulary";
import styles from "../Styles/orderOffer.module.css";
import { OrderOfferCustomPanelProps } from "../Utils/Types";

export function OrderOfferCustomPanel(props: OrderOfferCustomPanelProps) {
  const { children, error, title } = props;
  return (
    <Fragment>
      <div className={styles.orderOfferCustomPanel}>
        <Typography className={styles.subTitles}>{title}</Typography>
        {error ? (
          <Typography
            className={`${styles.subTitles} ${styles.orderOfferCustomPanelErrorText}`}
          >
            {Vocabulary.requiredField}
          </Typography>
        ) : null}
      </div>
      {children}
    </Fragment>
  );
}
