import { IconButton, InputBase } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Vocabulary } from "../Utils/Vocabulary";
import { Close, Search } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { SearchComponentProps } from "../Utils/Types";

export default function SearchComponent(props: SearchComponentProps) {
  const [searchField, setSearchField]: any = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamName = props.searchParamName
    ? props.searchParamName
    : "search";

  /**
   *
   */
  useEffect(() => {
    if (!searchParams.get(searchParamName)) {
      setSearchField("");
    } else {
      setSearchField(searchParams.get(searchParamName));
    }
  }, [searchParams]);

  /**
   *
   * @param event
   */
  const handleChangeSearch = (event: any) => {
    if (event.target.value.length === 0) {
      searchParams.delete(searchParamName);
    }
    setSearchField(event.target.value);
  };

  /**
   *
   */
  const handleClearSearch = () => {
    setSearchField("");
    searchParams.delete(searchParamName);
    setSearchParams(searchParams);
  };

  /**
   *
   * @param e
   */
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchParams.set(searchParamName, searchField);
      searchParams.set("page", "0");
      setSearchParams(searchParams);
    }
  };

  return (
    <Fragment>
      <InputBase
        sx={{ ml: 3, flex: 2 }}
        placeholder={Vocabulary.search}
        value={searchField}
        onChange={handleChangeSearch}
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
      />
      <IconButton onClick={handleClearSearch} type="button" sx={{ p: "6px" }}>
        {searchField.length > 0 ? <Close /> : <Search />}
      </IconButton>
    </Fragment>
  );
}
