import { Button, Divider, InputLabel, Menu, MenuItem } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useContext } from "react";
import { ThemeContext } from "@emotion/react";
import { emailTemplateAdditionalData } from "../../Utils/Constants";
import styles from "../../Styles/emailTemplate.module.css";
import { AdditionalDataProps } from "../../Utils/Types";

export default function AdditionalData(props: AdditionalDataProps) {
  const { openMenu, anchorEl, handleClose, handleClick } = props;
  const themeContext: any = useContext(ThemeContext);

  return (
    <div className={styles.additionalDataContainer}>
      <InputLabel
        style={{
          color: themeContext.theme?.palette.blackColor?.main,
          fontSize: 16,
        }}
      >
        {Vocabulary.addDynamicData} &nbsp; &nbsp;
      </InputLabel>
      <Button
        aria-controls={openMenu ? "demo-customized-menu" : undefined}
        aria-expanded={openMenu ? "true" : undefined}
        variant="outlined"
        size="large"
        disableElevation
        endIcon={<KeyboardArrowDown />}
        onClick={handleClick}
        className={styles.additionalDataButton}
      >
        {Vocabulary.additionalData}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => handleClose("")}
        elevation={0}
        PaperProps={{
          style: { boxShadow: "1px 1px 12px 5px #e9e9e9", width: 185 },
        }}
      >
        {Object.entries(emailTemplateAdditionalData).map((data) => (
          <span key={`additionalData_${data[0]}`}>
            <MenuItem onClick={() => handleClose(data[0])} disableRipple>
              {data[1]}
            </MenuItem>
            <Divider />
          </span>
        ))}
      </Menu>
    </div>
  );
}
