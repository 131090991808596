/**
 * Represents a collection of common text used in the application.
 */
export const Vocabulary: any = {
  // ... (existing key-value pairs)
  /** COMMON TEXT */
  login: "Conectare",
  loginValidationError: "Eroare la validarea datelor!",
  requiredField: "Acest câmp este obliugatoriu.",
  emailValidation: "Introduceți  o adresă valida.",
  pageNotFound: "Pagina nu a fost găsită!",
  search: "Căutare",
  siteName: "EcoPack",
  logout: "Deconectare",
  documentation: "Documentație",
  defaultManagement: "Gestiune implicită",
  subtotal: "Subtotal",
  confirm: "Confirmare",
  orderOfferWhatsappTemplate: "Șablon WhatsApp pentru comandă/ofertă",
  whatsAppFooterNoteObservation: "",
  pages: "Pagini",
  image: "Imagine",
  userManual: "Manual de utilizare",
  notifications: "Notificări",
  noNotifications: "Nu există notificări",
  myAccount: "Contul meu",
  legalPerson: "Persoană juridică",
  physicalPerson: "Persoană fizică",
  shouldClientReceiveEmail: "Clientul ar trebui să primească email?",
  syncProducts: "Sincronizare produse",
  users: "Utilizatori",
  user: "Utilizator",
  CNP: "CNP",
  userSettings: "Setări utilizator",
  noConversationsAvailable: "Nu sunt conversații disponibile",
  addNewConversation: "Adaugă conversație nouă",
  conversations: "Conversații WhatsApp",
  newConversation: "Conversație nouă",
  selectAllProductsForInvoice: "Selectează toate produsele pentru facturare",
  departments: "Departamente",
  options: "Opțiuni",
  WARNING: "Avertisment",
  phoneNumbers: "Numere de telefon",
  availablePhoneNumber: "Număr de telefon disponibil",
  whatsAppEditTemplateLimit:
    " Șabloanele aprobate pot fi editate de până la 10 ori într-o fereastră de 30 de zile sau o dată într-o fereastră de 24 de ore. Dacă ați efectuat deja această acțiune, vă rugăm să reveniți mai târziu.",
  whatsAppTemplateEditingWarning:
    "Doar șabloanele cu statusul APROBAT, RESPINS sau ÎN APROBARE pot fi editate. Puteți edita doar categoria sau componentele unui șablon. Nu puteți edita categoria unui șablon aprobat. Șabloanele aprobate pot fi editate de până la 10 ori într-o fereastră de 30 de zile sau o dată într-o fereastră de 24 de ore. Șabloanele respinse sau în pauză pot fi editate de un număr nelimitat de ori. După editarea unui șablon aprobat sau în aprobare, acesta va fi aprobat automat, cu excepția cazului în care nu trece de revizuirea șablonului.",
  countryCode: "Cod țară",
  phoneNumbersDisplayName: "Numele afișat pentru numărul de telefon",
  lastName: "Nume",
  product: "Produs",
  entityName: "Nume entitate",
  collected: "Încasată",
  collect: "Încasează",
  notCollected: "Neîncasată",
  canceled: "Anulată",
  corrected: "Stornată",
  invoiced: "Facturat",
  previewDocument: "Previzualizare document",
  previewVideo: "Previzualizare video",
  addPhoneNumber: "Adaugă număr de telefon",
  firstName: "Prenume",
  preview: "Previzualizare",
  continue: "Continuă",
  code: "Cod",
  learnMore: "Află mai mult",
  pin: "PIN",
  back: "Înapoi",
  phoneNumbersDisplayNameHelperText:
    "Numele dvs. afișat trebuie să se potrivească cu numele companiei dvs. și să respecte regulile privind numele afișat WhatsApp Business. ",
  twoStepVerificationPinHelperText:
    "Introduceți un cod pentru autentificarea în doi pași atunci când vă conectați utilizând numărul de telefon WhatsApp Business",
  lastStep: "Ultimul pas",
  registerPhoneNumber: "Înregistrare număr de telefon",
  register: "Înregistrare",
  verifyPhoneNumberRegistrationCode:
    "Introducere cod de verificare primit prin SMS",
  setPinForTwoStepVerification: "Setare PIN pentru autentificare în doi pași",
  buttonText: "Text buton",
  orderNr: "Număr comandă",
  thankYouForYourOrder: "Mulțumim! Comanda a fost plasată.",
  ANPC: "ANPC",
  contact: "Contact",
  addNewTemplate: "Adaugă șablon nou",
  cookiePolicy: "Politica Cookies",
  old: "Veche",
  goods: "Marfa",
  password: "Parolă",
  view: "Vizualizează",
  cancelAction: "Anulează",
  billingAction: "Facturează",
  previous: "Anterior",
  next: "Următor",
  crtNo: "Nr. crt.",
  invoice: "Factură",
  notice: "Aviz",
  issuingInvoice: "Emitere factură",
  issuingNotice: "Emitere aviz",
  email: "Email",
  addImage: "Adaugă imagine",
  phoneNumber: "Număr de telefon",
  roles: "Roluri",
  role: "Rol",
  lei: "Lei",
  department: "Departament",
  edit: "Editează",
  delete: "Șterge",
  description: "Descriere",
  noResultsFound: "Nu a fost găsit nici un rezultat...",
  nextPage: "Pagina următoare",
  previousPage: "Pagina anterioară",
  rowsPerPage: "Rânduri pe pagină",
  of: "din",
  downloadCSV: "Descarcă CSV",
  print: "Printează",
  uploadImageOnEmailTemplateMessage:
    "Asigurați-vă că ați plasat cursorul unde doriți să plasați imaginea!",
  rowsDeleted: "rânduri șterse",
  rowsSelected: "rânduri selectate",
  usersList: "Listă utilizatori",
  departmentsList: "Listă departamente",
  tasksList: "Listă task-uri",
  projectsList: "Listă proiecte",
  reportsList: "Listă rapoarte",
  deleteFilters: "Șterge filtre",
  tasks: "Task-uri",
  add: "Adaugă",
  import: "Importă",
  project: "Proiect",
  name: "Nume",
  ok: "OK",
  sendMessageTemplateToUser: "Trimite șablon",
  selectAConversation: "Selectează o conversație.",
  canOnlySendTemplateToClient:
    "Șabloanele pot fi trimise doar clienților existenți.",
  youHaveUnreadMessages: "Aveți mesaje necitite.",
  unsavedChangesTitleModal: "Modificări nesalvate",
  unsavedChangesTextModal:
    "Dacă părăsiți pagina, modificările nesalvate vor fi pierdute. Sunteți sigur că doriți să părăsiți pagina?",
  departmentHead: "Șef departament",
  projectManager: "Manager proiect",
  status: "Status",
  addNewUser: "Adaugă utilizator nou",
  editUser: "Editează utilizator",
  addNewDepartment: "Adaugă departament nou",
  save: "Salvare",
  approve: "Aprobare",
  saveContact: "Salvează contact",
  cancel: "Anulare",
  addFiles: "Adaugă fișiere",
  finish: "Finalizare",
  generate: "Generează",
  finished: "Finalizat",
  unfinished: "Nefinalizat",
  userName: "Nume utilizator",
  taskName: "Nume task",
  active: "Active",
  addConversation: "Adaugă conversație",
  archived: "Arhivat",
  cif: "CIF",
  messages: "Mesagerie WhatsApp",
  baseInfo: "Informații generale",
  dynamicInfo: "Informații dinamice",
  attachments: "Atașamente",
  issueDate: "Data emiterii",
  dueDate: "Data scadenței",
  otherInfos: "Alte informații",
  issuerName: "Întocmit de",
  issuerId: "CNP",
  deputyIdentityCard: "Carte identitate delegat",
  selesAgent: "Agent vânzări",
  filters: "Filtre",
  invoiceWarning:
    "Pentru a evita erori și neînțelegeri în procesul de facturare, vă rugăm să verificați cu atenție informațiile despre client înainte de a emite o factură. Asigurați-vă că toate datele sunt corecte și actualizate pentru a asigura un proces de facturare fără probleme. ",
  deputyName: "Delegat",
  fancyName: "Denumire",
  startDate: "Data start",
  nextContactDate: "Data următoarei contactări",
  nextTransportDate: "Data următoarei livrări",
  endDate: "Data final",
  fullName: "Nume complet",
  position: "Funcție",
  dragAndDrop:
    "Trageți și plasați orice fișier aici sau răsfoiți de pe dispozitiv",
  error: "Eroare!",
  notFound: "Nu a fost găsit.",
  dashboard: "Dashboard",
  newTask: "Task nou",
  url: "URL",
  profilePicture: "Poza de profil",
  addCustomField: "Adaugă câmp personalizat",
  customField: "Câmp personalizat",
  custom: "Personalizat",
  specificAction: "Acțiune specifică",
  quickReply: "Răspuns rapid",
  visitSite: "Vizitează site",
  callPhoneNumber: "Sună numărul de telefon",
  value: "Valoare",
  priority: "Prioritate",
  periodicity: "Periodicitate",
  periodic: "Periodic",
  type: "Tip",
  home: "Acasă",
  highPriority: "Înaltă",
  mediumPriority: "Medie",
  lowPriority: "Scăzută",
  chooseUser: "Alege utilizator",
  chooseProject: "Alege proiect",
  chooseDepartment: "Alege departament",
  clients: "Clienti",
  client: "Client",
  clientAddress: "Adresa client",
  clientDetails: "Detalii client",
  orderDetails: "Detalii comandă",
  phoneNumberDetails: "Detalii număr de telefon",
  order: "Comanda",
  Verified: "Verificat",
  notVerified: "Neverificat",
  postcode: "Cod poștal",
  creationDate: "Data creării",
  lastModified: "Ultima salvare",
  orderProducts: "Produse comandă",
  ordersStatuses: "Status comenzi",
  templates: "Șabloane",

  checkPhoneNumber: "Verificare număr de telefon",
  pagination: {
    next: "Urmator",
    previous: "Anterior",
    rowsPerPage: "Pe pagina:",
    displayRows: "din",
  },
  noData: "Nu exista date!",
  orders: "Comenzi",
  reset: "Resetează",
  statusesOrder: "Ordinea statusurilor",
  reports: "Rapoarte",
  confirmDeleteTitle: "Confirmare ștergere",
  confirmDeleteMessage: "Ești sigur că vrei să ștergi această înregistrare?",

  confirmDuplicateMessage:
    "Ești sigur că vrei să duplici această înregistrare?",
  no: "Nu",
  yes: "Da",
  editModalTitle: "Editează",
  username: "Nume utilizator",
  saveSuccess: "Datele au fost salvate cu succes!",
  saveError: "Eroare la salvarea datelor!",
  editOrderStatus: "Editează status comandă",
  addOrderStatus: "Adaugă status comandă",
  getError: "Eroare la preluarea datelor!",
  deleteSuccess: "Datele au fost șterse cu succes!",
  deleteError: "Eroare la ștergerea datelor!",
  newOrderStatus: "Status comandă nou",
  orderStatus: "Status comandă",
  phone: "Telefon",
  contactPerson: "Persoană contact",
  address: "Adresă",
  usefulInfo: "Informații utile",
  observations: "Observații",
  noMoreMessages: "Nu mai sunt mesaje",
  sendMessagesInfo:
    "Pentru a comunica eficient prin WhatsApp, este necesar să trimitem mai întâi un template de mesaj aprobat de WhatsApp. Acest mesaj introductiv trebuie să ofere utilizatorului toate detaliile necesare și să solicite un răspuns. După ce utilizatorul răspunde la acest mesaj, putem continua conversația fără restricții, conform regulilor WhatsApp, asigurând astfel o comunicare fluentă și eficientă.",
  fiscalCode: "Cod fiscal",
  tradeRegisterNo: "Nr. Registru Comerț",
  registered: "Înregistrat",
  notRegistered: "Neînregistrat",
  whatsAppRegisterPhoneNumberHelperText:
    "Ultimul pas înainte de a putea comunica prin WhatsApp Business este să înregistrați numărul de telefon. Dacă numărul de telefon verificat are deja activată verificarea în doi pași, introduceți codul PIN de 6 cifre. Dacă numărul de telefon verificat nu are activată verificarea în doi pași, setați un cod PIN de 6 cifre. ",
  details: "Detalii",
  week: "Săptămâna",
  oblioSettings: "Setări Oblio",
  day: "Ziua",
  city: "Oraș",
  county: "Județ",
  country: "Țară",
  takenBy: "Preluat de",
  addressDetails: "Detalii adresă",
  category: "Categorie",
  categories: "Categorii",
  tags: "Etichete",
  addVariable: "Adaugă variabilă",
  selectATemplateBeforeSendingAnEmail:
    "Selectați un șablon înainte de a trimite un email",
  variable: "Variabilă",
  samplesForBodyContent: "Exemple pentru conținutul variabilelor",
  addButton: "Adaugă buton",
  whatsAppHeaderNoteObservation:
    "Adăugați un titlu sau alegeți ce tip de conținut media veți folosi pentru acest antet.",
  messageswhatsAppFooterNoteObservation:
    "Dacă adăugați mai mult de 3 butoane, acestea vor apărea într-o listă. Numărul maxim de butoane permise este 10.",
  enterWhatsAppMessageFooterText:
    "Adăugați un scurt text în subsolul șablonului de mesaj.",
  enterWhatsAppMessageBodyText:
    "Introduceți textul corpului mesajului WhatsApp în limba selectată.",
  whatsAppFooterButtonsHelperText:
    "Creați butoane care le permit clienților să răspundă la mesajul dumneavoastră sau să execute o acțiune specifică.",
  bank: "Banca",
  iban: "IBAN",
  noDays: "Nr. zile",
  optional: "Opțional",
  required: "Obligatoriu",
  categoryName: "Nume categorie",
  periodicityName: "Nume periodicitate",
  color: "Culoare",
  chooseTemplate: "Alege șablon",
  emailContent: "Conținut email",
  exampleContent: "Exemplu conținut",
  price: "Preț",
  products: "Produse",
  actionSummary: "Sumar acțiune",
  emailContentError: "Conținutul email-ului este obligatoriu",
  pretOnSite: "Preț pe site",
  oblioPrice: "Preț Oblio",
  wordpressSync: "Sincronizare Wordpress",
  billing: "Facturare",
  diferences: "Diferențe",
  SUCCESS: "Succes",
  ERROR: "Eroare",
  INFO: "Informație",
  action: "Acțiune",
  info: "Informație",
  noIdentifier: "Fără identificator",
  identifier: "Identificator",
  initialsValues: "Valori inițiale",
  modifiedValues: "Valori după modificare",
  "email-templates": "Șabloane email",
  "order-statuses": "Status comenzi",
  "transport-options": "Opțiuni transport",
  "tax-classes": "Clase TVA",
  "client-categories": "Categorii clienți",
  whatsapps: "Configurare WhatsApp",
  company: "Companie",
  ignoreOblioIdsList: "Lista de ID-uri Oblio ignorate (separate prin virgulă)",
  nameContactPerson: "Nume persoană contact",
  orderSummary: "Sumar comandă:",
  contactPhoneNumber: "Număr de contact",
  date: "Data",
  dateWithDiacritics: "Dată",
  total: "Total",
  logs: "Loguri",
  confirmSaveOrUpdateOrder: "Confirmați salvarea sau actualizarea comenzii?",
  orderSettings: "Setări comandă",
  startOrderNumber: "Nr. comandă start",
  currentOrderNumber: "Nr. comandă curent",
  paymentMethods: "Metode de plată",
  emailTemplates: "Șabloane email",
  emailPreview: "Previzualizare email",
  addClient: "Adaugă client nou",
  associateConversationWithClient: "Asociază conversația",
  associateExistingClient: "Asociază client existent",
  addNewClientOrAssociateExisting:
    "Momentan, conversația nu este asociată cu niciun client din aplicație. Dorești să adaugi un client nou, care va fi asociat automat cu această conversație, sau să asociezi unul existent?",
  template: "Șablon",
  subject: "Subiect",
  from: "De la",
  method: "Metodă",
  phoneNumberFormat: "(Format acceptat +40XXXXXXXXX)",
  nrOfAcceptedButtons: "Nr. butoane permise: ",
  whatsappIntegration: "Integrare WhatsApp",
  clientType: "Tip client",
  to: "Către",
  emailTemplate: "Șablon email",
  editor: "Editor",
  emailTemplateName: "Nume șablon email",
  associateClientWithConversation: "Asociază client cu conversație",
  cc: "CC",
  header: "Antet",
  body: "Corp",
  footer: "Subsol",
  buttons: "Butoane",
  productDescription: "Descriere produs",
  loginWithFacebook: "Conectare cu Facebook",
  dimensions: "Dimensiuni",
  length: "Lungime",
  templateName: "Nume șablon",
  templateLanguage: "Limba șablon",
  width: "Lățime",
  enterTextInSelectedLanguage: "Introduceți text în limba selectată",
  whatsAppMessagesTemplates: "Șabloane mesaje WhatsApp",
  whatsAppTemplate: "Șablon WhatsApp",
  height: "Înălțime",
  productLink: "Link produs",
  whatsAppTemplateBodyContentForHelperText:
    "Pentru a ne ajuta să revizuim șablonul dvs. de mesaj, vă rugăm să adăugați un exemplu pentru fiecare variabilă din corpul dvs. de text. Nu utilizați informații reale despre clienți",
  enterContentFor: "Introduceți conținut pentru",
  enterEmailAddressesSeparatedByComma:
    "Introduceți adresele de e-mail separate prin virgulă",
  addDynamicData: "Adaugă date dinamice",
  additionalData: "Date suplimentare",
  emailTemplateAttachedToOrderStatus: "Atașează la statusul comenzii",
  productName: "Nume Produs",
  unitMeasure: "Unitate de masură",
  quantity: "Cantitate",
  unitPrice: "Preț unitar fără tva",
  cotaTVA: "Cota TVA",
  tva: "TVA",
  duplicate: "Duplică",
  youNeedToSelectAtLeastOneProductToGenerateInvoice:
    "Trebuie să selectați cel puțin un produs pentru a genera o factură",
  youNeedToSelectAtLeastOneProductToGenerateNotice:
    "Trebuie să selectați cel puțin un produs pentru a genera un aviz",
  nr: "Nr.",
  discount: "Discount",
  totalDiscount: "Total discount",
  totalDiscountWithouTVA: "Total discount fără TVA",
  totalWithoutTVA: "Total fără TVA",
  otherCosts: "Alte costuri",
  addProducts: "Adaugă produs",
  collectDocType: "Tip document încasare",
  subTotal: "Sub-total",
  valueWithoutTVA: "Valoare fără TVA",
  priveWithTVA: "Preț unitar cu TVA",
  priceWithoutTVA: "Preț fără TVA",
  shippingInfo: "Informații transport",
  sameAsBilling: "Adresa din datele de facturare",
  newShipping: "Adresa de livrare noua",
  cash: "Numerar la livrare",
  delivery: "Livrare",
  backTransfer: "Transfer bancar",
  orderHasntBeenGenerated: "Comanda nu a fost generata",
  card: "Card online",
  vatRate: "Cota TVA",
  subtotalWithTVA: "Subtotal cu TVA",
  subtotal_tax: "Valoare",
  clientContactedOverEmail: "Contactat prin email:",
  clientCalled: "Contactat telefonic:",
  clientMessaged: "Contactat prin mesaj:",
  takeCustomer: "Preia client",
  totalWithTVA: "Total cu TVA",
  totalTVAIncluded: "Total TVA inclus",
  showTVA: "Afișeaza TVA",
  service: "Serviciu",
  never: "Niciodată",
  courierShipping: "Transport curier",
  stock: "Stoc",
  newOrder: "Comandă nouă",
  close: "Închide",
  noTodaysClients: "Clienți de contactat astăzi",
  clientsToContact: "Clienți de contactat",
  clientsContacted: "Clienți  contactati",
  availableClients: "Clienți zilnici disponibili",
  takenClients: "Clienți zilnici preluati",
  delayedClients: "Clienți disponibili (întârziați)",
  new: "Nouă",
  cifIsRequired: "CIF-ul este obligatoriu",
  clientNameIsRequired: "Numele clientului este obligatoriu",
  seriesNameIsRequired: "Numele seriei este obligatoriu",
  productNameIsRequired: "Numele produsului este obligatoriu",
  productPriceIsRequired: "Prețul produsului este obligatoriu",
  productQuantityIsRequired: "Cantitatea produsului este obligatorie",
  issuerNameIsRequired: "Numele emitentului este obligatoriu",
  issuerIdIsRequired: "CNP-ul emitentului este obligatoriu",
  mentions: "Mentiuni",
  noticeNumber: "Nr. aviz însoțire",
  defaultInvoiceMentions:
    "Factura valabila fără semnatura si ătampila cf. art. V, ALIN(2) DIN ORD. NR. 17/2015 SI ART. 319 ALIN.(29) DIN LEGEA NR. 227/2015 PRIVIND CODUL FISCAL .Cumparatorul isi exprima acordul privind prelucrarea datelor cu caracter personal, comunicate, date ce vor fi utilizate in scopul intocmirii documentelor comerciale de facturare, incasare, expediere si ofertarii comerciale. Orice modificare de preț va fi afisata pe site-ul www.ECOPACKBUSINESS.ro",
  invoicesInfo: "Informații facturare",
  discountValue: "Valoare discount",
  shortDescription: "Descriere scurta",
  unitTVA: "Valoare unitara TVA",
  confirmDelete: "Confirma stergerea",
  settings: "Setari",
  managements: "Gestiuni",
  management: "Gestiune",
  otherSettings: "Alte setari",
  saveWhatsAppSettings: "Salvează setările WhatsApp",
  shippingSettings: "Setări transport",
  whatsAppBusinessAccountId: "Id cont WhatsApp Business",
  whatsAppBusinessAccountName: "Nume cont  WhatsApp Business",
  whatsAppBusinessPhoneNumberId: "Id număr de telefon WhatsApp Business",
  whatsAppBusinessVerifiedName: "Nume WhatsApp Business",
  whatsAppBusinessAccountDataUpdateMessage:
    "Sunteți sigur că doriți să actualizați datele contului WhatsApp Business?",
  oblio: "Oblio",
  conversationOptions: "Opțiuni conversație",
  LOCAL: "Local",
  WORDPRESS: "WordPress",
  OBLIO: "Oblio",
  META: "META",
  whatsApp: "WhatsApp",
  contactDate: "Data contactării",
  shipping: "Transport",
  clientNotesHistory: "Istoric note client",
  welcomeBack: "Bine ai revenit,",
  sendMessage: "Trimite mesaj",
  sendEmail: "Trimite email",
  createOrder: "Creează comandă",
  summary: "Sumar",
  visibleToClient: "Vizibil clientului",
  finishContact: "Finalizează contact",
  contactedOnPhone: "Telefonic",
  wasClientCalledOverPhone: "Clientul a fost contactat telefonic?",
  contacted: "Contactat",
  noOrder: "Fără comandă",
  notContacted: "Necontactat",
  shouldSendEmailToAllClients:
    "Activează/dezactivează trimiterea automată de emailuri pentru toți clienții.",
  addNotes: "Adaugă note",
  text: "Text",
  reply: "Răspunde",
  templatePreview: "Previzualizare șablon",
  note: "Nota",
  doYouWantToTakeOverThisClient: "Doriți să preluați acest client?",
  transportName: "Nume transport",
  transportValue: "Valoare transport",
  isDefaultTransport: "Transport implicit",
  addMethod: "Adaugă metodă",
  paymentMethod: "Metodă de plată",
  loadMoreMessages: "Incarca mai multe mesaje",
  noPreviewAvailable: "Previzualizare indisponibilă",
  noMessagesYet:
    "Nu există mesaje.\n Pentru a iniția o conversație, trimiteți un sablon. \n\n Dacă clientul va raspunde în 24 de ore veți putea trimite mesaje nelimitate.",
  romania: "România",
  send: "Trimite",
  document: "Document",
  addCaption: "Adaugă descriere",
  missingFile: "Fișier lipsă",
  orderConfirmMessage: "Vă mulțumim pentru comanda dumneavoastră!",
  chooseNextContactDate: "Alege data urmatoarei contactări:",
  loading: "Se încarca...",
  noMoreItems: "Nu mai sunt elemente!",
  shippingCost: "Cost transport (TVA inclus)",
  currency: "LEI",
  unsavedMessage: "Ai modificari nesalvate!",
  clientConversation: "Conversatie client",
  database: "Baza de date",
  transportMethods: "Metode transport",
  confirmMessage: "Parolele nu coincid!",
  phoneValidation: "Numarul de telefon este invalid",
  finishOrder: "Finalizare comanda",
  clientNote: "Nota adăugată de client",
  optionalOrderNote: "Note comandă (opțional)",
  invalidEmail: "Email invalid",
  contacts: "Contacte",
  agent: "Agent",
  imagePreview: "Previzualizare imagine",
  contactDeletionThreshold:
    "Numărul de zile după care contactele vechi sunt șterse automat",
  data: "Data",
  shouldRenderOldContactsOnDashboard: "Afișează contactele vechi pe Dashboard.",
  orderMessage:
    "Pentru comenzile ce depasesc 5KG se taxeaza în plus 1 LEU / KG.",
  pressTocChangeStatus: "Apasă pentru a adaugă/schimbă statusul comenzii",
  invoiceDetails: "Detalii de facturare",
  anotherAddress: "Livrezi la o altă adresă?",
  yourOrder: "Comanda ta",
  imageAlternativeText: "Text alternativ imagine",
  confirmPassword: "Confirmare parola",
  deleteImages: "Șterge imagini",
  outOfStock: "Stoc epuizat",
  inStock: "În stoc",
  onBackOrder: "Pe comandă",
  table: "Tabel",
  noStockInfo: "Fără informații despre stoc",
  endOrder: "Plasează comanda",
  footerText:
    "Avem la comercializare o gama variata de pungi de hartie, atat albita, cat si natur, dar si sacose de hartie cu maner flat sau sfoara.",
  copyright: "© EcoPack Business 2023",
  imageUploadErrorMessage:
    "Trebuie să incărcați o imagine și să oferiți o alternativă de text pentru aceasta!",
  /**AUDIT ACTIONS */
  CREATE: "Adăugare",
  UPDATE: "Actualizare",
  DELETE: "Ștergere",
  SOFT_DELETE: "Inactivare",
  IMPORT: "Import",
  DUPLICATE: "Duplicare",
  /**ERRORS */
  systemError: "Eroare de sistem. Contactează administratorul!",
  getDataError: "Eroare la preluarea datelor!",
  deleteDataError: "Eroare la ștergerea datelor!",
  importDataError: "Eroare la importul datelor!",
  createDataError: "Eroare la adăugarea datelor!",
  updateDataError: "Eroare la actualizarea datelor!",
  deleteDataSuccess: "Datele au fost șterse cu succes!",
  importDataSuccess: "Datele au fost importate cu succes!",
  createDataSuccess: "Datele au fost adăugate cu succes!",
  updateDataSuccess: "Datele au fost actualizate cu succes!",
  chooseStatusColor: "Alegeți o culoare pentru status.",
};
