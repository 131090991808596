import ReactRouterPrompt from "react-router-prompt";
import { DirtyFormGuardProps } from "../../Utils/Types";
import GenericModal from "../GenericModal";
import StandardActionButtons from "../StandarsActionButtons";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Typography } from "@mui/material";

export default function DirtyFormGuard(props: DirtyFormGuardProps) {
  const { when } = props;
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <GenericModal
            open={isActive}
            onClose={onCancel}
            title={Vocabulary.unsavedChangesTitleModal}
            actions={
              <StandardActionButtons
                okAction={onConfirm}
                cancelAction={onCancel}
                okText={Vocabulary.ok}
                cancelText={Vocabulary.cancel}
              />
            }
          >
            <Typography>{Vocabulary.unsavedChangesTextModal}</Typography>
          </GenericModal>
        )
      }
    </ReactRouterPrompt>
  );
}
