import GenericModal from "../GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import StandardActionButtons from "../StandarsActionButtons";
import { getData } from "../../Services/getData";
import { useSearchParams } from "react-router-dom";
import { endpoints } from "../../Utils/UrlEnum";
import { useContext } from "react";
import { WebSocketContext } from "../../Context/WebSocketContext";
import { AssociateAgentWithContactModalProps } from "../../Utils/Types";
import { AxiosResponse } from "axios";

export default function AssociateAgentWithContactModal(
  props: AssociateAgentWithContactModalProps
) {
  const { contact, openModal, setOpenModal } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const websocket = useContext(WebSocketContext);
  /**
   *
   */
  const associateAgentWithContact = () => {
    //delete search from url
    searchParams.delete("search");
    getData(`${endpoints.associateAgentWithContact}/${contact._id}`).then(
      (res:AxiosResponse) => {
        if (res) {
          if (contact._id) {
            searchParams.set("id", contact._id);
            setSearchParams(searchParams);
          }
          websocket.socket.emit("updateDashboardContacts");
        }
      }
    );
    setOpenModal(false);
  };

  /**
   *
   */
  const handleToggleOpenModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <GenericModal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      title={Vocabulary.doYouWantToTakeOverThisClient}
    >
      <StandardActionButtons
        okAction={associateAgentWithContact}
        cancelAction={handleToggleOpenModal}
        okText={Vocabulary.yes}
        cancelText={Vocabulary.no}
      />
    </GenericModal>
  );
}
