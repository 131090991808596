import { EContactStatus, ETableEditAction } from "../../Utils/Enums";
import { endpoints } from "../../Utils/UrlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTableCustomStyle from "../MUIDataTableCustomStyle";
import { ContactModel } from "../../Utils/Models";
import { createContext, useContext, useState } from "react";
import { formatStringDate } from "../../Utils/Utils";
import Config from "../../Utils/Config";
import ContactsTableFilters from "./ContactsTableFilters";
import withRole from "../../Hooks/withRole";
import { useSearchParams } from "react-router-dom";
import AssociateAgentWithContactModal from "./AssociateAgentWithContactModal";
import { WebSocketContext } from "../../Context/WebSocketContext";
import moment from "moment";
import clsx from "clsx";
import styles from "../../Styles/contactsTable.module.css";

export const CRUDContext = createContext(new ContactModel());

function ContactsTable(props: any) {
  const [contextValue, setContextValue] = useState(new ContactModel());
  const [searchParams, setSearchParams] = useSearchParams();
  const [associateContactOpenModal, setAssociateContactOpenModal] =
    useState(false);
  const userId = localStorage.getItem("userId");
  const [triggerReload, setTriggerReload] = useState<undefined | boolean>();
  const websocket = useContext(WebSocketContext);
  const contactsColumns = [
    {
      label: Vocabulary.client,
      name: "client",
      options: {
        sort: false,
        customBodyRender: (client: any) => {
          return client?.name;
        },
      },
    },
    {
      label: Vocabulary.agent,
      name: "agent",
      options: {
        sort: false,
        customBodyRender: (agent: any) => {
          return `${agent?.firstName || ""} ${agent?.lastName || ""}`;
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.orderNr,
      name: "order",
      options: {
        sort: false,
        customBodyRender: (order: any) => {
          return `${order?.number ? `#${order?.number}` : "-"}`;
        },
      },
    },
    {
      label: Vocabulary.data,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (date: any) => {
          return formatStringDate(date, Config.momentEUDateFormat);
        },
      },
    },
  ];

  /**
   *
   */
  const handleToggleAssociateAgentOpenModal = (data: any) => {
    if (!data.agent) {
      setAssociateContactOpenModal(true);
    } else {
      if (data._id) {
        searchParams.set("id", data._id);
        setSearchParams(searchParams);
      }
    }
  };

  /**
   *
   */
  websocket.socket.on(`${userId}dashboardContacts`, (data: any) => {
    if (data && data.summary) {
      setTriggerReload(true);
    }
  });

  /**
   *
   * @param row
   * @returns
   */
  const rowProps = (row: any) => {
    return clsx({
      [styles.finishedContacts]: row[2] === EContactStatus.FINALIZAT,
    });
  };

  return (
    <div>
      <div style={{ padding: "5px 20px" }}>
        <ContactsTableFilters />
      </div>
      <CRUDContext.Provider value={contextValue}>
        <MUIDataTableCustomStyle
          id={"contacts"}
          title={Vocabulary.contacts}
          columns={contactsColumns}
          filters={{
            start: Config.defaultStartDate,
            end: moment(new Date()).format(Config.momentUSDateFormat),
          }}
          hideOptions={props.hideOptions}
          CRUDEndPoint={endpoints.contactEndpoint}
          onEditAction={ETableEditAction.Modal}
          setContextValue={setContextValue}
          triggerReload={triggerReload}
          hideAddButton={true}
          addEntityIdToURL={true}
          onRowClick={(rowData: any) => {
            setContextValue(rowData);
            handleToggleAssociateAgentOpenModal(rowData);
          }}
          rowProps={rowProps}
          selectableRows={props.selectableRows}
        />
        <AssociateAgentWithContactModal
          openModal={associateContactOpenModal}
          setOpenModal={setAssociateContactOpenModal}
          contact={contextValue}
        />
      </CRUDContext.Provider>
    </div>
  );
}

export default withRole(ContactsTable);
