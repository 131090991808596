import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import styles from "../Styles/customPanel.module.css";
import InfoIcon from "@mui/icons-material/Info";
import { CustomPanelProps } from "../Utils/Types";

export default function CustomPanel(props: CustomPanelProps) {
  const { children, title, actionButtons, error } = props;

  return (
    <Box className={styles.box}>
      <div className={styles.header}>
        <Typography className={styles.typography}>{title}</Typography>

        {actionButtons ? (
          actionButtons
        ) : (
          <div style={{ display: "flex" }}>
            {props.error ? (
              <Typography className={styles.typographyError}>
                {error}
              </Typography>
            ) : null}
            <InfoIcon className={styles.headerButton}></InfoIcon>
          </div>
        )}
      </div>
      <div className={styles.children}>{children}</div>
    </Box>
  );
}
