/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
const url: any = process.env.REACT_APP_WEBSOKET_URL || "http://localhost:3001";
const socket = io(url, {
  transports: ["websocket"],
  extraHeaders: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
  secure: true,
});
/**
 *
 */
export const WebSocketContext = createContext<any>({
  shouldUpdate: false,
  setShouldUpdate: (value: boolean) => {},
  updateOblioStorage: false,
  setUpdateOblioStorage: (value: boolean) => {},
  socket: socket,
});

export const WebsocketProvider = (props: any) => {
  /**
   *
   * @param shouldUpdate
   */
  const setShouldUpdate = (shouldUpdate: boolean) => {
    setState({ ...state, shouldUpdate: shouldUpdate });
  };

  /**
   *
   */
  const setUpdateOblioStorage = (updateOblioStorage: boolean) => {
    setState({ ...state, updateOblioStorage: updateOblioStorage });
  };

  const initState = {
    shouldUpdate: false,
    setShouldUpdate: setShouldUpdate,
    updateOblioStorage: false,
    setUpdateOblioStorage: setUpdateOblioStorage,
    socket: socket,
  };
  const [state, setState] = useState(initState);

  // socket.on("connect_error", (err: any) => {
  //   // the reason of the error, for example "xhr poll error"
  //   console.log(err?.message);

  //   // some additional description, for example the status code of the initial HTTP response
  //   console.log(err?.description);

  //   // some additional context, for example the XMLHttpRequest object
  //   console.log(err?.context);
  // });

  // socket.on("disconnect", (reason, details: any) => {
  //   // the reason of the disconnection, for example "transport error"
  //   console.log(reason);

  //   // the low-level reason of the disconnection, for example "xhr post error"
  //   console.log(details.message);

  //   // some additional description, for example the status code of the HTTP response
  //   console.log(details.description);

  //   // some additional context, for example the XMLHttpRequest object
  //   console.log(details.context);
  // });

  /**
   *
   */
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token && token !== "") {
      socket.emit("storageData");
      socket.on("storage", (data: any) => {
        localStorage.setItem("storage", JSON.stringify(data));
        const event = new Event("storage");
        window.dispatchEvent(event);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldUpdate]);

  /**
   *
   */
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token && token !== "") {
      socket.emit("oblioManagement");
      socket.on("managements", (data: any) => {
        //get storage data and update managements property
        localStorage.setItem("managements", JSON.stringify(data.managements));
        const event = new Event("managements");
        window.dispatchEvent(event);
      });
    }
  }, [state.updateOblioStorage]);

  return (
    <WebSocketContext.Provider value={state}>
      {props.children}
    </WebSocketContext.Provider>
  );
};
