import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import genericStyles from "../../Styles/genericStyles.module.css";
import { PaymentPanelProps } from "../../Utils/Types";
import { Controller } from "react-hook-form";

export default function PaymentPanel(props: PaymentPanelProps) {
  const { disabled, setValue, paymentMethods, control, watch } = props;

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newPayment: string
  ) => {
    setValue("payment_method_title", newPayment, { shouldDirty: true });
  };

  return (
    <Controller
      name="payment_method_title"
      control={control}
      render={({ field }) => {
        return (
          <ToggleButtonGroup
            {...field}
            onChange={handleChange}
            exclusive
            color="primary"
            style={{ backgroundColor: props?.error ? "#ffdcdf" : "#fff" }}
            className={genericStyles.toggleButtons}
            value={watch("payment_method_title") || ""}
            disabled={disabled}
            aria-label="Shipping"
            size="large"
          >
            {paymentMethods?.map((setting: any, index: any) => {
              return (
                <ToggleButton
                  key={index}
                  className={genericStyles.fullWidthElement}
                  value={setting.name}
                >
                  {setting.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        );
      }}
    ></Controller>
  );
}
