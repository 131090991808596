import {
  Button,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Fragment, createContext, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { RegisterWhatsAppPhoneNumberSteps } from "../../Utils/Constants";
import { PhoneRegistrationStepType } from "../../Utils/Types";
import genericStyles from "../../Styles/genericStyles.module.css";
import { RegisterWhatsAppPhoneNumberContextMode } from "../../Utils/Models";
import { Add } from "@mui/icons-material";
import styles from "../../Styles/whatsApp.module.css";
import NoDataFound from "../NoDataFound";
import Config from "../../Utils/Config";

export const RegisterWhatsAppPhoneNumberContext = createContext(
  new RegisterWhatsAppPhoneNumberContextMode()
);

export default function RegisterWhatsAppPhoneNumber() {
  const [activeStep, setActiveStep] = useState(0);
  const [shouldAddNumber, setShouldAddNumber] = useState(false);

  /**
   *
   */
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   *
   */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   *
   */
  const handleAddPhoneNumber = () => {
    setShouldAddNumber(true);
  };

  return (
    <Fragment>
      {shouldAddNumber ? (
        <RegisterWhatsAppPhoneNumberContext.Provider
          value={{ handleNext, activeStep }}
        >
          <Paper style={{ padding: "20px" }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {RegisterWhatsAppPhoneNumberSteps.map(
                (step: PhoneRegistrationStepType, index: number) => {
                  return (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          index === 2 ? (
                            <Typography variant="caption">
                              {Vocabulary.lastStep}
                            </Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Grid container spacing={Config.standardGridSpacing} sx={{ padding: "20px" }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {step.component}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={2}>
                            <Button
                              variant="contained"
                              onClick={handleBack}
                              fullWidth
                              disabled={index === 0}
                            >
                              {Vocabulary.back}
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={2}>
                            <Button
                              variant="contained"
                              type="submit"
                              fullWidth
                              form={step.formName}
                            >
                              {index ===
                              RegisterWhatsAppPhoneNumberSteps.length - 1
                                ? Vocabulary.finish
                                : Vocabulary.continue}
                            </Button>
                          </Grid>
                        </Grid>
                      </StepContent>
                    </Step>
                  );
                }
              )}
            </Stepper>
          </Paper>
        </RegisterWhatsAppPhoneNumberContext.Provider>
      ) : (
        <Paper
          className={` ${genericStyles.centeredElementColumn} ${styles.whatsAppPhoneNumberAddButtonContainer}`}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            className={styles.whatsAppPhoneNumberAddButton}
            onClick={handleAddPhoneNumber}
          >
            {Vocabulary.addPhoneNumber}
          </Button>
          <NoDataFound />
        </Paper>
      )}
    </Fragment>
  );
}
