import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import styles from "../../Styles/settings.module.css";
import Config from "../../Utils/Config";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { FormsIds } from "../../Utils/Constants";
import { Save } from "@mui/icons-material";
import { getData } from "../../Services/getData";
import { endpoints } from "../../Utils/UrlEnum";
import { useEffect, useRef, useState } from "react";
import { updateData } from "../../Services/updateData";
import GenericModal from "../GenericModal";
import StandardActionButtons from "../StandarsActionButtons";
import { WhatsAppSettingsValidationSchema } from "../../Utils/Models";
import useCustomMemo from "../../Hooks/useCustomMemo";

export default function WhatsAppSettings() {
  const [open, setOpen] = useState(false);
  const formRef: any = useRef(null);
  const methods = useForm({
    resolver: yupResolver(WhatsAppSettingsValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const memo = useCustomMemo();
  const whatsAppTemplates = memo?.storage?.whatsAppTemplates || null;

  /**
   *
   */
  const handleOpenState = () => {
    setOpen(!open);
  };

  /**
   *
   */
  useEffect(() => {
    getCurrentSettings();
  }, []);

  /**
   *
   */
  const getCurrentSettings = () => {
    getData(endpoints.whatsApp.waba).then((response) => {
      if (response && response.data) {
        methods.reset(response.data);
      }
    });
  };

  /**
   *
   * @param data
   */
  const handleSubmit = (data: any) => {
    updateData(`${endpoints.whatsApp.waba}`, data).then((response) => {
      if (response && response.data) {
        methods.reset(response.data);
      }
      handleOpenState();
    });
  };

  /**
   *
   * @param error
   */
  const handleErrors = (error: any) => {
    // console.log(error);
  };

  return (
    <form
      onSubmit={methods.handleSubmit(handleSubmit, handleErrors)}
      id={FormsIds.whatsAppSettingsForm}
      className={styles.settingsForm}
      ref={formRef}
    >
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TextField
            {...methods.register("whatsAppBusinessAccountId")}
            name="whatsAppBusinessAccountId"
            id="whatsAppBusinessAccountId"
            label={Vocabulary.whatsAppBusinessAccountId}
            fullWidth
            variant="outlined"
            error={
              typeof methods.formState.errors.whatsAppBusinessAccountId ===
              "object"
            }
            helperText={methods.formState.errors.whatsAppBusinessAccountId?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TextField
            {...methods.register("name")}
            name="name"
            id="name"
            label={Vocabulary.whatsAppBusinessAccountName}
            fullWidth
            variant="outlined"
            error={typeof methods.formState.errors.name === "object"}
            helperText={methods.formState.errors.name?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TextField
            {...methods.register("phoneNumberId")}
            name="phoneNumberId"
            id="phoneNumberId"
            label={Vocabulary.whatsAppBusinessPhoneNumberId}
            fullWidth
            variant="outlined"
            error={typeof methods.formState.errors.phoneNumberId === "object"}
            helperText={methods.formState.errors.phoneNumberId?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TextField
            {...methods.register("phoneNumber")}
            name="phoneNumber"
            id="phoneNumber"
            label={Vocabulary.phoneNumber}
            fullWidth
            variant="outlined"
            error={typeof methods.formState.errors.phoneNumber === "object"}
            helperText={methods.formState.errors.phoneNumber?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TextField
            {...methods.register("verifiedName")}
            name="verifiedName"
            id="verifiedName"
            label={Vocabulary.whatsAppBusinessVerifiedName}
            fullWidth
            variant="outlined"
            error={typeof methods.formState.errors.verifiedName === "object"}
            helperText={methods.formState.errors.verifiedName?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Controller
            name={`whatsAppTemplateAssociatedWithOrderOffer`}
            control={methods.control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                value={field.value || " "}
                freeSolo={false}
                disablePortal
                disableClearable
                clearOnBlur={true}
                options={whatsAppTemplates?.data || []}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id == value.id
                }
                getOptionLabel={(option: any) => option?.name || ""}
                onChange={(event, value: any) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    value={field?.value || " "}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.orderOfferWhatsappTemplate}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Button
            startIcon={<Save />}
            onClick={handleOpenState}
            variant="contained"
          >
            {Vocabulary.saveWhatsAppSettings}
          </Button>
        </Grid>
      </Grid>
      <GenericModal
        open={open}
        onClose={handleOpenState}
        title={Vocabulary.confirm}
        actions={
          <StandardActionButtons
            cancelAction={handleOpenState}
            okText={Vocabulary.yes}
            cancelText={Vocabulary.no}
            formId={FormsIds.whatsAppSettingsForm}
            type={"submit"}
          />
        }
      >
        <Typography>
          {Vocabulary.whatsAppBusinessAccountDataUpdateMessage}
        </Typography>
      </GenericModal>
    </form>
  );
}
