import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import styles from "../../Styles/settings.module.css";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CloudSync, Save } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect } from "react";
import { getData } from "../../Services/getData";
import { AxiosResponse } from "axios";
import { endpoints } from "../../Utils/UrlEnum";
import { updateData } from "../../Services/updateData";
import { OblioSettingsValidationSchema } from "../../Utils/Models";
import { WebSocketContext } from "../../Context/WebSocketContext";
import useCustomMemo from "../../Hooks/useCustomMemo";

export default function OblioSettings() {
  const websocketContext = useContext(WebSocketContext);
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(OblioSettingsValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const memo = useCustomMemo();
  const managements = memo?.managements || [];

  /**
   *
   */
  useEffect(() => {
    getData(endpoints.setting.oblio).then((res: AxiosResponse) => {
      if (res?.data) {
        reset(res.data);
      }
    });
  }, []);

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    const newMethod = Object.assign({}, data);
    const url = `${endpoints.setting.main}${newMethod?._id}`;
    updateData(url, newMethod).then((res: AxiosResponse) => {
      if (res?.data) {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        reset(res.data);
      }
    });
  };

  /**
   *
   */
  const handleSyncProducts = () => {
    getData(endpoints.oblio.syncProducts).then((res: AxiosResponse) => {
      if (res?.data) {
        // console.log(res.data);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.settingsForm}>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={5}>
          <Controller
            name="value.ignoredIds"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                id="ignoredIds"
                name="ignoredIds"
                label={Vocabulary.ignoreOblioIdsList}
                fullWidth
                variant="outlined"
                onChange={onChange}
                value={value || ""}
                ref={ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={4}>
          <Controller
            name="value.defaultManagement"
            control={control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                value={field.value || " "}
                freeSolo={false}
                disablePortal
                disableClearable
                clearOnBlur={true}
                options={managements}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option.management === value.management;
                }}
                getOptionLabel={(option: any) =>
                  option?.management || option || ""
                }
                onChange={(event, value: any) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    value={field?.value || " "}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.defaultManagement}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid
          className={styles.oblioSettingsButtonContainer}
          item
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={1}
        >
          <Button
            type="submit"
            fullWidth
            startIcon={<Save />}
            variant="contained"
          >
            {Vocabulary.save}
          </Button>
        </Grid>
        <Grid
          className={styles.oblioSettingsButtonContainer}
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          xl={2}
        >
          <Button
            fullWidth
            onClick={handleSyncProducts}
            startIcon={<CloudSync />}
            color="secondary"
            variant="contained"
          >
            {Vocabulary.syncProducts}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
