import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/LoginPage";
import { localUrlEnum, pathParams } from "./Utils/UrlEnum";
import Loading from "./Utils/Loading";
import CustomThemeProvider from "./Context/CustomThemeProvider";
import LocalRouter from "./Router/LocalRouter";
import { WebsocketProvider } from "./Context/WebSocketContext";
import OrderOfferPasswordPage from "./Pages/OrderOfferPasswordPage";
import PageNotFound from "./Components/PageNotFound";
import { Vocabulary } from "./Utils/Vocabulary";
import OrderOfferPage from "./Pages/OrderOfferPage";
import OrderOfferConfirmPage from "./Pages/OrderOfferConfirmPage";

export default function App() {
  return (
    <CustomThemeProvider>
      <WebsocketProvider>
        <ToastContainer hideProgressBar={true} />
        {window.localStorage.getItem("userRoles") ? (
          <LocalRouter name={"LocalRouter"} />
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path={localUrlEnum.login} element={<Login />} />
              <Route path={localUrlEnum.mainRoute} element={<Login />} />
              <Route
                path={`${localUrlEnum.orderOfferPasswordCheck}${pathParams.orderId}`}
                element={<OrderOfferPasswordPage />}
              />
              <Route
                path={`${localUrlEnum.orderOffer}${pathParams.orderId}`}
                element={<OrderOfferPage />}
              />
              <Route
                path={`${localUrlEnum.orderOfferConfirmation}${pathParams.orderId}`}
                element={<OrderOfferConfirmPage />}
              />
              <Route
                path={localUrlEnum.pageNotFound}
                element={<PageNotFound text={Vocabulary.pageNotFound} />}
              />
            </Routes>
          </BrowserRouter>
        )}
        <Loading />
      </WebsocketProvider>
    </CustomThemeProvider>
  );
}
