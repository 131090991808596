import axios from "axios";
import { HttpStatusCode, HttpVerbs } from "./Constants";
import { Vocabulary } from "./Vocabulary";
import { toast } from "react-toastify";
import { localUrlEnum, endpoints } from "./UrlEnum";
import { logout } from "./Utils";

const notifyError = (message: string) => toast.error(message);
const notifySuccess = (message: string) => toast.success(message);

const token = localStorage.getItem("access_token");
let axiosConfig = {} as any;
if (token) {
  axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
let requestNumber = 0;
const request = axios.create(axiosConfig);
request.interceptors.response.use(
  (response) => {
    if (
      (response.status === HttpStatusCode.OK ||
        response.status === HttpStatusCode.Created) &&
      response.config.url !== endpoints.login
    ) {
      handleSuccess(response.config.method, response.data?.message);
    }
    requestNumber--;
    if (requestNumber === 0) {
      stopLoading();
    }
    return response;
  },
  (error) => {
    requestNumber--;
    switch (error.response.status) {
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.UnprocessableEntity:
        logout();
        window.location.href = localUrlEnum.login;
        break;
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.InternalServerError:
      case HttpStatusCode.Forbidden:
        handleError(error);
        break;
      case HttpStatusCode.NotFound:
        notifyError(error?.response?.data?.message || Vocabulary.notFound);
        break;
      default:
        notifyError(Vocabulary.error);
        break;
    }
    stopLoading();
  }
);

request.interceptors.request.use(
  (config) => {
    requestNumber++;
    startLoading();
    return config;
  },
  (error) => {
    stopLoading();
  }
);

/**
 * Start loading
 */
function startLoading() {
  const element = document.getElementById("loading");
  element?.classList.remove("loadingDisabled");
  element?.classList.add("loading");
}

/**
 * Stop loading
 */
function stopLoading() {
  const element = document.getElementById("loading");
  element?.classList.remove("loading");
  element?.classList.add("loadingDisabled");
}

/**
 * Handle error
 * @param error
 */
const handleError = (error: any) => {
  if (error.response.data.message && error.response.data.message !== "")
    if (Array.isArray(error.response.data.message)) {
      //check if error response data message is array and display the messages in a single notification
      //else display the message as a single notification
      let message = "";
      error.response.data.message.forEach((element: any) => {
        message += `${element} `;
      });
      notifyError(message);
    } else notifyError(error.response.data.message);
  else {
    switch (error.config.method) {
      case HttpVerbs.post:
        notifyError(Vocabulary.createDataError);
        break;
      case HttpVerbs.put:
      case HttpVerbs.patch:
        notifyError(Vocabulary.updateDataError);
        break;
      case HttpVerbs.delete:
        notifyError(Vocabulary.deleteDataError);
        break;
      default:
        break;
    }
  }
};

/**
 *
 * @param method
 */
const handleSuccess = (method: string | undefined, message?: string) => {
  if (message) {
    notifySuccess(message);
    return;
  }
  switch (method) {
    case HttpVerbs.post:
      notifySuccess(Vocabulary.createDataSuccess);
      break;
    case HttpVerbs.put:
    case HttpVerbs.patch:
      notifySuccess(Vocabulary.updateDataSuccess);
      break;
    case HttpVerbs.delete:
      notifySuccess(Vocabulary.deleteDataSuccess);
      break;
    default:
      break;
  }
};

export default request;
