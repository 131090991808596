import { Grid, TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ErrorOutline } from "@mui/icons-material";
import styles from "../../Styles/order.module.css";
import { BillingDocClientDataProps } from "../../Utils/Types";

export default function BillingDocClientData(props: BillingDocClientDataProps) {
  const { register, formState, clientData } = props;
  return (
    <>
      <Grid item xs={12} sm={12} md={12} className={styles.invoicePanelWarning}>
        <ErrorOutline fontSize="large" />
        <Typography>{Vocabulary.invoiceWarning}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          {...register("client.name")}
          label={Vocabulary.client}
          disabled
          variant="outlined"
          error={typeof formState.errors.client?.name === "object"}
          helperText={formState.errors.client?.name?.message?.toString()}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="body2">{`${
          clientData?.cif ? `${Vocabulary.fiscalCode},` : ""
        } ${`${clientData?.cif ? `${clientData?.cif},` : ""}`} ${
          clientData?.rc ? `${clientData.rc},` : ""
        }`}</Typography>
        <Typography variant="body2">{`${
          clientData?.address ? `${clientData?.address},` : ""
        } ${clientData?.city ? `${clientData?.city},` : ""} ${
          clientData?.state ? `${clientData?.state},` : ""
        }, ${clientData?.country || ""}`}</Typography>
        <Typography variant="body2">{`${clientData?.phone || ""}`}</Typography>
      </Grid>
    </>
  );
}
