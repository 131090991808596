import { useEffect, useMemo, useState } from "react";

export default function useCustomMemo() {
  const [cache, setCache] = useState(localStorage.getItem("storage"));
  const [oblioManagements, setOblioManagements] = useState(
    localStorage.getItem("managements")
  );

  /**
   *
   */
  useEffect(() => {
    const handleStorageChange = () => {
      setCache(localStorage.getItem("storage"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  useEffect(() => {
    const handleStorageChange = () => {
      setOblioManagements(localStorage.getItem("managements"));
    };

    window.addEventListener("managements", handleStorageChange);

    return () => {
      window.removeEventListener("managements", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  const storage = useMemo(() => {
    const localStorageCache = localStorage.getItem("storage");
    if (!localStorageCache) return [];
    const cache = JSON.parse(localStorageCache);
    if (cache) return cache || [];
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache]);

  const managements = useMemo(() => {
    const localStorageManagements = localStorage.getItem("managements");
    if (!localStorageManagements) return [];
    const managements = JSON.parse(localStorageManagements);
    if (managements) return managements || [];
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oblioManagements]);

  return { storage, managements };
}
