import {
  Autocomplete,
  Button,
  DialogActions,
  Grid,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  PickersActionBarProps,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Fragment, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import Config from "../../Utils/Config";
import { EWeekDays } from "../../Utils/Constants";
import { Vocabulary } from "../../Utils/Vocabulary";
import { getData } from "../../Services/getData";
import { endpoints } from "../../Utils/UrlEnum";
import { AxiosResponse } from "axios";
import { PeriodicityProps } from "../../Utils/Types";

export default function Periodicity(props: PeriodicityProps) {
  const {
    periodicityLength,
    control,
    client,
    getValues,
    setValue,
    renderLess,
  } = props;
  const [periodicityNames, setPeriodicityNames] = useState([]);
  const [disableDay, setDisableDay] = useState(true);

  /**
   *
   */
  useEffect(() => {
    getData(endpoints.periodicityNamesEndpoint).then((res: AxiosResponse) => {
      setPeriodicityNames(res?.data);
    });
    // //COMPLETARE AUTOMATA PERIODICITATE PENTRU ZI IN FUNCTIE DE DATA (DACA ESTE UN CLIENT NOU )
    // if (!client._id) {
    //   setValue(
    //     `periodicity.${
    //       periodicityLength ? periodicityLength - 1 : 0
    //     }.nextContactDate`,
    //     moment(new Date()).format(Config.momentUSDateFormat)
    //   );
    //   setValue(
    //     `periodicity.${periodicityLength ? periodicityLength - 1 : 0}.day`,
    //     EWeekDays?.find(
    //       (element: any) => element?.value === moment(new Date()).day()
    //     )
    //   );
    // } else {
    //   //if the nextContactDate exist set the day based on the date
    //   if (
    //     getValues(
    //       `periodicity.${
    //         periodicityLength ? periodicityLength - 1 : 0
    //       }.nextContactDate`
    //     )
    //   ) {
    //     setValue(
    //       `periodicity.${periodicityLength ? periodicityLength - 1 : 0}.day`,
    //       EWeekDays?.find(
    //         (element: any) =>
    //           element?.value ===
    //           moment(
    //             getValues(
    //               `periodicity.${
    //                 periodicityLength ? periodicityLength - 1 : 0
    //               }.nextContactDate`
    //             )
    //           ).day()
    //       )
    //     );
    //   }
    // }
  }, []);

  /**
   *
   * @param props
   * @returns
   */
  function CustomActionBar(props: PickersActionBarProps) {
    const { onClear, actions, className } = props;
    if (actions == null || actions.length === 0) {
      return null;
    }
    return (
      <DialogActions className={className}>
        <Button
          data-mui-test="clear-action-button"
          onClick={() => {
            onClear();
            setDisableDay(false);
          }}
        >
          {Vocabulary.delete}
        </Button>
      </DialogActions>
    );
  }

  /**
   *
   * @param onChange
   * @param date
   */
  const handleChangeNextContactDate = (onChange: any, date: any) => {
    onChange(moment(date).format(Config.momentUSDateFormat));
    setValue(
      `periodicity.${periodicityLength ? periodicityLength - 1 : 0}.day`,
      EWeekDays?.find((element: any) => element?.value === moment(date).day())
    );
    //add one day to next transport date
    //if the selected day is saturday move it to monday (next working day)
    if (moment(date).day() === 5) {
      setValue(
        `periodicity.${
          periodicityLength ? periodicityLength - 1 : 0
        }.nextTransportDate`,
        moment(date).add(3, "days").format(Config.momentUSDateFormat)
      );
    } else {
      setValue(
        `periodicity.${
          periodicityLength ? periodicityLength - 1 : 0
        }.nextTransportDate`,
        moment(date).add(1, "days").format(Config.momentUSDateFormat)
      );
    }
    setDisableDay(true);
  };

  return (
    <Fragment>
      {renderLess ? null : (
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={`periodicity.${
              periodicityLength ? periodicityLength - 1 : 0
            }.name`}
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={true}
                disableClearable
                getOptionDisabled={(option: any) =>
                  !!client?.roleIds?.find((element: any) => element === option)
                }
                onChange={(event, value) => {
                  field.onChange(value);
                }}
                value={getValues(
                  `periodicity.${
                    periodicityLength ? periodicityLength - 1 : 0
                  }.name`
                )}
                onInputChange={(event, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(option: any) => option}
                id="periodicity.name"
                options={periodicityNames}
                renderInput={(params) => (
                  <TextField
                    label={Vocabulary.periodicityName}
                    name="periodicity.name"
                    type="search"
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={renderLess ? 4.5 : 3.5}>
        <Controller
          name={`periodicity.${
            periodicityLength ? periodicityLength - 1 : 0
          }.nextContactDate`}
          control={control}
          defaultValue=""
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                {...field}
                sx={{ width: "100%" }}
                views={["year", "month", "day"]}
                format={Config.momentEUDateFormat}
                slots={{
                  actionBar: CustomActionBar,
                }}
                slotProps={{
                  actionBar: { actions: ["clear"] },
                }}
                name="periodicity.nextContactDate"
                label={Vocabulary.nextContactDate}
                value={
                  getValues(
                    `periodicity.${
                      periodicityLength ? periodicityLength - 1 : 0
                    }.nextContactDate`
                  )
                    ? moment(
                        getValues(
                          `periodicity.${
                            periodicityLength ? periodicityLength - 1 : 0
                          }.nextContactDate`
                        )
                      )
                    : null
                }
                defaultValue={null}
                minDate={moment(new Date())}
                onChange={(date: any) => {
                  handleChangeNextContactDate(field.onChange, date);
                }}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={renderLess ? 3 : 2}>
        <Controller
          name={`periodicity.${
            periodicityLength ? periodicityLength - 1 : 0
          }.day`}
          control={control}
          defaultValue={{ name: undefined, value: undefined }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Autocomplete
              freeSolo={false}
              disableClearable
              fullWidth
              disabled={disableDay}
              isOptionEqualToValue={(option, value) =>
                option?.name === value?.name
              }
              onChange={(event, value) => {
                field.onChange(value);
              }}
              value={getValues(
                `periodicity.${
                  periodicityLength ? periodicityLength - 1 : 0
                }.day`
              )}
              getOptionLabel={(option: any) => option?.name}
              id="day"
              options={EWeekDays}
              renderInput={(params) => (
                <TextField
                  label={Vocabulary.day}
                  name="day"
                  type="search"
                  {...params}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={renderLess ? 4.5 : 3.5}>
        <Controller
          name={`periodicity.${
            periodicityLength ? periodicityLength - 1 : 0
          }.nextTransportDate`}
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                {...field}
                sx={{ width: "100%" }}
                views={["year", "month", "day"]}
                format={Config.momentEUDateFormat}
                slots={{
                  actionBar: CustomActionBar,
                }}
                disabled={true}
                slotProps={{
                  actionBar: { actions: ["clear"] },
                }}
                name="periodicity.nextTransportDate"
                label={Vocabulary.nextTransportDate}
                value={
                  getValues(
                    `periodicity.${
                      periodicityLength ? periodicityLength - 1 : 0
                    }.nextTransportDate`
                  )
                    ? moment(
                        getValues(
                          `periodicity.${
                            periodicityLength ? periodicityLength - 1 : 0
                          }.nextTransportDate`
                        )
                      )
                    : null
                }
                defaultValue={null}
                minDate={moment(new Date())}
                onChange={(date: any) => {
                  field.onChange(
                    moment(date).format(Config.momentUSDateFormat)
                  );
                }}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Controller
          name={`periodicity.${
            periodicityLength ? periodicityLength - 1 : 0
          }.noDays`}
          defaultValue={14}
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              name="periodicity.noDays"
              id="noDays"
              type="number"
              label={Vocabulary.noDays}
              fullWidth
              onChange={(event) => field.onChange(event.target.value)}
              value={
                getValues(
                  `periodicity.${
                    periodicityLength ? periodicityLength - 1 : 0
                  }.noDays`
                ) || 0
              }
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Fragment>
  );
}
