import moment from "moment";

const Config: any = {
  apiUrl: "/api",
  momentUSDateFormat: "YYYY-MM-DD",
  momentEUDateFormat: "DD-MM-YYYY",
  datePickerFormat: "YYYY-MM-DD HH:mm:ss",
  momentEUDateTimeFormat: "DD-MM-YYYY HH:mm",
  momentEUTimeFormat: "HH:mm",
  standardGridSpacing: 2,
  rowsPerPage: 20,
  standardPage: 0,
  rowsPerPageOptions: [20, 50, 100],
  currencySymbol: "Lei",
  emptyOrderTotal: 0,
  elevation: 3,
  standardNewContactNoDays: 14,
  minSearchLength: 3,
  defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
  defaultEndDate: moment().format("YYYY-MM-DD"),
  poolingNewChatsInterval: 30000,
  autoUpdateInterval: 600000, // 600000 milliseconds = 10 minutes
  phoneRegExp:
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
};
export default Config;
