import MainHeader from "./MainHeader";
import { useEffect, useState } from "react";
import MainSlider from "./MainSlider";
import styles from "../Styles/dashboardContainer.module.css";
import { ComponentsNames, MainSliderDataForUser } from "../Utils/Constants";
import { Outlet, useNavigate } from "react-router-dom";
import { localUrlEnum } from "../Utils/UrlEnum";
import { isMobile } from "../Utils/Utils";

export default function DashboardContainer(props: any) {
  const navigate = useNavigate();
  const [hideSlider, setHideSlider]: any = useState(false);

  /**
   *
   */
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      navigate(localUrlEnum.login);
    }
  }, []);

  /**
   *
   */
  const handleToggleSlider = () => {
    setHideSlider(!hideSlider);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainHeader}>
        <MainHeader
          name={ComponentsNames?.MainHeader}
          shouldRenderSearchBar={props.shouldRenderSearchBar}
          setHideSlider={handleToggleSlider}
        />
      </div>
      {isMobile() ? (
        <div className={styles.secondContainer}>
          <div
            className={hideSlider ? styles.overlay : styles.overlayHide}
            onClick={handleToggleSlider}
          >
            <div
              className={hideSlider ? styles.mainSlider : styles.mainSliderHide}
            >
              <MainSlider
                name={ComponentsNames.MainSlider}
                data={MainSliderDataForUser}
                setHideSlider={handleToggleSlider}
              />
            </div>
          </div>

          <Outlet />
        </div>
      ) : (
        <div className={styles.secondContainer}>
          <div
            className={hideSlider ? styles.mainSlider : styles.mainSliderHide}
          >
            <MainSlider
              name={ComponentsNames.MainSlider}
              data={MainSliderDataForUser}
              setHideSlider={handleToggleSlider}
            />
          </div>

          <Outlet />
        </div>
      )}
    </div>
  );
}
