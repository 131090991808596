import { IconButton, Typography } from "@mui/material";
import GenericModal from "../GenericModal";
import { Info } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { EWidths } from "../../Utils/Enums";
import { useState } from "react";

export default function ChatExtraInfo() {
  const [open, setOpen] = useState<boolean>(false);
  /**
   *
   */
  const toggleModalState = () => {
    setOpen(!open);
  };
  return (
    <>
      <IconButton sx={{ color: "#FFFF" }} onClick={toggleModalState}>
        <Info />
      </IconButton>
      <GenericModal
        maxWidth={EWidths.MD}
        onClose={toggleModalState}
        open={open}
        title={Vocabulary.usefulInfo}
        children={<Typography>{Vocabulary.sendMessagesInfo}</Typography>}
      />
    </>
  );
}
