import { Button, Grid, IconButton, Popover, Typography } from "@mui/material";
import { EmailTemplateDeleteImagesProps } from "../../Utils/Types";
import { Fragment, useState } from "react";
import { Delete } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/emailTemplate.module.css";
import NoDataFound from "../NoDataFound";

export default function EmailTemplateDeleteImages(
  props: EmailTemplateDeleteImagesProps
) {
  const { emailTemplate, handleDeleteImage } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  /**
   *
   * @param event
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={styles.emailTemplateAddImageButton}
        startIcon={<Delete />}
        aria-describedby={id}
        variant="outlined"
        color="secondary"
        onClick={handleClick}
      >
        {Vocabulary.deleteImages}
      </Button>
      <Popover
        id={id}
        className={styles.emailTemplatePopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: 30 }}>
          <Grid container spacing={1}>
            {emailTemplate?.attachments && emailTemplate.attachments.length ? (
              emailTemplate.attachments.map((attachment, index) => {
                return (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={12} md={8} lg={10}>
                      <Typography>{attachment.alt}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <IconButton
                        onClick={() => {
                          handleDeleteImage(attachment);
                          handleClose();
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Fragment>
                );
              })
            ) : (
              <NoDataFound />
            )}
          </Grid>
        </div>
      </Popover>
    </div>
  );
}
