import { createContext, useState } from "react";
import { EmailTemplateModel } from "../../Utils/Models";
import MUIDataTableCustomStyle from "../MUIDataTableCustomStyle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { endpoints } from "../../Utils/UrlEnum";
import genericStyles from "../../Styles/genericStyles.module.css";
import EmailTemplateForm from "./EmailTemplateForm";
import { ETableEditAction, EWidths } from "../../Utils/Enums";
import GenericModal from "../GenericModal";
import { Button } from "@mui/material";
import { FormsIds, TablesIds } from "../../Utils/Constants";

export const CRUDContext = createContext(new EmailTemplateModel());

export default function EmailTemplatesTable() {
  const [contextValue, setContextValue] = useState(new EmailTemplateModel());
  const [open, setOpen] = useState(false);
  const [triggerReload, setTriggerReload] = useState<undefined | boolean>();
  const emailTemplatesColumns = [
    {
      label: `${Vocabulary.name}`,
      name: "emailTemplateName",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.subject}`,
      name: "subject",
      options: {
        sort: false,
      },
    },
  ];

  /**
   *
   */
  const handleCloseModal = () => {
    setOpen(false);
    setContextValue(new EmailTemplateModel());
    setTriggerReload(triggerReload !== undefined ? !triggerReload : false);
  };

  return (
    <div className={genericStyles.mainContainer}>
      <CRUDContext.Provider value={contextValue}>
        <MUIDataTableCustomStyle
          buttons={[]}
          onEditAction={ETableEditAction.Modal}
          title={Vocabulary.emailTemplates}
          columns={emailTemplatesColumns}
          CRUDEndPoint={endpoints.emailTemplatesEndpoint}
          handleModalForm={setOpen}
          triggerReload={triggerReload}
          id={TablesIds.emailTemplates}
          addOrEditForm={
            <GenericModal
              onClose={handleCloseModal}
              maxWidth={EWidths.LG}
              open={open}
              actions={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    color="secondary"
                    onClick={handleCloseModal}
                    variant="outlined"
                    fullWidth
                  >
                    {Vocabulary.cancel}
                  </Button>
                  <Button
                    fullWidth
                    type="submit"
                    form={FormsIds.editEmailTemplateForm}
                    variant="contained"
                  >
                    {Vocabulary.save}
                  </Button>
                </div>
              }
              title={Vocabulary.emailTemplate}
            >
              <EmailTemplateForm onClose={handleCloseModal} />
            </GenericModal>
          }
          setContextValue={setContextValue}
        />
      </CRUDContext.Provider>
    </div>
  );
}
