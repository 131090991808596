import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Visibility } from "@mui/icons-material";
import styles from "../Styles/loginPage.module.css";
import { Button, Paper, IconButton, InputAdornment, Grid } from "@mui/material";

import { Vocabulary } from "../Utils/Vocabulary";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../Services/getData";
import { localUrlEnum, endpoints } from "../Utils/UrlEnum";
import { AxiosResponse } from "axios";

export default function OrderOfferPasswordPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { orderId } = useParams();

  /**
   *
   */
  useEffect(() => {
    if (
      !orderId ||
      orderId === "null" ||
      orderId === "undefined" ||
      orderId === ""
    ) {
      navigate(localUrlEnum.pageNotFound);
    }
  }, [orderId]);

  /**
   *
   */
  const handleSubmit = () => {
    getData(
      `${endpoints.getOrderOfferForClient}?password=${password}&orderId=${orderId}`
    ).then((response: AxiosResponse) => {
      if (response && response?.data) {
        //save password in local storage
        window.localStorage.setItem("orderOfferPassword", password);
        navigate(`${localUrlEnum.orderOffer}/${response?.data?._id}`, {
          state: response?.data,
        });
      }
    });
  };

  return (
    <div className={styles.App}>
      <Paper className={styles.loginContainer} elevation={5}>
        <Grid container spacing={1} className={styles.gridMainContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={styles.firstGrid}
          >
            <img src={"/logo.png"} alt="EcoPack" width={"50%"} />
            <ValidatorForm className={styles.form} onSubmit={handleSubmit}>
              <TextValidator
                variant="standard"
                id="password"
                name="password"
                type={!showPassword ? "password" : "text"}
                label={Vocabulary.password}
                fullWidth
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                value={password}
                validators={["required"]}
                className={styles.textfield}
                errorMessages={[Vocabulary.requiredField]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: "#a5b3c0" }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                type="submit"
                className={styles.submit}
              >
                {Vocabulary.login}
              </Button>
            </ValidatorForm>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
