import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete } from "@mui/icons-material";
import styles from "../../Styles/order.module.css";
import { EditProduct } from "../Orders/EditProduct";
import CloseIcon from "@mui/icons-material/Close";
import {
  calcDiscount,
  calcPriceWithoutTVA,
  calcTVAValue,
  calcTVAValueFromPriceWithoutTVA,
  calcValue,
  calcValueWithTVA,
} from "../../Utils/Utils";
import { ProductPanelOrderOfferProps } from "../../Utils/Types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { endpoints } from "../../Utils/UrlEnum";

export default function ProductPanelOrderOffer(
  props: ProductPanelOrderOfferProps
) {
  const { index } = props;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const password = window.localStorage.getItem("orderOfferPassword");

  /**
   *
   * @param index \
   */
  const deleteLine = (index: any) => {
    const linesCopy = props.getValues("line_items");
    linesCopy.splice(index, 1);
    props.setValue("line_items", linesCopy);
  };

  /**
   *
   */
  useEffect(() => {
    makeSubTotals();
  }, []);

  /**
   *Functia in care se calculeaza toate totalele per line_item in fuctie de toate campurile editalible
   */
  const makeSubTotals = () => {
    props.setValue(
      `line_items[${index}].discountValue`,
      calcDiscount(
        props.getValues(`line_items[${index}].discount`),
        props.getValues(`line_items[${index}].quantity`),
        props.getValues(`line_items[${index}].priceWithoutTVA`)
      )
    );
    props.setValue(
      `line_items[${index}].subtotal_tax`,
      calcValue(
        props.getValues(`line_items[${index}].discount`),
        props.getValues(`line_items[${index}].quantity`),
        props.getValues(`line_items[${index}].priceWithoutTVA`)
      ).toFixed(2)
    );

    props.setValue(
      `line_items[${index}].unitTVA`,
      calcTVAValueFromPriceWithoutTVA(
        props.getValues(`line_items[${index}].priceWithoutTVA`),
        props.getValues(`line_items[${index}].TVA`)
      ).toFixed(2)
    );

    props.setValue(
      `line_items[${index}].total`,
      calcValueWithTVA(
        calcValue(
          props.getValues(`line_items[${index}].discount`),
          props.getValues(`line_items[${index}].quantity`),
          props.getValues(`line_items[${index}].priceWithoutTVA`)
        ),
        props.getValues(`line_items[${index}].TVA`)
      ).toFixed(2)
    );
  };

  /**
   *
   * @param e
   * @param product
   * @param reason
   * @param details
   */
  const handleProductChange = (
    e: any,
    product: any,
    reason: any,
    details: any
  ) => {
    if (reason === "selectOption") {
      props.setValue(`line_items[${index}].product.id`, product);
      props.setValue(`line_items[${index}]`, product);
      props.setValue(`line_items[${index}].quantity`, 1);
      props.setValue(`line_items[${index}].discount`, 0);
      props.setValue(`line_items[${index}].price`, product?.price);
      //DOAR LE PUN AICI SA FIE GOALE CA SUNT OBLIGATORII
      props.setValue(`cart_tax`, "");
      props.setValue(`shipping_tax`, "");
      props.setValue(`discount_tax`, "");
      props.setValue(
        `status`,
        props.watch("status") ? props.watch("status") : null
      );
      props.setValue(
        `line_items[${index}].unitTVA`,
        calcTVAValue(
          props.getValues(`line_items[${index}].price`),
          props.getValues(`line_items[${index}].TVA`)
        ).toFixed(2)
      );
      //Prima oara se calculeaza priceWithoutTVA din pretul de pe wordpress
      props.setValue(
        `line_items[${index}].priceWithoutTVA`,
        calcPriceWithoutTVA(
          props.getValues(`line_items[${index}].price`),
          props.getValues(`line_items[${index}].TVA`)
        )
      );

      props.setValue(
        `line_items[${index}].subtotal_tax`,
        calcValue(
          props.getValues(`line_items[${index}].discount`),
          props.getValues(`line_items[${index}].quantity`),
          props.getValues(`line_items[${index}].priceWithoutTVA`)
        ).toFixed(2)
      );
      props.setValue(
        `line_items[${index}].total`,
        calcValueWithTVA(
          calcValue(
            props.getValues(`line_items[${index}].discount`),
            props.getValues(`line_items[${index}].quantity`),
            props.getValues(`line_items[${index}].priceWithoutTVA`)
          ),
          props.getValues(`line_items[${index}].TVA`)
        ).toFixed(2)
      );
      makeSubTotals();
    }
  };

  return (
    <Accordion sx={{ marginBottom: "10px" }}>
      <AccordionSummary
        expandIcon={<MoreHorizIcon className={styles.expandButton} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Grid
          container
          spacing={Config.standardGridSpacing}
          className={styles.accordionSummary}
          onClick={(event: any) => event.stopPropagation()}
        >
          <Grid container className={styles.secondGrid}>
            <Grid className={styles.containerProduct}>
              <Grid item xs={12} sm={12} md={4} className={styles.productImage}>
                <img
                  width={"100%"}
                  height={"100%"}
                  src={
                    props.getValues(`line_items[${index}].image`)
                      ? props.getValues(`line_items[${index}].image`)
                      : "/missingImage.png"
                  }
                  alt=""
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={styles.autocompleteGrid}
              >
                <EditProduct
                  searchUrl={`${
                    endpoints.getPublicProductsSearch
                  }?password=${password}&orderId=${props.getValues(
                    "_id"
                  )}&search=`}
                  control={props.control}
                  index={index}
                  getValues={props.getValues}
                  watch={props?.watch}
                  disabled={props.getValues("wordPress_Id") ? true : false}
                  //La Onchange se fac automat calculale asemanatoare cu cele din makeSubTotals plus popularea campurilor din billing si shipping
                  onProductChange={(e, product, reason, details) => {
                    handleProductChange(e, product, reason, details);
                  }}
                  products={props?.products}
                  isOrderOffer={true}
                />
              </Grid>
            </Grid>
            <Grid className={styles.detailsProduct} container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  type="number"
                  className={styles.quantityOrderOffer}
                  label={Vocabulary.quantity}
                  disabled={props.disabled}
                  {...props.register(`line_items[${index}].quantity`)}
                  name={`line_items[${index}].quantity`}
                  id={`line_items[${index}].quantity`}
                  fullWidth
                  variant="standard"
                  onChange={(e: any) => {
                    props.setValue(
                      `line_items[${index}].quantity`,
                      e.target.value
                    );
                    makeSubTotals();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6}>
                <TextField
                  type="number"
                  label={Vocabulary.unitPrice}
                  disabled
                  {...props.register(`line_items[${index}].priceWithoutTVA`)}
                  name={`line_items[${index}].priceWithoutTVA`}
                  id={`line_items[${index}].priceWithoutTVA`}
                  fullWidth
                  variant="standard"
                  onChange={(e: any) => {
                    props.setValue(
                      `line_items[${index}].priceWithoutTVA`,
                      e.target.value
                    );
                    makeSubTotals();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {Vocabulary.currency}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  {...props.register(`line_items[${index}].subtotal_tax`)}
                  name={`line_items[${index}].subtotal_tax`}
                  id={`line_items[${index}].subtotal_tax`}
                  label={Vocabulary.valueWithoutTVA}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {Vocabulary.currency}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="standard"
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  {...props.register(`line_items[${index}].total`)}
                  name={`line_items[${index}].total`}
                  id={`line_items[${index}].total`}
                  label={Vocabulary.subtotal}
                  disabled
                  className={styles.quantityOrderOffer}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {Vocabulary.currency}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.accordionDetails}>
          {!openConfirmDelete ? (
            <div className={styles.deleteButton}>
              {props.disabled ? null : (
                <Tooltip title={Vocabulary.delete}>
                  <IconButton
                    aria-label="delete"
                    className={styles.deleteIcon}
                    onClick={() => setOpenConfirmDelete(!openConfirmDelete)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ) : (
            <div className={styles.deleteConfirm}>
              <IconButton
                aria-label="close"
                className={styles.closeIcon}
                onClick={() => setOpenConfirmDelete(!openConfirmDelete)}
              >
                <CloseIcon />
              </IconButton>

              <IconButton
                aria-label="confirmDelete"
                className={styles.confirmDelete}
                onClick={() => {
                  deleteLine(index);
                  setOpenConfirmDelete(!openConfirmDelete);
                }}
              >
                <Typography>{Vocabulary.confirmDelete}</Typography>
              </IconButton>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
