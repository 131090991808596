import { Button, InputLabel, Paper, TextField } from "@mui/material";
import GenericModal from "../../Components/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { SketchPicker } from "react-color";
import { useEffect, useState } from "react";
import { endpoints } from "../../Utils/UrlEnum";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { OrderStatusModel } from "../../Utils/Models";
import { EWidths } from "../../Utils/Enums";
import { AddOrderStatusModalProps } from "../../Utils/Types";
import { AxiosResponse } from "axios";

export default function AddOrderStatusModal(props: AddOrderStatusModalProps) {
  const {
    openAddOrderStatusModal,
    selectedRow,
    handleChangeOrderStatusModalValue,
    getOrderStatus,
    sortNumber,
  } = props;
  const [orderStatus, setOrderStatus] = useState<OrderStatusModel>(
    new OrderStatusModel()
  );

  /**
   *
   */
  useEffect(() => {
    if (selectedRow) setOrderStatus(selectedRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  /**
   *
   */
  function saveOrderStatus() {
    let url = endpoints.orderStatus;
    const newOrderStatus = Object.assign({}, orderStatus) as any;
    if (selectedRow) {
      url += `/${selectedRow._id}`;
      updateData(url, newOrderStatus).then((res: AxiosResponse) => {
        onClose();
        getOrderStatus();
      });
    } else {
      newOrderStatus.sortNumber = sortNumber + 1;
      postData(url, newOrderStatus).then((res: AxiosResponse) => {
        onClose();
        getOrderStatus();
      });
    }
  }

  /**
   *
   */
  function onClose() {
    handleChangeOrderStatusModalValue();
    setOrderStatus(new OrderStatusModel());
  }

  return (
    <div>
      <GenericModal
        maxWidth={EWidths.XS}
        open={openAddOrderStatusModal}
        onClose={onClose}
        title={
          selectedRow ? Vocabulary.editOrderStatus : Vocabulary.addOrderStatus
        }
        actions={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#4448",
              }}
              onClick={onClose}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={saveOrderStatus}
            >
              {Vocabulary.save}
            </Button>
          </div>
        }
      >
        <div style={{ padding: 10 }}>
          <TextField
            type="text"
            required
            label={Vocabulary.status}
            variant="standard"
            disabled={orderStatus?.isDefault}
            fullWidth
            value={orderStatus.name}
            onChange={(event: any) =>
              setOrderStatus({ ...orderStatus, name: event.target.value })
            }
          />
          <InputLabel style={{ margin: "10px 0px", fontSize: 15 }}>
            {Vocabulary.chooseStatusColor}
          </InputLabel>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: -15,
            }}
          >
            <SketchPicker
              disableAlpha={true}
              width="100%"
              color={orderStatus.color}
              onChangeComplete={(color: any) =>
                setOrderStatus({ ...orderStatus, color: color.hex })
              }
            />
            <Paper
              style={{
                width: "100%",
                height: 50,
                marginTop: "30px",
                marginLeft: 10,
                backgroundColor: orderStatus.color,
              }}
            />
          </div>
        </div>
      </GenericModal>
    </div>
  );
}
