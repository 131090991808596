import { useContext, useEffect, useRef, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { Attachment, EmailTemplateModel } from "../../Utils/Models";
import draftToHtml from "draftjs-to-html";
import {
  BlockMapBuilder,
  EditorState,
  Modifier,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AdditionalData from "./EmailTemplateAdditionalData";
import { endpoints } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import { CRUDContext } from "./EmailTemplatesTable";
import { ValidatorForm } from "react-material-ui-form-validator";
import styles from "../../Styles/emailTemplate.module.css";
import { postData } from "../../Services/postData";
import { updateData } from "../../Services/updateData";
import { EmailTemplateEditorOptions, FormsIds } from "../../Utils/Constants";
import EmailTemplateImages from "./EmailTemplateImages";
import EmailTemplateDeleteImages from "./EmailTemplateDeleteImages";
import { getHtml } from "../../Utils/Utils";
import { EmailTemplateFormProps } from "../../Utils/Types";
import { AxiosResponse } from "axios";

export default function EmailTemplateForm(props: EmailTemplateFormProps) {
  const { onClose } = props;
  const ref: any = useRef();
  const emailTemplateContext: any = useContext(CRUDContext);
  const [emailTemplateHTML, setEmailTemplateHTML] = useState("");
  const [emailTemplate, setEmailTemplate] = useState(new EmailTemplateModel());
  const [lastFocused, setLastFocused] = useState(Vocabulary.subject);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusEnum, setStatusEnum] = useState<any>([]);
  const [shouldLinkToOrderStatus, setShouldLinkToOrderStatus] = useState(
    emailTemplateContext.statusId && emailTemplateContext.statusId !== ""
  );
  const openMenu = Boolean(anchorEl);

  /**
   *
   */
  function getStatusEnum() {
    getData(endpoints.orderStatus).then((res: AxiosResponse) => {
      setStatusEnum(res.data);
    });
  }

  /**
   *
   */
  useEffect(() => {
    if (
      emailTemplateContext.emailTemplateName &&
      emailTemplateContext.emailTemplateName !== ""
    ) {
      handleHtmlStringToHtml(emailTemplateContext.html);
    } else if (!emailTemplateContext) {
      setEmailTemplate({ ...emailTemplate, html: EditorState.createEmpty() });
    }
    getStatusEnum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param e
   */
  const onChange = (e: any) => {
    setEmailTemplate({
      ...emailTemplate,
      html: e,
    });
    const html = draftToHtml(convertToRaw(e.getCurrentContent()));
    setEmailTemplateHTML(html);
  };

  /**
   *
   * @param value
   */
  const handleChangeOnFocus = (value: string) => {
    setLastFocused(value);
  };

  /**
   *
   */
  const handleChangeTemplateProps = (e: any) => {
    const newEmailTemplate = Object.assign({}, emailTemplate) as any;
    newEmailTemplate[e.target.name] = e.target.value;
    setEmailTemplate(newEmailTemplate);
  };

  /**
   *
   * @param text
   */
  const insertTextIntoHtml = (text: string) => {
    const editorState: any = emailTemplate?.html;
    const contentState = editorState.getCurrentContent();

    let newContentState = contentState.createEntity(
      "unstyled",
      "IMMUTABLE",
      text
    );
    const entityKey = contentState.getLastCreatedEntityKey();
    const selectionState = emailTemplate.html.getSelection();
    newContentState = Modifier?.insertText(
      newContentState,
      selectionState,
      text,
      "" as any,
      entityKey
    );
    const newHtml = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    return newHtml;
  };

  /**
   *
   * @param tag
   */
  const handleClose = (tag: any) => {
    if (tag !== "") {
      const text = `{{${tag}}}`;
      setAnchorEl(null);
      if (lastFocused === Vocabulary.subject) {
        setEmailTemplate({
          ...emailTemplate,
          subject: `${emailTemplate.subject} ${text}`,
        });
      } else {
        const newHtml = insertTextIntoHtml(text);
        setEmailTemplate({ ...emailTemplate, html: newHtml });
      }
    } else {
      setAnchorEl(null);
    }
  };

  /**
   *
   * @param e
   */
  function handleChangeTemplateStatus(e: any) {
    setEmailTemplate({
      ...emailTemplate,
      statusId: e.target.value,
    });
  }

  /**
   *
   * @param event
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   * @returns
   */
  const createEmailTemplate = async () => {
    const updatedEmailTemplate: any = Object.assign({}, emailTemplate);
    updatedEmailTemplate.html = emailTemplateHTML;
    const res = (await postData(
      endpoints.emailTemplatesEndpoint,
      updatedEmailTemplate
    )) as any;
    if (!res || res?.error) {
      return;
    }
    if (onClose) onClose();
  };

  /**
   *
   * @returns
   */
  const updateEmailTemplate = async () => {
    //make a copy of the context object
    const updatedEmailTemplate: any = Object.assign({}, emailTemplate);
    updatedEmailTemplate.html = emailTemplateHTML;
    const res = (await updateData(
      `${endpoints.emailTemplatesEndpoint}/${emailTemplate._id}`,
      updatedEmailTemplate
    )) as any;
    if (!res || res?.error) {
      return;
    }
    if (onClose) onClose();
  };

  /**
   *
   */
  const handleSubmit = () => {
    emailTemplate._id ? updateEmailTemplate() : createEmailTemplate();
  };

  /**
   *
   * @param html
   */
  const handleHtmlStringToHtml = (html: string) => {
    const _html: any = getHtml(html);
    setEmailTemplate(
      Object.assign({}, emailTemplateContext, {
        html: _html,
      })
    );
    setEmailTemplateHTML(html);
  };

  /**
   *
   * @param attachment
   */
  const handleDeleteImage = (attachment: Attachment) => {
    //delete image from local object
    const attachments = emailTemplate.attachments.filter(
      (a: Attachment) => a.cid !== attachment.cid
    );
    //make a copy of the context object
    const updatedEmailTemplate: any = Object.assign({}, emailTemplate);
    updatedEmailTemplate.attachments = attachments;
    //delete image by id = attachment.cid from html
    const editorState: any = emailTemplate?.html;
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);

    //replace image that has alt = attachment.alt with empty string

    const newHtml: any = html.replace(
      new RegExp(`<img[^>]+alt="${attachment.alt}"[^>]*>`, "g"),
      ""
    );
    updatedEmailTemplate.html = newHtml;

    updateData(
      `${endpoints.emailTemplatesEndpoint}/${emailTemplate._id}`,
      updatedEmailTemplate
    ).then(async (response: AxiosResponse) => {
      if (response && response.data) {
        handleHtmlStringToHtml(response.data.html);
        setEmailTemplate({ ...emailTemplate, attachments: attachments });
        //delete the image from disk
        await postData(`${endpoints.mediaEndpoint}/delete`, {
          path: attachment.path,
          fileName: attachment.filename,
        });
      }
    });
  };

  /**
   *
   * @param file
   * @param alt
   */
  const handleUploadAttachment = async (file: any, alt: string) => {
    //save image to disk on server
    const formData = new FormData() as any;
    formData.append("folder", "emailTemplates");
    formData.append("files", file, file.name);
    const res: any = await postData(endpoints.mediaEndpoint, formData);
    if (!res || res.error) return;
    //generate random name
    const randomName = Math.random().toString(36).substring(7);
    //add attachment to template attachments
    const attachments = emailTemplate.attachments.slice();
    const newAttachment = new Attachment();
    newAttachment.cid = randomName;
    newAttachment.alt = alt;
    newAttachment.filename = res?.data[0]?.fileName;
    newAttachment.path = res?.data[0]?.path;
    attachments.push(newAttachment);
    const editorState: any = emailTemplate?.html;
    const selectionState = emailTemplate.html.getSelection();
    const htmlContent = convertFromHTML(
      `<img id="${randomName}" src="cid:${randomName}" alt="${alt}"  />`
    );
    const htmlContentMap = BlockMapBuilder.createFromArray(
      htmlContent.contentBlocks
    );
    const newContent = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      selectionState,
      htmlContentMap
    );
    let newHtml: any = EditorState.push(
      editorState,
      newContent,
      "apply-entity"
    );
    //make a copy of the context object
    const updatedEmailTemplate: any = Object.assign({}, emailTemplate);
    newHtml = draftToHtml(convertToRaw(newHtml.getCurrentContent()));
    updatedEmailTemplate.html = newHtml;
    updatedEmailTemplate.attachments = attachments;
    updateData(
      `${endpoints.emailTemplatesEndpoint}/${emailTemplate._id}`,
      updatedEmailTemplate
    ).then((response: AxiosResponse) => {
      if (response && response.data) {
        handleHtmlStringToHtml(response.data.html);
        setEmailTemplate({ ...emailTemplate, attachments: attachments });
      }
    });
  };

  return (
    <ValidatorForm
      ref={ref}
      id={FormsIds.editEmailTemplateForm}
      onSubmit={handleSubmit}
    >
      <AdditionalData
        openMenu={openMenu}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleClick={handleClick}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            type="text"
            label={Vocabulary.emailTemplateName}
            name="emailTemplateName"
            variant="standard"
            fullWidth
            required
            value={emailTemplate.emailTemplateName}
            onChange={(e: any) => handleChangeTemplateProps(e)}
            className={styles.emailTemplateFormTextField}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            type="text"
            label={Vocabulary.subject}
            name="subject"
            variant="standard"
            fullWidth
            value={emailTemplate.subject}
            onFocus={() => handleChangeOnFocus(Vocabulary.subject)}
            onChange={(e: any) => handleChangeTemplateProps(e)}
            className={styles.emailTemplateFormTextField}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            type="text"
            label={Vocabulary.cc}
            placeholder={Vocabulary.enterEmailAddressesSeparatedByComma}
            name="cc"
            variant="standard"
            fullWidth
            value={emailTemplate.cc}
            onChange={(e: any) => handleChangeTemplateProps(e)}
            className={styles.emailTemplateFormTextField}
          />
        </Grid>

        <Grid item xs={12} md={2} lg={2}>
          <FormGroup>
            <FormControlLabel
              checked={shouldLinkToOrderStatus || false}
              onChange={(event: any) =>
                setShouldLinkToOrderStatus(event.target.checked)
              }
              control={<Checkbox color="primary" />}
              label={Vocabulary.emailTemplateAttachedToOrderStatus}
            />
          </FormGroup>
        </Grid>
        {shouldLinkToOrderStatus ? (
          <Grid item xs={12} md={4} lg={4}>
            <FormControl
              variant="standard"
              fullWidth
              className={styles.emailTemplateFormTextField}
            >
              <InputLabel>{Vocabulary.status}</InputLabel>
              <Select
                value={emailTemplate.statusId || ""}
                name="template"
                onChange={(e) => {
                  handleChangeTemplateStatus(e);
                }}
              >
                {statusEnum.map((status: any) => (
                  <MenuItem
                    key={`statusEnum_${status.name}`}
                    value={status._id}
                  >
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
      <div className={styles.emailTemplateEditorContainer}>
        <Editor
          key={emailTemplate.attachments.length}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbarClassName"
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            options: EmailTemplateEditorOptions,
          }}
          toolbarCustomButtons={[
            <EmailTemplateImages
              handleUploadAttachment={handleUploadAttachment}
              id={emailTemplate.attachments.length}
            />,
            <EmailTemplateDeleteImages
              handleDeleteImage={handleDeleteImage}
              emailTemplate={emailTemplate}
              id={emailTemplate.attachments.length}
            />,
          ]}
          editorState={emailTemplate.html}
          onEditorStateChange={onChange}
          onFocus={() => handleChangeOnFocus(Vocabulary.editor)}
        />
      </div>
    </ValidatorForm>
  );
}
