import {
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ContactPhoneProps } from "../../Utils/Types";
import { useContext } from "react";

import { ContactCRUDContext } from "./ContactModal";

export default function ContactPhone(props: ContactPhoneProps) {
  const { handleChange, disabled } = props;
  const contact = useContext(ContactCRUDContext);

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} sm={12} md={12}>
        <ToggleButtonGroup
          fullWidth
          disabled={disabled}
          onChange={(event, value) => handleChange("contactedViaPhone", value)}
          value={contact.contactedViaPhone}
          color="primary"
          style={{ backgroundColor: "#fff" }}
          exclusive
        >
          <ToggleButton value={true}>
            <Typography>{Vocabulary.contacted}</Typography>
          </ToggleButton>
          <ToggleButton value={false}>
            <Typography>{Vocabulary.notContacted}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 10 }}>
        <TextField
          fullWidth
          disabled={disabled}
          label={Vocabulary.observations}
          value={contact.observations}
          onChange={(e) => handleChange("observations", e.target.value)}
          style={{ backgroundColor: "#fff" }}
          multiline={true}
          rows={4}
        />
      </Grid>
    </Grid>
  );
}
