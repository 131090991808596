import { Divider, Typography } from "@mui/material";
import styles from "../../Styles/whatsApp.module.css";
import { WhatsAppTemplatePreviewProps } from "../../Utils/Types";
import {
  EHeaderTemplateMenuOptions,
  EWhatsAppComponentsTypes,
  EWhatsAppTemplateButtonType,
} from "../../Utils/Enums";
import {
  Article,
  Image,
  OpenInNew,
  Phone,
  PlayCircle,
  Reply,
} from "@mui/icons-material";
import { formatStringDate } from "../../Utils/Utils";
import Config from "../../Utils/Config";
import { Fragment } from "react";

export default function WhatsAppTemplatePreview(
  props: WhatsAppTemplatePreviewProps
) {
  const { template, shouldRenderCurrentDate } = props;

  /**
   *
   * @param componentName
   * @returns
   */
  const returnComponent = (componentName: any) => {
    const component = template?.components?.find(
      (component: any) => component.type === componentName
    );
    return component;
  };

  /**
   *
   * @param icon
   * @returns
   */
  const renderHeaderMedia = (icon: any) => {
    return <div className={styles.whatsAppTemplatePreviewDoc}>{icon}</div>;
  };

  /**
   *
   * @returns
   */
  const renderHeader = () => {
    //get template component where type is HEADER
    const header = returnComponent(EWhatsAppComponentsTypes.HEADER);
    if (!header) return null;
    switch (header.format) {
      case EHeaderTemplateMenuOptions.TEXT:
        return (
          <Typography variant="h6" sx={{ fontWeight: "bold", padding: "5px" }}>
            {header.text}
          </Typography>
        );
      case EHeaderTemplateMenuOptions.DOCUMENT:
        return renderHeaderMedia(
          <Article
            fontSize="large"
            className={styles.whatsAppHeaderMediaIcon}
          />
        );
      case EHeaderTemplateMenuOptions.IMAGE:
        return renderHeaderMedia(
          <Image fontSize="large" className={styles.whatsAppHeaderMediaIcon} />
        );

      case EHeaderTemplateMenuOptions.VIDEO:
        return renderHeaderMedia(
          <PlayCircle
            fontSize="large"
            className={styles.whatsAppHeaderMediaIcon}
          />
        );
      default:
        return null;
    }
  };

  /**
   *
   * @returns
   */
  const renderBody = () => {
    //get template component where type is BODY
    const body = returnComponent(EWhatsAppComponentsTypes.BODY);
    if (!body) return null;
    return <Typography sx={{ padding: "5px" }}>{body?.text}</Typography>;
  };

  /*
   *
   * @returns
   */
  const renderFooter = () => {
    //get template component where type is FOOTER
    const footer = returnComponent(EWhatsAppComponentsTypes.FOOTER);
    if (!footer) return null;
    return (
      <Typography variant="caption" sx={{ padding: "5px" }}>
        {footer?.text}
      </Typography>
    );
  };

  /**
   *
   * @param text
   * @param icon
   * @returns
   */
  const renderOneButton = (text: string, icon: any, index: any) => {
    return (
      <div key={index}>
        <Divider />
        <div className={styles.whatsAppTemplatePreviewButton}>
          {icon}
          <Typography>{text}</Typography>
        </div>
      </div>
    );
  };

  /**
   *
   * @returns
   */
  const renderButtons = () => {
    //get template component where type is BUTTONS
    const buttons = returnComponent(EWhatsAppComponentsTypes.BUTTONS);
    if (!buttons) return null;
    return (
      <Fragment>
        {buttons?.buttons?.map((button: any, index: number) => {
          switch (button.type) {
            case EWhatsAppTemplateButtonType.PHONE_NUMBER:
              return renderOneButton(
                button.text,
                <Phone className={styles.whatsAppTemplatePreviewButtonIcon} />,
                index
              );
            case EWhatsAppTemplateButtonType.QUICK_REPLY:
              return renderOneButton(
                button.text,
                <Reply className={styles.whatsAppTemplatePreviewButtonIcon} />,
                index
              );
            case EWhatsAppTemplateButtonType.URL:
              return renderOneButton(
                button.text,
                <OpenInNew
                  className={styles.whatsAppTemplatePreviewButtonIcon}
                />,
                index
              );
            default:
              return null;
          }
        })}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
      {shouldRenderCurrentDate && (
        <Typography
          variant="caption"
          textAlign={"right"}
          sx={{ padding: "5px" }}
        >
          {formatStringDate(new Date(), Config.momentEUTimeFormat)}
        </Typography>
      )}
      {renderButtons()}
    </Fragment>
  );
}
