import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Vocabulary } from "../../Utils/Vocabulary";
import genericStyles from "../../Styles/genericStyles.module.css";
import styles from "../../Styles/shipping.module.css";
import { Grid, Paper, TextField } from "@mui/material";
import Config from "../../Utils/Config";
import { ShippingModel } from "../../Utils/Models";
import { ShippingPanelProps } from "../../Utils/Types";
import { useEffect, useState } from "react";

export default function ShippingPanel(props: ShippingPanelProps) {
  const [shipping, setShipping] = useState<string | undefined>();
  const { disabled } = props;
  /**
   *
   * @param event
   * @param newShipping
   */
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newShipping: string
  ) => {
    if (newShipping !== null) {
      setShipping(newShipping);
    }
    if (newShipping === "newShipping") {
      props.setValue("shipping", new ShippingModel(), { shouldDirty: true });
      props.setValue("differentShippingInfo", true);
    }
    if (newShipping === "sameAsShipping") {
      props.setValue("shipping", props.getValues("billing"), {
        shouldDirty: true,
      });
      props.setValue("differentShippingInfo", false);
    }
  };

  /**
   *
   */
  useEffect(() => {
    const diffShipping = props.watch("differentShippingInfo");
    if (diffShipping) {
      setShipping("newShipping");
    } else if (diffShipping === false) {
      setShipping("sameAsShipping");
    }
  }, [props.watch("differentShippingInfo")]);

  return (
    <>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12}>
          <ToggleButtonGroup
            color="primary"
            style={{ backgroundColor: props?.error ? "#ffdcdf" : "#fff" }}
            className={genericStyles.toggleButtons}
            value={shipping}
            disabled={disabled}
            exclusive
            onChange={handleChange}
            aria-label="Shipping"
            size="large"
          >
            <ToggleButton
              className={genericStyles.fullWidthElement}
              value="sameAsShipping"
            >
              {Vocabulary.sameAsBilling}
            </ToggleButton>
            <ToggleButton
              className={genericStyles.fullWidthElement}
              value="newShipping"
            >
              {Vocabulary.newShipping}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Paper className={styles.newShippingAddress}>
        <Grid container spacing={Config.standardGridSpacing}>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.last_name")}
              id="shipping.last_name"
              variant={"standard"}
              name="shipping.last_name"
              label={Vocabulary.lastName}
              fullWidth
              error={
                typeof props.formState.errors.shipping?.last_name === "object"
              }
              helperText={props.formState.errors.shipping?.last_name?.message?.toString()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.first_name")}
              id="shipping.first_name"
              name="shipping.first_name"
              label={Vocabulary.firstName}
              fullWidth
              variant={"standard"}
              error={
                typeof props.formState.errors.shipping?.first_name === "object"
              }
              helperText={props.formState.errors.shipping?.first_name?.message?.toString()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.city")}
              id="shipping.city"
              name="shipping.city"
              label={Vocabulary.city}
              fullWidth
              variant={"standard"}
              error={typeof props.formState.errors.shipping?.city === "object"}
              helperText={props.formState.errors.shipping?.city?.message?.toString()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.state")}
              id="shipping.state"
              name="shipping.state"
              label={Vocabulary.county}
              fullWidth
              variant={"standard"}
              error={typeof props.formState.errors.shipping?.state === "object"}
              helperText={props.formState.errors.shipping?.state?.message?.toString()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.country")}
              id="shipping.country"
              name="shipping.country"
              label={Vocabulary.country}
              fullWidth
              variant={"standard"}
              error={
                typeof props.formState.errors.shipping?.country === "object"
              }
              helperText={props.formState.errors.shipping?.country?.message?.toString()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.address_1")}
              id="shipping.address_1"
              name="shipping.address_1"
              label={Vocabulary.addressDetails}
              fullWidth
              variant={"standard"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.address_2")}
              id="shipping.address_2"
              name="shipping.address_2"
              label={Vocabulary.addressDetails}
              fullWidth
              variant={"standard"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextField
              {...props.register("shipping.postcode")}
              id="shipping.postcode"
              name="shipping.postcode"
              label={Vocabulary.postcode}
              fullWidth
              variant={"standard"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
