import { ContactSummaryProps } from "../../Utils/Types";
import { Chip, Grid, Typography } from "@mui/material";
import Config from "../../Utils/Config";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Check, Close, Phone } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Fragment } from "react";
import { EContactStatus } from "../../Utils/Enums";
import styles from "../../Styles/dashboardContacts.module.css";

export default function ContactSummary(props: ContactSummaryProps) {
  const { onClick, contact } = props;
  /**
   *
   * @param condition
   * @returns
   */
  const contactEstablished = (icon: any, text: string, condition: boolean) => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        className={genericStyles.centeredElementRow}
      >
        <div className={styles.contactWidgetGridWithDivider}>
          <div className={styles.contactSummaryIconAndText}>{icon}</div>
          <div className={styles.contactSummaryIconAndText}>
            <Typography>{text}</Typography>
          </div>
          <div>
            {condition ? <Check color="success" /> : <Close color="error" />}
          </div>
        </div>
      </Grid>
    );
  };

  const renderOrderStatus = () => {
    if (contact.order?._id) {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={genericStyles.centeredElementRow}
        >
          <Chip
            sx={{
              border: "1px solid #000000",
              backgroundColor: `${contact?.order?.status?.color}`,
            }}
            label={`Status comandă: ${contact?.order?.status?.name}`}
          />
        </Grid>
      );
    }
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        className={genericStyles.centeredElementRow}
      >
        <Typography>{Vocabulary.noOrder}</Typography>{" "}
      </Grid>
    );
  };

  return (
    <Fragment>
      <Grid
        onClick={onClick}
        container
        spacing={Config.standardGridSpacing}
        className={styles.contactSummaryGridContainer}
        style={{
          backgroundColor:
            contact.status === EContactStatus.FINALIZAT ? "#a1e6bb" : "inherit",
        }}
      >
        <Grid item xs={12} sm={12} md={3}>
          <Typography>{contact?.client?.name?.toUpperCase()}</Typography>
          <div className={styles.contactSummaryClientName}>
            <Typography variant="caption">{contact?.client?.email}</Typography>
            <Typography variant="caption">
              {contact?.client?.phone[0]}
            </Typography>
          </div>
        </Grid>
        <div className={styles.contactSummaryDetails}>
          {/* {contactEstablished(
            <Email />,
            Vocabulary.clientContactedOverEmail,
            contact?.contactedViaEmail
          )} */}
          {renderOrderStatus()}
          {contactEstablished(
            <Phone />,
            Vocabulary.clientCalled,
            contact?.contactedViaPhone
          )}
          {/* {contactEstablished(
            <Sms />,
            Vocabulary.clientMessaged,
            contact?.contactedViaEmail
          )} */}
          {contact?.agent && (
            <div
              className={`${genericStyles.centeredElementColumn}`}
              style={{ maxWidth: "50px" }}
            >
              <Typography variant="caption">
                {`${Vocabulary.takenBy}: ${contact?.agent?.username}`}
              </Typography>
            </div>
          )}
        </div>
      </Grid>
    </Fragment>
  );
}
