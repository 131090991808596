import { ContactWidgetProps } from "../../Utils/Types";
import { Fragment, useState } from "react";
import ContactSummary from "./ContactSummary";
import { useSearchParams } from "react-router-dom";
import AssociateAgentWithContactModal from "./AssociateAgentWithContactModal";

export default function ContactWidget(props: ContactWidgetProps) {
  const { contact } = props;
  const [openModal, setOpenModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   *
   */
  const handleToggleOpenModal = () => {
    if (!contact.agent) {
      setOpenModal(true);
    } else {
      if (contact._id) {
        searchParams.set("id", contact._id);
        setSearchParams(searchParams);
      }
    }
  };

  return (
    <Fragment>
      <ContactSummary contact={contact} onClick={handleToggleOpenModal} />
      <AssociateAgentWithContactModal
        contact={contact}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </Fragment>
  );
}
