import { Card, CardActions, CardMedia, IconButton } from "@mui/material";
import { useState } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { ImageGalleryProps } from "../../Utils/Types";

export default function ImageGallery(props: ImageGalleryProps) {
  const { images } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  /**
   *
   */
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  /**
   *
   */
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Card>
      <CardMedia
        height="500"
        component={"img"}
        sx={{ objectFit: "contain" }}
        src={images[currentIndex]?.src}
      />
      {images?.length > 1 ? (
        <CardActions sx={{ justifyContent: "center" }}>
          <IconButton onClick={goToPrevious}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={goToNext}>
            <ChevronRight />
          </IconButton>
        </CardActions>
      ) : null}
    </Card>
  );
}
