import { useOutletContext } from "react-router-dom";
import { WhatsAppBusinessAccountData } from "../../Utils/Models";
import RegisterWhatsAppPhoneNumber from "./RegisterWhatsAppPhoneNumber";
import { Button, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/whatsApp.module.css";
import { Fragment, useState } from "react";
import GenericModal from "../GenericModal";
import StandardActionButtons from "../StandarsActionButtons";
import TwoFactorAuthenticationSetupStep from "./TwoFactorAuthenticationSetupStep";
import { FormsIds } from "../../Utils/Constants";
import Config from "../../Utils/Config";

export default function WhatsAppPhoneNumber() {
  const [open, setOpen] = useState(false);
  const whatsAppBusinessAccountData: WhatsAppBusinessAccountData =
    useOutletContext();

  /**
   *
   */
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      {whatsAppBusinessAccountData?.phoneNumber ? (
        <Paper className={styles.whatsAppPhoneNumberDetails}>
          <Grid container spacing={Config.standardGridSpacing}>
            <Grid item xs={12}>
              <Typography variant="h5">{`${Vocabulary.details}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={styles.whatsAppBusinessDetailsDivider} />
            </Grid>
            <Grid
              item
              xs={12}
              className={styles.whatsAppBusinessPhoneNumberDetails}
            >
              <Typography
                sx={{ marginRight: "15px" }}
              >{`${Vocabulary.availablePhoneNumber}: `}</Typography>
              <Typography
                sx={{ fontWeight: "bold" }}
              >{`${whatsAppBusinessAccountData?.phoneNumber}`}</Typography>
            </Grid>
            <Grid
              item
              xs={9.8}
              className={styles.whatsAppBusinessPhoneNumberDetails}
            >
              <Typography
                sx={{ marginRight: "15px" }}
              >{`${Vocabulary.status}: `}</Typography>
              <Chip
                className={
                  whatsAppBusinessAccountData?.isPhoneNumberVerified
                    ? styles.whatsAppBusinessRegisteredPhoneNumberChip
                    : styles.whatsAppBusinessNotRegisteredPhoneNumberChip
                }
                label={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {whatsAppBusinessAccountData?.isPhoneNumberVerified
                      ? Vocabulary.registered
                      : Vocabulary.notRegistered}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2.2}>
              {!whatsAppBusinessAccountData?.isPhoneNumberVerified &&
              whatsAppBusinessAccountData.phoneNumber ? (
                <Button
                  onClick={handleChangeOpen}
                  color="primary"
                  variant="contained"
                >
                  {Vocabulary.registerPhoneNumber}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <RegisterWhatsAppPhoneNumber />
      )}
      <GenericModal
        open={open}
        onClose={handleChangeOpen}
        title={Vocabulary.registerPhoneNumber}
        actions={
          <StandardActionButtons
            cancelAction={handleChangeOpen}
            okText={Vocabulary.yes}
            cancelText={Vocabulary.no}
            formId={FormsIds.twoFactorAuthentication}
            type="submit"
          />
        }
      >
        <div>{Vocabulary.whatsAppRegisterPhoneNumberHelperText}</div>
        <TwoFactorAuthenticationSetupStep />
      </GenericModal>
    </Fragment>
  );
}
