import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Grid,
  TextField,
  ToggleButtonGroup,
  Tooltip,
  ToggleButton,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { endpoints } from "../../Utils/UrlEnum";
import { updateData } from "../../Services/updateData";
import { WebSocketContext } from "../../Context/WebSocketContext";
import { getData } from "../../Services/getData";
import {
  TransportSettingsFormValidationSchema,
  TransportSettingsModel,
} from "../../Utils/Models";
import { postData } from "../../Services/postData";
import CloseIcon from "@mui/icons-material/Close";
import { deleteData } from "../../Services/deleteData";
import { AxiosResponse } from "axios";
import { FormsIds } from "../../Utils/Constants";
import styles from "../../Styles/settings.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Add } from "@mui/icons-material";

export default function TransportSettings() {
  const websocketContext = useContext(WebSocketContext);
  const ref: any = useRef();
  const { reset, handleSubmit, setValue, watch, control, getValues } = useForm({
    resolver: yupResolver(TransportSettingsFormValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const transportMethods: any = watch("transportsMethods");

  /**
   *
   */
  function addNewMethod() {
    const newPaymentMethod: any = Object.assign([], transportMethods);
    newPaymentMethod.push(new TransportSettingsModel());
    setValue("transportsMethods", newPaymentMethod);
  }

  /**
   *
   * @param index
   */
  async function deleteMethod(index: any, editedTransport: any) {
    const currentPaymentMethods = transportMethods || [];
    let newTransportMethod: any = Object.assign([], currentPaymentMethods);
    newTransportMethod = currentPaymentMethods
      .slice(0, index)
      .concat(currentPaymentMethods.slice(index + 1));
    setValue("transportsMethods", newTransportMethod);
    if (editedTransport._id) {
      const url = `${endpoints.transportOption}/${editedTransport._id}`;
      deleteData(url).then((res: AxiosResponse) => {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        getTransports();
      });
    }
  }
  /**
   *
   */
  useEffect(() => {
    getTransports();
  }, []);

  /**
   *
   */
  function getTransports() {
    getData(endpoints.transportOption).then((res: AxiosResponse) => {
      if (res?.data) {
        const newTransportMethods: any = { transportsMethods: res.data };
        reset(newTransportMethods);
      }
    });
  }

  /**
   *
   * @param model
   * @returns
   */
  async function updateElement(editedTransport: any) {
    if (editedTransport._id) {
      const url = `${endpoints.transportOption}/${editedTransport._id}`;
      const res = (await updateData(url, editedTransport)) as any;
      if (res?.data) {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        getTransports();
      }
    } else {
      const url = `${endpoints.transportOption}`;
      const res = (await postData(url, editedTransport)) as any;
      if (res?.data) {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        getTransports();
      }
    }
  }

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    updateElement(model);
  };

  /**
   *
   * @param model
   */
  const onError = (model: any) => {
    // console.log(model); //DOAR PT ERORI
  };

  return (
    <form
      className={styles.settingsForm}
      onSubmit={handleSubmit(onSubmit, onError)}
      method="get"
      ref={ref}
      id={FormsIds.editOrderSettings}
    >
      {transportMethods?.map((transport: any, index: any) => {
        return (
          <Paper className={styles.transportMethodSection}>
            <Grid key={index} container xs={12} sm={12} md={12} spacing={1}>
              <Grid xs={12} sm={12} md={3} item>
                <Controller
                  name={`transportsMethods.${index}.name`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      onBlur={onBlur}
                      disabled={
                        !getValues(`transportsMethods.${index}.showEdit`)
                      }
                      label={Vocabulary.transportName}
                      onChange={onChange}
                      value={value || ""}
                      ref={ref}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={12} md={2.5} item>
                <Controller
                  name={`transportsMethods.${index}.value`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      fullWidth
                      disabled={
                        !getValues(`transportsMethods.${index}.showEdit`)
                      }
                      variant="outlined"
                      type="number"
                      onBlur={onBlur}
                      label={Vocabulary.transportValue}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={3}
                item
                className={styles.transportMethodsSwitchContainer}
              >
                <Typography className={styles.transportMethodsIsDefaultText}>
                  {Vocabulary.isDefaultTransport}
                </Typography>
                <Controller
                  name={`transportsMethods.${index}.isDefault`}
                  control={control}
                  render={({ field: { value, onChange, ref } }) => (
                    <Switch
                      disabled={
                        !getValues(`transportsMethods.${index}.showEdit`)
                      }
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ToggleButtonGroup
                  className={styles.transportMethodsToggleButtonGroup}
                  exclusive
                  aria-label="text alignment"
                >
                  {!getValues(`transportsMethods.${index}.showEdit`) ? (
                    <>
                      <Tooltip title={Vocabulary.edit}>
                        <ToggleButton
                          onClick={() => {
                            setValue(
                              `transportsMethods.${index}.showEdit`,
                              true
                            );
                            setValue(
                              `transportsMethods.${index}.confirmDelete`,
                              false
                            );
                          }}
                          value="center"
                          aria-label="centered"
                        >
                          <EditIcon fontSize="small" />
                        </ToggleButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      {!getValues(
                        `transportsMethods.${index}.confirmDelete`
                      ) ? (
                        <>
                          <Tooltip title={Vocabulary.save}>
                            <ToggleButton
                              onClick={() => {
                                setValue(
                                  `transportsMethods.${index}.showEdit`,
                                  false
                                );
                                updateElement(
                                  getValues(`transportsMethods.${index}`)
                                );
                              }}
                              value="center"
                              aria-label="centered"
                              form={FormsIds.editOrderSettings}
                            >
                              <SaveIcon fontSize="small" />
                            </ToggleButton>
                          </Tooltip>
                          <Tooltip title={Vocabulary.delete}>
                            <ToggleButton
                              onClick={() =>
                                setValue(
                                  `transportsMethods.${index}.confirmDelete`,
                                  true
                                )
                              }
                              value="center"
                              aria-label="centered"
                            >
                              <DeleteIcon
                                fontSize="small"
                                className={genericStyles.darkPastelRedColor}
                              />
                            </ToggleButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title={Vocabulary.cancel}>
                            <ToggleButton
                              onClick={() =>
                                setValue(
                                  `transportsMethods.${index}.showEdit`,
                                  false
                                )
                              }
                              value="center"
                              aria-label="centered"
                            >
                              <CloseIcon fontSize="small" />
                            </ToggleButton>
                          </Tooltip>
                          <Tooltip title={""}>
                            <ToggleButton
                              onClick={() => {
                                setValue(
                                  `transportsMethods.${index}.showEdit`,
                                  false
                                );
                                deleteMethod(index, transportMethods[index]);
                              }}
                              value="center"
                              aria-label="centered"
                            >
                              <Typography
                                className={genericStyles.darkPastelRedColor}
                                style={{ fontSize: 10 }}
                              >
                                {Vocabulary.confirmDelete}
                              </Typography>
                            </ToggleButton>
                          </Tooltip>
                        </>
                      )}
                    </>
                  )}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
      <Button
        size="large"
        variant="contained"
        startIcon={<Add />}
        onClick={() => {
          addNewMethod();
          setValue(
            `transportsMethods.${transportMethods.length}.showEdit`,
            true
          );
        }}
      >
        {Vocabulary.addMethod}
      </Button>
    </form>
  );
}
