import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import genericStyles from "../Styles/genericStyles.module.css";
import { EWidths } from "../Utils/Enums";
import { GenericModalProps } from "../Utils/Types";

export default function GenericModal(props: GenericModalProps) {
  const { open, children, onClose, actions, title, maxWidth, otherOptions } =
    props;
  return (
    <Dialog
      open={open}
      style={props.style}
      maxWidth={maxWidth ? maxWidth : EWidths.SM}
    >
      <DialogTitle
        className={genericStyles.genericModalTitleContainer}
        sx={{
          backgroundColor: "primary.main",
          color: "textColorSecondary.main",
        }}
      >
        <div className={genericStyles.genericModalExtraOption}>
          <p>{title}</p>
          <div >
            {otherOptions}
          </div>
        </div>
        <p>
          <Close
            onClick={onClose}
            className={genericStyles.genericModalCloseIcon}
          />
        </p>
      </DialogTitle>
      <DialogContent className={genericStyles.genericModalDialogContent}>
        {children}
      </DialogContent>
      {actions ? <div style={{ margin: 20 }}>{actions}</div> : null}
    </Dialog>
  );
}
