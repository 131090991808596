import { useSearchParams } from "react-router-dom";

export default function useCustomSearchParam() {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   *
   * @param name
   * @param value
   */
  const addParamToQueryString = (name: any, value: any) => {
    //if value is null delete the param from the query string
    if (value === null) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }
    setSearchParams(searchParams);
  };
  /**
   *
   * @param name
   * @param value
   */
  const removeParamFromQueryString = (name: any) => {
    name?.map((param: any) => {
      searchParams.delete(param);
    });
    setSearchParams(searchParams);
  };

  /**
   *
   */
  const removeAllParamsFromQueryString = () => {
    searchParams.forEach((value, key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  return {
    addParamToQueryString,
    removeParamFromQueryString,
    searchParams,
    setSearchParams,
    removeAllParamsFromQueryString,
  };
}
