import { Fragment, useState } from "react";
import { EFileExtensions, EWhatsAppMessageType } from "../../Utils/Enums";
import { endpoints } from "../../Utils/UrlEnum";
import { FileMessageExplorerProps } from "../../Utils/Types";
import wordIcon from "../../Images/Icons/Word-icon.png";
import excelIcon from "../../Images/Icons/Excel-icon.png";
import pdfIcon from "../../Images/Icons/PDF-icon.png";
import genericIcon from "../../Images/Icons/Generic-icon.png";
import { DocumentModel } from "../../Utils/Models";
import { Button, Fade, Modal, Paper, Typography } from "@mui/material";
import WhatsAppTemplatePreview from "../WhatsAppIntegration/WhatsAppTemplatePreview";
import styles from "../../Styles/chat.module.css";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import PreviewPDF from "../Widgets/PreviewPDF";
import { toast } from "react-toastify";
import { Download } from "@mui/icons-material";

export default function FileMessageExplorer(props: FileMessageExplorerProps) {
  const { message } = props;
  const [activeFile, setActiveFile] = useState<any>(null);
  const [activeImage, setActiveImage] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  /**
   *
   * @param file
   */
  const downloadFile = (
    id: string | null | undefined,
    fileName: string | null | undefined
  ) => {
    if (!id) return;
    fetch(`${endpoints.whatsApp.image}/${id}`)
      .then((response) => response.blob())
      .then((response) => {
        if (response.size === 0) {
          toast.warn(Vocabulary.missingFile);
          return;
        }
        const href = URL.createObjectURL(response);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName || id); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  /**
   *
   * @param src
   * @param fileName
   * @returns
   */
  const renderFileMessage = (src: string, fileName: string) => {
    return (
      <div className={styles.renderFileMessage}>
        <img width={50} src={src} alt="file" />
        <label className={styles.renderFileMessageLabel}>{fileName}</label>
        <Download />
      </div>
    );
  };

  /**
   *
   * @param document
   * @returns
   */
  const renderDocument = (document: DocumentModel | null) => {
    const docName = document?.filename;
    const ext = docName?.substring(docName?.lastIndexOf(".") + 1).toLowerCase();
    switch (ext) {
      case EFileExtensions.DOC:
      case EFileExtensions.DOCX:
        return renderFileMessage(
          wordIcon,
          message?.document?.filename as string
        );
      case EFileExtensions.XLS:
      case EFileExtensions.XLSX:
      case EFileExtensions.ODS:
      case EFileExtensions.CSV:
        return renderFileMessage(
          excelIcon,
          message?.document?.filename as string
        );
      case EFileExtensions.PDF:
        return (
          <div className={styles.renderFileMessage}>
            <img
              onClick={() => setActiveFile(message)}
              width={50}
              src={pdfIcon}
              alt="file"
            />
            <label>{message?.document?.filename}</label>
          </div>
        );
      default:
        return renderFileMessage(
          genericIcon,
          message?.document?.filename as string
        );
    }
  };

  /**
   * what happens when you click a file
   * @param {Object} file
   */
  const fileClick = (e: any, message: any) => {
    e.preventDefault();
    e.cancelBubble = true;
    if (message.type === EWhatsAppMessageType.IMAGE) {
      e.preventDefault();
      setActiveImage(message);
      return false;
    } else if (
      message.type === EWhatsAppMessageType.DOCUMENT &&
      message.document.mime_type === "application/pdf"
    ) {
      setSelectedFile(message);
    } else {
      downloadFile(message?.document?.id, message?.document?.filename);
    }
    return false;
  };

  /**
   *
   * @returns
   */
  const renderFile = () => {
    let children = (
      <Fragment>
        <img src={genericIcon} alt="file" />
      </Fragment>
    );
    switch (message.type) {
      case EWhatsAppMessageType.TEXT:
        return (
          <div
            style={{
              textTransform: "none",
              justifyContent: "flex-start",
              wordBreak: "break-all",
            }}
          >
            <Typography className={styles.messageText}>
              {message.message}
            </Typography>
          </div>
        );

      case EWhatsAppMessageType.TEMPLATE:
        return (
          <div
            style={{
              textTransform: "none",
              justifyContent: "flex-start",
              wordBreak: "break-all",
            }}
          >
            <WhatsAppTemplatePreview
              template={{ components: message.message }}
            />
          </div>
        );

      case EWhatsAppMessageType.IMAGE:
      case EWhatsAppMessageType.STICKER:
        children = (
          <img
            onClick={() => setActiveFile(message)}
            height={150}
            src={`${endpoints.whatsApp.image}/${message.image?.id}`}
            alt=""
          />
        );
        break;
      case EWhatsAppMessageType.VIDEO:
        children = (
          <video width={250} controls>
            <source
              src={`${endpoints.whatsApp.image}/${message.image?.id}`}
              type={message?.image?.mime_type || ""}
            />
          </video>
        );
        break;
      case EWhatsAppMessageType.DOCUMENT:
        children = renderDocument(message?.document);
        break;
      default:
        break;
    }

    return (
      <Button
        onClick={(e) => {
          return fileClick(e, message);
        }}
        key={message.id}
        sx={{
          textTransform: "none",
          justifyContent: "flex-start",
          wordBreak: "break-all",
        }}
      >
        <a
          className={styles.renderFile}
          href={`${document.location.protocol}//${message.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span> {children}</span>
        </a>
      </Button>
    );
  };

  return (
    <Fragment>
      {activeImage ? (
        <Modal
          className={styles.modal}
          open={true}
          onClose={() => setActiveImage(null)}
        >
          <Fade in={true}>
            <Paper
              elevation={Config.elevation}
              className={styles.fileExplorerPaper}
            >
              <img
                src={`${endpoints.whatsApp.image}/${activeFile?.image?.id}`}
                alt={Vocabulary.imagePreview}
              />
            </Paper>
          </Fade>
        </Modal>
      ) : null}
      {selectedFile ? (
        <PreviewPDF
          title={selectedFile?.document?.filename}
          open={selectedFile ? true : false}
          onClose={() => {
            setSelectedFile(null);
          }}
          htmlContent=""
          url={`${endpoints.whatsApp.image}/${selectedFile?.document?.id}`}
        />
      ) : null}
      {renderFile()}
    </Fragment>
  );
}
