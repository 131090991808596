import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Config from "../../Utils/Config";
import genericStyles from "../../Styles/genericStyles.module.css";
import { TransportMethodsProps } from "../../Utils/Types";
import { Controller } from "react-hook-form";
import { useEffect } from "react";
import { checkIfObjectIsEmpty } from "../../Utils/Utils";

export default function TransportMethods(props: TransportMethodsProps) {
  const { control, transportOptions, getValues, setValue, error, disabled } =
    props;

  useEffect(() => {
    if (
      getValues("shipping_method.name") === undefined ||
      getValues("shipping_method.name") === null ||
      checkIfObjectIsEmpty(getValues("shipping_method"))
    ) {
      //set value to default settings ( transport method where default field is true)
      const defaultTransport = transportOptions?.find(
        (transport: any) => transport.isDefault === true
      );
      setValue("shipping_method", defaultTransport);
      setValue("shipping_total", defaultTransport?.value || 0);
    }
  }, []);

  /**
   *
   * @param event
   * @param newShipping
   */
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newShipping: any
  ) => {
    if (newShipping !== null) {
      setValue("shipping_method", newShipping,{ shouldDirty: true });
      setValue("shipping_total", newShipping?.value);
    }
  };

  return (
    <Grid container spacing={Config.standardGridSpacing}>
      <Grid item xs={12} sm={12} md={9}>
        <Controller
          name="shipping_method"
          control={control}
          render={({ field }) => {
            return (
              <ToggleButtonGroup
                color="primary"
                className={genericStyles.toggleButtons}
                style={{ backgroundColor: error ? "#ffdcdf" : "#fff" }}
                {...field}
                exclusive
                aria-label="transport"
                size="large"
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  value: any
                ) => {
                  handleChange(event, value);
                }}
              >
                {transportOptions?.map((transport: any, index: any) => {
                  return (
                    <ToggleButton
                      value={transport}
                      selected={field.value?.name === transport.name}
                      className={genericStyles.fullWidthElement}
                      key={index}
                    >
                      {transport.name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Controller
          name={"shipping_total"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e);
                if (
                  getValues("shipping_method.name") !== undefined &&
                  getValues("shipping_method.name") !== null
                )
                  setValue("shipping_method", undefined);
              }}
              id="shipping_total"
              name="shipping_total"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              fullWidth
              label={Vocabulary.shippingCost}
              disabled={disabled}
              variant={"standard"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {Vocabulary.currency}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
