import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext, useEffect, useRef, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { CRUDContext } from "./UsersTable";
import { getData } from "../../Services/getData";
import { AxiosResponse } from "axios";
import { endpoints } from "../../Utils/UrlEnum";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postData } from "../../Services/postData";
import { updateData } from "../../Services/updateData";
import { UserFormProps } from "../../Utils/Types";
import { FormsIds } from "../../Utils/Constants";
import Config from "../../Utils/Config";
import { UserFormValidationSchema } from "../../Utils/Models";

export default function UserForm(props: UserFormProps) {
  const userContext: any = useContext(
    props.selectedUser ? props.selectedUser : CRUDContext
  );
  const ref: any = useRef();
  const firstUpdate = useRef(true);
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([] as any);
  const { register, control, handleSubmit, getValues, formState, reset } =
    useForm({
      resolver: yupResolver(UserFormValidationSchema),
      mode: "onChange",
      reValidateMode: "onChange",
      context: undefined,
      shouldFocusError: true,
      shouldUnregister: false,
      criteriaMode: "firstError",
    });
  const formData = useWatch({ control: control });

  /**
   *
   */
  useEffect(() => {
    if (formData && !firstUpdate.current) {
      props.setContextValue({
        ...formData,
      });
    }
  }, [formData]);

  /**
   *
   */
  useEffect(() => {
    if (userContext.lastName && userContext.lastName !== "") reset(userContext);
  }, []);

  /**
   *
   */
  useEffect(() => {
    getData(endpoints.rolesEndpoint).then((res: AxiosResponse) => {
      setRoles(res.data);
    });
  }, []);

  /**
   *
   */
  useEffect(
    () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      if (!firstUpdate.current) ref.current.submit();
    },
    [
      // props.shouldHandleSubmit
    ]
  );

  /**
   *
   * @param id
   */
  async function updateElement(model: any) {
    const url = `${endpoints.usersEndpoint}/${userContext?._id}`;
    const res = (await updateData(url, model)) as any;
    if (!res || res?.error) {
      return;
    }
    if (props.selectedUser) {
      localStorage.setItem("userName", res?.data?.username);
    }
    props.setContextValue([]);
    props.onClose();
  }

  /**
   *
   * @param id
   */
  async function createElement(model: any) {
    const url = `${endpoints.usersEndpoint}`;
    const res = (await postData(url, model)) as any;
    if (!res || res?.error) {
      return;
    }
    props.onClose();
    props.setContextValue([]);
  }

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    userContext._id ? updateElement(model) : createElement(model);
  };

  /**
   *
   * @param model
   */
  const onError = (model: any) => {
    // console.log(model);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      method="get"
      ref={ref}
      id={FormsIds.usersForm}
    >
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("lastName")}
            id="lastName"
            name="lastName"
            label={Vocabulary.lastName}
            value={getValues("lastName")||""}
            fullWidth
            variant="outlined"
            error={typeof formState.errors.lastName === "object"}
            helperText={formState.errors.lastName?.message?.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("firstName")}
            value={getValues("firstName")||""}
            name="firstName"
            id="firstName"
            label={Vocabulary.firstName}
            fullWidth
            variant="outlined"
            error={typeof formState.errors.firstName === "object"}
            helperText={formState.errors.firstName?.message?.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("username")}
            value={getValues("username")||""}
            name="username"
            id="username"
            label={Vocabulary.username}
            fullWidth
            error={typeof formState.errors.username === "object"}
            helperText={formState.errors.username?.message?.toString()}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("email")}
            variant="outlined"
            name="email"
            value={getValues("email")||""}
            id="email"
            label={Vocabulary.email}
            fullWidth
            error={typeof formState.errors.email === "object"}
            helperText={formState.errors.email?.message?.toString()}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="roleIds"
            control={control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                disableClearable
                multiple
                disablePortal
                getOptionDisabled={(option: any) =>
                  !!getValues("roleIds")?.find(
                    (element: any) => element._id === option._id
                  )
                }
                isOptionEqualToValue={(option, value) => option?._id === value}
                value={getValues("roleIds") || []}
                getOptionLabel={(option: any) => option.name}
                id="roleIds"
                options={roles}
                onChange={(event, value) => field.onChange(value)}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.roles}
                    name="roleIds"
                    type="search"
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("password")}
            variant="outlined"
            id="password"
            name="password"
            type={!showPassword ? "password" : "text"}
            label={Vocabulary.password}
            fullWidth
            error={typeof formState.errors.password === "object"}
            helperText={formState.errors.password?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ color: "#a5b3c0" }}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("confirmPassword")}
            variant="outlined"
            id="confirmPassword"
            name="confirmPassword"
            type={!showPassword ? "password" : "text"}
            label={Vocabulary.confirmPassword}
            fullWidth
            error={typeof formState.errors.confirmPassword === "object"}
            helperText={formState.errors.confirmPassword?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ color: "#a5b3c0" }}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
