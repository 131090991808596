/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "../../Styles/chat.module.css";
import {
  CameraAlt,
  Close,
  Refresh,
  Send,
  SentimentDissatisfied,
} from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Controller, useForm } from "react-hook-form";
import { useContext, useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { endpoints } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import MessagesList from "./MessagesList";
import {
  EWhatsAppComponentsTypes,
  EWhatsAppMessageType,
} from "../../Utils/Enums";
import { postData } from "../../Services/postData";
import { WebSocketContext } from "../../Context/WebSocketContext";
import { ChatProps } from "../../Utils/Types";
import {
  ConversationModel,
  MessageModel,
  MessageValidationSchema,
} from "../../Utils/Models";
import { removeNullProperties } from "../../Utils/Utils";
import ChatTemplatesList from "./ChatTemplatesList";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NoDataFound from "../NoDataFound";
import MessagesAttachments from "./MessagesAttachments";
import FilePreview from "./FilePreview";
import ChatExtraInfo from "./ChatExtraInfo";
import ChatOptions from "./ChatOptions";
import useCustomMemo from "../../Hooks/useCustomMemo";
import { deleteData } from "../../Services/deleteData";
import { AxiosResponse } from "axios";
import whatsAppBackground from "../../Images/whatsAppBackground.jpg";

export default function Chat(props: ChatProps) {
  const ref: any = useRef(null);
  const { id, disabled } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const conversationId = searchParams.get("conversation");
  const messageValidationSchema = MessageValidationSchema;
  const { handleSubmit, control, setValue, watch, getValues } = useForm({
    resolver: yupResolver(messageValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });
  const [chat, setChat] = useState<any>();
  const [messages, setMessages] = useState<any[]>([]);
  const [noMoreMessages, setNoMoreMessages] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [shouldScrollPage, setShouldScrollPage] = useState<boolean>(false);
  const websocket = useContext(WebSocketContext);
  const [file, setFile] = useState(false);
  const memo = useCustomMemo();
  const whatsAppTemplates: any = memo?.storage?.whatsAppTemplates?.data;
  const navigate = useNavigate();
  const [prevConversationId, setPrevConversationId] = useState<string | null>(
    null
  ); // State to store the previous conversation ID
  const location = useLocation();

  /**
   *
   */
  useEffect(() => {
    //unsubscribe from the previous conversation;
    if (prevConversationId && conversationId !== prevConversationId) {
      websocket.socket.off(`${prevConversationId}chat`);
    }
    // Update the previous conversation ID to the current one
    setPrevConversationId(conversationId);
  }, [location.search]);

  /**
   *
   */
  const handleDeleteConversation = () => {
    deleteData(`${endpoints.conversation.main}/${chat?._id}`).then(
      (res: AxiosResponse) => {
        if (res) {
          //delete conversation in url
          searchParams.delete("conversation");
          setSearchParams(searchParams);
        }
      }
    );
  };

  /**
   *
   */
  websocket.socket.on(`${conversationId}chat`, (data: any) => {
    if (data && data.message) {
      //if message exist update it else push it into the list
      const newMessages = messages.slice();
      const messageIndex = newMessages.findIndex(
        (message) => message.id === data.message.id
      );
      if (messageIndex !== -1) {
        newMessages[messageIndex].status = data.message.status;
      } else {
        newMessages.push(data.message);
        setShouldScrollPage(false);
      }
      setMessages(newMessages);
    }
  });

  /**
   *
   */
  const renderMoreMessagesButton = () => {
    setPage(page + 1);
  };

  /**
   *
   * @param newMessage
   */
  const updateMessageList = (newMessage: any) => {
    const newMessages = messages.slice();
    newMessages.push(newMessage);
    setMessages(newMessages);
  };

  /**
   *
   * @param message
   */
  const replyToMessage = (message: any) => {
    setValue("context.message_id", message?.id);
  };

  /**
   *
   * @param e
   */
  const sendMessage = (data: any) => {
    //reset textfield
    setValue("text.body", "");
    removeNullProperties(data);
    if (!chat || !chat._id || chat?.text?.body === "") return;
    postData(`${endpoints.whatsApp.message}/${chat._id}`, data).then(
      (res: any) => {
        setValue("context.message_id", null);
        if (res && res?.data) {
          updateMessageList(res.data.newMessage);
        }
      }
    );
  };

  /**
   *
   * @param message
   */
  const sendTemplateMessage = (message: any) => {
    removeNullProperties(message);
    postData(
      `${endpoints.whatsApp.templateMessage}/${chat._id}/${chat.client._id}`,
      message
    ).then((res: any) => {
      setValue("context.message_id", null);
      if (res && res?.data) {
        updateMessageList(res.data.newMessage);
      }
    });
  };

  /**
   *
   * @param errors
   */
  const handleErrors = (errors: any) => {
    // console.log(errors);
  };

  /**
   *
   */
  useEffect(() => {
    const currentConversationId = conversationId || id;
    if (currentConversationId && currentConversationId !== "") {
      getData(
        `${endpoints.conversation.main}/${currentConversationId}?page=${page}`
      ).then((res: any) => {
        if (res?.data) {
          if (res.data.messages.length === 0) {
            setNoMoreMessages(true);
          }
          updateConversationState(res.data);
          setValue("to", res?.data?.client?.phone?.[0] || res?.data?.wa_id);
          if (page > 0) {
            const newMessages = messages.slice();
            setMessages([...res.data.messages, ...newMessages]);
            setShouldScrollPage(true);
          } else {
            setMessages(res.data.messages?.length ? res.data.messages : []);
          }
        } else {
          navigate("/messages");
        }
      });
    } else {
      updateConversationState(new ConversationModel());
    }
  }, [id, conversationId, page]);

  /**
   *
   * @param conversation
   */
  const updateConversationState = (conversation: ConversationModel) => {
    setChat(conversation);
  };

  /**
   *
   * @param message
   * @returns
   */
  const renderMessageContextText = (contextMessage: any) => {
    switch (contextMessage?.type) {
      case EWhatsAppMessageType.TEXT:
        if (typeof contextMessage.message === "string") {
          return <Typography>{contextMessage.message}</Typography>;
        }
        break;
      case EWhatsAppMessageType.TEMPLATE:
        // its array
        //check for the object in the array of object that has the type BODY
        const body = contextMessage.message?.find(
          (msg: any) => msg.type === EWhatsAppComponentsTypes.BODY
        );
        if (body) {
          return <Typography>{body.text}</Typography>;
        }
        break;
      case EWhatsAppMessageType.IMAGE:
        return (
          <div className={styles.imageReplyContainer}>
            <div className={styles.imageReplyIconContainer}>
              <CameraAlt />
              <label>{Vocabulary.image}</label>
            </div>
            <img
              width={30}
              src={`${endpoints.whatsApp.image}/${contextMessage.image?.id}`}
              alt=""
            />
          </div>
        );
      case EWhatsAppMessageType.DOCUMENT:
        return <Typography>{contextMessage?.document?.filename}</Typography>;
      default:
        return null;
    }
  };

  /**
   *
   * @param message
   * @returns
   */
  const renderMessageContext = (
    messageId: any,
    shouldUserDeleteReplyMessageContext?: boolean
  ) => {
    if (!messageId) return null;
    const contextMessage = messages.find(
      (msg: MessageModel) => msg.id === messageId
    );
    return (
      <div
        className={`${styles.repliedMessageContainer} ${
          shouldUserDeleteReplyMessageContext
            ? styles.liveMessageReply
            : styles.oldMessageReply
        }`}
      >
        <div className={styles.repliedMessage}>
          <Typography variant="caption">{`${
            contextMessage?.sender || ""
          }`}</Typography>
          {renderMessageContextText(contextMessage)}
        </div>
        {shouldUserDeleteReplyMessageContext ? (
          <Tooltip
            title={Vocabulary.delete}
            onClick={() => replyToMessage(null)}
          >
            <IconButton type="submit" color="blueGrey">
              <Close />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  /**
   *
   */
  const renderChatHeader = () => {
    return (
      <div className={styles.chatHeader}>
        <div>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            {chat?.client?.name ||
              chat?.potentialClientName ||
              Vocabulary.clientConversation}
          </Typography>
          <Typography variant="body2">
            {chat?.client?.phone?.[0] || chat?.wa_id}
          </Typography>
        </div>
        <div>
          <ChatExtraInfo />
          <ChatOptions
            chat={chat}
            handleDeleteConversation={handleDeleteConversation}
            updateConversationState={updateConversationState}
          />
        </div>
      </div>
    );
  };

  /**
   *
   * @returns
   */
  const renderNoMessages = (className: string, message: string) => {
    return (
      <div className={className}>
        <SentimentDissatisfied fontSize="large" />
        <Typography variant="body1">{message}</Typography>
      </div>
    );
  };

  return chat?._id ? (
    <>
      <Paper className={styles.conversationContainer}>
        {renderChatHeader()}
        {file ? (
          <FilePreview
            setFile={setFile}
            file={file}
            phone={chat?.client?.phone?.[0] || chat?.wa_id}
            conversationId={conversationId || id}
            className={props.className}
          />
        ) : (
          <>
            <div
              id="messagesContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                backgroundSize: "contain",
                backgroundColor: "#ffffff",
                backgroundImage: `url(${whatsAppBackground})`,
              }}
              className={`${props.className}`}
            >
              {messages.length > 0 ? (
                <>
                  {!noMoreMessages ? (
                    <Button
                      fullWidth
                      color="blueGrey"
                      onClick={renderMoreMessagesButton}
                      endIcon={<Refresh />}
                      disabled={disabled}
                    >
                      {Vocabulary.loadMoreMessages}
                    </Button>
                  ) : (
                    renderNoMessages(
                      `${styles.noMessages} ${styles.noMoreMessages}`,
                      Vocabulary.noMoreMessages
                    )
                  )}
                  <MessagesList
                    disabled={disabled}
                    shouldScrollPage={shouldScrollPage}
                    messages={messages}
                    replyToMessage={replyToMessage}
                    renderMessageContext={renderMessageContext}
                    key={chat?._id}
                  />
                </>
              ) : (
                renderNoMessages(styles.noMessages, Vocabulary.noMessagesYet)
              )}
              {watch("context.message_id")
                ? renderMessageContext(getValues("context.message_id"), true)
                : null}
            </div>
            <form ref={ref} onSubmit={handleSubmit(sendMessage, handleErrors)}>
              <div className={styles.messageInputContainer}>
                <ChatTemplatesList
                  templates={whatsAppTemplates}
                  clientId={chat?.client?._id}
                  disabled={disabled}
                  sendTemplateMessage={sendTemplateMessage}
                />
                <MessagesAttachments setFile={setFile} />
                <Controller
                  name="text.body"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={styles.messageInputTextField}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                      value={value || ""}
                      fullWidth
                      disabled={disabled}
                    />
                  )}
                />
                <Tooltip title={Vocabulary.send}>
                  <IconButton
                    type="submit"
                    color="blueGrey"
                    disabled={disabled}
                  >
                    <Send
                      fontSize="large"
                      sx={{
                        transform: "rotate(-45deg)",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </form>
          </>
        )}
      </Paper>
    </>
  ) : (
    <NoDataFound />
  );
}
