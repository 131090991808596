/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vocabulary } from "../../Utils/Vocabulary";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { endpoints, localUrlEnum } from "../../Utils/UrlEnum";
import { AxiosResponse } from "axios";
import WhatsAppTemplateComponentContainer from "./WhatsAppTemplateComponentContainer";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  DefaultWhatsAppTemplateComponents,
  FormsIds,
  WhatsAppTemplatesSupportedLanguages,
  WhatsAppTemplatesSupportedLanguagesTranslation,
} from "../../Utils/Constants";
import { Help, Save } from "@mui/icons-material";
import TemplateButtonComponent from "./TemplateButtonComponent";
import TemplateHeaderComponent from "./TemplateHeaderComponent";
import TemplateBodyComponent from "./TemplateBodyComponent";
import ObservationWidget from "../Widgets/ObservationWidget";
import { EWhatsAppTemplateCategory } from "../../Utils/Enums";
import styles from "../../Styles/whatsApp.module.css";
import { postData } from "../../Services/postData";
import { updateData } from "../../Services/updateData";
import GenericModal from "../GenericModal";
import StandardActionButtons from "../StandarsActionButtons";
import Config from "../../Utils/Config";
import { WhatsAppTemplateValidationSchema } from "../../Utils/Models";

export default function WhatsAppTemplateForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  /**
   * check if response.data.components has less then 3 element merge its with the default values
   * @param receivedComponents
   * @returns
   */
  const mergeComponents = (receivedComponents: any) => {
    const finalResponse = receivedComponents;
    if (receivedComponents.length < 3) {
      const mergedProperty = DefaultWhatsAppTemplateComponents.map(
        (defaultComponent: any) => {
          const receivedComponent = receivedComponents.find(
            (component: any) => component.type === defaultComponent.type
          );
          return receivedComponent
            ? { ...defaultComponent, ...receivedComponent }
            : defaultComponent;
        }
      );
      return mergedProperty;
    }
    return finalResponse;
  };
  const methods = useForm({
    defaultValues: {
      id: "",
      name: "",
      category: EWhatsAppTemplateCategory.UTILITY,
      allow_category_change: true,
      language: "ro",
      components: DefaultWhatsAppTemplateComponents,
    },
    resolver: yupResolver(WhatsAppTemplateValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  useEffect(() => {
    if (id) {
      getTemplate(id);
    }
  }, []);

  /**
   *
   * @param id
   */
  const getTemplate = (id: string) => {
    getData(`${endpoints.whatsApp.template}/${id}`).then(
      (response: AxiosResponse) => {
        if (response && response.data) {
          response.data.components = mergeComponents(response.data.components);
          methods.reset(response.data);
          setEdit(true);
        }
      }
    );
  };

  const updateTemplate = () => {
    const data = methods.getValues();
    updateData(`${endpoints.whatsApp.template}/${data.id}`, data).then(
      (response: AxiosResponse) => {
        if (response && response.data && response.data.id) {
          getTemplate(response.data.id);
        }
        setOpen(false);
      }
    );
  };

  /**
   *
   * @param model
   */
  const handleSubmit = (model: any) => {
    const data = methods.getValues();
    if (model.id) {
      //render modal
      setOpen(true);
    } else {
      postData(endpoints.whatsApp.template, data).then(
        (response: AxiosResponse) => {
          if (response && response.data) {
            //push id into url
            navigate(`${localUrlEnum.whatsAppTemplate}/${response.data.id}`);
            getTemplate(response.data.id);
          }
        }
      );
    }
  };

  /**
   *
   * @param model
   */
  const handleError = (model: any) => {
    // console.log(methods.getValues());
    // console.log(model);
  };

  return (
    <Fragment>
      <form
        id={FormsIds.whatsAppTemplateForm}
        onSubmit={methods.handleSubmit(handleSubmit, handleError)}
      >
        {edit ? (
          <Paper className={styles.editHelperText}>
            <Help fontSize="large" className={styles.editHelPerTextIcon} />
            <Typography>{Vocabulary.whatsAppTemplateEditingWarning}</Typography>
          </Paper>
        ) : null}
        <WhatsAppTemplateComponentContainer
          otherHeaderComponents={
            <Button startIcon={<Save />} variant="contained" type="submit">
              {Vocabulary.save}
            </Button>
          }
          name={`${Vocabulary.whatsAppTemplate} ${
            methods.getValues("id") || ""
          }`}
          children={
            <Grid container spacing={Config.standardGridSpacing}>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <TextField
                  {...methods.register("name")}
                  name="name"
                  id="name"
                  label={Vocabulary.templateName}
                  fullWidth
                  variant="outlined"
                  error={typeof methods.formState.errors.name === "object"}
                  helperText={methods.formState.errors.name?.message?.toString()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={edit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <Controller
                  name="language"
                  control={methods.control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      disabled={edit}
                      value={field.value || " "}
                      freeSolo={false}
                      disablePortal
                      disableClearable
                      clearOnBlur={true}
                      options={WhatsAppTemplatesSupportedLanguages}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      getOptionLabel={(option: any) =>
                        WhatsAppTemplatesSupportedLanguagesTranslation[
                          option
                        ] || ""
                      }
                      onChange={(event, value: any) => {
                        field.onChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          value={field?.value || " "}
                          error={!!error}
                          helperText={error?.message}
                          label={Vocabulary.templateLanguage}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          }
        />
        <WhatsAppTemplateComponentContainer
          name={`${Vocabulary.header}`}
          otherHeaderComponents={
            <ObservationWidget
              shouldRenderIcon={true}
              text={Vocabulary.whatsAppHeaderNoteObservation}
            />
          }
          isOptional={true}
          children={<TemplateHeaderComponent methods={methods} />}
        />
        <WhatsAppTemplateComponentContainer
          name={`${Vocabulary.body}`}
          children={<TemplateBodyComponent methods={methods} />}
        />
        <WhatsAppTemplateComponentContainer
          name={`${Vocabulary.footer}`}
          isOptional={true}
          children={
            <TextField
              {...methods.register("components.2.text")}
              name="components.2.text"
              id="components.2.text"
              label={Vocabulary.enterWhatsAppMessageFooterText}
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              error={
                typeof methods.formState.errors.components?.[2] === "object"
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{`${
                    methods.getValues("components.2.text")?.length || 0
                  }/60`}</InputAdornment>
                ),
              }}
              helperText={
                methods.formState.errors.components?.[2]?.text?.message?.toString() ||
                ""
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
        />
        <WhatsAppTemplateComponentContainer
          name={`${Vocabulary.buttons}`}
          isOptional={true}
          otherHeaderComponents={
            <ObservationWidget
              shouldRenderIcon={true}
              text={Vocabulary.whatsAppFooterButtonsHelperText}
            />
          }
          children={<TemplateButtonComponent methods={methods} />}
        />
      </form>
      <GenericModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title={Vocabulary.confirmSaveOrUpdateOrder}
        actions={
          <StandardActionButtons
            okAction={() => updateTemplate()}
            cancelAction={() => setOpen(false)}
            okText={Vocabulary.continue}
            cancelText={Vocabulary.cancel}
          />
        }
      >
        <div>{Vocabulary.whatsAppEditTemplateLimit}</div>
      </GenericModal>
    </Fragment>
  );
}
