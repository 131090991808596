import genericStyles from "../Styles/genericStyles.module.css";
import { TabPanelProps } from "../Utils/Types";

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, style, className, ...other } = props;

  return (
    <div
      className={`${genericStyles.fullWidthElement} ${className}`}
      style={style}
      role="tabpanel"
      hidden={value !== index}
      id="scrollableDiv"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
