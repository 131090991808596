import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as ReactDOM from "react-dom/client";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);

reportWebVitals();
