import { Add, Delete } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Fragment, useState } from "react";
import { WhatsAppTemplateButtons } from "../../Utils/Constants";
import ObservationWidget from "../Widgets/ObservationWidget";
import { TemplateButtonComponentProps } from "../../Utils/Types";
import { Controller } from "react-hook-form";
import styles from "../../Styles/whatsApp.module.css";
import { EWhatsAppTemplateButtonType } from "../../Utils/Enums";
import Config from "../../Utils/Config";

export default function TemplateButtonComponent(
  props: TemplateButtonComponentProps
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { methods } = props;
  const templateButtons = methods.watch(`components[3].buttons`);
  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   * @param button
   */
  const addButtonToTemplate = (button: any) => {
    //get the current value of the buttons
    const currentButtons = templateButtons || [];
    //add the new button to the array
    currentButtons.push(button);
    //set the new value of the buttons
    methods.setValue(`components[3].buttons`, currentButtons);
  };

  /**
   *
   * @param index
   */
  const deleteButtonByIndex = (index: any) => {
    const currentButtons = templateButtons || [];
    currentButtons.splice(index, 1);
    methods.setValue(`components[3].buttons`, currentButtons);
  };

  /**
   *
   * @param type
   */
  const countNOSpecificTypeButtons = (type: string) => {
    return templateButtons?.filter((button: any) => button.type === type)
      .length;
  };

  /**
   *
   * @returns
   */
  const renderButtons = () => {
    //iterate over the buttons and render them depending on the type of button
    return templateButtons?.map((button: any, index: any) => {
      return (
        <div key={index} className={styles.whatsAppTemplateButtonContainer}>
          <Controller
            name={`components[3].buttons[${index}].text`}
            control={methods.control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                onChange={field.onChange}
                fullWidth
                label={Vocabulary.buttonText}
                inputProps={{
                  maxLength: "25",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{`${
                      methods.getValues(`components[3].buttons[${index}].text`)
                        ?.length || 0
                    }/25`}</InputAdornment>
                  ),
                }}
                helperText={
                  methods.formState.errors.components?.[3]?.buttons?.[
                    index
                  ]?.text?.message?.toString() || ""
                }
                error={
                  typeof methods.formState.errors.components?.[3]?.buttons?.[
                    index
                  ] === "object"
                }
              />
            )}
          />
          {button.type === EWhatsAppTemplateButtonType.URL ? (
            <Controller
              name={`components[3].buttons[${index}].url`}
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  fullWidth
                  label={Vocabulary.url}
                  className={styles.whatsAppTemplateButtonTextFieldValue}
                  inputProps={{
                    maxLength: "2000",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{`${
                        methods.getValues(`components[3].buttons[${index}].url`)
                          ?.length || 0
                      }/2000`}</InputAdornment>
                    ),
                  }}
                  helperText={
                    methods.formState.errors.components?.[3]?.buttons?.[
                      index
                    ]?.url?.message?.toString() || ""
                  }
                  error={
                    typeof methods.formState.errors.components?.[3]?.buttons?.[
                      index
                    ] === "object"
                  }
                />
              )}
            />
          ) : null}
          {button.type === EWhatsAppTemplateButtonType.PHONE_NUMBER ? (
            <Controller
              name={`components[3].buttons[${index}].phone_number`}
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  fullWidth
                  label={`${Vocabulary.phoneNumber} ${Vocabulary.phoneNumberFormat}`}
                  className={styles.whatsAppTemplateButtonTextFieldValue}
                  inputProps={{
                    maxLength: "20",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{`${
                        methods.getValues(
                          `components[3].buttons[${index}].phone_number`
                        )?.length || 0
                      }/20`}</InputAdornment>
                    ),
                  }}
                  helperText={
                    methods.formState.errors.components?.[3]?.buttons?.[
                      index
                    ]?.phone_number?.message?.toString() || ""
                  }
                  error={
                    typeof methods.formState.errors.components?.[3]?.buttons?.[
                      index
                    ] === "object"
                  }
                />
              )}
            />
          ) : null}
          <IconButton onClick={() => deleteButtonByIndex(index)}>
            <Delete />
          </IconButton>
        </div>
      );
    });
  };

  return (
    <Fragment>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ObservationWidget
            shouldRenderIcon={false}
            text={Vocabulary.whatsAppFooterNoteObservation}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            disabled={templateButtons?.length === 10}
            onClick={handleClick}
            startIcon={<Add />}
            variant="contained"
            color="secondary"
          >
            {Vocabulary.addButton}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {renderButtons()}
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        id="whatsApp-template-buttons-menu"
        open={open}
      >
        {WhatsAppTemplateButtons.map((option, mainIndex) => (
          <div key={mainIndex}>
            <Typography sx={{ fontWeight: "bold", marginLeft: "15px" }}>
              {option.name}
            </Typography>
            {option.menu.map((menuItem: any, index: any) => (
              <MenuItem
                key={index}
                onClick={() => {
                  addButtonToTemplate(menuItem.content);
                }}
                disabled={
                  menuItem.buttonNr &&
                  countNOSpecificTypeButtons(menuItem.content.type) >=
                    menuItem.buttonNr
                }
              >
                <Fragment>
                  <Typography>{menuItem.name}</Typography>
                  {menuItem.buttonNr && (
                    <Typography
                      sx={{ marginLeft: "15px" }}
                      variant="caption"
                    >{`(${Vocabulary.nrOfAcceptedButtons} ${menuItem.buttonNr})`}</Typography>
                  )}
                </Fragment>
              </MenuItem>
            ))}
            {option.shouldRenderDivider && <Divider />}
          </div>
        ))}
      </Menu>
    </Fragment>
  );
}
