/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Delete, Edit } from "@mui/icons-material";
import { localUrlEnum, endpoints } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import AddOrderStatusModal from "./AddOrderStatusModal";
import DeleteOrderStatus from "./DeleteOrderStatus";
import { deleteData } from "../../Services/deleteData";
import { postData } from "../../Services/postData";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Config from "../../Utils/Config";
import { WebSocketContext } from "../../Context/WebSocketContext";
import genericStyles from "../../Styles/genericStyles.module.css";
import { AxiosResponse } from "axios";
import { OrderStatusState } from "../../Utils/Models";
import { tableTheme } from "../../Utils/Constants";

export default function OrderStatusesTable() {
  const navigate = useNavigate();
  const websocketContext = useContext(WebSocketContext);
  const location = useLocation();
  const [openAddOrderStatusModal, setOpenAddOrderStatusModal] = useState(false);
  const [showDeleteOrderStatusModal, setShowDeleteOrderStatusModal] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [state, setState] = useState<OrderStatusState>(new OrderStatusState());

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split("/");
    getOrderStatus(state?.page, state?.perPage, search[3] ? search[3] : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   *
   */
  const getOrderStatus = (page: any, perPage: any, search?: any) => {
    let url = `${endpoints.orderStatus}/paginated?page=${page}&perPage=${perPage}`;
    if (search) {
      url = `${endpoints.orderStatus}/paginated?page=${page}&perPage=${perPage}&search=${search}`;
    }
    getData(url).then((res: AxiosResponse) => {
      setState({
        ...state,
        orderStatusPerPage: res.data.results,
        orderStatusNumber: res.data.numberOfResults,
        page: page,
        perPage: perPage,
        search: search || null,
      });
    });
  };

  /**
   *
   */
  const handleChangeOrderStatusModalValue = async () => {
    setOpenAddOrderStatusModal(!openAddOrderStatusModal);
    setSelectedRow(null);
  };
  /**
   *
   * @param {*} row
   */
  const onDeleteOrderStatus = async (row: any) => {
    setDeleteId(row);
    setShowDeleteOrderStatusModal(true);
  };

  /**
   *
   * @param {*} row
   */
  const onEditOrderStatus = async (row: any) => {
    setSelectedRow(row);
    setOpenAddOrderStatusModal(true);
  };

  /**
   *
   * @param rowIndex
   */
  const sortDown = (rowIndex: number) => {
    const body = {
      status1: state.orderStatusPerPage[rowIndex],
      status2: state.orderStatusPerPage[rowIndex + 1],
    };
    postData(`${endpoints.orderStatus}/update/sortNumber`, body).then(
      (res: AxiosResponse) => {
        getOrderStatus(state.page, state.perPage, state.search);
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      }
    );
  };

  /**
   *
   * @param rowIndex
   */
  const sortUp = (rowIndex: number) => {
    const body = {
      status1: state.orderStatusPerPage[rowIndex],
      status2: state.orderStatusPerPage[rowIndex - 1],
    };
    postData(`${endpoints.orderStatus}/update/sortNumber`, body).then(
      (res: AxiosResponse) => {
        getOrderStatus(state.page, state.perPage, state.search);
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      }
    );
  };
  /**
   *
   */
  const handleDelete = () => {
    deleteData(`${endpoints.orderStatus}/${deleteId._id}`).then(
      async (res: AxiosResponse) => {
        getOrderStatus(state.page, state.perPage, state.search);
        setShowDeleteOrderStatusModal(false);
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      }
    );
  };

  const requestHeaders = [
    {
      label: Vocabulary.statusesOrder,
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
          width: 50,
        }),
        setCellProps: () => ({
          align: "center",
          width: 50,
        }),
        filter: false,
        sort: false,
        selectableRowsHeader: true,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "centers",
                justifyContent: "space-between",
              }}
            >
              <Button
                disabled={
                  rowIndex === state.orderStatusPerPage?.length - 1
                    ? true
                    : false
                }
                color="inherit"
                style={{ padding: 0 }}
              >
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    sortDown(rowIndex);
                  }}
                />
              </Button>
              <Button
                disabled={rowIndex === 0 ? true : false}
                color="inherit"
                style={{ padding: 0 }}
              >
                <KeyboardArrowUpIcon
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    sortUp(rowIndex);
                  }}
                />
              </Button>
            </div>
          );
        },
      },
    },
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.color,
      name: "color",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div
              style={{
                width: 60,
                height: 20,
                borderRadius: 50,
                border: "1px solid gray",
                backgroundColor: state.orderStatusPerPage[rowIndex].color,
              }}
            ></div>
          );
        },
      },
    },
    {
      label: " ",
      name: "isDefault",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          return (
            <div
              style={{
                color: "white !important",
                float: "right",
              }}
            >
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      onEditOrderStatus(
                        state.orderStatusPerPage[rowIndex.rowIndex]
                      );
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                {dataIndex ? null : (
                  <Tooltip title={Vocabulary.delete}>
                    <ToggleButton
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteOrderStatus(
                          state.orderStatusPerPage[rowIndex.rowIndex]
                        );
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <Delete />
                    </ToggleButton>
                  </Tooltip>
                )}
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPage: state.perPage,
      rowsPerPageOptions: Config.rowsPerPageOptions,
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: {
          next: Vocabulary.nextPage,
          previous: Vocabulary.previousPage,
          rowsPerPage: Vocabulary.rowsPerPage,
          displayRows: Vocabulary.of,
        },
        toolbar: {
          search: Vocabulary.search,
          downloadCsv: Vocabulary.downloadCSV,
          print: Vocabulary.print,
        },
        selectedRows: {
          text: Vocabulary.rowsSelected,
          delete: Vocabulary.delete,
        },
      },
      count: state.orderStatusNumber,
      page: state.page,
      search: true,
      serverSide: true,
      sort: true,

      onChangePage: (page: any) => {
        navigate(
          `${localUrlEnum.orderStatus}?page=${page + 1}?perPage=${
            state.perPage
          }`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `${localUrlEnum.orderStatus}?page=${1}?perPage=${numberOfRows}`
        );
        window.scrollTo(0, 0);
      },
      onSearchChange: async (search: string | null) => {
        if (search) {
          navigate(
            `${localUrlEnum.orderStatus}/${state.page}/${state.perPage}/${search}`
          );
        } else {
          setState({ ...state, search: null });
          navigate(
            `${localUrlEnum.orderStatus}/${state.page}/${state.perPage}
            }`
          );
        }
      },
      onSearchClose: async () => {
        setState({ ...state, search: null });
        navigate(
          `${localUrlEnum.orderStatus}?page=${state.page + 1}?perPage=${
            state.perPage
          }`
        );
      },
      customToolbar: () => {
        return (
          <div style={{ margin: 5 }}>
            <Button
              variant="contained"
              onClick={handleChangeOrderStatusModalValue}
            >
              <AddCircleIcon />
              {Vocabulary.add}
            </Button>
          </div>
        );
      },
    };
  };

  return (
    <div className={genericStyles.mainContainer}>
      <ThemeProvider theme={tableTheme}>
        <MUIDataTable
          title={Vocabulary.orderStatus}
          data={state.orderStatusPerPage}
          columns={requestHeaders}
          options={getTableOptions()}
        />
      </ThemeProvider>
      <AddOrderStatusModal
        openAddOrderStatusModal={openAddOrderStatusModal}
        handleChangeOrderStatusModalValue={handleChangeOrderStatusModalValue}
        selectedRow={selectedRow}
        sortNumber={state.orderStatusPerPage?.length}
        getOrderStatus={() => {
          getOrderStatus(state.page, state.perPage, state.search);
          websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        }}
      />
      <DeleteOrderStatus
        openDeleteModal={showDeleteOrderStatusModal}
        setOpenDeleteModal={setShowDeleteOrderStatusModal}
        handleDelete={handleDelete}
      />
    </div>
  );
}
