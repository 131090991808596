import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { AllRoutes } from "../Utils/Constants";
import withRole from "../Hooks/withRole";
import { LocalRouterProps } from "../Utils/Types";

const LocalRouter = (props: LocalRouterProps) => {
  const [router, setRouter] = useState<any>();

  /**
   *
   */
  useEffect(() => {
    if (!props.routes) return;
    setRouter(filterAndVerifyRoutes(AllRoutes, props.routes));
  }, [props]);

  /**
   *
   * @param routes
   * @returns
   */

  /**
   *
   * @param routerPaths
   * @param routes
   * @returns
   */
  function filterAndVerifyRoutes(routerPaths: any, routes: any) {
    return routerPaths.reduce((filteredPaths: any, pathObj: any) => {
      const isValidPath = routes.includes(pathObj.path);

      if (isValidPath) {
        if (pathObj.children && pathObj.children.length > 0) {
          const validChildren = filterAndVerifyRoutes(pathObj.children, routes);
          if (validChildren.length > 0) {
            filteredPaths.push({ ...pathObj, children: validChildren });
          }
        } else {
          filteredPaths.push(pathObj);
        }
      }

      return filteredPaths;
    }, []);
  }

  return (
    <RouterProvider
      router={createBrowserRouter(router || AllRoutes)}
    ></RouterProvider>
  );
};

export default withRole(LocalRouter);
