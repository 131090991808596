import { Divider, Grid, Typography } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../Styles/orderOffer.module.css";
import OrderOfferFooter from "../Components/OrderOffer/OrderOfferFooter";
import { formatStringDate } from "../Utils/Utils";
import { Fragment, useEffect } from "react";
import Config from "../Utils/Config";
import { localUrlEnum } from "../Utils/UrlEnum";

export default function OrderOfferConfirmPage() {
  const { state } = useLocation();
  const { orderId } = useParams();
  const navigate = useNavigate();

  /**
   *
   */
  useEffect(() => {
    if (
      !orderId ||
      orderId === "null" ||
      orderId === "undefined" ||
      orderId === ""
    ) {
      navigate(localUrlEnum.pageNotFound);
    }
  }, [orderId]);

  useEffect(() => {
    //if state is null redirect to page not found
    if (!state._id) {
      window.location.href = "/pageNotFound";
    }
  }, []);

  /**
   *
   * @param content
   * @returns
   */
  const jaggedBorderContainer = (content: any) => {
    return (
      <>
        <div
          className={`${styles.jaggedBorderHeader} ${styles.jaggedBorderFlipVertical}`}
        />
        <div className={styles.jaggedBorderContainer}>{content}</div>
        <div className={styles.jaggedBorderFooter} />
      </>
    );
  };

  /**
   *
   * @param description
   * @param text
   * @returns
   */
  const renderOrderData = (description: string, text: string | null) => {
    return (
      <div className={styles.orderSummaryContainer}>
        <Typography variant="overline">{`${description}:`}</Typography>
        <Typography className={genericStyles.boldTypography}>
          {text || ""}
        </Typography>
      </div>
    );
  };

  /**
   *
   * @param description
   * @param text
   * @returns
   */
  const renderOrderDetailsText = (
    description: string,
    text: string,
    isProduct?: boolean,
    key?: any
  ) => {
    return (
      <Fragment key={key}>
        <Grid
          item
          xs={12}
          sm={8}
          lg={8}
          className={
            isProduct
              ? styles.gridOrderDetailsTextWithProduct
              : styles.gridOrderDetailsText
          }
        >
          <Typography className={genericStyles.boldTypography}>
            {description}
          </Typography>
        </Grid>
        <Grid
          sx={{
            backgroundColor: isProduct ? "#ffff" : "#f8f8f8",
            padding: "10px !important",
          }}
          item
          xs={12}
          sm={4}
          lg={4}
        >
          <Typography>{text}</Typography>
        </Grid>
      </Fragment>
    );
  };

  /**
   *
   * @returns
   */
  const renderOrderDetails = () => {
    return (
      <Grid
        className={styles.orderDetailsGridContainer}
        container
        spacing={Config.standardGridSpacing}
      >
        {renderOrderDetailsText(Vocabulary.product, Vocabulary.price)}
        {state?.line_items &&
          state?.line_items.map((item: any, index: number) =>
            renderOrderDetailsText(
              `${item?.name} x ${item?.quantity}`,
              `${item?.total || 0}${Vocabulary.currency.toLowerCase()}`,
              true,
              index
            )
          )}
        {/* {renderOrderDetailsText(
          Vocabulary.subtotal,
          state?.line_items
            .map((item: any) => item?.total)
            .reduce((a: any, b: any) => a + b, 0) ||
            0 + Vocabulary.currency.toLowerCase()
        )} */}
        {/* {renderOrderDetailsText(
          Vocabulary.delivery,
          `${state?.shipping_total || 0}${Vocabulary.currency.toLowerCase()}`
        )}
        {renderOrderDetailsText(
          Vocabulary.paymentMethod,
          state?.payment_method_title
        )}
        {renderOrderDetailsText(
          Vocabulary.total,
          `${state?.total || 0}${Vocabulary.currency.toLowerCase()}`
        )} */}
      </Grid>
    );
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <a href="https://ecopackbusiness.ro/">
          <img
            className={styles.orderOfferHeaderImage}
            src={"/logo.png"}
            alt="EcoPack"
            width={150}
          />
        </a>
      </div>
      <div className={styles.mainContainerBox}>
        <Typography
          textAlign={"center"}
          sx={{ margin: "20px 0 !important", color: "#939194" }}
          variant="h5"
        >
          {Vocabulary.finishOrder}
        </Typography>
        {/* <Typography
          textAlign={"center"}
          sx={{ margin: "20px 0 !important", color: "#939194" }}
          variant="h6"
        >
          {Vocabulary.orderMessage}
        </Typography> */}
        <Typography sx={{ margin: "20px 0 !important" }} variant="h5">
          {Vocabulary.thankYouForYourOrder}
        </Typography>
        {jaggedBorderContainer(
          <>
            {renderOrderData(Vocabulary.orderNr, state?.number)}
            <Divider />
            {renderOrderData(
              Vocabulary.dateWithDiacritics,
              formatStringDate(state?.updatedAt, "LL")
            )}
            <Divider />
            {renderOrderData(
              Vocabulary.total,
              `${state?.total || 0} ${Vocabulary.currency.toLowerCase()}`
            )}
            <Divider />
            {renderOrderData(
              Vocabulary.paymentMethod,
              state?.payment_method_title
            )}
          </>
        )}
        <Typography
          sx={{ margin: "20px 0 !important", color: "#939194" }}
          className={styles.explanationText}
          variant="h5"
        >
          {Vocabulary.orderDetails}
        </Typography>
        {renderOrderDetails()}
      </div>
      <OrderOfferFooter />
    </div>
  );
}
