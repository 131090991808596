import { Button, Stepper } from "@mui/material";
import { StepperNotesProps } from "../../Utils/Types";
import DisplayOneNote from "./DisplayOneNote";
import { Vocabulary } from "../../Utils/Vocabulary";
import Note from "../Contacts/Note";
import { useEffect, useState } from "react";
import { NoteModel } from "../../Utils/Models";
import styles from "../../Styles/stepper.module.css";
import { Add } from "@mui/icons-material";

export default function StepperNotes(props: StepperNotesProps) {
  const [note, setNote] = useState(new NoteModel());
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const { notes, updateNotes, disabled } = props;
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("userId");

  /**
   *
   */
  useEffect(() => {
    if (shouldSubmit && note && note.text !== "") {
      //create copy of notes array
      const newNotes = [...notes];
      //if note has no agent, set agent to userId
      if (!note.agent) note.agent = userId;
      //find note in notes array by _id and update it
      //or add new note to notes array
      const index = newNotes.findIndex((n: NoteModel) => n._id === note._id);
      if (index !== -1) {
        newNotes[index] = note;
        updateNotes(newNotes);
        toggleOpenState();
      } else {
        newNotes.push(note);
        updateNotes(newNotes);
        toggleOpenState();
      }
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  /**
   *
   */
  const toggleOpenState = () => {
    setOpen(!open);
  };

  /**
   *
   */
  const closeNoteModal = () => {
    toggleOpenState();
    setNote(new NoteModel());
  };

  /**
   *
   * @param note
   */
  const deleteContactNote = (note: NoteModel) => {
    //find note in notes array by _id and delete it
    const newNotes = notes.filter((n: NoteModel) => n._id !== note._id);
    updateNotes(newNotes);
  };

  return (
    <div>
      <Button
        disabled={disabled}
        className={styles.addButton}
        variant="contained"
        color="secondary"
        onClick={toggleOpenState}
        startIcon={<Add fontSize="small" />}
      >
        {Vocabulary.addNotes}
      </Button>

      <Stepper
        orientation="vertical"
        style={{ maxHeight: 500, overflowY: "scroll", padding: 10 }}
      >
        {notes?.map((note: NoteModel, index: number) => {
          return (
            <DisplayOneNote
              disabled={disabled}
              note={note}
              key={index}
              handleEdit={(note: NoteModel) => {
                setNote(note);
                toggleOpenState();
              }}
              handleDelete={(note: NoteModel) => {
                deleteContactNote(note);
              }}
            />
          );
        })}
      </Stepper>
      <Note
        setContextValue={setNote}
        open={open}
        note={note}
        handleClose={closeNoteModal}
        id={note._id}
        setShouldSubmit={setShouldSubmit}
      />
    </div>
  );
}
