import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  TextField,
  ToggleButtonGroup,
  Tooltip,
  ToggleButton,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { endpoints } from "../../Utils/UrlEnum";
import { updateData } from "../../Services/updateData";
import { WebSocketContext } from "../../Context/WebSocketContext";
import { getData } from "../../Services/getData";
import { AxiosResponse } from "axios";
import { FormsIds } from "../../Utils/Constants";
import styles from "../../Styles/settings.module.css";
import Config from "../../Utils/Config";
import { OrderSettingsFormValidationSchema } from "../../Utils/Models";

export default function OrderSettings() {
  const websocketContext = useContext(WebSocketContext);
  const ref: any = useRef();
  const [showEdit, setShowEdit] = useState(false);
  const [showEditPayment, setShowEditPayment] = useState(false);
  const { reset, handleSubmit, setValue, watch, control, getValues } = useForm({
    resolver: yupResolver(OrderSettingsFormValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const paymentMethods = watch("value.paymentMethods");
  /**
   *
   */
  useEffect(() => {
    getData(endpoints.setting.orderSettings).then((res: AxiosResponse) => {
      if (res?.data) {
        reset(res.data);
      }
    });
  }, []);

  /**
   *
   * @param model
   * @returns
   */
  async function updateElement(model: any) {
    const newMethod = Object.assign({}, model);
    const url = `${endpoints.setting.orderSettings}${newMethod?._id}`;
    const res = (await updateData(url, newMethod)) as any;
    if (res?.data) {
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      reset(res.data);
    }
  }

  /**
   *
   * @param model
   * @returns
   */
  async function updateElementSettingsNumber(model: any) {
    const newMethod = Object.assign({}, model);
    const url = `${endpoints.setting.orderSettingsNumber}${newMethod?._id}`;
    const res = (await updateData(url, newMethod)) as any;
    if (res?.data) {
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      reset(res.data);
    }
  }

  /**
   *
   */
  function addNewMethod() {
    setShowEdit(true);
    setShowEditPayment(!showEditPayment);
    const newPaymentMethod: any = Object.assign([], paymentMethods);
    newPaymentMethod.push({
      name: "",
      shouldDelete: true,
    });
    setValue("value.paymentMethods", newPaymentMethod);
  }

  /**
   *
   * @param index
   */
  function deleteMethod(index: any) {
    const currentPaymentMethods = paymentMethods || [];
    let newPaymentMethod: any = Object.assign([], currentPaymentMethods);
    newPaymentMethod = currentPaymentMethods
      .slice(0, index)
      .concat(currentPaymentMethods.slice(index + 1));
    setValue("value.paymentMethods", newPaymentMethod);
  }

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    setShowEditPayment(!showEditPayment);
    updateElement(model);
  };

  /**
   *
   * @param model
   */
  const onError = (model: any) => {
    // console.log(model); //DOAR PT ERORI
  };

  /**
   *
   * @param model
   */
  const onSubmitSettingsNumber = (model: any) => {
    setShowEdit(!showEdit);
    updateElementSettingsNumber(model);
  };

  return (
    <div className={styles.settingsForm}>
      <form
        onSubmit={handleSubmit(onSubmitSettingsNumber, onError)}
        method="get"
        ref={ref}
        id={FormsIds.orderSettingsNumber}
      >
        <Grid container spacing={Config.standardGridSpacing}>
          <Grid item xs={12} sm={12} md={5.5}>
            <Controller
              name="value.startOrderNumber"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  id="startOrderNumber"
                  type="number"
                  name="startOrderNumber"
                  label={Vocabulary.startOrderNumber}
                  disabled={!showEdit}
                  fullWidth
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || 0}
                  ref={ref}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5.5}>
            <Controller
              name="value.currentOrderNumber"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  id="currentOrderNumber"
                  type="number"
                  disabled
                  name="currentOrderNumber"
                  label={Vocabulary.currentOrderNumber}
                  fullWidth
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value || 0}
                  ref={ref}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1}>
            <ToggleButtonGroup
              className={styles.transportMethodsToggleButtonGroup}
              exclusive
              aria-label="text alignment"
            >
              {!showEdit ? (
                <>
                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      onClick={() => setShowEdit(!showEdit)}
                      value="center"
                      aria-label="centered"
                    >
                      <EditIcon fontSize="small" />
                    </ToggleButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title={Vocabulary.save}>
                  <ToggleButton
                    type="submit"
                    value="center"
                    id="orderSettingsNumber"
                    aria-label="centered"
                  >
                    <SaveIcon fontSize="small" />
                  </ToggleButton>
                </Tooltip>
              )}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </form>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        method="get"
        ref={ref}
        id={FormsIds.editOrderSettings}
      >
        <Grid container spacing={Config.standardGridSpacing}>
          {paymentMethods?.map((paymentMethod: any, index: any) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={11 / paymentMethods.length}
              >
                <Controller
                  name={`value.paymentMethods.${index}.name`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      style={{ marginTop: 5 }}
                      fullWidth
                      variant="outlined"
                      disabled={!showEditPayment}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value || ""}
                      required
                      ref={ref}
                      InputProps={{
                        endAdornment: (
                          <>
                            {showEditPayment &&
                            getValues(
                              `value.paymentMethods.${index}.shouldDelete`
                            ) ? (
                              <DeleteIcon
                                style={{ cursor: "pointer", color: "#f33" }}
                                onClick={() => deleteMethod(index)}
                              />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={12} md={1}>
            <ToggleButtonGroup
              style={{
                display: "flex",
                marginTop: 10,

                gap: 2,
                justifyContent: "center",
              }}
              exclusive
              aria-label="text alignment"
            >
              {!showEditPayment ? (
                <>
                  <Tooltip title={Vocabulary.add}>
                    <ToggleButton
                      onClick={() => addNewMethod()}
                      value="center"
                      aria-label="centered"
                    >
                      <AddIcon fontSize="small" />
                    </ToggleButton>
                  </Tooltip>

                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      onClick={() => setShowEditPayment(!showEditPayment)}
                      value="center"
                      aria-label="centered"
                    >
                      <EditIcon fontSize="small" />
                    </ToggleButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title={Vocabulary.save}>
                  <ToggleButton
                    value="center"
                    type="submit"
                    id="editOrderSettings"
                    aria-label="centered"
                  >
                    <SaveIcon fontSize="small" />
                  </ToggleButton>
                </Tooltip>
              )}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
