import { Button } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { StandardActionButtonsProps } from "../Utils/Types";

export default function StandardActionButtons(
  props: StandardActionButtonsProps
) {
  const { okAction, cancelAction, okText, cancelText, formId, type } = props;
  return (
    <div className={genericStyles.modalActions}>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={cancelAction}
      >
        {cancelText}
      </Button>
      <Button
        form={formId}
        fullWidth
        color="primary"
        variant="contained"
        type={type ? type : "button"}
        onClick={() => (okAction ? okAction() : null)}
      >
        {okText}
      </Button>
    </div>
  );
}
