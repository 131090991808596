import { createTheme } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
    save?: Palette["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
  }

  interface ThemeOptions {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
    save?: Palette["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
  }

  interface Palette {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
    save?: Palette["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
  }
  interface PaletteOptions {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
    save?: PaletteOptions["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
  }

  interface PaletteColor {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
    save?: PaletteOptions["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
  }

  interface SimplePaletteColorOptions {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
    save?: PaletteOptions["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    header: true;
    dashboard: true;
    cancel: true;
    save: true;
    blueGrey: true;
    purpleGrey: true;
    iconDefault: true;
    defaultBorder: true;
  }
}

declare module "@mui/material/Fab" {
  interface FabPropsColorOverrides {
    header: true;
    dashboard: true;
    cancel: true;
    save: true;
    blueGrey: true;
    purpleGrey: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    header: true;
    dashboard: true;
    cancel: true;
    save: true;
    blueGrey: true;
    purpleGrey: true;
    pinkColor: true;
    iconDefault: true;
  }
}

const dark = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#009846",
    },
    dashboard: {
      main: "#000000",
    },
    defaultBorder: {
      main: "#808080",
      dark: "	#404040",
      light: "#BEBEBE",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgba(59, 56, 57, 1)",
    },
    cancel: {
      main: "#D8D8D8",
    },
    save: {
      main: "#ACE1AF",
    },
    header: {
      main: "#7D8FB3",
      contrastText: "#fff",
    },
    successColor: {
      main: "#12A152",
    },
    errorColor: {
      main: "#f25",
    },
    textColor: {
      main: "black",
    },
    textColorSecondary: {
      main: "white",
    },
    iconDefault: {
      main: "#455a64",
    },
    iconBlue: {
      main: "#2196f3",
    },
    blueColor: {
      main: "#8090B5",
    },
    greenColor: {
      main: "#6DA77C",
    },
    pinkColor: {
      main: "#FFB0CB",
    },
    yellowColor: {
      main: "#F9F871",
    },
    orangeColor: {
      main: "#FFD381",
    },
    blueGrey: {
      light: blueGrey[300],
      main: blueGrey[500],
      dark: blueGrey[700],
      contrastText: "#fff",
    },
    purpleGrey: {
      light: "#c9b2bd",
      main: "#b291a2",
      dark: "#7c6571",
      contrastText: "#fff",
    },
  },
  typography: {
    fontSize: 12,
  },
  direction: "ltr",
});

export default dark;
