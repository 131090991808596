import { Divider, Typography } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { SpecialDividerProps } from "../Utils/Types";

export default function SpecialDivider(props: SpecialDividerProps) {
  const { title, button } = props;

  return (
    <div className={genericStyles.specialDividerContainer}>
      <div className={genericStyles.specialDividerSecondContainer}>
        <Typography className={genericStyles.specialDividerTypography}>
          {title}
        </Typography>
        {button ? button : null}
      </div>
      <Divider className={genericStyles.fullWidthElement} />
    </div>
  );
}
