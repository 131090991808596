/* eslint-disable @typescript-eslint/no-unused-vars */

import { EditClientModal } from "../Clients/EditClientModal";
import { Grid, Paper, TextField } from "@mui/material";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { ClientPanelProps } from "../../Utils/Types";
import styles from "../../Styles/clientPanel.module.css";

export default function ClientPanel(props: ClientPanelProps) {
  const { formState, disabled } = props;

  /**
   *
   * @param name
   * @param value
   */
  const setValue = (name: string, value: any) => {
    props.setValue(name, value, {
      shouldDirty: true,
    });
  };

  return (
    <Paper className={styles.mainContainer}>
      <Grid container spacing={Config.standardGridSpacing}>
        {disabled ? (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="company"
                name="company"
                label={Vocabulary.company}
                value={props.getValues("billing.company") || "-"}
                fullWidth
                variant="standard"
                disabled={disabled}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <EditClientModal
                control={props.control}
                formState={props.formState}
                setValue={props.setValue}
                clearErrors={props.clearErrors}
                getValues={props.getValues}
                onClientChange={(e, client, reason, details) => {
                  if (reason !== "clear") {
                    props.clearErrors("billing"); // will clear both errors from test.firstName and test.lastName
                    setValue(
                      "billing.company",
                      client?.name ? client?.name : ""
                    );

                    setValue(
                      "billing.last_name",
                      client?.contactPerson?.lastName
                        ? client?.contactPerson?.lastName
                        : ""
                    );
                    setValue("client_id", client && client?._id ? client : "");
                    setValue(
                      "billing.first_name",
                      client?.contactPerson?.firstName
                        ? client?.contactPerson?.firstName
                        : ""
                    );
                    setValue(
                      "billing.city",
                      client?.address?.city ? client?.address?.city : ""
                    );
                    setValue(
                      "billing.state",
                      client?.address?.county ? client?.address?.county : ""
                    );
                    setValue(
                      "billing.address_1",
                      client?.address?.details ? client?.address?.details : ""
                    );

                    setValue(
                      "billing.phone",
                      client?.phone[0] ? client?.phone[0] : ""
                    );
                    setValue(
                      "billing.email",
                      client?.email ? client?.email : ""
                    );
                    setValue(
                      "billing.postcode",
                      client?.address?.postcode ? client?.address?.postcode : ""
                    );
                  }
                }}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name={"billing.company"}
                control={props.control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="company"
                    name="company"
                    label={Vocabulary.company}
                    value={props.getValues("billing.company") || "-"}
                    fullWidth
                    onChange={(e: any) => {
                      onChange(e.target.value);
                    }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.last_name"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.last_name"
                name="billing.last_name"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.last_name", e.target.value);
                  }
                }}
                label={Vocabulary.lastName}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.last_name === "object"}
                helperText={formState.errors.billing?.last_name?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.first_name"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.first_name"
                name="billing.first_name"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.first_name", e.target.value);
                  }
                }}
                label={Vocabulary.firstName}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.first_name === "object"}
                helperText={formState.errors.billing?.first_name?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.city"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.city"
                name="billing.city"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.city", e.target.value);
                  }
                }}
                label={Vocabulary.city}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.city === "object"}
                helperText={formState.errors.billing?.city?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.state"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.state"
                name="billing.state"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.state", e.target.value);
                  }
                }}
                label={Vocabulary.county}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.state === "object"}
                helperText={formState.errors.billing?.state?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.country"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.country"
                name="billing.country"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.country", e.target.value);
                  }
                }}
                label={Vocabulary.country}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.country === "object"}
                helperText={formState.errors.billing?.country?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.address_1"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.address_1"
                name="billing.address_1"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.address_1", e.target.value);
                  }
                }}
                label={Vocabulary.addressDetails}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.address_1 === "object"}
                helperText={formState.errors.billing?.address_1?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.address_2"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.address_2"
                name="billing.address_2"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.address_2", e.target.value);
                  }
                }}
                label={Vocabulary.addressDetails}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.address_2 === "object"}
                helperText={formState.errors.billing?.address_2?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.phone"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.phone"
                name="billing.phone"
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                label={Vocabulary.phone}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.phone === "object"}
                helperText={formState.errors.billing?.phone?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.email"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.email"
                name="billing.email"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.email", e.target.value);
                  }
                }}
                label={Vocabulary.email}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.email === "object"}
                helperText={formState.errors.billing?.email?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            name={"billing.postcode"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.postcode"
                name="billing.postcode"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    setValue("shipping.postcode", e.target.value);
                  }
                }}
                label={Vocabulary.postcode}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.postcode === "object"}
                helperText={formState.errors.billing?.postcode?.message?.toString()}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
