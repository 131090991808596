import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getData } from "../../Services/getData";
import { endpoints } from "../../Utils/UrlEnum";
import { ProductModel } from "../../Utils/Models";
import Config from "../../Utils/Config";
import parse from "html-react-parser";
import { ExpandMore } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/products.module.css";
import { renderStockMessage } from "../../Utils/Utils";
import ImageGallery from "./ImageGallery";
import { AxiosResponse } from "axios";

export default function Product() {
  const [product, setProduct] = useState(new ProductModel());
  const [productImages, setProductImages] = useState([] as any);
  const { id } = useParams();

  /**
   *
   */
  useEffect(() => {
    if (id) getProduct(id);
  }, []);

  /**
   *
   * @param id
   */
  const getProduct = (id: string) => {
    getData(`${endpoints.getProducts}/${id}`).then((res: AxiosResponse) => {
      if (!res.data) return;
      setProduct(res.data);
      setProductImages(res.data.images);
    });
  };

  return (
    <div className={styles.productPageMainContainer}>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={Config.standardGridSpacing}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={styles.productPageImageGalleryGrid}
            >
              <ImageGallery images={productImages} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                {product?.short_description
                  ? parse(product?.short_description)
                  : "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={styles.productPageGridContainer}
            >
              <Typography
                className={styles.productPageSpaceAfterText}
                variant="subtitle1"
              >{`${Vocabulary.categories}: `}</Typography>
              {product.categories?.map((category: any, index: number) => {
                return (
                  <Typography variant="subtitle1" key={index}>
                    {category.name}
                    {"; "}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container spacing={Config.standardGridSpacing}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                className={styles.productPageSpaceAfterText}
                variant="h5"
              >
                {product.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {product.tags?.map((tag: any, index: number) => {
                return (
                  <Chip
                    color="primary"
                    className={styles.productPageTag}
                    key={index}
                    label={
                      <Typography variant="caption">{tag.name}</Typography>
                    }
                  />
                );
              })}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={styles.productPageGridContainer}
            >
              <Typography
                className={styles.productPageSpaceAfterText}
                variant="h6"
              >{`${Vocabulary.price}:`}</Typography>
              <Typography variant="h6">
                {product.price_html ? parse(product?.price_html) : "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={styles.productPageGridContainer}
            >
              <Typography
                className={styles.productPageSpaceAfterText}
                variant="h6"
              >{`${Vocabulary.stock}:`}</Typography>
              <Typography variant="h6">
                {renderStockMessage(product.stock_status)}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={styles.productPageGridContainer}
            >
              <Typography
                className={styles.productPageSpaceAfterText}
                variant="subtitle1"
              >{`${Vocabulary.productLink}:`}</Typography>
              <Typography variant="subtitle1">
                <Link to={product.permalink} target="_blank">
                  {product.permalink}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  id="product-description"
                >
                  {Vocabulary.productDescription}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {product?.description ? parse(product?.description) : "-"}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
