import { TipsAndUpdates } from "@mui/icons-material";
import { Typography } from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
import { ObservationWidgetProps } from "../../Utils/Types";
import { Link } from "react-router-dom";
import { Vocabulary } from "../../Utils/Vocabulary";

export default function ObservationWidget(props: ObservationWidgetProps) {
  return (
    <div className={genericStyles.observationWidgetContainer}>
      {props.shouldRenderIcon && (
        <TipsAndUpdates
          className={genericStyles.observationWidgetIcon}
          color="primary"
        />
      )}
      <Typography variant="body2">{`${props?.text || ""}`}</Typography>
      {props.link && (
        <Link to={props.link}>
          <Typography sx={{ marginLeft: "5px" }} variant="body2">
            {Vocabulary.learnMore}{" "}
          </Typography>
        </Link>
      )}
    </div>
  );
}
