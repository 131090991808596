/* eslint-disable @typescript-eslint/no-unused-vars */

import { Grid, TextField } from "@mui/material";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { ClientPanelOrderOfferProps } from "../../Utils/Types";
import styles from "../../Styles/clientPanel.module.css";

export default function ClientPanelOrderOffer(
  props: ClientPanelOrderOfferProps
) {
  const { formState } = props;
  const shouldBeDisabled = props.getValues("wordPress_Id") ? true : false;

  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name={"billing.first_name"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.textFieldForOffer}
                id="billing.first_name"
                name="billing.first_name"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.first_name", e.target.value);
                  }
                }}
                label={Vocabulary.firstName}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.first_name === "object"}
                helperText={formState.errors.billing?.first_name?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name={"billing.last_name"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.last_name"
                name="billing.last_name"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.last_name", e.target.value);
                  }
                }}
                label={Vocabulary.lastName}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.last_name === "object"}
                helperText={formState.errors.billing?.last_name?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.company"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.company"
                name="billing.company"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.company", e.target.value);
                  }
                }}
                label={Vocabulary.company}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.company === "object"}
                helperText={formState.errors.billing?.company?.message?.toString()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.city"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.city"
                name="billing.city"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.city", e.target.value);
                  }
                }}
                label={Vocabulary.city}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.city === "object"}
                helperText={formState.errors.billing?.city?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.state"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.state"
                name="billing.state"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.state", e.target.value);
                  }
                }}
                label={Vocabulary.county}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.state === "object"}
                helperText={formState.errors.billing?.state?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.country"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.country"
                name="billing.country"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.country", e.target.value);
                  }
                }}
                label={Vocabulary.country}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.country === "object"}
                helperText={formState.errors.billing?.country?.message?.toString()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.address_1"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.address_1"
                name="billing.address_1"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.address_1", e.target.value);
                  }
                }}
                label={Vocabulary.addressDetails}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.address_1 === "object"}
                helperText={formState.errors.billing?.address_1?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.address_2"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.address_2"
                name="billing.address_2"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.address_2", e.target.value);
                  }
                }}
                label={Vocabulary.addressDetails}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.address_2 === "object"}
                helperText={formState.errors.billing?.address_2?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.phone"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.phone"
                name="billing.phone"
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                type="number"
                label={Vocabulary.phone}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.phone === "object"}
                helperText={formState.errors.billing?.phone?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.email"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.email"
                name="billing.email"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.email", e.target.value);
                  }
                }}
                label={Vocabulary.email}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.email === "object"}
                helperText={formState.errors.billing?.email?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name={"billing.postcode"}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="billing.postcode"
                name="billing.postcode"
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (!props.getValues("differentShippingInfo")) {
                    props.setValue("shipping.postcode", e.target.value);
                  }
                }}
                label={Vocabulary.postcode}
                fullWidth
                value={value || ""}
                variant={"standard"}
                error={typeof formState.errors.billing?.postcode === "object"}
                helperText={formState.errors.billing?.postcode?.message?.toString()}
                disabled={shouldBeDisabled}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
