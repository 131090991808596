import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TextField } from "@mui/material";
import { FormsIds } from "../../Utils/Constants";
import { VerifyCodeConfirmationSchema } from "../../Utils/Models";

export default function VerifyCodeConfirmationStep() {
  const methods = useForm({
    resolver: yupResolver(VerifyCodeConfirmationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  return (
    <form id={FormsIds.verifyCodeForm}>
      <TextField
        {...methods.register("code")}
        id="code"
        name="code"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        label={Vocabulary.code}
        error={typeof methods.formState.errors.code === "object"}
        helperText={methods.formState.errors.code?.message?.toString()}
      />
    </form>
  );
}
