/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import genericStyles from "../../Styles/genericStyles.module.css";
import MUIDataTableCustomStyle from "../../Components/MUIDataTableCustomStyle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState, createContext } from "react";
import { endpoints } from "../../Utils/UrlEnum";
import { AutocompleteChangeReason, Button, Chip } from "@mui/material";
import { Role } from "../../Utils/Types";
import UserForm from "./UserForm";
import { UserModel } from "../../Utils/Models";
import { ETableEditAction, EWidths } from "../../Utils/Enums";
import GenericModal from "../GenericModal";
import { FormsIds, TablesIds } from "../../Utils/Constants";

export const CRUDContext = createContext(new UserModel());

export default function UsersTable() {
  const [contextValue, setContextValue] = useState(new UserModel());
  const [open, setOpen] = useState(false);
  const [triggerReload, setTriggerReload] = useState<undefined | boolean>();

  /**
   *
   */
  const usersColumns = [
    {
      label: `${Vocabulary.lastName}`,
      name: "lastName",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.firstName}`,
      name: "firstName",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.roles}`,
      name: "roleIds",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (rowData: Role[]) => {
          return (
            <>
              {rowData.map((role: Role) => (
                <Chip
                  color="primary"
                  key={role._id}
                  label={role.name}
                  style={{
                    margin: 5,
                  }}
                />
              ))}
            </>
          );
        },
      },
    },
  ];

  return (
    <div className={genericStyles.mainContainer}>
      <CRUDContext.Provider value={contextValue}>
        <MUIDataTableCustomStyle
          onEditAction={ETableEditAction.Modal}
          buttons={[]}
          title={Vocabulary.users}
          columns={usersColumns}
          CRUDEndPoint={endpoints.usersEndpoint}
          id={TablesIds.users}
          handleModalForm={setOpen}
          addOrEditForm={
            <GenericModal
              onClose={() => {
                setOpen(false);
                setContextValue(new UserModel());
              }}
              maxWidth={EWidths.MD}
              open={open}
              actions={
                <div className={genericStyles.modalActions}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setOpen(false);
                      setContextValue(new UserModel());
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {Vocabulary.cancel}
                  </Button>
                  <Button
                    fullWidth
                    type="submit"
                    form={FormsIds.usersForm}
                    variant="contained"
                  >
                    {Vocabulary.save}
                  </Button>
                </div>
              }
              title={Vocabulary.editModalTitle}
            >
              <UserForm
                setContextValue={(
                  e: any,
                  client: UserModel | null,
                  reason: AutocompleteChangeReason,
                  details?: any
                ) => ({})}
                onClose={() => {
                  setOpen(false);
                  setTriggerReload(
                    triggerReload !== undefined ? !triggerReload : false
                  );
                  setContextValue(new UserModel());
                }}
              />
            </GenericModal>
          }
          setContextValue={setContextValue}
          triggerReload={triggerReload}
        />
      </CRUDContext.Provider>
    </div>
  );
}
