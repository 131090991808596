/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Tab,
  Tabs,
  Badge,
  Chip,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  createTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { DynamicTabsProps } from "../Utils/Types";
import { useContext } from "react";
import { CustomThemeContext } from "../Context/CustomThemeProvider";
import { styled } from "@mui/material/styles";
import { Vocabulary } from "../Utils/Vocabulary";
import { isMobile, isTablet } from "../Utils/Utils";
import genericStyles from "../Styles/genericStyles.module.css";
import { ThemeProvider } from "@emotion/react";

export default function DynamicTabs(props: DynamicTabsProps) {
  const { labels, tabValue, badge } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const themeContext: any = useContext(CustomThemeContext);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      top: -14,
      left: 9,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "#fff",
      backgroundColor: "#000",
      overflow: "visible !important",
    },
  }));

  /**
   * Set selected tab value and put it in the url, so it can be remembered
   * @param tabIndex
   */
  const handleChangeTab = (tabIndex: number) => {
    if (props.handleChange) props.handleChange(tabIndex);
    //iterate over all params and delete them
    const params = searchParams.toString().split("&");
    params.forEach((param) => {
      const [key, value] = param.split("=");
      searchParams.delete(key);
    });
    searchParams.set("tab", tabIndex.toString());
    setSearchParams(searchParams);
  };

  /**
   *
   * @returns
   */
  const renderChip = () => {
    if (badge !== undefined && badge !== null)
      return (
        <Chip
          className={genericStyles.dynamicTabsChip}
          label={`${Vocabulary.noTodaysClients}: ${badge}`}
        />
      );
    return null;
  };

  /**
   *
   * @param item
   * @returns
   */
  const getCustomMarginForNavigation: any = (item: number) => {
    switch (item) {
      case 5:
        return 40;

      case 6:
        return 80;

      default:
        return "auto";
    }
  };

  /**
   *
   * @param item
   * @returns
   */
  const getCustomWidthForNavigation: any = (item: number) => {
    switch (item) {
      case 5:
        return "105%";

      case 6:
        return "105%";

      default:
        return "100%";
    }
  };

  const renderMobileOrTablet = () => {
    return (
      <ThemeProvider theme={dynamicTheme}>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: -20,
            right: -20,
            zIndex: 200,

            overflowX: "scroll",
          }}
          elevation={5}
        >
          <BottomNavigation
            value={tabValue}
            style={{
              width: getCustomWidthForNavigation(props.labels.length),
              marginLeft: getCustomMarginForNavigation(props.labels.length),
              marginRight: getCustomMarginForNavigation(props.labels.length),
            }}
            onChange={(event, newValue) => {
              handleChangeTab(newValue);
            }}
          >
            {labels?.map((tab: any, index: any) => (
              <BottomNavigationAction
                // icon={
                //   <StyledBadge
                //     max={999}
                //     showZero={false}
                //     anchorOrigin={{
                //       vertical: "top",
                //       horizontal: "left",
                //     }}
                //     badgeContent={tab?.badge ? tab?.badge : "0"}
                //     color="primary"
                //   ></StyledBadge>
                // }
                key={index}
                label={tab?.label ? `${tab?.label}` : tab}
              />
            ))}
          </BottomNavigation>
        </Paper>
      </ThemeProvider>
    );
  };

  /**
   *
   * @returns
   */
  const renderNormal = () => {
    return (
      <Tabs
        value={tabValue}
        textColor="inherit"
        className="tabs-container"
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            background: themeContext.theme?.palette.primary.main,
            color: "white",
            overflow: "visible",
          },
        }}
      >
        {labels?.map((tabLabel: any, index: any) => {
          return (
            <Tab
              key={index}
              style={{
                marginRight: 1,
                marginLeft: 1,
                display: tabLabel === "null" ? "none" : "auto",
                backgroundColor:
                  tabValue === index
                    ? themeContext.theme?.palette.primary.main
                    : tabLabel.tabColor
                    ? tabLabel.tabColor
                    : "#4444",
                color: tabValue === index ? "#fff" : "inherit",
                overflow: "visible",
                borderRadius: "10px 10px 0px 0px",
                fontWeight: tabValue === index ? 600 : "inherit",
              }}
              label={
                <StyledBadge
                  max={999}
                  showZero={false}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  badgeContent={tabLabel?.badge ? tabLabel?.badge : "0"}
                  color="primary"
                >
                  {tabLabel?.label ? tabLabel?.label : tabLabel}
                </StyledBadge>
              }
              onClick={() => handleChangeTab(index)}
            />
          );
        })}
        {renderChip()}
      </Tabs>
    );
  };

  return isMobile() || isTablet() ? renderMobileOrTablet() : renderNormal();
}

export const dynamicTheme = () =>
  createTheme({
    components: {
      MuiBottomNavigationAction: {
        styleOverrides: {
          label: {
            fontSize: "12px !important",
            opacity: "1 !important",
            fontWeight: "bold",
          },
          root: {
            maxWidth: "100% !important",
            backgroundColor: "#f7f8fa !important",
            "&.Mui-selected": {
              backgroundColor: "#88aa91 !important",
              color: "#fff",
            },
          },
        },
      },
    },
  });
