import {
  // Checkbox,
  // FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CustomDrawer from "../CustomDrawer";
import { EditNoteProps } from "../../Utils/Types";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { NoteModel, NoteValidationSchema } from "../../Utils/Models";
import genericStyles from "../../Styles/genericStyles.module.css";
import parse from "html-react-parser";
import { ENoteType } from "../../Utils/Enums";
import styles from "../../Styles/note.module.css";
import SpecialDivider from "../SpecialDivider";
import Config from "../../Utils/Config";
import { FormsIds } from "../../Utils/Constants";

export default function Note(props: EditNoteProps) {
  const { open, handleClose, setContextValue, setShouldSubmit, note } = props;
  const { handleSubmit, control, formState, reset, getValues, watch } = useForm(
    {
      resolver: yupResolver(NoteValidationSchema),
      mode: "onChange",
      reValidateMode: "onChange",
      context: undefined,
      shouldFocusError: true,
      shouldUnregister: false,
      criteriaMode: "firstError",
    }
  );

  /**
   *
   */
  useEffect(() => {
    if (note && note?._id) {
      reset(note);
    }
  }, [note]);

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    setContextValue(model);
    reset(new NoteModel());
    setShouldSubmit(true);
  };

  /**
   *
   * @returns
   */
  const renderDetails = () => {
    if ((getValues("details") || "").length <= 0) return null;
    switch (getValues("type")) {
      case ENoteType.EMAIL:
        return (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SpecialDivider title={Vocabulary.emailPreview} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={styles.emailPreviewGrid}
            >
              <div className={styles.emailPreviewBox}>
                {parse(watch("details") || "")}
              </div>
            </Grid>
          </>
        );
      default:
        return (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography>{watch("details")}</Typography>
          </Grid>
        );
    }
  };

  return (
    <CustomDrawer
      className={genericStyles.customDrawer}
      open={open}
      handleDrawerClose={() => {
        handleClose();
      }}
      title={Vocabulary.note}
      formId={FormsIds.noteForm}
    >
      <form
        id={FormsIds.noteForm}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Grid container spacing={Config.standardGridSpacing}>
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControlLabel
              control={
                <Controller
                  name={"visibility"}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={Vocabulary.visibleToClient}
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name={"text"}
              control={control}
              render={({ field: props }) => (
                <TextField
                  {...props}
                  variant="outlined"
                  name="email"
                  id="email"
                  multiline
                  rows={4}
                  label={Vocabulary.text}
                  fullWidth
                  error={typeof formState.errors.text === "object"}
                  helperText={formState.errors.text?.message?.toString()}
                />
              )}
            />
          </Grid>
          {renderDetails()}
        </Grid>
      </form>
    </CustomDrawer>
  );
}
