import MUIDataTableCustomStyle from "../MUIDataTableCustomStyle";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  LogFiltersMethods,
  LogFiltersStatuses,
  TablesIds,
} from "../../Utils/Constants";
import { EComponentTypes, ETableEditAction, EWidths } from "../../Utils/Enums";
import { Chip, ToggleButton, Tooltip, Typography } from "@mui/material";
import { formatStringDate } from "../../Utils/Utils";
import Config from "../../Utils/Config";
import { endpoints } from "../../Utils/UrlEnum";
import { Visibility } from "@mui/icons-material";
import GenericModal from "../GenericModal";
import { useState } from "react";
import Log from "./Log";
import styles from "../../Styles/logs.module.css";
import Filters from "../Widgets/Filters";
import { Filter } from "../../Utils/Types";
import * as yup from "yup";

export default function LogsTable() {
  const [open, setOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState<any>({});

  const filters: Filter[] = [
    {
      property: "start",
      label: Vocabulary.startDate,
      type: EComponentTypes.DATE_PICKER,
      validation: yup.string().required(Vocabulary.requiredField),
      defaultValue: Config.defaultStartDate,
    },
    {
      property: "end",
      label: Vocabulary.endDate,
      type: EComponentTypes.DATE_PICKER,
      validation: yup.string().required(Vocabulary.requiredField),
      defaultValue: Config.defaultEndDate,
    },
    {
      property: "method",
      label: Vocabulary.method,
      type: EComponentTypes.AUTOCOMPLETE,
      validation: yup.string().nullable(),
      options: LogFiltersMethods,
      defaultValue: "",
      autocompleteFilterProperty: "value",
    },
    {
      property: "status",
      label: Vocabulary.status,
      type: EComponentTypes.AUTOCOMPLETE,
      validation: yup.string().nullable(),
      options: LogFiltersStatuses,
      defaultValue: "",
      autocompleteFilterProperty: "value",
    },
  ];
  const logsColumns = [
    {
      label: Vocabulary.userName,
      name: "userName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.method,
      name: "method",
      options: {
        sort: false,
        customBodyRender: (method: any) => (
          <Typography>{Vocabulary[method]}</Typography>
        ),
      },
    },
    {
      label: Vocabulary.table,
      name: "table",
      options: {
        sort: false,
        customBodyRender: (table: string) => (
          <Typography>{Vocabulary[table]}</Typography>
        ),
      },
    },
    {
      label: Vocabulary.database,
      name: "database",
      options: {
        sort: false,
        customBodyRender: (table: string) => (
          <Typography>{Vocabulary[table]}</Typography>
        ),
      },
    },
    {
      label: Vocabulary.entityName,
      name: "name",
      options: {
        sort: false,
        customBodyRender: (name: any) => <Typography>{name || "-"}</Typography>,
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (status: any) => (
          <Chip
            className={styles[status]}
            label={<Typography>{Vocabulary[status]}</Typography>}
          />
        ),
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (date: any) => (
          <Typography>
            {formatStringDate(date, Config.momentEUDateTimeFormat)}
          </Typography>
        ),
      },
    },
  ];

  /**
   *
   * @param rowIndex
   * @param value
   * @returns
   */
  const renderCustomColumns = (rowIndex: number, value: any) => {
    return !value.wordPress_Id || value.wordPress_Id !== "" ? (
      <Tooltip title={Vocabulary.preview}>
        <ToggleButton
          onClick={(e: any) => {
            e.stopPropagation();
            toggleModal();
            setSelectedLog(value);
          }}
          value="center"
          aria-label="centered"
        >
          <Visibility />
        </ToggleButton>
      </Tooltip>
    ) : null;
  };

  /**
   *
   */
  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <div className={genericStyles.mainContainer}>
      <Filters filters={filters} />
      <MUIDataTableCustomStyle
        buttons={[]}
        onEditAction={ETableEditAction.Modal}
        title={Vocabulary.logs}
        id={TablesIds.logs}
        hideAddButton={true}
        columns={logsColumns}
        hideDeleteButton={true}
        hideEditButton={true}
        renderCustomColumns={renderCustomColumns}
        CRUDEndPoint={endpoints.audit.main}
      />
      <GenericModal
        title={Vocabulary.actionSummary}
        onClose={toggleModal}
        open={open}
        maxWidth={EWidths.MD}
      >
        <Log log={selectedLog} />
      </GenericModal>
    </div>
  );
}
