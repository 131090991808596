import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ClientModel } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { endpoints } from "../../Utils/UrlEnum";
import { Add, Edit } from "@mui/icons-material";
import ClientForm from "./ClientForm";
import GenericModal from "../GenericModal";
import Config from "../../Utils/Config";
import { EWidths } from "../../Utils/Enums";
import { Controller } from "react-hook-form";
import { EditClientModalProps } from "../../Utils/Types";
import { AxiosResponse } from "axios";
import { FormsIds } from "../../Utils/Constants";

export const EditClientModal = (props: EditClientModalProps) => {
  const ref: any = useRef();
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [clients, setClients] = useState([] as ClientModel[]);
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function getSearchedClients(search: string) {
    getData(`${endpoints.clientSearchEndpoint}${search}`).then(
      (res: AxiosResponse) => {
        if (res?.data?.length > 0) setClients(res?.data);
      }
    );
  }
  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   *
   */
  useEffect(() => {
    if (clients.length === 0) getSearchedClients("");
  }, []);

  /**
   *
   * @param e
   * @param client
   * @param reason
   * @param details
   */
  const onClientSuccess = (
    e: any,
    client: ClientModel,
    reason: AutocompleteChangeReason,
    details?: any
  ) => {
    props.onClientChange(e, client, reason, details);
  };

  return (
    <Fragment>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={genericStyles.editClientModalEditButton}
        >
          <Controller
            name="client_id"
            control={props.control}
            defaultValue={null}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                size="small"
                id="client_id"
                fullWidth={true}
                freeSolo={false}
                disablePortal
                isOptionEqualToValue={(option: any, value: any) => {
                  return option?._id === value?._id || option?.id === value;
                }}
                getOptionLabel={(option: any) => option?.name || ""}
                options={clients}
                value={props.getValues("client_id") || null}
                onChange={(
                  e: any,
                  client: ClientModel,
                  reason: AutocompleteChangeReason,
                  details?: any
                ) => {
                  props.clearErrors("client_id"); // will clear both errors from test.firstName and test.lastName
                  props.onClientChange(e, client, reason, details);
                  field.onChange(client);
                }}
                onInputChange={(e, value, reason) => {
                  if (value) {
                    getSearchedClients(value);
                  } else {
                    getSearchedClients("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth={true}
                    name="client"
                    label={Vocabulary.client}
                    variant="standard"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />

          <IconButton
            aria-label="edit"
            disabled={!props.getValues("client_id") || false}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Edit fontSize="small" />
          </IconButton>

          <IconButton
            aria-label="add"
            onClick={() => {
              setOpenNew(true);
            }}
          >
            <Add fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>

      <GenericModal
        onClose={() => {
          setOpen(false);
          setOpenNew(false);
        }}
        maxWidth={EWidths.MD}
        open={open || openNew}
        actions={
          <div className={genericStyles.modalActions}>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => {
                setOpen(false);
                setOpenNew(false);
              }}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              fullWidth
              type="submit"
              form={FormsIds.clients}
              variant="contained"
            >
              {Vocabulary.save}
            </Button>
          </div>
        }
        title={Vocabulary.editModalTitle}
      >
        <ClientForm
          onSuccess={onClientSuccess}
          selectedClient={
            openNew ? new ClientModel() : props.getValues("client_id")
          }
          onClose={() => {
            setOpen(false);
            setOpenNew(false);
          }}
        />
      </GenericModal>
    </Fragment>
  );
};
