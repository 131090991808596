import { Grid, InputAdornment, TextField } from "@mui/material";
import { TemplateBodyComponentProps } from "../../Utils/Types";
import { Vocabulary } from "../../Utils/Vocabulary";
import WhatsAppTagManagementComponent from "./WhatsAppTagManagementComponent";
import Config from "../../Utils/Config";

export default function TemplateBodyComponent(
  props: TemplateBodyComponentProps
) {
  const { methods } = props;

  return (
    <Grid container spacing={Config.standardGridSpacing}>
      <WhatsAppTagManagementComponent
        methods={methods}
        maxlengthMainString={1024}
        mainProperty="components.1.text"
        examplePropertyName={`components.1.example.body_text.0.`}
        children={
          <TextField
            {...methods.register("components.1.text")}
            name="components.1.text"
            id="components.1.text"
            multiline
            rows={4}
            label={Vocabulary.enterWhatsAppMessageBodyText}
            fullWidth
            inputProps={{
              maxLength: "1024",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{`${
                  methods.getValues("components.1.text")?.length || 0
                }/1024`}</InputAdornment>
              ),
            }}
            variant="outlined"
            error={typeof methods.formState.errors.components?.[1] === "object"}
            helperText={
              methods.formState.errors.components?.[1]?.text?.message?.toString() ||
              ""
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
      />
    </Grid>
  );
}
