/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { EBillingType, EInvoiceDocStatus } from "../../Utils/Enums";
import { Close, Delete, Edit, Visibility } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ListItemBillingDocProps } from "../../Utils/Types";
import { formatStringDate, openInNewTab } from "../../Utils/Utils";
import Config from "../../Utils/Config";
import styles from "../../Styles/order.module.css";
import { Fragment, useState } from "react";
import GenericModal from "../GenericModal";
import StandardActionButtons from "../StandarsActionButtons";

export default function ListItemBillingDoc(props: ListItemBillingDocProps) {
  const {
    doc,
    handleDeleteBillingDoc,
    handleCancelBillingDoc,
    handleIssueInvoiceOnNotice,
    handleIssueCollectInvoice,
  } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  return (
    <Fragment>
      <Grid spacing={0} container className={styles.listItemBillingDoc}>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          className={styles.billingDocStatusContainer}
        >
          <Typography
            sx={{ fontWeight: "bold" }}
          >{`${doc?.seriesName} ${doc?.number}`}</Typography>
          {doc?.type === EBillingType.invoice && (
            <Chip
              className={` ${styles[doc?.status]}`}
              label={
                <Typography sx={{ fontWeight: "bold" }}>
                  {Vocabulary[doc?.status]?.toUpperCase()}
                </Typography>
              }
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Typography>{`${doc?.type}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Typography>
            {formatStringDate(doc?.issueDate, Config.momentEUDateFormat) || ""}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          className={styles.extraOptionContainerGrid}
        >
          {doc?.type === EBillingType.notice ? (
            doc?.hasBeenBilled ? (
              <Chip
                className={styles.invoicedChip}
                label={Vocabulary.invoiced.toUpperCase()}
              />
            ) : (
              <Button
                disabled={doc?.hasBeenBilled || false}
                onClick={() => handleIssueInvoiceOnNotice(doc)}
                className={styles.invoiceButton}
                variant="contained"
              >
                {Vocabulary.billingAction}
              </Button>
            )
          ) : (
            doc.referenceDocument && (
              <Typography>{`${Vocabulary.notice} ${doc.referenceDocument.seriesName} ${doc.referenceDocument.number}`}</Typography>
            )
          )}
          {/* {doc?.type === EBillingType.invoice &&
            doc?.status === EInvoiceDocStatus.notCollected && (
              <Button
                disabled={doc?.hasBeenBilled || false}
                onClick={() => handleIssueCollectInvoice(doc)}
                className={styles.collectButton}
                variant="contained"
              >
                {Vocabulary.collect}
              </Button>
            )} */}
        </Grid>
        <Grid item xs={3} sm={3} md={0.5}>
          <Tooltip title={Vocabulary.view}>
            <IconButton
              onClick={() => {
                openInNewTab(doc?.link || "");
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3} sm={3} md={0.5}>
          <Tooltip title={Vocabulary.edit}>
            <IconButton
              onClick={() => {
                openInNewTab(doc?.edit || "");
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3} sm={3} md={0.5}>
          <Tooltip title={Vocabulary.cancelAction}>
            <IconButton
              onClick={() =>
                handleCancelBillingDoc(doc.seriesName, doc.number, doc.type)
              }
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sm={3} md={0.5}>
          <Tooltip title={Vocabulary.delete}>
            <IconButton
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <GenericModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        title={Vocabulary.confirmDeleteTitle}
        actions={
          <StandardActionButtons
            okAction={() =>
              handleDeleteBillingDoc(doc.seriesName, doc.number, doc.type)
            }
            cancelAction={() => setOpenDeleteModal(false)}
            okText={Vocabulary.yes}
            cancelText={Vocabulary.no}
          />
        }
      >
        <div>{Vocabulary.confirmDeleteMessage}</div>
      </GenericModal>
    </Fragment>
  );
}
