/* eslint-disable no-unused-vars */
export enum ETableEditAction {
  Modal = "modal",
  Redirect = "redirect",
}

export enum ENoteType {
  SYSTEM = "SYSTEM", //CRUD actions
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  SMS = "SMS",
  USER = "USER",
  OTHERS = "OTHERS",
}

export enum EWidths {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}

export enum EContactStatus {
  FINALIZAT = "Finalizat",
  IN_PROCESARE = "În procesare",
}

export enum EWordpressStockStatus {
  IN_STOCK = "instock",
  OUT_OF_STOCK = "outofstock",
  ON_BACKORDER = "onbackorder",
}

export enum EBillingType {
  invoice = "Factura",
  notice = "Aviz",
}

export enum ETemplateStatus {
  APPROVED = "Aprobat",
  PENDING = "În aprobare",
  REJECTED = "Respins",
}

export enum EWhatsAppTemplateCategoryTranslation {
  AUTHENTICATION = "Autentificare",
  MARKETING = "Marketing",
  UTILITY = "Utilitate",
}

export enum EWhatsAppTemplateCategory {
  AUTHENTICATION = "AUTHENTICATION",
  MARKETING = "MARKETING",
  UTILITY = "UTILITY",
}

export enum EHeaderTemplateMenuOptions {
  NONE = "NONE",
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  LOCATION = "LOCATION",
  DOCUMENT = "DOCUMENT",
}

export enum EWhatsAppTemplateButtonType {
  URL = "URL",
  PHONE_NUMBER = "PHONE_NUMBER",
  QUICK_REPLY = "QUICK_REPLY",
}

export enum EWhatsAppComponentsTypes {
  HEADER = "HEADER",
  BODY = "BODY",
  FOOTER = "FOOTER",
  BUTTONS = "BUTTONS",
}

export enum EWhatsAppMessagesStatus {
  SENT = "sent",
  DELIVERED = "delivered",
  READ = "read",
  DELETED = "deleted",
  PENDING = "pending",
  FAILED = "failed",
}

export enum EWhatsAppMessageType {
  TEXT = "text",
  TEMPLATE = "template",
  IMAGE = "image",
  BUTTON = "button",
  DOCUMENT = "document",
  STICKER = "sticker",
  VIDEO = "video",
}

export enum EAuditAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  SOFT_DELETE = "SOFT_DELETE",
  IMPORT = "IMPORT",
  DUPLICATE = "DUPLICATE",
}

export enum EAuditStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum EAuditDatabase {
  LOCAL = "LOCAL",
  WORDPRESS = "WORDPRESS",
  OBLIO = "OBLIO",
  META = "META",
}

export enum EComponentTypes {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE_PICKER = "DATE_PICKER",
  TIME = "TIME_PICKER",
  SELECT = "SELECT",
  CHECKBOX = "CHECKBOX",
  AUTOCOMPLETE = "AUTOCOMPLETE",
  RADIO = "RADIO",
}

export enum EFileExtensions {
  DOC = "doc",
  DOCX = "docx",
  XLS = "xls",
  XLSX = "xlsx",
  ODS = "ods",
  CSV = "csv",
  PDF = "pdf",
}

export enum EMimeTypes {
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  PDF = "application/pdf",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  TXT = "text/plain",
  PNG = "image/png",
  JPEG = "image/jpeg",
  JPG = "image/jpeg",
  ODS = "application/vnd.oasis.opendocument.spreadsheet",
}

export enum EChatModalAction {
  NoAction = 1,
  AssociateExistingClient = 2,
  CreateNewClient = 3,
}

export enum EInvoiceDocStatus {
  collected = "collected",
  canceled = "canceled",
  notCollected = "notCollected",
  corrected = "corrected",
  storno = "storno",
}

export enum ECollectDocType {
  Receipt = "Chitanta",
  TaxReceipt = "Bon Fiscal",
  OtherCashCollection = "Alta incasare numerar",
  PaymentOrder = "Ordin de Plata",
  PostalOrder = "Mandat Postal",
  Card = "Card",
  CEC = "CEC",
  OrderTicket = "Bilet ordin",
  OtherBankCollection = "Alta incasare banca",
}
