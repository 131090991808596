import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { WhatsAppMenuItems } from "../../Utils/Constants";
import genericStyles from "../../Styles/genericStyles.module.css";
import styles from "../../Styles/whatsApp.module.css";
import { checkSecondaryPathsMatch } from "../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { WhatsAppMenuProps } from "../../Utils/Types";

export default function WhatsAppMenu(props: WhatsAppMenuProps) {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   *
   * @param path
   */
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div className={styles.whatsAppMenuContainer}>
      <Typography textAlign={"center"} variant="h5">
        {props.whatsAppBusinessAccountData?.name}
      </Typography>
      <Typography textAlign={"center"}>
        {props.whatsAppBusinessAccountData?.whatsAppBusinessAccountId}
      </Typography>
      <List>
        {WhatsAppMenuItems.map((item, index) => {
          return (
            <ListItem key={index} onClick={() => handleNavigate(item.path)}>
              <ListItemIcon>
                <IconButton
                  className={
                    checkSecondaryPathsMatch(item.path, location.pathname)
                      ? genericStyles.selected
                      : genericStyles.nonSelected
                  }
                >
                  {item.icon}
                </IconButton>
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    checkSecondaryPathsMatch(item.path, location.pathname)
                      ? genericStyles.selectedText
                      : genericStyles.nonSelectedText
                  }
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
