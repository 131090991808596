import { FilePreviewProps } from "../../Utils/Types";
import styles from "../../Styles/chat.module.css";
import { Fab, IconButton, TextField, Typography } from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import wordIcon from "../../Images/Icons/Word-icon.png";
import excelIcon from "../../Images/Icons/Excel-icon.png";
import pdfIcon from "../../Images/Icons/PDF-icon.png";
import genericIcon from "../../Images/Icons/Generic-icon.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { EMimeTypes } from "../../Utils/Enums";
import { MediaMessageValidationSchema } from "../../Utils/Models";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { endpoints } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";

export default function FilePreview(props: FilePreviewProps) {
  const { file, setFile, phone, conversationId, className } = props;
  const mediaMessageValidationSchema = MediaMessageValidationSchema;
  const { register, setValue, handleSubmit } = useForm({
    resolver: yupResolver(mediaMessageValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "firstError",
  });

  /**
   * Set the file value to the form
   */
  useEffect(() => {
    if (file) {
      setValue("file", file.data);
    }
  }, [props]);

  /**
   *
   * @param size
   * @returns
   */
  const calculateFileSize = (size: number) => {
    return Math.round(size / 1024);
  };

  /**
   *
   * @param src
   * @param fileName
   * @returns
   */
  const renderDoc = (src: string, size: any) => {
    return (
      <div className={styles.filePreviewContent}>
        <img height={"30%"} src={src} alt="file" />
        <label>{size ? `${calculateFileSize(size)}KB` : ""}</label>
        <label>{Vocabulary.noPreviewAvailable}</label>
      </div>
    );
  };

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    const formData = new FormData() as any;
    formData.append("caption", model.caption);
    formData.append("phone", phone);
    formData.append("conversationId", conversationId);
    formData.append("type", file.messageType);
    formData.append("file", model.file, model.file.name);
    postData(endpoints.whatsApp.sendFileMessage, formData).then(
      (response: any) => {
        if (response) {
          setFile(null);
        }
      }
    );
  };

  /**
   *
   * @param model
   */
  const onError = (model: any) => {
    // console.log(model);
  };

  const renderFilePreview = () => {
    switch (file?.data?.type) {
      case EMimeTypes.DOC:
      case EMimeTypes.DOCX:
        return renderDoc(wordIcon, file?.data?.size);
      case EMimeTypes.XLS:
      case EMimeTypes.XLSX:
      case EMimeTypes.ODS:
        return renderDoc(excelIcon, file?.data?.size);
      case EMimeTypes.PDF:
        return renderDoc(pdfIcon, file?.data?.size);
      case EMimeTypes.JPEG:
      case EMimeTypes.JPG:
      case EMimeTypes.PNG:
        return (
          <div className={styles.filePreviewImage}>
            <img
              src={URL.createObjectURL(file?.data)}
              alt={file?.data?.name}
              height={"85%"}
            />
          </div>
        );
      default:
        return renderDoc(genericIcon, file?.data?.size);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={`${className} ${styles.filePreviewContainer}`}>
        <div className={styles.filePreviewHeader}>
          <IconButton
            onClick={() => {
              setFile(null);
            }}
          >
            <Close />
          </IconButton>
          <Typography>{file?.data?.name}</Typography>
        </div>
        {renderFilePreview()}
      </div>
      <div className={styles.messageImagePreviewSendButtonContainer}>
        <TextField
          {...register("caption")}
          name="caption"
          id="caption"
          label={Vocabulary.addCaption}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Fab
          sx={{ marginLeft: 1 }}
          size="medium"
          color="primary"
          aria-label="add"
        >
          <IconButton type="submit">
            <Send />
          </IconButton>
        </Fab>
      </div>
    </form>
  );
}
