import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import { DisplayOneNoteProps } from "../../Utils/Types";
import moment from "moment";
import Config from "../../Utils/Config";
import { IconsForNotes } from "../../Utils/Constants";
import { ENoteType } from "../../Utils/Enums";
// import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete, Edit } from "@mui/icons-material";
import styles from "../../Styles/note.module.css";
import { extractStringFromHtml } from "../../Utils/Utils";

export default function DisplayOneNote(props: DisplayOneNoteProps) {
  const { note, handleEdit, handleDelete, disabled } = props;

  return (
    <div key={`${note.id}_${note.note}`}>
      <Step active={true}>
        <StepLabel
          StepIconComponent={() => (
            <div className={styles.displayOneNoteStepIconComponent}>
              <Chip
                className={styles.displayOneNoteChip}
                label={moment(note.createdAt).format(
                  Config.momentEUDateTimeFormat
                )}
              />
              <IconButton
                size="small"
                style={{
                  marginLeft: 10,
                  color: "#fff",
                  backgroundColor:
                    IconsForNotes[note.type || ENoteType.OTHERS]
                      ?.backgroundColor,
                }}
                aria-label="add"
              >
                {IconsForNotes[note.type || ENoteType.OTHERS]?.icon}
              </IconButton>
            </div>
          )}
        />
        <StepContent>
          <Box className={styles.displayOneNoteBox}>
            <Grid container spacing={1}>
              <Grid item xs={3} md={3} lg={3}>
                {note?.agent?.firstName || note?.client?.firstName}{" "}
                {note?.agent?.lastName || note?.client?.lastName}
              </Grid>
              {/* <Grid
                item
                xs={8.3}
                md={8.3}
                lg={8.3}
                className={styles.displayOneNoteVisibleToClientChipGrid}
              >
                <span className={styles.displayOneNoteVisibleToClientChip}>
                  {note.visibility ? (
                    <Chip label={Vocabulary.visibleToClient} />
                  ) : null}
                </span>
              </Grid> */}
              <Grid item xs={12} md={12} lg={12}>
                <Typography>{note.text}</Typography>
              </Grid>

              {note.details && (
                <Grid item xs={12} md={12} lg={12}>
                  <Divider sx={{ margin: "10px 0px !important" }} />
                  <Typography>
                    {`${extractStringFromHtml(note.details || "").substring(
                      0,
                      300
                    )} ${note?.details?.length > 200 ? "..." : ""}`}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <div className={styles.displayOneNoteButtons}>
              <IconButton disabled={disabled} onClick={() => handleEdit(note)}>
                <Edit style={{ cursor: "pointer" }} />
              </IconButton>

              {note.type === ENoteType.OTHERS ? (
                <IconButton
                  disabled={disabled}
                  onClick={() => handleDelete(note)}
                >
                  <Delete style={{ cursor: "pointer" }} />
                </IconButton>
              ) : null}
            </div>
          </Box>
        </StepContent>
      </Step>
    </div>
  );
}
