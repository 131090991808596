import {
  AttachFile,
  Chat,
  Check,
  Dashboard,
  DoneAll,
  Email,
  HourglassBottom,
  Image,
  MiscellaneousServices,
  MonitorHeart,
  Pending,
  People,
  Person,
  Phone,
  PhoneIphone,
  PriorityHigh,
  Sms,
  TextSnippet,
  ThumbDown,
  ThumbUp,
  WhatsApp,
} from "@mui/icons-material";
import { localUrlEnum, pathParams } from "./UrlEnum";
import PageNotFound from "../Components/PageNotFound";
import { Vocabulary } from "./Vocabulary";
import PrivateRoute from "../Router/PrivateRouter";
import DashboardContainer from "../Components/DashboardContainer";
import Login from "../Pages/LoginPage";
import DashboardPage from "../Pages/Dashboard";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ClientsTable from "../Components/Clients/ClientsTable";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import SettingsIcon from "@mui/icons-material/Settings";
import UsersTable from "../Components/Users/UsersTable";
import OrdersTable from "../Components/Orders/OrdersTable";
import OrderStatus from "../Components/OrderStatus/OrderStatusesTable";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ProductsTable from "../Components/Products/ProductsTable";
import ViewListIcon from "@mui/icons-material/ViewList";
import Order from "../Components/Orders/Order";
import EmailTemplatesTable from "../Components/Templates/EmailTemplatesTable";
import Product from "../Components/Products/Product";
import Settings from "../Pages/Settings";
import OrderOfferPasswordPage from "../Pages/OrderOfferPasswordPage";
import OrderOfferPage from "../Pages/OrderOfferPage";
import OrderOfferConfirmPage from "../Pages/OrderOfferConfirmPage";
import WhatsAppIntegrationMainPage from "../Components/WhatsAppIntegration/WhatsAppIntegrationMainPage";
import WhatsAppTemplatesTable from "../Components/WhatsAppIntegration/WhatsAppTemplatesTable";
import { createTheme } from "@mui/material";
import {
  EAuditAction,
  EAuditStatus,
  EHeaderTemplateMenuOptions,
  ETemplateStatus,
  EWhatsAppComponentsTypes,
  EWhatsAppMessageType,
  EWhatsAppTemplateButtonType,
  EWhatsAppTemplateCategoryTranslation,
} from "./Enums";
import WhatsAppTemplateForm from "../Components/WhatsAppIntegration/WhatsAppTemplateForm";
import WhatsAppPhoneNumber from "../Components/WhatsAppIntegration/WhatsAppPhoneNumber";
import PhoneNumberRegistrationStep from "../Components/WhatsAppIntegration/PhoneNumberRegistrationStep";
import VerifyCodeConfirmationStep from "../Components/WhatsAppIntegration/VerifyCodeConfirmationStep";
import TwoFactorAuthenticationSetupStep from "../Components/WhatsAppIntegration/TwoFactorAuthenticationSetupStep";
import Messenger from "../Components/Messages/Messenger";
import LogsTable from "../Components/Logs/LogsTable";

export const HttpStatusCode = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  RequestTimeout: 408,
  InternalServerError: 500,
  NotImplemented: 501,
  UnprocessableEntity: 422,
};

export const HttpVerbs = {
  post: "post",
  get: "get",
  put: "put",
  delete: "delete",
  patch: "patch",
};

export const SortOptions = {
  asc: "asc",
  desc: "desc",
};

export const WhatsAppMenuItems = [
  {
    name: Vocabulary.phoneNumber,
    path: localUrlEnum.whatsAppPhoneNumbers,
    icon: <PhoneIphone />,
  },
  {
    name: Vocabulary.templates,
    path: localUrlEnum.whatsAppTemplates,
    icon: <Chat />,
  },
];

export const MainSliderData = [
  {
    name: Vocabulary.dashboard,
    path: localUrlEnum.dashboard,
    icon: <Dashboard />,
  },
  {
    name: Vocabulary.users,
    path: localUrlEnum.users,
    icon: <People />,
  },
  {
    name: Vocabulary.clients,
    path: localUrlEnum.clients,
    icon: <Diversity3Icon />,
  },
  {
    name: Vocabulary.products,
    path: localUrlEnum.products,
    icon: <ShoppingBagIcon />,
  },
  {
    name: Vocabulary.orders,
    path: localUrlEnum.orders,
    icon: <ViewListIcon />,
  },
  {
    name: Vocabulary.ordersStatuses,
    path: localUrlEnum.orderStatus,
    icon: <ChecklistIcon />,
  },
  {
    name: Vocabulary.templates,
    path: "",
    icon: <TextSnippet />,
    children: [
      {
        name: Vocabulary.email,
        path: localUrlEnum.emailTemplates,
        icon: <Email />,
      },
    ],
  },
  {
    name: Vocabulary.whatsappIntegration,
    path: localUrlEnum.whatsAppPhoneNumbers,
    icon: <WhatsApp />,
  },
  {
    name: Vocabulary.logs,
    path: localUrlEnum.logs,
    icon: <MonitorHeart />,
  },
  {
    name: Vocabulary.settings,
    path: localUrlEnum.settings,
    icon: <SettingsIcon />,
  },
];

export const MainSliderDataForUser = [
  {
    name: Vocabulary.dashboard,
    path: localUrlEnum.dashboard,
    icon: <Dashboard />,
  },
  {
    name: Vocabulary.clients,
    path: localUrlEnum.clients,
    icon: <Diversity3Icon />,
  },
  {
    name: Vocabulary.products,
    path: localUrlEnum.products,
    icon: <ShoppingBagIcon />,
  },
  {
    name: Vocabulary.orders,
    path: localUrlEnum.orders,
    icon: <ViewListIcon />,
  },
  {
    name: Vocabulary.ordersStatuses,
    path: localUrlEnum.orderStatus,
    icon: <ChecklistIcon />,
  },
  {
    name: Vocabulary.templates,
    path: "",
    icon: <TextSnippet />,
    children: [
      {
        name: Vocabulary.email,
        path: localUrlEnum.emailTemplates,
        icon: <Email />,
      },
    ],
  },
  {
    name: Vocabulary.whatsappIntegration,
    path: localUrlEnum.whatsAppPhoneNumbers,
    icon: <WhatsApp />,
  },
];

export const ClientExcelHeader = [
  "CLIENT",
  "CATEGORIE",
  "SAPTAMANA",
  "COD_FISCAL",
  "NR_REGISTRU_COMERT",
  "OBSERVATII",
  "ADRESA",
  "ORAS",
  "JUDET",
  "BANCA",
  "CONT_BANCAR",
  "TARA",
  "EMAIL",
  "PERSOANA_DE_CONTACT",
  "TELEFON",
];

export const ROLES = {
  ROLE_ADMINISTRATOR: "ROLE_ADMINISTRATOR",
  ROLE_USER: "ROLE_USER",
};

export const ComponentsNames = {
  MainSlider: "MainSlider",
  LocalRouter: "LocalRouter",
  MainHeader: "MainHeader",
  ContactsTable: "ContactsTable",
};

export const AllRoutes = [
  { path: localUrlEnum.login, element: <Login /> },
  {
    path: `${localUrlEnum.orderOfferPasswordCheck}${pathParams.orderId}`,
    element: <OrderOfferPasswordPage />,
  },
  {
    path: `${localUrlEnum.orderOffer}${pathParams.orderId}`,
    element: <OrderOfferPage />,
  },
  {
    path: `${localUrlEnum.orderOfferConfirmation}${pathParams.orderId}`,
    element: <OrderOfferConfirmPage />,
  },
  {
    path: localUrlEnum.mainRoute,
    element: (
      <PrivateRoute>
        <DashboardContainer />
      </PrivateRoute>
    ),
    children: [
      {
        path: localUrlEnum.mainRoute,
        element: <DashboardPage shouldRenderSearchBar={true} />,
      },
      {
        path: localUrlEnum.dashboard,
        element: <DashboardPage shouldRenderSearchBar={true} />,
      },

      {
        path: localUrlEnum.users,
        element: <UsersTable />,
      },
      {
        path: localUrlEnum.clients,
        element: <ClientsTable />,
      },
      {
        path: localUrlEnum.orders,
        element: <OrdersTable />,
      },

      {
        path: localUrlEnum.settings,
        element: <Settings />,
      },
      {
        path: `${localUrlEnum.logs}${pathParams.optionalId}`,
        element: <LogsTable />,
      },
      {
        path: localUrlEnum.orderStatus,
        element: <OrderStatus />,
      },
      {
        path: localUrlEnum.products,
        element: <ProductsTable />,
      },
      {
        path: `${localUrlEnum.order}${pathParams.optionalId}`,
        element: <Order />,
      },
      {
        path: `${localUrlEnum.product}${pathParams.optionalId}`,
        element: <Product />,
      },
      {
        path: localUrlEnum.whatsAppPhoneNumbers,
        element: <WhatsAppIntegrationMainPage />,
        children: [{ path: "", element: <WhatsAppPhoneNumber /> }],
      },
      {
        path: localUrlEnum.whatsAppTemplates,
        element: <WhatsAppIntegrationMainPage />,
        children: [{ path: "", element: <WhatsAppTemplatesTable /> }],
      },
      {
        path: `${localUrlEnum.whatsAppTemplate}${pathParams.optionalId}`,
        element: <WhatsAppIntegrationMainPage />,
        children: [{ path: "", element: <WhatsAppTemplateForm /> }],
      },
      {
        path: localUrlEnum.emailTemplates,
        element: <EmailTemplatesTable />,
      },
      {
        path: `${localUrlEnum.messages}${pathParams.optionalId}`,
        element: <Messenger />,
      },
    ],
  },
  {
    path: localUrlEnum.pageNotFound,
    element: <PageNotFound text={Vocabulary.pageNotFound} />,
  },
];

export const HeaderTemplateMenuOptions = [
  { label: "Fără", value: EHeaderTemplateMenuOptions.NONE },
  { label: "Text", value: EHeaderTemplateMenuOptions.TEXT },
  { label: "Imagine", value: EHeaderTemplateMenuOptions.IMAGE },
  { label: "Video", value: EHeaderTemplateMenuOptions.VIDEO },
  { label: "Document", value: EHeaderTemplateMenuOptions.DOCUMENT },
];

const AdminRouter = {
  routes: [
    localUrlEnum.login,
    localUrlEnum.mainRoute,
    localUrlEnum.pageNotFound,
    localUrlEnum.dashboard,
    localUrlEnum.clients,
    localUrlEnum.orderStatus,
    localUrlEnum.products,
    `${localUrlEnum.orderOfferPasswordCheck}${pathParams.orderId}`,
    `${localUrlEnum.orderOffer}${pathParams.orderId}`,
    `${localUrlEnum.orderOfferConfirmation}${pathParams.orderId}`,
    localUrlEnum.orders,
    `${localUrlEnum.order}${pathParams.optionalId}`,
    `${localUrlEnum.product}${pathParams.optionalId}`,
    localUrlEnum.users,
    localUrlEnum.emailTemplates,
    localUrlEnum.settings,
    `${localUrlEnum.logs}${pathParams.optionalId}`,
    `${localUrlEnum.users}${pathParams.optionalId}`,
    localUrlEnum.whatsAppPhoneNumbers,
    localUrlEnum.whatsAppTemplates,
    `${localUrlEnum.whatsAppTemplate}${pathParams.optionalId}`,
    localUrlEnum.renderChildren,
    `${localUrlEnum.messages}${pathParams.optionalId}`,
  ],
};

const UserRouter = {
  routes: [
    localUrlEnum.login,
    localUrlEnum.mainRoute,
    localUrlEnum.pageNotFound,
    localUrlEnum.dashboard,
    localUrlEnum.clients,
    localUrlEnum.orderStatus,
    `${localUrlEnum.orderOfferPasswordCheck}${pathParams.orderId}`,
    `${localUrlEnum.orderOffer}${pathParams.orderId}`,
    `${localUrlEnum.orderOfferConfirmation}${pathParams.orderId}`,
    localUrlEnum.products,
    localUrlEnum.orders,
    `${localUrlEnum.order}${pathParams.optionalId}`,
    `${localUrlEnum.product}${pathParams.optionalId}`,
    localUrlEnum.emailTemplates,
    localUrlEnum.whatsAppPhoneNumbers,
    localUrlEnum.whatsAppTemplates,
    `${localUrlEnum.whatsAppTemplate}${pathParams.optionalId}`,
    localUrlEnum.renderChildren,
    `${localUrlEnum.messages}${pathParams.optionalId}`,
  ],
};

export const RoleUI: { [key: string]: any } = {
  Admin: {
    MainSlider: {
      data: MainSliderData,
    },
    LocalRouter: AdminRouter,
    ContactsTable: {
      bulkActionsAllowed: true,
      selectableRows: "multiple",
      hideOptions: false,
    },
  },
  Agent: {
    MainSlider: {
      data: MainSliderDataForUser,
    },
    ContactsTable: {
      hideOptions: true,
    },
    LocalRouter: UserRouter,
  },
};

export const EWeekDays = [
  { name: "LUNI", value: 1 },
  { name: "MARTI", value: 2 },
  { name: "MIERCURI", value: 3 },
  { name: "JOI", value: 4 },
  { name: "VINERI", value: 5 },
  { name: "SAMBATA", value: 6 },
  { name: "DUMINICA", value: 0 },
];

export const emailTemplateAdditionalData = {
  "order.Number": "Număr comandă",
  "client.name": "Nume client",
  "client.email": "Email client",
};

/**
 * responsive design resolution breakpoints
 */
export const ResolutionBreakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const WhatsAppTemplateButtons = [
  {
    name: Vocabulary.quickReply,
    menu: [
      {
        name: Vocabulary.custom,
        content: {
          type: "QUICK_REPLY",
          text: "",
        },
      },
    ],
    shouldRenderDivider: true,
  },
  {
    name: Vocabulary.specificAction,
    menu: [
      {
        name: Vocabulary.visitSite,
        buttonNr: 2,
        content: {
          type: EWhatsAppTemplateButtonType.URL,
          text: "",
          url: "",
          example: [],
        },
      },
      {
        name: Vocabulary.callPhoneNumber,
        buttonNr: 1,
        content: {
          type: EWhatsAppTemplateButtonType.PHONE_NUMBER,
          text: "",
          phone_number: "",
        },
      },
    ],
  },
];

export const DefaultWhatsAppTemplateComponents = [
  {
    type: EWhatsAppComponentsTypes.HEADER,
    format: "NONE",
  },
  {
    type: EWhatsAppComponentsTypes.BODY,
    text: "",
  },
  {
    type: EWhatsAppComponentsTypes.FOOTER,
    text: "",
  },
  {
    type: EWhatsAppComponentsTypes.BUTTONS,
    buttons: [],
  },
];

export const WhatsappMessageStatuses: any = {
  sent: {
    name: "Trimis",
    icon: <Check fontSize="small" />,
  },
  delivered: {
    name: "Livrat",
    icon: <DoneAll fontSize="small" />,
  },
  read: {
    name: "Citit",
    icon: <DoneAll sx={{ color: "#2196f3" }} fontSize="small" />,
  },
  pending: {
    name: "În așteptare",
    icon: <HourglassBottom fontSize="small" />,
  },
  failed: {
    name: "Eșuat",
    icon: <PriorityHigh fontSize="small" />,
  },
};

export const IconsForNotes: any = {
  SYSTEM: {
    name: "SYSTEM",
    label: "System",
    icon: <SettingsIcon />,
    backgroundColor: "#009846",
  },
  EMAIL: {
    name: "EMAIL",
    label: "Email",
    icon: <Email />,
    backgroundColor: "#009846",
  },
  PHONE: {
    name: "PHONE",
    label: "Phone",
    icon: <Phone />,
    backgroundColor: "#009846",
  },
  SMS: {
    name: "SMS",
    label: "Sms",
    icon: <Sms />,
    backgroundColor: "#009846",
  },
  USER: {
    name: "USER",
    label: "User",
    icon: <Person />,
    backgroundColor: "#009846",
  },
  OTHERS: {
    name: "OTHERS",
    label: "Others",
    icon: <MiscellaneousServices />,
    backgroundColor: "#009846",
  },
};

export const EmailTemplateEditorOptions = [
  "inline",
  "blockType",
  "fontSize",
  "fontFamily",
  "list",
  "textAlign",
  "colorPicker",
  "link",
  "emoji",
  "remove",
  "history",
];

export const WhatsAppTemplateStatuses: any = {
  APPROVED: ETemplateStatus.APPROVED,
  PENDING: ETemplateStatus.PENDING,
  REJECTED: ETemplateStatus.REJECTED,
};

export const WhatsAppTemplateIconsStatuses: any = {
  APPROVED: <ThumbUp />,
  PENDING: <Pending />,
  REJECTED: <ThumbDown />,
};

export const WhatsAppTemplateChipColors: any = {
  APPROVED: "#E1F0DA",
  PENDING: "#BED7DC",
  REJECTED: "#FF9B9B",
};

export const WhatsAppTemplateCategory: any = {
  AUTHENTICATION: EWhatsAppTemplateCategoryTranslation.AUTHENTICATION,
  MARKETING: EWhatsAppTemplateCategoryTranslation.MARKETING,
  UTILITY: EWhatsAppTemplateCategoryTranslation.UTILITY,
};

export const WhatsAppTemplatesSupportedLanguagesTranslation: any = {
  ro: "Română",
  en_US: "Engleză (US)",
  en_GB: "Engleză (GB)",
};

export const tableTheme: any = (defaultTheme: any) =>
  createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&:after": {
              borderBottom: "2px solid #009846 !important",
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: "#009846 !important",
            color: "#fff !important",
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.down("md")]: {
              // marginTop: 50,
            },
          },
        },
      },

      MuiButtonBase: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.down("md")]: {
              marginTop: 4,
            },
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            display: "flex",
            flexDirection: "row-reverse",
          },
          root: {
            [defaultTheme.breakpoints.down("md")]: {
              borderTopLeftRadius: "0px !important",
              borderTopRightRadius: "0px !important",
              display: "flex",
              textAlign: "center",
              backgroundColor: "#ededef !important",
              zIndex: 200,
              padding: 10,
            },
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              color: "#009846",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "rgb(64, 74, 105, 0.3)",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: "rgb(64, 74, 105, 0.1)",
            fontWeight: 800,
          },
          root: {
            padding: "7px !important",
            paddingLeft: "25px !important",
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            backgroundColor: "#FFF",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFF",
            color: "#000",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#009846",
            },
            [defaultTheme.breakpoints.down("md")]: {
              marginTop: 5,
            },
          },
        },
      },
    },
  });

export const WhatsAppTemplatesSupportedLanguages = ["ro", "en_US", "en_GB"];

export const FormsIds = {
  phoneNumber: "phoneNumber",
  verifyCodeForm: "verifyCodeForm",
  twoFactorAuthentication: "twoFactorAuthentication",
  importClientsForm: "importClientsForm",
  clients: "clients",
  contactEmailForm: "contactEmailForm",
  billingDocumentsForm: "billingDocumentsForm",
  orderForm: "orderForm",
  orderSettingsNumber: "orderSettingsNumber",
  editOrderSettings: "editOrderSettings",
  usersForm: "usersForm",
  orderOfferForm: "orderOfferForm",
  editEmailTemplateForm: "editEmailTemplateForm",
  whatsAppTemplateForm: "whatsAppTemplateForm",
  whatsAppSettingsForm: "whatsAppSettingsForm",
  chatListForm: "chatListForm",
  noteForm: "noteForm",
};

export const TablesIds = {
  logs: "logs",
  orders: "orders",
  clients: "clients",
  products: "products",
  emailTemplates: "emailTemplates",
  users: "users",
};

export const RegisterWhatsAppPhoneNumberSteps = [
  {
    label: Vocabulary.registerPhoneNumber,
    component: <PhoneNumberRegistrationStep />,
    formName: FormsIds.phoneNumber,
  },
  {
    label: Vocabulary.verifyPhoneNumberRegistrationCode,
    component: <VerifyCodeConfirmationStep />,
    formName: FormsIds.verifyCodeForm,
  },
  {
    label: Vocabulary.setPinForTwoStepVerification,
    component: <TwoFactorAuthenticationSetupStep />,
    formName: FormsIds.twoFactorAuthentication,
  },
];

export const WhatsAppTemplatePropertyTags = [
  { label: "Nume client", value: "name" },
  { label: "Total comandă", value: "total" },
  { label: "Număr comandă", value: "number" },
  { label: "Link comandă", value: "orderLink" },
  { label: "Parolă", value: "password" },
  { label: "Nume complet persoană de contact", value: "contactPerson.name" },
  {
    label: "Nume persoană de contact",
    value: "contactPerson.firstName",
  },
  {
    label: "Prenume persoană de contact",
    value: "contactPerson.lastName",
  },
  {
    label: "Email persoană de contact",
    value: "contactPerson.email",
  },
  {
    label: "Telefon persoană de contact",
    value: "contactPerson.phone",
  },
  {
    label: "Status comandă",
    value: "status.name",
  },
];

export const LogFiltersMethods = [
  EAuditAction.CREATE,
  EAuditAction.UPDATE,
  EAuditAction.DELETE,
  EAuditAction.SOFT_DELETE,
  EAuditAction.IMPORT,
  EAuditAction.DUPLICATE,
];

export const LogFiltersStatuses = [
  EAuditStatus.ERROR,
  EAuditStatus.SUCCESS,
  EAuditStatus.INFO,
];

export const ImageExtensions = [".jpg", ".jpeg", ".png", ".svg", ".gif"];

export const DocumentExtensions = [
  ".doc",
  ".docx",
  ".pdf",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".txt",
];

export const MessagesAttachmentsOptions = [
  {
    label: Vocabulary.image,
    type: EWhatsAppMessageType.IMAGE,
    icon: <Image />,
    accept: ".png, .jpeg, .jpg",
  },
  {
    label: Vocabulary.document,
    type: EWhatsAppMessageType.DOCUMENT,
    icon: <AttachFile />,
    accept: DocumentExtensions.join(","),
  },
];
