import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Add } from "@mui/icons-material";
import { WhatsAppTagManagementComponentProps } from "../../Utils/Types";
import genericStyles from "../../Styles/genericStyles.module.css";
import styles from "../../Styles/whatsApp.module.css";
import { Fragment, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { WhatsAppTemplatePropertyTags } from "../../Utils/Constants";

export default function WhatsAppTagManagementComponent(
  props: WhatsAppTagManagementComponentProps
) {
  const [numberOfTags, setNumberOfTags] = useState(0);
  const tagRegex = /\{\{([^}]*)\}\}/g;
  /**
   *
   * @returns
   */
  const countTagsInText = () => {
    //iterate over body text and search for this pattern {{ }}
    const text = props.methods.watch(props.mainProperty) || "";
    const matches = text?.match(tagRegex);
    const currentTagNr = matches?.length || 0;
    if (currentTagNr !== numberOfTags) setNumberOfTags(currentTagNr);
    return currentTagNr || 0;
  };

  /**
   * Add a new variable to the body text
   */
  const handleAddVariableToText = () => {
    const currentTagNr = countTagsInText();
    if (
      props.maxNrOfTags !== undefined &&
      props.maxNrOfTags !== null &&
      currentTagNr >= props.maxNrOfTags
    )
      return;
    const text = props.methods.watch(props.mainProperty);
    const newTag = `{{${currentTagNr + 1}}}`;
    const newText = `${text} ${newTag}`;
    if (props.maxlengthMainString && newText.length > props.maxlengthMainString)
      return;
    props.methods.setValue(props.mainProperty, newText);
  };

  /**
   *
   */
  useEffect(() => {
    const inputText = props.methods.watch(props.mainProperty) || "";
    const segments = inputText
      .split(/(\{\{\d+\}\})/)
      .filter((segment: any) => segment !== "");

    let index = 1;
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].match(tagRegex)) {
        segments[i] = `{{${index++}}}`;
      }
    }
    const outputText = segments.join("");
    props.methods.setValue(props.mainProperty, outputText);
  }, [numberOfTags]);

  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button
          disabled={
            props.maxNrOfTags !== undefined &&
            props.maxNrOfTags !== null &&
            countTagsInText() >= props.maxNrOfTags
          }
          startIcon={<Add />}
          onClick={handleAddVariableToText}
          variant="contained"
          color="secondary"
        >
          {Vocabulary.addVariable}
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {props.children}
      </Grid>
      {countTagsInText() > 0 ? (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <fieldset className={genericStyles.fieldSet}>
            <Typography sx={{ padding: "5px" }}>
              {Vocabulary.samplesForBodyContent}
            </Typography>
            <Typography sx={{ padding: "5px" }} variant="caption">
              {Vocabulary.whatsAppTemplateBodyContentForHelperText}
            </Typography>
            {Array.from(Array(countTagsInText()), (_, i) => (
              <div className={styles.whatsAppTagManagementWidget}>
                <Typography sx={{ marginRight: "5px" }} key={i}>{`{{${
                  i + 1
                }}}`}</Typography>
                <Controller
                  name={`${props.examplePropertyName}.${i}.`}
                  control={props.methods.control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {`${Vocabulary.exampleContent} {{${i + 1}}}`}
                      </InputLabel>
                      <Select
                        labelId="level-label"
                        {...field}
                        label={`${Vocabulary.enterContentFor} {{${i + 1}}}`}
                      >
                        {WhatsAppTemplatePropertyTags.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            ))}
          </fieldset>
        </Grid>
      ) : null}
    </Fragment>
  );
}
