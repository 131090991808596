/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useEffect, useState } from "react";
import styles from "../../Styles/whatsApp.module.css";
import ConnectWithFacebook from "./ConnectWithFacebook";
import { getData } from "../../Services/getData";
import { endpoints } from "../../Utils/UrlEnum";
import { WhatsAppBusinessAccountData } from "../../Utils/Models";
import WhatsAppMenu from "./WhatsAppMenu";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import Config from "../../Utils/Config";

export default function WhatsAppIntegrationMainPage() {
  const [shouldUpdate, setShouldUpdate] = useState<boolean | null>(null);
  const [whatsAppBusinessAccountData, setWhatsAppBusinessAccountData] =
    useState<WhatsAppBusinessAccountData>(new WhatsAppBusinessAccountData());
  /**
   *
   */
  useEffect(() => {
    getData(endpoints.whatsApp.waba).then((response) => {
      if (response && response.data) {
        setWhatsAppBusinessAccountData(response.data);
      }
    });
  }, [shouldUpdate]);

  return (
    <div className={styles.whatsAppMainContainer}>
      {/* {whatsAppBusinessAccountData?._id ? ( */}
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <WhatsAppMenu
            whatsAppBusinessAccountData={whatsAppBusinessAccountData}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={10}
          xl={10}
          className={styles.whatsAppOutLetContainer}
        >
          <Outlet context={whatsAppBusinessAccountData} />
        </Grid>
      </Grid>
      {/* // ) : (
      //   <ConnectWithFacebook setShouldUpdate={setShouldUpdate} />
      // )} */}
    </div>
  );
}
