import styles from "../Styles/dashboard.module.css";
import TodaysContacts from "../Components/Contacts/TodaysContacts";
import { DashboardPageProps } from "../Utils/Types";
import { Grid } from "@mui/material";
import MessengerList from "../Components/Messages/MessengerList";
import Chat from "../Components/Messages/Chat";

export default function Dashboard(props: DashboardPageProps) {
  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={7.5}>
          <TodaysContacts />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={4} xl={1.75}>
          <MessengerList />
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={8} xl={2.75}>
          <Chat className={styles.dashboardMessagesContainerHeight} />
        </Grid>
      </Grid>
    </div>
  );
}
