import { Grid } from "@mui/material";
import MessengerList from "./MessengerList";
import Chat from "./Chat";
import styles from "../../Styles/chat.module.css";

export default function Messenger() {
  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
          <MessengerList />
        </Grid>
        <Grid item xl={10} lg={10} md={8} sm={12} xs={12}>
          <Chat className={styles.messagesContainerHeight} />
        </Grid>
      </Grid>
    </div>
  );
}
