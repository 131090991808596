import { Divider, Grid, Typography } from "@mui/material";
import { LogProps } from "../../Utils/Types";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import { checkIfPropertyIsString, formatStringDate } from "../../Utils/Utils";
import { useEffect, useState } from "react";

export default function Log(props: LogProps) {
  const { log } = props;
  const [newDiff, setNewDiff] = useState([]);
  const [oldDiff, setOldDiff] = useState([]);

  /**
   *
   */
  useEffect(() => {
    if (log && log.differences) {
      const oldData = [] as any;
      const newData = [] as any;
      log.differences.forEach((difference: string) => {
        const diff = difference.split("__");
        if (diff[0] === "old") oldData.push(diff[1]);
        else if (diff[0] === "new") newData.push(diff[1]);
      });
      setNewDiff(newData);
      setOldDiff(oldData);
    }
  }, [log]);

  return (
    <Grid container spacing={Config.standardGridSpacing}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5">{`${Vocabulary.otherInfos}:`}</Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Typography>
          {Vocabulary.userName}: {log && log.userName}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Typography>
          {Vocabulary.method}: {log && Vocabulary[log.method]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Typography>
          {Vocabulary.table}: {log && Vocabulary[log?.table]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Typography>
          {Vocabulary.database}: {log.database || "-"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Typography>
          {Vocabulary.identifier}:{" "}
          {log && checkIfPropertyIsString(log.object)
            ? log.object
            : Vocabulary.noIdentifier}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Typography>
          {Vocabulary.date}:{" "}
          {log &&
            formatStringDate(log.createdAt, Config.momentEUDateTimeFormat)}
        </Typography>
      </Grid>
      {log && log.differences && log.differences.length !== 0 ? (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h5">{`${Vocabulary.diferences}:`}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={5.5}>
            <Typography variant="h6">{`${Vocabulary.initialsValues}: `}</Typography>
            {oldDiff.map((d: string, key: number) => {
              const diff = d.split(":");
              return (
                <p key={`${key}_olddiff`}>
                  {diff.map((value: string, key: number) => {
                    value = value.replaceAll('"', "");
                    return (
                      <span key={`${key}_oldvalue`}>
                        {Vocabulary[value] ? Vocabulary[value] : value}
                        {diff.length - 1 === key ? "" : ": "}
                      </span>
                    );
                  })}
                </p>
              );
            })}
          </Grid>
          <Grid item xs={0} md={0} lg={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={6} md={6} lg={5.5}>
            <Typography variant="h6">{`${Vocabulary.modifiedValues}:`}</Typography>
            {newDiff.map((d: string, key: number) => {
              const diff = d.split(":");
              return (
                <p key={`${key}_newdiff`}>
                  {diff.map((value: string, key: number) => {
                    value = value.replaceAll('"', "");
                    return (
                      <span key={`${key}_newvalue`}>
                        {Vocabulary[value] ? Vocabulary[value] : value}
                        {diff.length - 1 === key ? "" : ": "}
                      </span>
                    );
                  })}
                </p>
              );
            })}
          </Grid>
        </>
      ) : (
        ""
      )}
    </Grid>
  );
}
