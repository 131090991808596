import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { Fragment } from "react";
import { ListItemProductLineProps } from "../../Utils/Types";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Controller } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomMemo from "../../Hooks/useCustomMemo";

export default function ListItemProductLine(props: ListItemProductLineProps) {
  const {
    name,
    quantity,
    priceWithoutTVA,
    price,
    managements,
    index,
    control,
    discount,
    productType,
  } = props;
  const memo = useCustomMemo();
  const defaultManagement = memo?.storage.defaultManagement || null;
  /**
   *
   * @returns
   */
  const getDefaultManagement = () => {
    if (!managements || !defaultManagement) return "";
    const defaultM = managements?.find(
      (management: any) =>
        management?.management === defaultManagement?.management
    );
    return defaultM?.management || "";
  };

  return (
    <Fragment>
      <Grid
        className={genericStyles.centeredElementColumn}
        item
        xs={12}
        sm={12}
        md={3.5}
      >
        <Typography>{name}</Typography>
      </Grid>
      <Grid
        className={genericStyles.centeredElementColumn}
        item
        xs={12}
        sm={12}
        md={1.5}
      >
        <Typography>{quantity || "-"}</Typography>
      </Grid>
      <Grid
        className={genericStyles.centeredElementColumn}
        item
        xs={12}
        sm={12}
        md={2}
      >
        <Typography>{priceWithoutTVA || "-"}</Typography>
      </Grid>
      <Grid
        className={genericStyles.centeredElementColumn}
        item
        xs={12}
        sm={12}
        md={2}
      >
        <Typography>{discount ? `${discount}%` : price || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={2.5}>
        {control ? (
          productType === Vocabulary.service || discount ? (
            <Typography>{"-"}</Typography>
          ) : (
            <Controller
              name={`products.${index}.management`}
              control={control}
              defaultValue={getDefaultManagement()}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  value={field.value || " "}
                  freeSolo={false}
                  disablePortal
                  disableClearable
                  clearOnBlur={true}
                  options={managements}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.management === value;
                  }}
                  getOptionLabel={(option: any) =>
                    option?.management || option || ""
                  }
                  onChange={(event, value: any) => {
                    field.onChange(value.management);
                  }}
                  renderInput={(params) => (
                    <TextField
                      value={field?.value || " "}
                      error={!!error}
                      helperText={error?.message}
                      label={Vocabulary.management}
                      {...params}
                      variant="standard"
                    />
                  )}
                />
              )}
            />
          )
        ) : (
          <Typography>{Vocabulary.management}</Typography>
        )}
      </Grid>
    </Fragment>
  );
}
