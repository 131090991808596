import { useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Visibility } from "@mui/icons-material";
import styles from "../Styles/loginPage.module.css";
import {
  Button,
  Paper,
  IconButton,
  InputAdornment,
  Typography,
  Grid,
} from "@mui/material";
import { postData } from "../Services/postData";
import { localUrlEnum, endpoints } from "../Utils/UrlEnum";
import { useNavigate } from "react-router-dom";
import { Vocabulary } from "../Utils/Vocabulary";
import { LoginModel } from "../Utils/Models";
import { LoginResponseType } from "../Utils/Types";
import { HttpStatusCode } from "../Utils/Constants";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const navigate = useNavigate();
  const captchaRef = useRef<any>(null);
  const [user, setUser] = useState<LoginModel>(new LoginModel());
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState("");

  /**
   *
   */
  async function handleSubmit() {
    try {
      if (captchaRef.current) {
        const token = captchaRef.current.getValue();
        user.reCaptcha = token;
        const response: LoginResponseType = await postData(
          endpoints.login,
          user
        );
        if (response && response?.data && response?.data?.userId) {
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("userName", response.data.userName);
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem(
            "userRoles",
            JSON.stringify(response.data.roles)
          );
          localStorage.setItem("fullName", response.data.fullName);
          navigate(localUrlEnum.dashboard);
          window.location.reload();
        } else {
          setErrorText(Vocabulary.loginValidationError);
        }
      }
    } catch (err: any) {
      if (err.response && err.response.status === HttpStatusCode.Unauthorized)
        setErrorText(Vocabulary.loginValidationError);
      else if (err.message) setErrorText(err.message);
    }
  }

  /**
   *
   * @param event
   */
  function onChangeTextField(event: any) {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  }

  return (
    <div className={styles.App}>
      <Paper className={styles.loginContainer} elevation={5}>
        <Grid container spacing={1} className={styles.gridMainContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={styles.firstGrid}
          >
            <img src={"/logo.png"} alt="EcoPack" width={"40%"} />
            <ValidatorForm className={styles.form} onSubmit={handleSubmit}>
              <TextValidator
                variant="standard"
                id="username"
                name="username"
                fullWidth
                label="Username"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={user.username}
                onChange={(e) => onChangeTextField(e)}
                className={styles.textfield}
              />
              <TextValidator
                variant="standard"
                id="password"
                name="password"
                type={!showPassword ? "password" : "text"}
                label={Vocabulary.password}
                fullWidth
                validators={["required"]}
                value={user.password}
                onChange={(e) => onChangeTextField(e)}
                className={styles.textfield}
                errorMessages={[Vocabulary.requiredField]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: "#a5b3c0" }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className={styles.ReCAPTCHA}>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={
                    process.env.REACT_APP_SITE_KEY
                      ? process.env.REACT_APP_SITE_KEY
                      : ""
                  }
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                className={styles.submit}
              >
                {Vocabulary.login}
              </Button>
              <Typography className={styles.errorText}>{errorText}</Typography>
            </ValidatorForm>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
