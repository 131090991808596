import {
  AccordionSummary,
  Button,
  createTheme,
  Grid,
  Accordion,
  ThemeProvider,
  Typography,
  AccordionDetails,
  TextField,
} from "@mui/material";
import styles from "../Styles/orderOffer.module.css";
import PaymentPanel from "../Components/Orders/PaymentPanel";
import TotalPanel from "../Components/Orders/TotalPanel";
import {
  OrderLineModel,
  OrderOfferStateModel,
  OrderOfferFormValidationSchema,
} from "../Utils/Models";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { localUrlEnum, endpoints } from "../Utils/UrlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import { isMobile } from "../Utils/Utils";
import ShippingPanel from "../Components/Orders/ShippingPanel";
import TransportMethods from "../Components/Orders/TransportMethods";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ProductPanelOrderOffer from "../Components/OrderOffer/ProductPanelOrderOffer";
import ClientPanelOrderOffer from "../Components/OrderOffer/ClientPanelOrderOffer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getData } from "../Services/getData";
import { OrderOfferCustomPanel } from "../Components/OrderOfferCustomPanel";
import { updateData } from "../Services/updateData";
import { AxiosResponse } from "axios";
import OrderOfferFooter from "../Components/OrderOffer/OrderOfferFooter";
import { FormsIds } from "../Utils/Constants";

export default function OrderOfferPage() {
  const ref: any = useRef();
  const [expanded, setExpanded] = useState(false);
  const [localState, setLocalState] = useState<OrderOfferStateModel>(
    new OrderOfferStateModel()
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState,
    reset,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(OrderOfferFormValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const lineItems = watch("line_items");
  const { orderId } = useParams();

  /**
   *
   */
  useEffect(() => {
    if (
      !orderId ||
      orderId === "null" ||
      orderId === "undefined" ||
      orderId === "" ||
      orderId === "*"
    ) {
      navigate(localUrlEnum.pageNotFound);
    }
  }, [orderId]);

  /**
   *
   */
  useEffect(() => {
    if (state && getValues("_id") !== null) {
      reset(state);
      //check if password is saved in storage
      const password = window.localStorage.getItem("orderOfferPassword");
      if (!password) {
        //redirect to page not found
        window.location.href = localUrlEnum.pageNotFound;
      }
      const requests = [
        getData(
          `${endpoints.getPublicProductsSearch}?password=${password}&orderId=${state._id}`
        ),
        getData(
          `${endpoints.getPublicTransportOptions}?password=${password}&orderId=${state._id}`
        ),
        getData(
          `${endpoints.getPublicPaymentMethods}?password=${password}&orderId=${state._id}`
        ),
      ];
      Promise.all(requests).then((res) => {
        setLocalState({
          products: res[0]?.data,
          transportOptions: res[1]?.data,
          paymentMethods: res[2]?.data,
        });
      });
    }
  }, [state]);

  /**
   *
   * @param id
   */
  const addNewProductForList = () => {
    const newOrderLine: any = new OrderLineModel();
    if (lineItems) {
      //AM FOLOSIT WATCH CA SA SE RERANDEZE COMPONENTA ProductPanel
      getValues("line_items")?.push(newOrderLine);
      setValue("line_items", lineItems);
    } else {
      setValue("line_items", [newOrderLine]);
    }
  };

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    //check if password is saved in storage
    const password = window.localStorage.getItem("orderOfferPassword");
    if (!password) {
      //redirect to page not found
      window.location.href = localUrlEnum.pageNotFound;
    }
    if (model?._id !== null && model?._id !== undefined && model?._id !== "") {
      const url = `${endpoints.orderOfferEndpoint}/order?password=${password}&orderId=${state._id}`;
      updateData(url, model).then((res: AxiosResponse) => {
        //remove password from local storage
        window.localStorage.removeItem("orderOfferPassword");
        //redirect to success page
        navigate(`${localUrlEnum.orderOfferConfirmation}/${res.data._id}`, {
          state: res.data,
        });
      });
    }
  };

  /**
   *
   * @param model
   */
  const onError = (model: any) => {
    if (model.billing) setExpanded(!expanded);
    // console.log(model);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      method="get"
      ref={ref}
      id={FormsIds.orderOfferForm}
      className={styles.mainContainer}
    >
      <div className={styles.header}>
        <a href="https://ecopackbusiness.ro/">
          <img
            className={styles.orderOfferHeaderImage}
            src={"/logo.png"}
            alt="EcoPack"
            width={150}
          />
        </a>
        <Button
          className={styles.saveButton}
          type="submit"
          id={FormsIds.orderOfferForm}
          variant="contained"
        >
          {Vocabulary.endOrder}
        </Button>
      </div>
      <div className={styles.mainContainerBox}>
        <ThemeProvider theme={orderOfferTheme}>
          <Grid container spacing={1}>
            {/* <Grid className={styles.title} item xs={12} sm={12} md={12}>
              <Typography className={styles.subTitles} variant="h6">
                {Vocabulary.orderMessage}
              </Typography>
            </Grid> */}
            {/**CLIENTI */}
            <div className={styles.containers}>
              {isMobile() ? (
                <Accordion expanded={expanded}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        onClick={() => setExpanded(!expanded)}
                        className={styles.expandButton}
                      />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className={styles.subTitles}>
                      {Vocabulary.invoiceDetails}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ClientPanelOrderOffer
                      register={register}
                      reset={reset}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      watch={watch}
                      clearErrors={clearErrors}
                      formState={formState}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Grid item xs={12} sm={12} md={12} style={{ margin: 7 }}>
                  <Typography className={styles.subTitles}>
                    {Vocabulary.invoiceDetails}
                  </Typography>
                  <ClientPanelOrderOffer
                    register={register}
                    reset={reset}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    watch={watch}
                    clearErrors={clearErrors}
                    formState={formState}
                  />
                </Grid>
              )}
              {/**INFORMATII TRANSPORT */}
              <Grid className={styles.grid} item xs={12} sm={12} md={12}>
                <OrderOfferCustomPanel
                  title={Vocabulary.anotherAddress}
                  error={
                    getValues("differentShippingInfo") !== null &&
                    getValues("differentShippingInfo") !== undefined
                      ? false
                      : true
                  }
                >
                  <ShippingPanel
                    register={register}
                    reset={reset}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    formState={formState}
                    error={
                      getValues("differentShippingInfo") !== null &&
                      getValues("differentShippingInfo") !== undefined
                        ? false
                        : true
                    }
                  />
                </OrderOfferCustomPanel>
              </Grid>
              {/**METODE DE PLATA */}
              <Grid className={styles.grid} item xs={12} sm={12} md={12}>
                <OrderOfferCustomPanel
                  title={Vocabulary.paymentMethods}
                  error={getValues("payment_method_title") ? false : true}
                >
                  <PaymentPanel
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    watch={watch}
                    error={getValues("payment_method_title") ? false : true}
                    paymentMethods={localState?.paymentMethods}
                  />
                </OrderOfferCustomPanel>
              </Grid>
            </div>
            <div className={styles.containers}>
              <Typography className={styles.subTitles}>
                {Vocabulary.yourOrder}
              </Typography>
              {/**PANOU PRODUSE */}
              <Grid item xs={12} sm={12} md={12} style={{ margin: 7 }}>
                {lineItems?.map((product: any, index: any) => (
                  <ProductPanelOrderOffer
                    key={index}
                    index={index}
                    register={register}
                    reset={reset}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    control={control}
                    products={localState?.products}
                    isOrderOffer={true}
                  />
                ))}
                <Button
                  onClick={(e: any) => addNewProductForList()}
                  variant="contained"
                >
                  {Vocabulary.addProducts}
                </Button>
              </Grid>
              {/**METODE TRANSPORT */}
              <Grid
                className={styles.grid}
                item
                xs={12}
                sm={12}
                md={12}
                style={{ margin: 7 }}
              >
                <OrderOfferCustomPanel
                  error={
                    getValues("shipping_total") !== null &&
                    getValues("shipping_total") !== undefined
                      ? false
                      : true
                  }
                  title={Vocabulary.transportMethods}
                >
                  <TransportMethods
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    disabled={true}
                    error={
                      getValues("shipping_total") !== null &&
                      getValues("shipping_total") !== undefined
                        ? false
                        : true
                    }
                    transportOptions={localState?.transportOptions}
                  />
                </OrderOfferCustomPanel>
              </Grid>
              {/**NOTA CLIENT */}
              <Grid
                className={styles.grid}
                item
                xs={12}
                sm={12}
                md={12}
                style={{ margin: 7 }}
              >
                <TextField
                  {...register(`customer_note`)}
                  name="customer_note"
                  id="customer_note"
                  label={Vocabulary.optionalOrderNote}
                  fullWidth
                  multiline
                  rows={4}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/**TOTALURI */}
              <Grid item xs={12} sm={12} md={12} style={{ margin: 7 }}>
                <TotalPanel
                  register={register}
                  reset={reset}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                  isOrderOffer={true}
                />
              </Grid>
            </div>
          </Grid>
        </ThemeProvider>
      </div>
      <OrderOfferFooter />
    </form>
  );
}

export const orderOfferTheme: any = (defaultTheme: any) =>
  createTheme({
    ...defaultTheme,
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#000",
            zIndex: 200,
            marginLeft: 3,
          },
        },
      },
    },
  });
