import React from "react";
import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { localUrlEnum } from "../Utils/UrlEnum";

const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
  const location = useLocation();
  const outlet: any = useOutlet();
  const additionalProp: any = outlet?.props?.children?.props?.children?.props;

  /**
   *
   * @returns
   */
  const isLoggedIn = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      return false;
    }
    return true;
  };

  /**
   *
   */
  const renderChildrenWithProps: any = () => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        // Clone the child element with additional props
        return React.cloneElement(child, additionalProp);
      }
      return child;
    });
  };

  return isLoggedIn() ? (
    renderChildrenWithProps()
  ) : (
    <Navigate
      replace={true}
      to={localUrlEnum.login}
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};

export default PrivateRoute;
