/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, createContext } from "react";
import genericStyles from "../../Styles/genericStyles.module.css";
import MUIDataTableCustomStyle from "../../Components/MUIDataTableCustomStyle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { localUrlEnum, endpoints } from "../../Utils/UrlEnum";
import { ProductModel } from "../../Utils/Models";
import parse from "html-react-parser";
import { ETableEditAction } from "../../Utils/Enums";
import { renderStockMessage } from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { TablesIds } from "../../Utils/Constants";
import useCustomMemo from "../../Hooks/useCustomMemo";

export const ProductsCRUDContext = createContext(new ProductModel());

export default function ProductsTable() {
  const [contextValue, setContextValue] = useState(new ProductModel());
  // const [triggerReload, setTriggerReload] = useState<undefined | boolean>(
  //   undefined
  // );
  const navigate = useNavigate();
  const memo = useCustomMemo();
  const defaultManagement = memo?.storage.defaultManagement || null;

  const productsColumns = [
    {
      label: `${Vocabulary.name}`,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.type}`,
      name: "type",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.pretOnSite}`,
      name: "price_html",
      options: {
        sort: false,
        customBodyRender: (description: any) => {
          return <div>{description ? parse(description) : "-"}</div>;
        },
      },
    },
    {
      label: `${Vocabulary.oblioPrice}`,
      name: "oblio_stock",
      options: {
        sort: false,
        customBodyRender: (oblio_stock: any) => {
          //search for standard stock
          const standardStock = oblio_stock?.find(
            (p: any) => p.management === defaultManagement?.management
          );
          return standardStock?.price
            ? parseFloat(standardStock?.price).toFixed(2)
            : "-";
        },
      },
    },
    {
      label: Vocabulary.stock,
      name: "stock_status",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return renderStockMessage(value);
        },
      },
    },
  ];

  // /**
  //  *
  //  * @param data
  //  */
  // const syncWordPressData = () => {
  //   setTriggerReload(triggerReload !== undefined ? !triggerReload : false);
  // };

  return (
    <div className={genericStyles.mainContainer}>
      <ProductsCRUDContext.Provider value={contextValue}>
        <MUIDataTableCustomStyle
          onEditAction={ETableEditAction.Redirect}
          // buttons={
          //   <Button
          //     onClick={() => syncWordPressData()}
          //     style={{ marginLeft: 10 }}
          //     variant="contained"
          //     startIcon={<CloudSyncIcon />}
          //   >
          //     {!isMobile() ? Vocabulary.wordpressSync : null}
          //   </Button>
          // }
          title={Vocabulary.products}
          hideAddButton={true}
          columns={productsColumns}
          CRUDEndPoint={endpoints.getProducts}
          id={TablesIds.products}
          CRUDContext={ProductsCRUDContext}
          setContextValue={setContextValue}
          hideOptions={true}
          rowClickURL={`${localUrlEnum.product}/`}
          onRowClick={(rowData: any) => {
            setContextValue(rowData);
            navigate(`${localUrlEnum.product}/${rowData._id}`);
          }}
          customGetDataTable={endpoints.syncWordPressData}
          // customTriggerReload={triggerReload}
        ></MUIDataTableCustomStyle>
      </ProductsCRUDContext.Provider>
    </div>
  );
}
