/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormsIds } from "../../Utils/Constants";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ObservationWidget from "../Widgets/ObservationWidget";
import { useContext } from "react";
import { RegisterWhatsAppPhoneNumberContext } from "./RegisterWhatsAppPhoneNumber";
import { postData } from "../../Services/postData";
import { endpoints } from "../../Utils/UrlEnum";
import Config from "../../Utils/Config";
import { PhoneNumberRegistrationSchema } from "../../Utils/Models";

export default function PhoneNumberRegistrationStep() {
  const phoneRegistrationContext = useContext(
    RegisterWhatsAppPhoneNumberContext
  );
  const helpLink = "https://www.facebook.com/business/help/757569725593362";
  const methods = useForm({
    defaultValues: {
      cc: "+40",
      phone_number: "",
      verified_name: "",
    },
    resolver: yupResolver(PhoneNumberRegistrationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   * @param data
   */
  const handleSubmit = (data: any) => {
    postData(endpoints.whatsApp.createPhoneNumber, data).then((response) => {
      if (response && response.data) {
        phoneRegistrationContext.handleNext();
      }
    });
  };

  /**
   *
   * @param error
   */
  const handleError = (error: any) => {
    // console.log(error);
  };

  return (
    <form
      id={FormsIds.phoneNumber}
      onSubmit={methods.handleSubmit(handleSubmit, handleError)}
    >
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ObservationWidget
            shouldRenderIcon={true}
            link={helpLink}
            text={`${Vocabulary.phoneNumbersDisplayNameHelperText}`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            {...methods.register("cc")}
            name="cc"
            id="cc"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
            fullWidth
            label={Vocabulary.countryCode}
            error={typeof methods.formState.errors.cc === "object"}
            helperText={methods.formState.errors.cc?.message?.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={4}>
          <TextField
            {...methods.register("phone_number")}
            fullWidth
            id="phone_number"
            name="phone_number"
            label={Vocabulary.phoneNumber}
            InputLabelProps={{
              shrink: true,
            }}
            error={typeof methods.formState.errors.phone_number === "object"}
            helperText={methods.formState.errors.phone_number?.message?.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={7}>
          <TextField
            {...methods.register("verified_name")}
            id="verified_name"
            name="verified_name"
            fullWidth
            label={Vocabulary.phoneNumbersDisplayName}
            InputLabelProps={{
              shrink: true,
            }}
            error={typeof methods.formState.errors.verified_name === "object"}
            helperText={methods.formState.errors.verified_name?.message?.toString()}
          />
        </Grid>
      </Grid>
    </form>
  );
}
