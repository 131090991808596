import Dropzone from "react-dropzone";
import { useState } from "react";
import { Paper } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { CloudUpload } from "@mui/icons-material";
import styles from "../Styles/dropzone.module.css";
import { DropzoneComponentProps } from "../Utils/Types";

export default function DropzoneComponent(props: DropzoneComponentProps) {
  const [path, setPath] = useState<any>(props.path);
  return (
    <Dropzone
      accept={props.acceptedFileTypes}
      onDrop={(acceptedFiles) => {
        acceptedFiles.forEach((file: any) => {
          props.onSave(file);
        });
        setPath(acceptedFiles.map((file) => URL.createObjectURL(file)));
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Paper>
          <div {...getRootProps()} className={styles.dropzoneArea}>
            <input {...getInputProps()} />
            <p>{Vocabulary.dragAndDrop}</p>
            <CloudUpload fontSize="large" />
            {path ? (
              <img
                width={200}
                key={path}
                src={path}
                alt={props.altText || Vocabulary.profilePicture}
              />
            ) : null}
            <p>{props?.error?.message}</p>
          </div>
        </Paper>
      )}
    </Dropzone>
  );
}
