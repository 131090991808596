import { Grid, Typography } from "@mui/material";
import styles from "../../Styles/orderOffer.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Email, Facebook, LocationOn, Phone } from "@mui/icons-material";

export default function OrderOfferFooter() {
  return (
    <Grid container spacing={0} className={styles.footer}>
      <Grid item xs={12} sm={12} md={3} className={styles.contactFooterGrid}>
        <a
          href="https://ecopackbusiness.ro/"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            style={{ filter: "brightness(0) invert(1)" }}
            src={"/logo.png"}
            alt="EcoPack"
            width={"45%"}
          />
        </a>
        <Typography className={styles.footerText}>
          {Vocabulary.footerText}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4} className={styles.contactFooterGrid}>
        <Typography className={styles.linkTextCategory}>
          {Vocabulary.pages.toUpperCase()}
        </Typography>
        <a
          className={styles.linkText}
          href="https://ecopackbusiness.ro/"
          data-brz-link-type="external"
        >
          {Vocabulary.home}
        </a>
        <a
          className={styles.linkText}
          href="https://ecopackbusiness.ro/contact/"
          data-brz-link-type="external"
        >
          {Vocabulary.contact}
        </a>
        <a
          className={styles.linkText}
          href="https://ecopackbusiness.ro/politica-cookies/"
          data-brz-link-type="external"
        >
          {Vocabulary.cookiePolicy}
        </a>
        <a
          className={styles.linkText}
          href="https://anpc.ro/"
          data-brz-link-type="external"
        >
          {Vocabulary.ANPC}
        </a>
      </Grid>
      <Grid item xs={12} sm={12} md={4} className={styles.contactFooterGrid}>
        <Typography className={styles.linkTextCategory}>
          {Vocabulary.contact.toUpperCase()}
        </Typography>
        <a
          className={styles.linkText}
          href="tel:0758 68 68 62"
          data-brz-link-type="external"
        >
          <Phone fontSize="large" className={styles.linkIcon} />
          0758 68 68 62
        </a>
        <a
          className={styles.linkText}
          href="mailto:pungidehartiesuceava@yahoo.com"
          data-brz-link-type="external"
        >
          <Email fontSize="large" className={styles.linkIcon} />
          pungidehartiesuceava@yahoo.com
        </a>
        <p className={styles.linkText}>
          <LocationOn fontSize="large" className={styles.linkIcon} />
          Str. Mircea Damaschin, nr. 12, Suceava
        </p>

        <a
          className={styles.linkText}
          href="https://www.facebook.com/PungiDeHartieSuceava"
          data-brz-link-type="external"
        >
          <Facebook fontSize="large" className={styles.linkIcon} />
        </a>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography style={{ color: "#bdbdbd" }} className={styles.footerText}>
          {Vocabulary.copyright}
        </Typography>
      </Grid>
    </Grid>
  );
}
