import moment from "moment";
import Config from "./Config";
import { SortOptions } from "./Constants";
import {
  EHeaderTemplateMenuOptions,
  ENoteType,
  EWhatsAppComponentsTypes,
  EWhatsAppMessageType,
  EWhatsAppTemplateButtonType,
  EWhatsAppTemplateCategory,
} from "./Enums";
import { Vocabulary } from "./Vocabulary";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
export class LoginModel {
  username: string | null = "";
  password: string | null = "";
  reCaptcha: string | null = "";
}

export class UserModel {
  _id: string | null = null;
  lastName: string | null = "";
  firstName: string | null = "";
  email: string | null = "";
  username: string | null = "";
  password: string | null = "";
  roleIds: RoleModel[] | null = [];
}

export class MessageContextModal {
  id: string | null = null;
  from: string | null = "";
}

export class MessageImage {
  id: string | null = null;
  mime_type: string | null = "";
  sha256: string | null = "";
}

export class DocumentModel {
  id: string | null = null;
  filename: string | null = "";
  mime_type: string | null = "";
  sha256: string | null = "";
  caption: string | null = "";
}

export class MessageModel {
  id: string | null = null;
  message: any = "";
  sender: string | null = "";
  timestamp: string | Date = "";
  type: string | null = "";
  image: MessageImage | null = new MessageImage();
  document: DocumentModel | null = new DocumentModel();
  isUser: boolean | null = false;
  status = "";
  context: MessageContextModal | null = new MessageContextModal();
}

export class OrderLineModel {
  id: number | null | string = uuidv4();
  name: string | null = "";
  discount: number | null = 0;
  discountValue: number | null = 0;
  product_id: number | null = null;
  wp_product_id: string | null = "";
  variation_id: number | null = null;
  quantity: number | null = 1;
  tax_class: string | null = "";
  subtotal: string | null = "";
  subtotal_tax: string | null = "";
  total: string | null = "";
  total_tax: string | null = "";
  taxes: any[] = [];
  meta_data: any[] = [];
  sku: string | null = "";
  price: string | null = "";
  priceWithoutTVA: string | null = "";
  TVA: string | null = "";
  lineChecked: boolean | null = false;
  hasBeenInvoiced: boolean | null = false;
  management: string | null = null;
  image: string | null = "";
  constructor() {
    this.id = uuidv4();
  }
}

export class TVA {
  _id: string | null = null;
  name: string | null = "";
  value: string | null = "";
}

export class NoteModel {
  _id: string | null = uuidv4();
  type = ENoteType.OTHERS;
  text = "";
  client: ClientModel | null = null;
  agent: UserModel | null | string = null;
  visibility = false;
  createdAt: string | null | Date = new Date();
}

export class OrderModel {
  _id: string | null = null;
  wordPress_Id: string | null = "";
  differentShippingInfo: boolean | null = false;
  parent_id: number | null = null;
  status: OrderStatusModel | null = null;
  currency: string | null = "";
  version: string | null = "";
  line_items: OrderLineModel[] = [];
  client: ClientModel | null = new ClientModel();
  client_id: string | null = "";
  prices_include_tax: boolean | null = null;
  date_created = "";
  date_modified: string | null = "";
  discount_total: string | null = "";
  discount_tax: string | null = "";
  shipping_total: string | null = "";
  shipping_method: string | null = "";
  shipping_tax: string | null = "";
  cart_tax: string | null = "";
  total: string | null = "";
  total_tax: string | null = "";
  customer_id: number | null = null;
  order_key: string | null = "";
  billing: BillingModel = new BillingModel();
  shipping: ShippingModel = new ShippingModel();
  payment_method: string | null = "";
  payment_method_title: string | null = Vocabulary.cash;
  transaction_id: string | null = "";
  customer_ip_address: string | null = "";
  customer_user_agent: string | null = "";
  created_via: string | null = "";
  customer_note: string | null = "";
  order_notes: NoteModel[] = [];
  date_completed: string | null = "";
  date_paid: string | null = "";
  cart_hash: string | null = "";
  number: string | null = "";
  meta_data: any[] = [];
  products: OrderLineModel[] = [];
  tax_lines: any[] = [];
  shipping_lines: any[] = [];
  fee_lines: any[] = [];
  coupon_lines: any[] = [];
  refunds: any[] = [];
  payment_url: string | null = "";
  is_editable: boolean | null = null;
  needs_payment: boolean | null = null;
  needs_processing: boolean | null = null;
  date_created_gmt: string | null = "";
  date_modified_gmt: string | null = "";
  date_completed_gmt: string | null = "";
  date_paid_gmt: string | null = "";
  currency_symbol: string | null = "";
  _links: object | null = {};
  set_paid: boolean | null = null;
  createdAt: Date | null = null;
  billingDocuments: OblioBillingDocument[] = [];
}

export class OblioBillingDocument {
  type = "";
  issueDate: string | null = "";
  seriesName = "";
  number = "";
  link: string | null = "";
  edit: string | null = "";
  issuerName: string | null = "";
  products: OblioBillingProduct[] = [];
  status = "";
  hasBeenBilled: boolean | null = null;
  referenceDocument: ReferenceDocumentModel | null =
    new ReferenceDocumentModel();
}

export class OblioBillingProduct {
  name: string | null = "";
  price: number | null = null;
  quantity: number | null = null;
  vatPercentage: number | null = null;
  vatIncluded: boolean | null = null;
  productType: string | null = "Marfa";
}

export class ShippingModel {
  first_name: string | null = "";
  last_name: string | null = "";
  company: string | null = "";
  address_1: string | null = "";
  address_2: string | null = "";
  city: string | null = "";
  state: string | null = "";
  postcode: string | null = "";
  country: string | null = "";
}

export class BillingModel {
  first_name: string | null = "";
  last_name: string | null = "";
  company: string | null = "";
  address_1: string | null = "";
  address_2: string | null = "";
  city: string | null = "";
  state: string | null = "";
  postcode: string | null = "";
  country: string | null = "";
  email: string | null = "";
  phone: string | null = "";
}

export class OrderStatusModel {
  _id: string | null = null;
  name: string | null = "";
  slug: string | null = "";
  isDefault = false;
  color = "#FFF";
  sortNumber: number | null = null;
}

export class OrderSettingsModel {
  _id: string | null = null;
  name: string | null = "";
  translation: string | null = "";
  value: OrderValue | null = {
    currentOrderNumber: 0,
    paymentMethods: [],
    startOrderNumber: 0,
  };
}

export class TransportSettingsModel {
  isDefault: boolean | null = false;
  name: string | null = "";
  value: string | null = "";
}

export class OrderValue {
  currentOrderNumber: number | null = 0;
  paymentMethods: string[] | null = [];
  startOrderNumber: number | null = 0;
}

export class ProductDimensionsModel {
  length = "";
  width = "";
  height = "";
}

export class ProductModel {
  _id: string | null = null;
  wordPress_Id: string | null = null;
  name: string | null = "";
  slug: string | null = "";
  permalink = "";
  date_created: string | null = "";
  date_created_gmt: string | null = "";
  date_modified: string | null = "";
  date_modified_gmt: string | null = "";
  type: string | null = "";
  status: string | null = "";
  featured: boolean | null = null;
  catalog_visibility: string | null = "";
  description = "";
  short_description = "";
  sku: string | null = "";
  price = "";
  regular_price: string | null = "";
  sale_price: string | null = "";
  date_on_sale_from: string | null = "";
  date_on_sale_from_gmt: string | null = "";
  date_on_sale_to: string | null = "";
  date_on_sale_to_gmt: string | null = "";
  on_sale: boolean | null = null;
  purchasable: boolean | null = null;
  total_sales: number | null = null;
  virtual: boolean | null = null;
  downloadable: boolean | null = null;
  downloads: any[] | null = [];
  download_limit: number | null = null;
  download_expiry: number | null = null;
  external_url: string | null = "";
  button_text: string | null = "";
  tax_status: string | null = "";
  manage_stock: boolean | null = null;
  stock_quantity: string | null = "";
  backorders: string | null = "";
  backorders_allowed: boolean | null = null;
  backordered: boolean | null = null;
  low_stock_amount: string | null = "";
  sold_individually: boolean | null = null;
  weight: string | null = "";
  dimensions: ProductDimensionsModel | null = new ProductDimensionsModel();
  shipping_required: boolean | null = null;
  shipping_taxable: boolean | null = null;
  shipping_class: string | null = "";
  shipping_class_id: string | null = "";
  reviews_allowed: boolean | null = null;
  average_rating: string | null = "";
  rating_count: number | null = null;
  upsell_ids: any[] | null = [];
  cross_sell_ids: any[] | null = [];
  parent_id: string | null = "";
  purchase_note: string | null = "";
  categories: any[] | null = [];
  tags: any[] | null = [];
  images: any[] | null = [];
  attributes: any[] | null = [];
  default_attributes: any[] | null = [];
  variations: any[] | null = [];
  grouped_products: any[] | null = [];
  menu_order: number | null = null;
  price_html = "";
  related_ids: any[] | null = [];
  meta_data: any[] | null = [];
  stock_status: string | null = "";
  has_options: boolean | null = null;
  post_password: string | null = "";
  _links: any[] | null = [];
}

export class OrderOfferStateModel {
  products: ProductModel[] = [];
  transportOptions: TransportSettingsModel[] = [];
  paymentMethods: string[] = [];
}

export class ClientModel {
  _id: string | null = null;
  name: string | null = "";
  category: string[] | null = [];
  periodicity: PeriodicityModel[] | null = [];
  fiscalCode: string | null = "";
  tradeRegisterNo: string | null = "";
  observations: string | null = "";
  address: AddressModel | null = {
    city: "",
    county: "",
    country: "",
    details: "",
    postcode: " ",
  };
  bank: string | null = "";
  iban: string | null = "";
  contactPerson: ContactPersonModel | null = {
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  };
  email: string | null = "";
  phone: string[] = [];
  notes: NoteModel[] = [];
}

export class LineItem {
  id: string | null = null;
  name: string | null = null;
  product_id: string | null = null;
  variation_id: string | null = null;
  quantity: string | null = null;
  tax_class: string | null = null;
  subtotal: string | null = null;
  subtotal_tax: string | null = null;
  total: string | null = null;
  total_tax: string | null = null;
  taxes: string[] = [];
  meta_data: string[] = [];
  sku: string | null = null;
  price: string | null = null;
  management: string | null = null;
}

export class ContactPersonModel {
  name: string | null = "";
  firstName: string | null = "";
  lastName: string | null = "";
  email: string | null = "";
  phone: string | null = "";
}

export class PeriodicityModel {
  name: string | null = "";
  nextContactDate: string | Date | null = "";
  noDays: number | null = null;
  week: number | null = null;
  day: number | null = null;
}

export class AddressModel {
  city: string | null = "";
  county: string | null = "";
  country: string | null = "";
  details: string | null = "";
  postcode: string | null = "";
}

export class RoleModel {
  _id = null;
  name: string | null = "";
  priority: number | null = null;
  createdAt: string | null = "";
  updatedAt: string | null = "";
}

export class Attachment {
  filename: string | null = "";
  path: string | null = "";
  cid: string | null = "";
  alt: string | null = "";
}
export class EmailTemplateModel {
  _id: string | null = null;
  emailTemplateName: string | null = "";
  html: any = "";
  statusId: string | null = null;
  subject: string | null = "";
  cc: string | null = "";
  attachments: Attachment[] = [];
}

export class ContactModel {
  _id: string | null = null;
  conversation: any;
  contactedViaEmail = false;
  contactedViaPhone = false;
  agent: UserModel | null = null;
  order: OrderModel | null = null;
  client: ClientModel = new ClientModel();
  observations: string | null = null;
  notes: NoteModel[] = [];
  status: string | null = null;
  createdAt: string | null = null;
  updatedAt: string | null = null;
}

export class TodaysContactsState {
  noTotalResults: number | null = 0;
  noTakenContacts = 0;
  noAvailableContacts = 0;
  noDelayedContacts = 0;
}

export class ConversationModel {
  _id: string | null = null;
  client: ClientModel | null = null;
  wa_id: string | null = null;
  potentialClientName: string | null = null;
  messages: MessageModel[] = [];
  lastRead: string | null = null;
  createdAt: string | null = null;
  updatedAt: string | null = null;
  deletedAt: string | null = null;
}

export class ChatState {
  chat: ConversationModel | null = null;
  messages: MessageModel[] = [];
  page = 0;
  templates: any[] = [];
}

export class InvoiceModel {
  cif: string | null = "";
  client: OblioClientModel | null = new OblioClientModel();
  issueDate: string | null = "";
  dueDate: string | null = "";
  seriesName: string | null = "";
  number: number | null = null;
  language: string | null = null;
  precision: number | null = null;
  currency: string | null = null;
  exchangeRate: number | null = null;
  products: OblioProductModel[] = [];
  issuerName: string | null = "";
  issuerId: string | null = "";
  noticeNumber: string | null = "";
  internalNote: string | null = "";
  deputyName: string | null = "";
  deputyIdentityCard: string | null = "";
  deputyAuto: boolean | null = false;
  selesAgent: string | null = "";
  mentions: string | null = "";
  sendEmail: boolean | null = false;
  workStation: string | null = "";
  disableAutoSeries: boolean | null = false;
  deliveryDate: string | null = "";
  collectDate: string | null = "";
  referenceDocument: ReferenceDocumentModel | null =
    new ReferenceDocumentModel();
  collect: CollectModel | null = new CollectModel();
  useStock: number | null = null;
}

export class OblioProductModel {
  name: string | null = "";
  code: string | null = "";
  description: string | null = "";
  price: number | null = null;
  measuringUnit: string | null = "";
  currency: string | null = "";
  exchangeRate: number | null = null;
  vatName: string | null = "";
  vatPercentage: number | null = null;
  vatIncluded: boolean | null = null;
  quantity: number | null = null;
  management: string | null = "";
  productType: string | null = "";
  nameTranslation: string | null = "";
  measuringUnitTranslation: string | null = "";
  save: boolean | null = null;
}

export class OblioClientModel {
  cif: string | null = "";
  name: string | null = "";
  rc: string | null = "";
  code: string | null = "";
  address: string | null = "";
  state: string | null = "";
  city: string | null = "";
  country: string | null = "";
  iban: string | null = "";
  bank: string | null = "";
  email: string | null = "";
  phone: string | null = "";
  contact: string | null = "";
  vatPayer: boolean | null = null;
  save: boolean | null = null;
  autocomplete: boolean | null = null;
}

export class ReferenceDocumentModel {
  type: string | null = "";
  number: number | null = null;
  seriesName: string | null = "";
  refund: boolean | null = null;
}

export class CollectModel {
  type: string | null = "";
  seriesName: string | null = "";
  documentNumber: number | null = null;
  value: number | null = null;
  issueDate: string | null = "";
  mentions: string | null = "";
}

export class WhatsAppBusinessAccountData {
  _id: string | null = null;
  name: string | null = "";
  whatsAppBusinessAccountId: string | null = "";
  phoneNumberId: string | null = "";
  phoneNumber: string | null = "";
  verifiedName: string | null = "";
  isPhoneNumberVerified: boolean | null = false;
}

export class OrderStatusState {
  orderStatusPerPage: any[] = [];
  orderStatusNumber: number | undefined = 0;
  page = 0;
  perPage: number | undefined = Config.rowsPerPage;
  search: string | null = null;
  order: string | null = SortOptions.asc;
  filter: string | null = null;
  preview: boolean | null = false;
  selectedRequest: any = {};
  loading: boolean | null = false;
}

export class WhatsAppTemplateState {
  templates: any[] = [];
  limit: number | null = Config.rowsPerPage;
  before: string | null = null;
  after: string | null = null;
  count: number | undefined = 0;
  page = 0;
  perPage: number | undefined = Config.rowsPerPage;
}

export class IssueBillingDocument {
  invoiceModel: InvoiceModel | null = null;
  checkedLinesIds: string[] = [];
  order: OrderModel | null = null;

  constructor(
    _model: InvoiceModel,
    _checkedLinesIds: string[],
    _order: OrderModel
  ) {
    this.invoiceModel = _model;
    this.checkedLinesIds = _checkedLinesIds;
    this.order = _order;
  }
}

export class RegisterWhatsAppPhoneNumberContextMode {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleNext: any = () => {};
  activeStep: number | null = 0;
}

export class LogModel {
  userName = "";
  method = "";
  table = "";
  createdAt: string | null = "";
  updatedAt: string | null = "";
  differences: any;
  object: any;
  database = "";
}

export const BillingValidationSchema = yup.object().shape({
  address_1: yup.string().nullable(),
  address_2: yup.string().nullable(),
  city: yup.string().nullable(),
  company: yup.string().nullable(),
  country: yup.string().nullable(),
  email: yup.string().nullable(),
  first_name: yup.string().nullable(),
  last_name: yup.string().nullable(),
  phone: yup.string().nullable().matches(Config.phoneRegExp, {
    message: Vocabulary.phoneValidation,
    excludeEmptyString: true,
  }),
  postcode: yup.string().nullable(),
  state: yup.string().nullable(),
});

export const AddressValidationSchema = yup.object().shape({
  city: yup.string().nullable(),
  county: yup.mixed().nullable(),
  country: yup.string().nullable(),
  details: yup.string().nullable(),
  postcode: yup.string().nullable(),
});

export const ContactPersonValidationSchema = yup
  .object()
  .shape({
    email: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    name: yup.string().nullable(),
    phone: yup.string(),
  })
  .nullable();

export const OrderClientValidationSchema = yup
  .object()
  .shape({
    address: AddressValidationSchema,
    category: yup.array().nullable(),
    contactPerson: ContactPersonValidationSchema,
    fiscalCode: yup.string().nullable(),
    iban: yup.string().nullable(),
    name: yup.string().nullable(),
    periodicity: yup.array(),
    phone: yup.array(),
  })
  .nullable();

export const LineItemValidationSchema = yup.object().shape({
  TVA: yup.number(),
  name: yup.string().required(Vocabulary.requiredField),
  price: yup.string(),
  priceWithoutTVA: yup.number(),
  product_id: yup.mixed().nullable(),
  quantity: yup.number(),
  subtotal: yup.string(),
  discount: yup.number(),
  discountValue: yup.string(),
  subtotal_tax: yup.string(),
  total: yup.number(),
  total_tax: yup.string(),
  wp_product_id: yup.string(),
  unitTVA: yup.string(),
  hasBeenInvoiced: yup.boolean().nullable(),
  lineChecked: yup.boolean().nullable(),
  management: yup.string().nullable(),
});

export const OrderNoteValidationSchema = yup.object().shape({
  agent: yup.mixed().nullable(),
  client: yup.mixed().nullable(),
  text: yup.string().nullable(),
  type: yup.string(),
  visibility: yup.string().nullable(),
});

export const ShippingValidationSchema = yup
  .object()
  .shape({
    address_1: yup.string().nullable(),
    address_2: yup.string().nullable(),
    city: yup.string().nullable(),
    company: yup.string().nullable(),
    country: yup.string().nullable(),
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    postcode: yup.string().nullable(),
    state: yup.string().nullable(),
  })
  .nullable();

export const ShippingMethodValidationSchema = yup
  .object()
  .shape({
    name: yup.string(),
    value: yup.number(),
    isDefault: yup.boolean(),
  })
  .nullable()
  .default(null);

export const BillingDocumentValidationSchema = yup
  .object()
  .shape({
    type: yup.string().nullable(),
    issueDate: yup.string().nullable(),
    seriesName: yup.string().nullable(),
    number: yup.string().nullable(),
    link: yup.string().nullable(),
    edit: yup.string().nullable(),
    issuerName: yup.string().nullable(),
  })
  .nullable();

export const OrderValidationSchema = yup.object().shape({
  billing: BillingValidationSchema,
  cart_tax: yup.string(),
  client_id: OrderClientValidationSchema,
  coupon_lines: yup.array(),
  currency: yup.string(),
  customer_note: yup.string().nullable(),
  currency_symbol: yup.string(),
  differentShippingInfo: yup.boolean().default(undefined).nullable(),
  discount_total: yup.string(),
  discount_tax: yup.string(),
  fee_lines: yup.array(),
  line_items: yup
    .array()
    .of(LineItemValidationSchema)
    .typeError(Vocabulary.requiredField),
  meta_data: yup.array(),
  number: yup.string().nullable(),
  payment_method_title: yup.string().required(Vocabulary.requiredField),
  order_notes: yup.array().of(OrderNoteValidationSchema),
  refunds: yup.array(),
  shipping: ShippingValidationSchema,
  shipping_lines: yup.array(),
  shipping_total: yup.string(),
  shipping_tax: yup.string(),
  shipping_method: ShippingMethodValidationSchema,
  status: yup.mixed().nullable(),
  tax_lines: yup.mixed(),
  total_tax: yup.string(),
  total: yup.string(),
  wordPress_Id: yup.string().nullable(),
  _id: yup.string().nullable(),
  date_created: yup.string().nullable(),
  createdAt: yup.string().nullable(),
  date_modified: yup.string().nullable(),
  updatedAt: yup.string().nullable(),
  invoiceAllProducts: yup.boolean().default(false),
  billingDocuments: yup.array().of(BillingDocumentValidationSchema).nullable(),
});

export const OblioSettingsValidationSchema = yup.object().shape({
  _id: yup.string(),
  value: yup.object().shape({
    ignoredIds: yup.string().required(Vocabulary.required),
    defaultManagement: yup
      .object()
      .shape({
        id: yup.string().required(Vocabulary.required),
        management: yup.string().required(Vocabulary.required),
        managementType: yup.string().required(Vocabulary.required),
        workStation: yup.string().required(Vocabulary.required),
      })
      .required(Vocabulary.required),
  }),
});

export const ContactSettingsValidationSchema = yup.object().shape({
  _id: yup.string(),
  value: yup.object().shape({
    shouldRenderOldContacts: yup.boolean().default(false),
    contactDeletionThreshold: yup.number().nullable().default(30),
  }),
});

export const ClientSettingsValidationSchema = yup.object().shape({
  _id: yup.string(),
  value: yup.object().shape({
    shouldSendEmailToAllClients: yup
      .boolean()
      .default(false)
      .required(Vocabulary.requiredField),
  }),
});

export const MessageTextValidationSchema = yup
  .object()
  .shape({
    body: yup.string().required(Vocabulary.requiredField),
    preview_url: yup
      .boolean()
      .required(Vocabulary.requiredField)
      .default(false),
  })
  .required(Vocabulary.requiredField);

export const MessageTemplateValidationSchema = yup
  .object()
  .when("type", (type: any, schema: any) => {
    return type[0] === EWhatsAppMessageType.TEMPLATE
      ? schema
          .shape({
            name: yup.string().required(Vocabulary.requiredField),
            language: yup.object().shape({
              code: yup.string().required(Vocabulary.requiredField),
            }),
          })
          .required(Vocabulary.requiredField)
      : schema.nullable().notRequired();
  });

export const MessageComponentsValidationSchema = yup
  .array()
  .when("type", (type: any, schema: any) => {
    return type[0] === EWhatsAppMessageType.TEMPLATE
      ? schema
          .of(
            yup.object().shape({
              type: yup.string().required(Vocabulary.requiredField),
              parameters: yup.array().required(Vocabulary.requiredField),
            })
          )
          .required(Vocabulary.requiredField)
      : schema.nullable().notRequired();
  });

export const MessageValidationSchema = yup
  .object()
  .shape({
    messaging_product: yup
      .string()
      .required(Vocabulary.requiredField)
      .default("whatsapp"),
    recipient_type: yup
      .string()
      .required(Vocabulary.requiredField)
      .default("individual"),
    to: yup.string().required(Vocabulary.requiredField),
    type: yup
      .string()
      .required(Vocabulary.requiredField)
      .default(EWhatsAppMessageType.TEXT),
    text: MessageTextValidationSchema,
    context: yup.object().shape({
      message_id: yup.string().nullable(),
    }),
    template: MessageTemplateValidationSchema,
    components: MessageComponentsValidationSchema,
  })
  .required(Vocabulary.requiredField);

export const MediaMessageValidationSchema = yup.object().shape({
  file: yup.mixed().required(Vocabulary.requiredField),
  caption: yup.string().nullable(),
});

export const NoteValidationSchema = yup.object().shape({
  _id: yup.string().nullable(),
  type: yup.string(),
  client: yup.mixed().nullable(),
  agent: yup.mixed().nullable(),
  text: yup.string().required(Vocabulary.requiredField),
  visibility: yup.boolean().default(false),
  details: yup.string(),
});

export const ClientValidationSchema = yup.object().shape({
  _id: yup.string().nullable(),
  name: yup.string().required(Vocabulary.requiredField),
  email: yup.string().email(Vocabulary.emailValidation),
  phone: yup.array().of(
    yup
      .string()
      .required(Vocabulary.requiredField)
      .matches(/\(?([0-9]{2})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/, "")
  ),
  isLegalPerson: yup.boolean().required(Vocabulary.requiredField),
  CNP: yup.string().nullable(),
  fiscalCode: yup
    .string()
    .when("isLegalPerson", (isLegalPerson: any, schema: any) => {
      if (isLegalPerson[0]) {
        return schema.required(Vocabulary.requiredField);
      }
      return schema.nullable().notRequired();
    }),
  tradeRegisterNo: yup.string().nullable(),
  category: yup.array(),
  periodicity: yup.mixed(),
  address: AddressValidationSchema,
  bank: yup.string(),
  iban: yup.string().nullable(),
  observations: yup.string(),
  contactPerson: ContactPersonValidationSchema,
  receiveEmails: yup.boolean().default(true),
  notes: yup.array().of(NoteValidationSchema),
});

export const ClientsAutocompleteValidationSchema = yup
  .object()
  .shape({
    clientId: yup.string().required(Vocabulary.requiredField),
  })
  .required();
export const ContactEmailValidationSchema = yup.object().shape({
  emailTemplate: yup.mixed().required(Vocabulary.requiredField),
  emailContent: yup.string().required(Vocabulary.requiredField),
});

export const FiltersValidationSchema = yup.object().shape({
  start: yup.string().required(Vocabulary.requiredField),
  end: yup.string().required(Vocabulary.requiredField),
});

export const OrderSettingsFormValidationSchema = yup.object().shape({
  _id: yup.string(),
  value: yup.object().shape({
    startOrderNumber: yup.number(),
    currentOrderNumber: yup.number(),
    paymentMethods: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        shouldDelete: yup.boolean(),
      })
    ),
  }),
});

export const TransportSettingsFormValidationSchema = yup.object().shape({
  transportsMethods: yup.array().of(
    yup.object().shape({
      name: yup.string().required(Vocabulary.requiredField),
      value: yup.string().required(Vocabulary.requiredField),
      isDefault: yup.boolean(),
      showEdit: yup.boolean(),
      confirmDelete: yup.boolean(),
    })
  ),
});

export const WhatsAppSettingsValidationSchema = yup.object().shape({
  _id: yup.string().required(Vocabulary.requiredField),
  whatsAppBusinessAccountId: yup.string().required(Vocabulary.requiredField),
  name: yup.string().required(Vocabulary.requiredField),
  phoneNumberId: yup.string().nullable(),
  phoneNumber: yup.string().nullable(),
  verifiedName: yup.string().nullable(),
  whatsAppTemplateAssociatedWithOrderOffer: yup.mixed().nullable(),
});

export const PhoneNumberRegistrationSchema = yup.object().shape({
  cc: yup.string().required(Vocabulary.requiredField).default("+40"),
  phone_number: yup.string().required(Vocabulary.requiredField),
  verified_name: yup.string().required(Vocabulary.requiredField),
});

export const TwoFactorAuthenticationSetupSchema = yup.object().shape({
  pin: yup.string().required(Vocabulary.requiredField),
});

export const VerifyCodeConfirmationSchema = yup.object().shape({
  code: yup.string().required(Vocabulary.requiredField),
});

export const UserFormValidationSchema = yup.object().shape({
  _id: yup.string().nullable(),
  lastName: yup.string().required(Vocabulary.requiredField),
  firstName: yup.string().required(Vocabulary.requiredField),
  username: yup.string().required(Vocabulary.requiredField),
  email: yup
    .string()
    .email(Vocabulary.emailValidation)
    .required(Vocabulary.requiredField),
  password: yup.string().when(["_id"], (_id: any, schema: any) => {
    return _id[0] !== null && _id[0] !== undefined
      ? schema.nullable().notRequired()
      : schema.required(Vocabulary.requiredField);
  }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), " "], Vocabulary.confirmMessage)
    .when(["_id"], (_id: any, schema: any) => {
      return _id[0] !== null && _id[0] !== undefined
        ? schema.nullable().notRequired()
        : schema.required(Vocabulary.requiredField);
    }),
  roleIds: yup
    .array()
    .required(Vocabulary.requiredField)
    .min(1, Vocabulary.requiredField),
});

export const WhatsAppTemplateValidationSchema: any = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(Vocabulary.requiredField),
  category: yup.string().required().default(EWhatsAppTemplateCategory.UTILITY),
  allow_category_change: yup.boolean().nullable().default(true),
  language: yup.string().default("ro").required(Vocabulary.requiredField),
  components: yup.array().of(
    yup.object().shape({
      type: yup.string().required(Vocabulary.requiredField),
      format: yup.string().when("type", (type: any, schema: any) => {
        return type[0] === EWhatsAppComponentsTypes.HEADER
          ? schema.required(Vocabulary.requiredField)
          : schema.nullable().notRequired();
      }),
      text: yup.string().when(["type", "format"], (data: any, schema: any) => {
        if (data[0] === EWhatsAppComponentsTypes.BODY)
          return schema.required(Vocabulary.requiredField);
        else if (
          data.length > 1 &&
          data[0] === EWhatsAppComponentsTypes.HEADER &&
          data[1] === "TEXT"
        )
          return schema.required(Vocabulary.requiredField);
        return schema.nullable().notRequired();
      }),
      example: yup
        .object()
        .when(["type", "text", "format"], (data: any, schema: any) => {
          if (data[0] === EWhatsAppComponentsTypes.HEADER) {
            switch (data[2]) {
              case EHeaderTemplateMenuOptions.TEXT:
                return schema.shape({
                  header_text: yup
                    .array()
                    .of(yup.string().required(Vocabulary.requiredField)),
                });
              case EHeaderTemplateMenuOptions.IMAGE:
              case EHeaderTemplateMenuOptions.VIDEO:
              case EHeaderTemplateMenuOptions.DOCUMENT:
                return schema.shape({
                  header_handle: yup
                    .array()
                    .of(yup.string().required(Vocabulary.requiredField)),
                });
              default:
                return schema.nullable().notRequired();
            }
          } else if (data[0] === EWhatsAppComponentsTypes.BODY) {
            //check if data[1] contains tags like {{nr}} (regex)
            const regex = /{{\w+}}/g;
            const found = data[1].match(regex);
            if (found)
              return schema.shape({
                body_text: yup
                  .array()
                  .of(
                    yup
                      .array()
                      .of(yup.string().required(Vocabulary.requiredField))
                  ),
              });
          }
          return schema.nullable().notRequired();
        }),
      buttons: yup.array().when("type", (type: any, schema: any) => {
        return type[0] === EWhatsAppComponentsTypes.BUTTONS
          ? schema
              .of(
                yup
                  .object()
                  .shape({
                    type: yup.string().required(Vocabulary.requiredField),
                    text: yup.string().required(Vocabulary.requiredField),
                    phone_number: yup
                      .string()
                      .when("type", (type: any, schema: any) => {
                        return type === EWhatsAppTemplateButtonType.PHONE_NUMBER
                          ? schema.required(Vocabulary.requiredField)
                          : schema.nullable().notRequired();
                      }),
                    url: yup.string().when("type", (type: any, schema: any) => {
                      return type === EWhatsAppTemplateButtonType.URL
                        ? schema.required(Vocabulary.requiredField)
                        : schema.nullable().notRequired();
                    }),
                  })
                  .required(Vocabulary.requiredField)
              )
              .required(Vocabulary.requiredField)
          : schema.nullable().notRequired();
      }),
    })
  ),
});

export const CollectInvoiceValidationSchema = yup
  .object()
  .shape({
    type: yup.string().nullable(),
    seriesName: yup.string().nullable(),
    documentNumber: yup.number().nullable(),
    value: yup.number().nullable(),
    issueDate: yup.string().nullable(),
    mentions: yup.string().nullable(),
  })
  .nullable();

export const InvoicePanelValidationSchema = yup.object().shape({
  type: yup.string().nullable(),
  issueDate: yup
    .string()
    .required(Vocabulary.requiredField)
    .default(moment().format(Config.momentUSDateFormat)),
  dueDate: yup.string().nullable(),
  seriesName: yup.string().nullable(),
  number: yup.string().nullable(),
  link: yup.string().nullable(),
  products: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable(),
      price: yup.number().nullable(),
      quantity: yup.number().nullable(),
      vatPercentage: yup.number().nullable(),
      vatIncluded: yup.number().nullable(),
      productType: yup.string().nullable().default(Vocabulary.goods),
      management: yup.string().nullable(),
      measuringUnit: yup.string().nullable(),
      discount: yup.string().nullable(),
      discountType: yup.string().nullable(),
    })
  ),
  client: yup.object().shape({
    cif: yup.string().nullable(),
    name: yup.string().required(Vocabulary.requiredField),
    rc: yup.string().nullable(),
    address: yup.string().nullable(),
    state: yup.string().nullable(),
    code: yup.string().nullable(),
    city: yup.string().nullable(),
    country: yup.string().nullable(),
    phone: yup.string().nullable(),
    email: yup.string().nullable(),
    iban: yup.string().nullable(),
    bank: yup.string().nullable(),
    contact: yup.string().nullable(),
  }),
  issuerName: yup.string().required(Vocabulary.requiredField),
  issuerId: yup.string().nullable(),
  noticeNumber: yup.string().nullable(),
  internalNote: yup.string().nullable(),
  deputyName: yup.string().nullable(),
  deputyIdentityCard: yup.string().nullable(),
  selesAgent: yup.string().nullable(),
  mentions: yup.string().nullable(),
  referenceDocument: yup
    .object()
    .shape({
      type: yup.string().nullable(),
      seriesName: yup.string().nullable(),
      number: yup.string().nullable(),
      refund: yup.number().nullable(),
    })
    .nullable(),
  collect: CollectInvoiceValidationSchema,
  workStation: yup.string().nullable(),
});

export const OrderOfferFormValidationSchema = yup.object().shape({
  billing: yup.object().shape(
    {
      address_1: yup.string().nullable(),
      city: yup.string().required(Vocabulary.requiredField),
      company: yup
        .string()
        .when(["first_name", "last_name"], (data: any, schema: any) => {
          return !data[0] && !data[1]
            ? schema.required(Vocabulary.requiredField)
            : schema;
        }),
      first_name: yup.string().when(["company"], (data: any, schema: any) => {
        return !data[0] ? schema.required(Vocabulary.requiredField) : schema;
      }),
      last_name: yup.string().when(["company"], (data: any, schema: any) => {
        return !data[0] ? schema.required(Vocabulary.requiredField) : schema;
      }),
      country: yup
        .string()
        .default(Vocabulary.romania)
        .required(Vocabulary.requiredField),
      email: yup
        .string()
        .email(Vocabulary.invalidEmail)
        .required(Vocabulary.requiredField),
      phone: yup
        .string()
        .matches(Config.phoneRegExp, Vocabulary.phoneValidation)
        .required(Vocabulary.requiredField),
      state: yup.string().required(Vocabulary.requiredField),
    },
    [
      ["first_name", "last_name"],
      ["last_name", "company"],
      ["first_name", "company"],
    ]
  ),
  cart_tax: yup.string(),
  client_id: OrderClientValidationSchema,
  coupon_lines: yup.array(),
  currency: yup.string(),
  customer_note: yup.string().nullable(),
  currency_symbol: yup.string(),
  differentShippingInfo: yup
    .boolean()
    .default(false)
    .required(Vocabulary.requiredField),
  discount_total: yup.string(),
  discount_tax: yup.string(),
  fee_lines: yup.array(),
  line_items: yup
    .array()
    .of(LineItemValidationSchema)
    .typeError(Vocabulary.requiredField),
  meta_data: yup.array(),
  number: yup.string().nullable(),
  payment_method_title: yup
    .string()
    .default(undefined)
    .required(Vocabulary.requiredField),
  order_notes: yup.array().of(OrderNoteValidationSchema),
  refunds: yup.array(),
  shipping: yup.object().shape({
    address_1: yup.string(),
    address_2: yup.string(),
    city: yup.string().required(Vocabulary.requiredField),
    company: yup.string().nullable(),
    country: yup.string().default(Vocabulary.romania).nullable(),
    first_name: yup.string().nullable(),
    last_name: yup.string().nullable(),
    postcode: yup.string().nullable(),
    state: yup.string().nullable(),
  }),
  shipping_lines: yup.array(),
  shipping_total: yup.string(),
  shipping_tax: yup.string(),
  shipping_method: ShippingMethodValidationSchema,
  status: yup.mixed().nullable(),
  tax_lines: yup.mixed(),
  total_tax: yup.string(),
  total: yup.string(),
  wordPress_Id: yup.string().nullable(),
  _id: yup.string().nullable(),
  date_created: yup.string().nullable(),
  createdAt: yup.string().nullable(),
  date_modified: yup.string().nullable(),
  updatedAt: yup.string().nullable(),
  invoiceAllProducts: yup.boolean().default(false),
  billingDocuments: yup.array().of(BillingDocumentValidationSchema),
});
