import { Chip, Divider, Paper, Typography } from "@mui/material";
import { WhatsAppTemplateComponentContainerProps } from "../../Utils/Types";
import { Vocabulary } from "../../Utils/Vocabulary";

export default function WhatsAppTemplateComponentContainer(
  props: WhatsAppTemplateComponentContainerProps
) {
  return (
    <Paper sx={{ padding: "20px", marginBottom: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="h5"> {props.name}</Typography>{" "}
          {props.isOptional ? (
            <Chip sx={{ marginLeft: "5px" }} label={Vocabulary.optional} />
          ) : null}
        </div>
        {props?.otherHeaderComponents
          ? props?.otherHeaderComponents
          : null}
      </div>

      <Divider sx={{ margin: "20px 0" }} />
      {props.children}
    </Paper>
  );
}
