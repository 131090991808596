import { AxiosResponse } from "axios";
import { getData } from "../../Services/getData";
import CustomInfiniteScrollList from "../CustomInfiniteScrollList";
import ContactWidget from "./ContactWidget";
import { useContext, useEffect, useState } from "react";
import useQueryStringParser from "../../Hooks/useQueryStringParser";
import { WebSocketContext } from "../../Context/WebSocketContext";
import { ContactsListProps } from "../../Utils/Types";
import styles from "../../Styles/dashboardContacts.module.css";
export default function ContactsList(props: ContactsListProps) {
  const { shouldUpdate, setShouldUpdate, url, websocketEventName } = props;
  const [contacts, setContacts] = useState([]);
  const websocket = useContext(WebSocketContext);

  /**
   *
   */
  useEffect(() => {
    if (shouldUpdate) {
      getContacts();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  /**
   *
   */
  websocket.socket.on(`${websocketEventName}`, (data: any) => {
    if (data && data[websocketEventName]) {
      setContacts(data[websocketEventName]);
    }
  });

  /**
   *
   */
  useQueryStringParser((data: any) => {
    if (data) getContacts(data.search);
  });

  /**
   *
   * @param search
   */
  const getContacts = (search?: string) => {
    getData(`${url}/${search ? search : ""}`).then((res: AxiosResponse) => {
      if (res && res.data) {
        setContacts(res.data);
      }
    });
  };

  return (
    <CustomInfiniteScrollList
      id={contacts.length}
      style={styles.customContactScrollList}
      items={contacts}
      render={(contact, index) => {
        return <ContactWidget contact={contact} key={index} />;
      }}
    />
  );
}
