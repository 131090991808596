import {
  ThemeProvider,
  Button,
  ToggleButtonGroup,
  Tooltip,
  ToggleButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useContext, useEffect, useState } from "react";
import { Vocabulary } from "../Utils/Vocabulary";
import genericStyles from "../Styles/genericStyles.module.css";
import { Edit, Delete } from "@mui/icons-material";
import GenericModal from "./GenericModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { deleteData } from "../Services/deleteData";
import { getData } from "../Services/getData";
import { useNavigate } from "react-router-dom";
import { ETableEditAction } from "../Utils/Enums";
import { MUIDataTableCustomStyleProps } from "../Utils/Types";
import StandardActionButtons from "./StandarsActionButtons";
import { isMobile } from "../Utils/Utils";
import useQueryStringParser from "../Hooks/useQueryStringParser";
import Config from "../Utils/Config";
import useCustomSearchParam from "../Hooks/useCustomSearchParam";
import { tableTheme } from "../Utils/Constants";
import { WebSocketContext } from "../Context/WebSocketContext";

export default function MUIDataTableCustomStyle(
  props: MUIDataTableCustomStyleProps
) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableData, setTableData]: any = useState([]);
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(Config.rowsPerPage);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const navigate = useNavigate();
  const customSearchParams = useCustomSearchParam();
  const [searchText, setSearchText] = useState("");
  const websocketContext = useContext(WebSocketContext);
  useQueryStringParser((data: any) => {
    if (data) getDataTable(data);
  });

  /**
   *
   * @param options
   * @returns
   */
  async function getDataTable(options?: Record<string, any>) {
    if (!options) options = {};
    options.page = options?.page ? parseInt(options.page) : Config.standardPage;
    options.perPage = options?.perPage ? parseInt(options.perPage) : perPage;

    const urlParts: string[] = [];
    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        const value = options[key];
        if (value !== undefined && value !== null) {
          urlParts.push(`${key}=${value}`);
        }
      }
    }
    if (props.filters)
      // Add properties from filters that are not present in options
      for (const key in props.filters) {
        if (
          Object.prototype.hasOwnProperty.call(props.filters, key) &&
          !(key in options)
        ) {
          const value = props.filters[key];
          if (value !== undefined && value !== null) {
            urlParts.push(`${key}=${value}`);
          }
        }
      }

    const url = `${props.CRUDEndPoint}/paginated?${urlParts.join("&")}`;
    const res = (await getData(url)) as any;
    if (!res || res?.error) {
      return;
    }
    setSearchText(options?.search ? options?.search : "");
    setTableData(res.data.results);
    setCount(res.data.numberOfResults);
  }

  /**
   *
   * @param data
   * @returns
   */
  async function customGetDataTable(data: any) {
    const url = data;
    const res = (await getData(url)) as any;
    if (!res || res?.error) {
      return;
    }
    setTableData(res.data.results);
    setCount(res.data.numberOfResults);
  }

  /**
   *
   */
  useEffect(() => {
    if (props.customGetDataTable && props.customTriggerReload !== undefined) {
      //TO DO: should consider existing filters or delete them from url
      customGetDataTable(props.customGetDataTable);
    }
  }, [props.customTriggerReload]);

  /**
   *
   */
  useEffect(() => {
    if (props.triggerReload !== undefined) {
      //if query contains params remove them, else get data
      if (customSearchParams.searchParams.toString().length > 0)
        customSearchParams.removeAllParamsFromQueryString();
      else getDataTable();
    }
  }, [props.triggerReload]);

  /**
   *
   * @param id
   */
  async function deleteElement() {
    const url = `${props.CRUDEndPoint}/${selectedId}`;
    const res = (await deleteData(url)) as any;
    if (!res || res?.error) {
      return;
    }
    if (props.shouldTriggerStorageUpdate) {
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
    }
    setSelectedId(null);
    setOpenDeleteModal(false);
    props.setContextValue([]);
    getDataTable();
  }

  const actionsColumns = [
    ...props.columns,
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,
        display: props.hideOptions ? false : true,
        empty: true,
        customBodyRenderLite: (rowIndex: number, value: any) => {
          return (
            <>
              <div style={{ float: "right" }}>
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  {props.renderCustomColumns
                    ? props.renderCustomColumns(rowIndex, tableData[rowIndex])
                    : null}

                  {props?.hideEditButton ? null : (
                    <Tooltip title={Vocabulary.edit}>
                      <ToggleButton
                        onClick={(e: any) => {
                          if (props.onEditAction === ETableEditAction.Modal) {
                            e.stopPropagation();
                            props.setContextValue(tableData[rowIndex]);
                            if (props?.handleModalForm)
                              props?.handleModalForm(true);
                            if (props.addEntityIdToURL) {
                              customSearchParams.searchParams.set(
                                "id",
                                tableData[rowIndex]._id
                              );
                              customSearchParams.setSearchParams(
                                customSearchParams.searchParams
                              );
                            }
                          } else {
                            navigate(
                              `${props.rowClickURL}${tableData[rowIndex]._id}`
                            );
                          }
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Edit />
                      </ToggleButton>
                    </Tooltip>
                  )}
                  {props.hideDeleteButton ? null : (
                    <Tooltip title={Vocabulary.delete}>
                      <ToggleButton
                        disabled={tableData[rowIndex]?.shouldDelete === false}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setOpenDeleteModal(true);
                          setSelectedId(tableData[rowIndex]._id);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Delete />
                      </ToggleButton>
                    </Tooltip>
                  )}
                </ToggleButtonGroup>
              </div>
            </>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      customToolbarSelect: props.customToolbarSelect,
      selectableRows: props.selectableRows ? props.selectableRows : "none",
      responsive: responsive,
      viewColumns: false,
      rowsPerPage: perPage,
      rowsPerPageOptions: Config.rowsPerPageOptions,
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      serverSide: true,
      searchOpen: false,
      searchText: searchText,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: {
          next: Vocabulary.nextPage,
          previous: Vocabulary.previousPage,
          rowsPerPage: Vocabulary.rowsPerPage,
          displayRows: Vocabulary.of,
        },
        toolbar: {
          search: Vocabulary.search,
          downloadCsv: Vocabulary.downloadCSV,
          print: Vocabulary.print,
        },
        selectedRows: {
          text: Vocabulary.rowsSelected,
          delete: Vocabulary.delete,
        },
      },
      searchProps: {
        onKeyDown: (e: any) => {
          if (e.key === "Enter") {
            customSearchParams.addParamToQueryString("search", searchText);
            customSearchParams.addParamToQueryString("page", "0");
            window.scrollTo(0, 0);
          }
        },
      },
      onChangePage: (pageNumber: number) => {
        customSearchParams.addParamToQueryString("page", pageNumber);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setPerPage(numberOfRows);
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        return {
          style: {
            cursor: props.onRowClick ? "pointer" : "auto",
          },
          className: props.rowProps ? props.rowProps(row) : null,
        };
      },
      onSearchChange: async (search: any) => {
        setSearchText(search);
        if (!search || search?.length === 0) {
          customSearchParams.searchParams.delete("search");
          customSearchParams.setSearchParams(customSearchParams.searchParams);
        }
      },
      count: count,
      onRowsDelete: (rowData: any) => {
        //ask for confirmation

        const data = rowData?.data?.map(
          (row: any) => tableData[row.dataIndex]._id
        );
        setSelectedId(data.join(","));
        setOpenDeleteModal(true);
      },
      onRowClick: props.onRowClick
        ? (rowData: any, rowState: any) => {
            if (props.onRowClick)
              props?.onRowClick(tableData[rowState.rowIndex]);
          }
        : () => {
            return;
          },
      customToolbar: () => {
        return (
          <div>
            {props?.hideAddButton ? null : (
              <Button
                variant="contained"
                className={genericStyles.addButton}
                onClick={() => {
                  if (props.onEditAction === ETableEditAction.Modal) {
                    props?.handleModalForm(true);
                  } else {
                    navigate(props.rowClickURL || "");
                  }
                }}
              >
                <AddCircleIcon />
                {!isMobile() ? (
                  <div style={{ marginLeft: 5 }}>{Vocabulary.add}</div>
                ) : null}
              </Button>
            )}
            {React.isValidElement(props.buttons)
              ? props.buttons
              : props.buttons &&
                props.buttons?.map(
                  (button: {
                    label: string;
                    handleClick: void;
                    icon: JSX.Element;
                  }) => (
                    <Button
                      variant="contained"
                      className={genericStyles.addButton}
                    >
                      {button.icon && button.icon}
                      <div style={{ marginLeft: 5 }}>
                        {button.label && button.label}
                      </div>
                    </Button>
                  )
                )}
          </div>
        );
      },
    };
  }
  return (
    <div>
      <ThemeProvider theme={tableTheme}>
        <MUIDataTable
          title={props.title}
          data={tableData}
          columns={actionsColumns}
          options={getTableOptions()}
        />
      </ThemeProvider>
      <GenericModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        title={Vocabulary.confirmDeleteTitle}
        actions={
          <StandardActionButtons
            okAction={() => deleteElement()}
            cancelAction={() => setOpenDeleteModal(false)}
            okText={Vocabulary.yes}
            cancelText={Vocabulary.no}
          />
        }
      >
        <div>{Vocabulary.confirmDeleteMessage}</div>
      </GenericModal>

      {props.addOrEditForm}
    </div>
  );
}
