import light from "./light";
import dark from "./dark";

const themes: any = {
  light,
  dark,
};

export default function getTheme(theme: any) {
  return themes[theme];
}
