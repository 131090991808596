import { Grid, IconButton, Toolbar } from "@mui/material";
import Config from "../../Utils/Config";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Vocabulary } from "../../Utils/Vocabulary";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import genericStyles from "../../Styles/genericStyles.module.css";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { RestartAlt } from "@mui/icons-material";
import { FiltersValidationSchema } from "../../Utils/Models";

export default function ContactsTableFilters() {
  const customSearchParams = useCustomSearchParam();
  const methods = useForm({
    defaultValues: {
      start: Config.defaultStartDate,
      end: moment(new Date()).format(Config.momentUSDateFormat),
    },
    resolver: yupResolver(FiltersValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  const resetFilters = () => {
    customSearchParams.removeParamFromQueryString(["start", "end"]);
    methods.reset({
      start: Config.defaultStartDate,
      end: moment(new Date()).format(Config.momentUSDateFormat),
    });
  };

  return (
    <div className={genericStyles.tableFilters}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7.5}
          alignContent={"center"}
        >
          {Vocabulary.filters}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={2} alignContent={"center"}>
          <Controller
            name="start"
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  {...field}
                  label={Vocabulary.startDate}
                  sx={{ width: "100%" }}
                  views={["year", "month", "day"]}
                  format={Config.momentEUDateFormat}
                  value={moment(methods.getValues("start"))}
                  onChange={(date: any) => {
                    field.onChange(moment(date));
                    customSearchParams.addParamToQueryString(
                      "start",
                      moment(date).format(Config.momentUSDateFormat)
                    );
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={2} alignContent={"center"}>
          <Controller
            name="end"
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  {...field}
                  format={Config.momentEUDateFormat}
                  label={Vocabulary.endDate}
                  sx={{ width: "100%" }}
                  maxDate={moment(new Date())}
                  views={["year", "month", "day"]}
                  value={moment(methods.getValues("end"))}
                  onChange={(date: any) => {
                    field.onChange(moment(date));
                    customSearchParams.addParamToQueryString(
                      "end",
                      moment(date).format(Config.momentUSDateFormat)
                    );
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={1}
          xl={0.5}
          alignContent={"center"}
        >
          <Toolbar title={Vocabulary.reset}>
            <IconButton onClick={resetFilters}>
              <RestartAlt />
            </IconButton>
          </Toolbar>
        </Grid>
      </Grid>
    </div>
  );
}
