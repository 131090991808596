import { Typography } from "@mui/material";
import { PageNotFoundProps } from "../Utils/Types";

const styles: any = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  },
};

export default function PageNotFound(props: PageNotFoundProps) {
  return (
    <div style={styles.container}>
      <img
        src={"/pageNotFound.svg"}
        alt="MARELVI"
        width={"100%"}
        height={"50%"}
      />
      <Typography variant="h4">{props.text}</Typography>
    </div>
  );
}
