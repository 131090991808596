import { ThemeProvider } from "@emotion/react";
import { Autocomplete, createTheme, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ClientsAutocompleteValidationSchema } from "../../Utils/Models";
import { yupResolver } from "@hookform/resolvers/yup";
import useCustomMemo from "../../Hooks/useCustomMemo";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ClientsAutocompleteProps } from "../../Utils/Types";

export default function ClientsAutocomplete(props: ClientsAutocompleteProps) {
  const { formId, submitAction } = props;
  const methods = useForm({
    resolver: yupResolver(ClientsAutocompleteValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const memo = useCustomMemo();
  const clients: any = memo?.storage?.clients;

  /**
   *
   * @param errors
   */
  const onErrors = (errors: any) => {
    // console.log(errors);
  };

  return (
    <form id={formId} onSubmit={methods.handleSubmit(submitAction, onErrors)}>
      <Controller
        name="clientId"
        control={methods.control}
        defaultValue={undefined}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <ThemeProvider theme={clientsList}>
            <Autocomplete
              size="small"
              id="clientId"
              fullWidth={true}
              freeSolo={false}
              isOptionEqualToValue={(option: any, value: any) =>
                option?.id === value
              }
              value={field.value}
              getOptionLabel={(option: any) => option?.name || ""}
              options={clients}
              onChange={(e, data: any) => {
                if (data?._id) methods.setValue("clientId", data?._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="client"
                  label={Vocabulary.client}
                  variant="standard"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </ThemeProvider>
        )}
      />
    </form>
  );
}

export const clientsList: any = (defaultTheme: any) =>
  createTheme({
    ...defaultTheme,
    overrides: {
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            zIndex: 1300,
          },
        },
      },
    },
  });
