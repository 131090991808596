/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { getData } from "../../Services/getData";
import { Vocabulary } from "../../Utils/Vocabulary";
import genericStyles from "../../Styles/genericStyles.module.css";
import { CustomThemeContext } from "../../Context/CustomThemeProvider";
import styles from "../../Styles/previewPDF.module.css";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { GetApp, ZoomIn, ZoomOut } from "@mui/icons-material";
import { PreviewDocumentProps } from "../../Utils/Types";
import { pdfjs, Document as PdfDocument, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PreviewPDF(props: PreviewDocumentProps) {
  const { url, htmlContent, open, onClose, title } = props;
  const contentRef: any = useRef(null);
  const scaleAmount = 0.5;
  const themeContext: any = useContext(CustomThemeContext);
  const [state, setState] = useState({
    numPages: 0,
    templateLoaded: 0,
    pageNumber: 1,
    scale: 1.75,
    showEmail: false,
    emailSent: false,
    addImage: false,
  });
  const [content, setContent] = useState(htmlContent);

  /**
   *
   */
  useEffect(() => {
    if (url && url !== "") {
      getData(url).then((response: any) => {
        if (response?.errors) return;
        setState({
          ...state,
          templateLoaded: 1,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  /**
   *
   */
  useEffect(() => {
    if (!content) {
      setContent(htmlContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContent]);

  /**
   *
   * @param data
   */
  function onDocumentLoadSuccess(data: { numPages: any }) {
    const { numPages } = data;
    setState({ ...state, numPages });
  }

  /**
   *
   */
  function zoomIn() {
    setState({ ...state, scale: state.scale + scaleAmount });
  }

  /**
   *
   */
  function zoomOut() {
    setState({ ...state, scale: state.scale - scaleAmount });
  }

  /**
   *
   */
  function renderPages() {
    const pages: Array<any> = [];
    for (let i = 0; i < state.numPages; i++) {
      pages.push(
        <Page
          renderTextLayer={false}
          renderAnnotationLayer={false}
          className={styles.pdfPreviewPage}
          key={`page_${i + 1}`}
          scale={state.scale}
          pageNumber={i + 1}
        />
      );
    }
    return pages;
  }

  /**
   *
   */
  const downloadPDF = () => {
    if (url)
      fetch(url)
        .then((response) => response.blob())
        .then((response) => {
          const blob = new Blob([response], { type: "application/pdf" });
          const link = document.createElement("a");
          link.download = title;
          link.href = URL.createObjectURL(blob);
          link.click();
        });
  };

  return (
    <Dialog
      style={{ zIndex: 2000 }}
      maxWidth="xl"
      classes={{ paper: styles.overflowHidden }}
      open={open}
      onClose={() => onClose(state.emailSent)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        className={genericStyles.fullWidthElement}
      >
        <div style={{ flexGrow: 2 }}>{title}</div>
        <div>{props.customTopMenu && props.customTopMenu}</div>
      </DialogTitle>
      <DialogContent ref={contentRef} className={styles.dialogContent}>
        {url ? (
          <>
            {!state.templateLoaded ? (
              <div className={styles.loadingWrapper}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <PdfDocument
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {renderPages()}
              </PdfDocument>
            )}
          </>
        ) : (
          Vocabulary.loading
        )}
      </DialogContent>
      <DialogActions classes={{ root: styles.actionsWrapper }}>
        <IconButton onClick={downloadPDF}>
          <GetApp />
        </IconButton>
        <IconButton disabled={state.scale <= 0.3} onClick={zoomOut}>
          <ZoomOut />
        </IconButton>
        <IconButton disabled={state.scale >= 2} onClick={zoomIn}>
          <ZoomIn />
        </IconButton>
        <Button
          onClick={() => props.onClose(state.emailSent)}
          style={{ background: themeContext.theme?.palette.cancel?.main }}
        >
          {Vocabulary.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewPDF;
