import { Add, SpeakerNotesRounded } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
} from "@mui/material";
import styles from "../../Styles/chat.module.css";
import whatsAppStyles from "../../Styles/whatsApp.module.css";
import { useState } from "react";
import { ChatTemplatesProps } from "../../Utils/Types";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericModal from "../GenericModal";
import { EWidths } from "../../Utils/Enums";
import WhatsAppTemplatePreview from "../WhatsAppIntegration/WhatsAppTemplatePreview";
import StandardActionButtons from "../StandarsActionButtons";
import { getData } from "../../Services/getData";
import { endpoints } from "../../Utils/UrlEnum";
import whatsAppBackground from "../../Images/whatsAppBackground.jpg";

export default function ChatTemplatesList(props: ChatTemplatesProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [message, setMessage] = useState<any>();
  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    setAnchorEl(event?.currentTarget || null);
  };

  /**
   *
   * @param template
   */
  const handleSelectTemplate = (template: any) => {
    setAnchorEl(null);
    getData(
      `${endpoints.whatsApp.templateMessage}/${template.id}/${props.clientId}`
    ).then((response) => {
      if (response && response.data) {
        setSelectedTemplate(response.data.template);
        setMessage(response.data);
        toggleModalState();
      }
    });
  };

  /**
   *
   */
  const closeModal = () => {
    toggleModalState();
    setSelectedTemplate(null);
  };

  return (
    <div className={styles.addTemplateChatButtonContainer}>
      <Button
        className={styles.addTemplateChatButton}
        variant="contained"
        color="blueGrey"
        onClick={handleClick}
        disabled={props.disabled || !props.clientId}
        fullWidth
        startIcon={
          <Add
            className={
              open
                ? `${styles.addTemplateChatButtonClosed} ${styles.rotate}`
                : `${styles.addTemplateChatButtonOpen}`
            }
          />
        }
      >
        {Vocabulary.templates}
      </Button>
      <IconButton
        onClick={handleClick}
        disabled={props.disabled || !props.clientId}
        className={styles.addTemplateChatIconButton}
      >
        <SpeakerNotesRounded />
      </IconButton>
      <Popover
        sx={{ zIndex: 9999999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClick(null)}
        id={id}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List className={styles.chatExtraActionList}>
          {props?.templates?.map((template: any, index) => (
            <ListItem
              key={index}
              onClick={() => {
                handleSelectTemplate(template);
              }}
            >
              <ListItemText primary={template.name} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <GenericModal
        maxWidth={EWidths.MD}
        onClose={closeModal}
        open={openModal}
        actions={
          <StandardActionButtons
            okText={Vocabulary.send}
            cancelText={Vocabulary.cancel}
            cancelAction={closeModal}
            okAction={() => {
              props.sendTemplateMessage(message);
              closeModal();
            }}
          />
        }
        style={{}}
        title={`${Vocabulary.templatePreview}: ${selectedTemplate?.name || ""}`}
        children={
          selectedTemplate?.id && (
            <div
              style={{
                padding: 25,
                background: "#ffffff",
                backgroundImage: `url(${whatsAppBackground})`,
                backgroundSize: "cover",
              }}
            >
              <Paper className={whatsAppStyles.whatsAppTemplatePreviewPaper}>
                <WhatsAppTemplatePreview
                  template={selectedTemplate}
                  shouldRenderCurrentDate={true}
                />
              </Paper>
            </div>
          )
        }
      />
    </div>
  );
}
