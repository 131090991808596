import GenericModal from "../../Components/GenericModal";
import { DeleteOrderStatusProps } from "../../Utils/Types";
import { Vocabulary } from "../../Utils/Vocabulary";
import StandardActionButtons from "../StandarsActionButtons";

export default function DeleteOrderStatus(props: DeleteOrderStatusProps) {
  const { openDeleteModal, setOpenDeleteModal, handleDelete } = props;
  return (
    <GenericModal
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      title={Vocabulary.confirmDeleteTitle}
      actions={
        <StandardActionButtons
          okAction={() => handleDelete()}
          cancelAction={() => setOpenDeleteModal(false)}
          okText={Vocabulary.yes}
          cancelText={Vocabulary.no}
        />
      }
    >
      <div>{Vocabulary.confirmDeleteMessage}</div>
    </GenericModal>
  );
}
