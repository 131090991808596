import MUIDataTable from "mui-datatables";
import { localUrlEnum, endpoints } from "../../Utils/UrlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import { getData } from "../../Services/getData";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { WhatsAppTemplateState } from "../../Utils/Models";
import {
  WhatsAppTemplateCategory,
  WhatsAppTemplateChipColors,
  WhatsAppTemplateIconsStatuses,
  WhatsAppTemplateStatuses,
  WhatsAppTemplatesSupportedLanguagesTranslation,
  tableTheme,
} from "../../Utils/Constants";
import {
  Button,
  Chip,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { isMobile } from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import genericStyles from "../../Styles/genericStyles.module.css";
import { deleteData } from "../../Services/deleteData";
import GenericModal from "../GenericModal";
import StandardActionButtons from "../StandarsActionButtons";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import useQueryStringParser from "../../Hooks/useQueryStringParser";

export default function WhatsAppTemplatesTable() {
  const [state, setState] = useState<WhatsAppTemplateState>(
    new WhatsAppTemplateState()
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const customSearchParams = useCustomSearchParam();
  const whatsAppTemplatesColumns = [
    {
      label: Vocabulary.templateName,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (rowData: any) => {
          return (
            <Chip
              sx={{ backgroundColor: WhatsAppTemplateChipColors[rowData] }}
              label={WhatsAppTemplateStatuses[rowData]}
              icon={WhatsAppTemplateIconsStatuses[rowData]}
            />
          );
        },
      },
    },
    {
      label: Vocabulary.category,
      name: "category",
      options: {
        sort: false,
        customBodyRender: (rowData: any) => {
          return WhatsAppTemplateCategory[rowData];
        },
      },
    },
    {
      label: Vocabulary.templateLanguage,
      name: "language",
      options: {
        sort: false,
        customBodyRender: (rowData: any) => {
          return WhatsAppTemplatesSupportedLanguagesTranslation[rowData];
        },
      },
    },
    {
      label: Vocabulary.preview,
      name: "components",
      options: {
        sort: false,
        customBodyRender: (rowData: any) => {
          // Find the "BODY" component from the components array
          const bodyComponent = rowData.find(
            (component: any) => component.type === "BODY"
          );
          let truncatedText = "-";
          // Check if the "BODY" component exists and if it has text
          if (bodyComponent && bodyComponent.text) {
            // Truncate the text to the first 30 characters
            truncatedText = bodyComponent.text.slice(0, 30);
            if (bodyComponent.text.length > 30) {
              truncatedText += "...";
            }
          }
          return truncatedText;
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <Tooltip
                title={Vocabulary.edit}
                className={genericStyles.tooltipEditButton}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={() => {
                    const currentRow = state.templates[tableMeta.rowIndex];
                    navigate(
                      `${localUrlEnum.whatsAppTemplate}/${currentRow.id}`
                    );
                  }}
                >
                  <Edit />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={Vocabulary.delete}>
                <ToggleButton
                  value="center"
                  className={genericStyles.tooltipDeleteButton}
                  aria-label="centered"
                  onClick={(event: any) => {
                    setOpenDeleteModal(true);
                    setSelectedRow(state.templates[tableMeta.rowIndex]);
                  }}
                >
                  <Delete />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          );
        },
      },
    },
  ];

  /**
   *
   */
  useQueryStringParser((data: any) => {
    if (data) getWhatsAppTemplates(data.search);
  });

  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPage: state.perPage,
      rowsPerPageOptions: [],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      count: state.count,
      page: state.page,
      search: true,
      searchText: searchText,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: {
          next: Vocabulary.nextPage,
          previous: Vocabulary.previousPage,
          rowsPerPage: Vocabulary.rowsPerPage,
          displayRows: Vocabulary.of,
        },
        toolbar: {
          search: Vocabulary.search,
          downloadCsv: Vocabulary.downloadCSV,
          print: Vocabulary.print,
        },
        selectedRows: {
          text: Vocabulary.rowsSelected,
          delete: Vocabulary.delete,
        },
      },
      onSearchChange: async (search: any) => {
        setSearchText(search);
        if (!search || search?.length === 0) {
          customSearchParams.searchParams.delete("search");
          customSearchParams.setSearchParams(customSearchParams.searchParams);
        }
      },
      searchProps: {
        onKeyDown: (e: any) => {
          if (e.key === "Enter") {
            customSearchParams.addParamToQueryString("search", searchText);
            customSearchParams.addParamToQueryString("page", "0");
            window.scrollTo(0, 0);
          }
        },
      },
      customToolbar: () => {
        return (
          <div>
            <Button
              variant="contained"
              className={genericStyles.addButton}
              onClick={() => {
                navigate(`${localUrlEnum.whatsAppTemplate}`);
              }}
            >
              <AddCircle />
              {!isMobile() ? (
                <div style={{ marginLeft: 5 }}>{Vocabulary.addNewTemplate}</div>
              ) : null}
            </Button>
          </div>
        );
      },
    };
  };

  /**
   * Delete a template
   */
  const deleteTemplate = () => {
    const url = `${endpoints.whatsApp.main}/${selectedRow.id}/${selectedRow.name}`;
    deleteData(url).then((response: AxiosResponse) => {
      setOpenDeleteModal(false);
      if (response) {
        getWhatsAppTemplates();
      }
    });
  };

  /**
   *
   * @param limit
   * @param before
   * @param after
   */
  const getWhatsAppTemplates = (search?: string) => {
    const url = `${endpoints.whatsApp.template}${
      search ? `?search=${search}` : ""
    }`;
    getData(url).then((response: AxiosResponse) => {
      if (response && response.data)
        setState({
          ...state,
          templates: response.data.data,
          before: response.data.paging.cursors.before,
          after: response.data.paging.cursors.after,
          count: response.data.data.length,
        });
    });
  };

  return (
    <div>
      <ThemeProvider theme={tableTheme}>
        <MUIDataTable
          title={Vocabulary.whatsAppMessagesTemplates}
          columns={whatsAppTemplatesColumns}
          data={state.templates}
          options={getTableOptions()}
        />
      </ThemeProvider>
      <GenericModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        title={Vocabulary.confirmDeleteTitle}
        actions={
          <StandardActionButtons
            okAction={() => deleteTemplate()}
            cancelAction={() => setOpenDeleteModal(false)}
            okText={Vocabulary.yes}
            cancelText={Vocabulary.no}
          />
        }
      >
        <div>{Vocabulary.confirmDeleteMessage}</div>
      </GenericModal>
    </div>
  );
}
