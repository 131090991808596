import { CloudUpload } from "@mui/icons-material";
import { Button, Popover, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import DropzoneComponent from "../DropzoneComponent";
import StandardActionButtons from "../StandarsActionButtons";
import styles from "../../Styles/emailTemplate.module.css";
import { EmailTemplateImagesProps } from "../../Utils/Types";
import { ImageExtensions } from "../../Utils/Constants";

export default function EmailTemplateImages(props: EmailTemplateImagesProps) {
  const { handleUploadAttachment } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [file, setFile] = useState<any>(null);
  const [altText, setAltText] = useState("");
  const [shouldValidate, setShouldValidate] = useState(false);
  /**
   *
   * @param event
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   */
  const handleSaveImage = () => {
    if (file && altText && altText !== "") {
      handleUploadAttachment(file, altText);
      setAltText("");
      setFile(null);
      handleClose();
    }
    setShouldValidate(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Button
        className={styles.emailTemplateAddImageButton}
        startIcon={<CloudUpload />}
        aria-describedby={id}
        variant="outlined"
        color="secondary"
        onClick={handleClick}
      >
        {Vocabulary.addImage}
      </Button>
      <Popover
        id={id}
        className={styles.emailTemplatePopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <form className={styles.emailTemplateImageHandler}>
          <Typography>
            {Vocabulary.uploadImageOnEmailTemplateMessage}
          </Typography>
          <DropzoneComponent
            acceptedFileTypes={ImageExtensions}
            onSave={(file: any) => setFile(file)}
            path={""}
          />
          <TextField
            fullWidth
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            label={Vocabulary.imageAlternativeText}
            required={true}
          />
          <StandardActionButtons
            okAction={handleSaveImage}
            cancelAction={handleClose}
            okText={Vocabulary.save}
            cancelText={Vocabulary.cancel}
          />
          {shouldValidate ? (
            <Typography sx={{ color: "red" }}>
              {Vocabulary.imageUploadErrorMessage}
            </Typography>
          ) : null}
        </form>
      </Popover>
    </div>
  );
}
