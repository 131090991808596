import {
  Collapse,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import styles from "../Styles/mainSlider.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { mainPathMatches } from "../Utils/Utils";
import { MainSliderProps } from "../Utils/Types";
import withRole from "../Hooks/withRole";

function MainSlider(props: MainSliderProps, location: any) {
  location = useLocation();
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);

  /**
   *
   * @param element
   * @param key
   * @param shouldCollapse
   * @returns
   */
  const renderListItem = (element: any, key: any, shouldCollapse: boolean) => {
    return (
      <ListItem
        key={key}
        className={styles.listItem}
        onClick={() =>
          shouldCollapse ? handleChangeCollapse() : handleNavigate(element.path)
        }
      >
        <IconButton
          className={
            mainPathMatches(element.path, location.pathname)
              ? genericStyles.selected
              : genericStyles.nonSelected
          }
        >
          {element.icon}
        </IconButton>
        <div className={styles.arrowContainer}>
          <Typography
            className={
              mainPathMatches(element.path, location.pathname)
                ? genericStyles.selectedText
                : genericStyles.nonSelectedText
            }
          >
            {element.name}
          </Typography>
          {shouldCollapse ? (
            collapse ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowUp />
            )
          ) : null}
        </div>
      </ListItem>
    );
  };

  /**
   *
   * @param element
   * @param key
   * @returns
   */
  const renderNestedListItem = (element: any, key: any) => {
    return (
      <ListItem
        key={key}
        className={styles.listItem}
        onClick={() => {
          handleNavigate(element.path);
        }}
      >
        <Typography
          className={
            mainPathMatches(element.path, location.pathname)
              ? genericStyles.selectedText
              : genericStyles.nonSelectedText
          }
        >
          {element.name}
        </Typography>
      </ListItem>
    );
  };

  /**
   * Function to handle the collapse of the list
   */
  const handleChangeCollapse = () => {
    setCollapse(!collapse);
  };

  /**
   *
   * @param path
   */
  const handleNavigate = (path: string) => {
    navigate(path);
    props.setHideSlider();
  };

  return (
    <div className={styles.container}>
      <div className={styles.slider}>
        <List>
          {props.data?.map((element: any, key: any) => {
            return element.children ? (
              <Fragment key={key}>
                {renderListItem(element, key, true)}
                <Collapse in={collapse} unmountOnExit>
                  <List className={styles.nestedListItem}>
                    {element.children.map((child: any, key: any) => {
                      return renderNestedListItem(child, key);
                    })}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              renderListItem(element, key, false)
            );
          })}
        </List>
      </div>
    </div>
  );
}

export default withRole(MainSlider);
