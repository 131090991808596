import { Divider, List, ListItem, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "./NoDataFound";
import { useEffect, useState } from "react";
import { Vocabulary } from "../Utils/Vocabulary";
import styles from "../Styles/customInfiniteScrollList.module.css";
import { CustomInfiniteScrollListProps } from "../Utils/Types";
import genericStyles from "../Styles/genericStyles.module.css";

export default function CustomInfiniteScrollList(
  props: CustomInfiniteScrollListProps
) {
  const { items } = props;
  const [visibleItems, setVisibleItems] = useState(20); // Initial number of visible items
  const [listItems, setListItems] = useState<any[]>([]); // Initial list items to display

  /**
   *
   */
  useEffect(() => {
    //set initial list items
    setListItems(items?.slice(0, visibleItems));
  }, [items, visibleItems]);

  /**
   *
   */
  const loadMoreItems = () => {
    setVisibleItems((prev) => prev + 20); // Increase the number of visible items by 20
  };

  return listItems?.length > 0 ? (
    <InfiniteScroll
      dataLength={listItems.length}
      next={loadMoreItems}
      className={props.style ? props.style : ""}
      height={"85vh"}
      scrollableTarget="scrollableDiv"
      hasMore={visibleItems < items.length}
      loader={
        <div className={styles.customListLoaderText}>
          <Typography variant="h6">{Vocabulary.loading}</Typography>
        </div>
      }
      endMessage={
        items.length > 0 ? (
          <div className={styles.customListLoaderText}>
            <Typography variant="h6">
              {listItems.length < 14 ? " " : Vocabulary.noMoreItems}
            </Typography>
          </div>
        ) : null
      }
    >
      <List
        disablePadding
        className={props.listStyle ? props.listStyle : styles.customList}
      >
        {listItems.map((item, index) => (
          <ListItem
            key={index}
            className={props.listItemsStyle ? props.listItemsStyle : ""}
          >
            <div className={styles.customListItemContainer}>
              {props.render(item, index)}
              <Divider className={genericStyles.fullWidthElement} />
            </div>
          </ListItem>
        ))}
      </List>
    </InfiniteScroll>
  ) : (
    <NoDataFound />
  );
}
