import { DoneAll, Message, Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import ContactPhone from "./ContactPhone";
import Chat from "../Messages/Chat";
import ContactOrder from "./ContactOrder";
import StepperNotes from "../Widgets/StepperNotes";
import styles from "../../Styles/dashboardContacts.module.css";
import { postData } from "../../Services/postData";
import { endpoints } from "../../Utils/UrlEnum";
import { updateData } from "../../Services/updateData";
import GenericModal from "../GenericModal";
import { EContactStatus, ENoteType, EWidths } from "../../Utils/Enums";
import CustomPanel from "../CustomPanel";
import Periodicity from "../Clients/Periodicity";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Config from "../../Utils/Config";
import { getData } from "../../Services/getData";
import {
  ClientValidationSchema,
  ContactModel,
  EmailTemplateModel,
} from "../../Utils/Models";
import { useSearchParams } from "react-router-dom";
import {
  ContactModalProps,
  CreateAndUpdateOrderResponse,
} from "../../Utils/Types";
import ContactEmail from "./ContactEmail";
import { AxiosResponse } from "axios";
import { isMobile, isTablet } from "../../Utils/Utils";
import { IconsForNotes } from "../../Utils/Constants";
import moment from "moment";

export const ContactCRUDContext = createContext(new ContactModel());

export default function ContactModal(props: ContactModalProps) {
  const { contactId, setShouldUpdate, open, setOpen } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [contactContextValue, setContactContextValue] = useState(
    new ContactModel()
  );
  const [conversationId, setConversationId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [openFinishDialog, setOpenFinishDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const clientNotesOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { control, getValues, setValue } = useForm({
    defaultValues: {
      periodicity: [
        {
          name: "",
          nextContactDate: undefined,
          noDays: 0,
          day: {
            name: "",
            value: 0,
          },
        },
      ],
    },
    resolver: yupResolver(ClientValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  useEffect(() => {
    if (!contactId) return;
    getData(`${endpoints.contactEndpoint}/${contactId}`).then(
      (res: AxiosResponse) => {
        if (res && res?.data) {
          if (res.data.contact.status === EContactStatus.FINALIZAT)
            setDisabled(true);
          setContactContextValue(res.data.contact);
          setConversationId(res.data.conversationId);
          setOpen(true);
        }
      }
    );
  }, [contactId]);

  /**
   *
   */
  const closeModal = () => {
    setOpen(false);
    searchParams.delete("id");
    setSearchParams(searchParams);
    setDisabled(false);
  };

  /**
   *
   */
  const updateContact = (contact: ContactModel, callback?: any) => {
    updateData(
      `${endpoints.contactEndpoint}/${contactContextValue._id}`,
      contact
    ).then((res: AxiosResponse) => {
      if (res) {
        setContactContextValue(res.data);
        setShouldUpdate(true);
        if (callback) callback();
      }
    });
  };

  /**
   *
   * @param emailTemplate
   */
  const sendEmailToClient = (
    emailTemplate: EmailTemplateModel,
    callback: any
  ) => {
    const url = `${endpoints.contactEndpoint}/email/${contactContextValue._id}`;
    postData(url, emailTemplate).then((res: AxiosResponse) => {
      if (res) {
        setContactContextValue(res.data);
        callback();
      }
    });
  };

  /**
   *
   * @param property
   * @param value
   */
  const handleChange = (property: string, value: string) => {
    setContactContextValue({ ...contactContextValue, [property]: value });
  };

  /**
   *
   * @param notes
   */
  const updateContactNotes = (notes: any) => {
    postData(
      `${endpoints.updateContactNotes}/${contactContextValue._id}`,
      notes
    ).then((res: AxiosResponse) => {
      if (res) {
        setContactContextValue(res.data);
      }
    });
  };

  /**
   * open the dialog
   */
  const handleDialogState = () => {
    if (!openFinishDialog) generateNewPeriodicity();
    //open dialog
    setOpenFinishDialog(!openFinishDialog);
  };

  /**
   *calculate next contact date and push it to the periodicity array
   */
  const generateNewPeriodicity = () => {
    //get older periodicity array
    const oldPeriodicity: any = contactContextValue.client?.periodicity;
    //create a copy object of the last element in the periodicity array
    const lastElement = oldPeriodicity[oldPeriodicity.length - 1];
    const newPeriodicity: any = Object.assign({}, lastElement);
    //if last element noDays is set, add it to the current date, else use current date plus 14 days
    const unformattedNewContactDate = new Date().setDate(
      new Date().getDate() +
        (lastElement.noDays || Config.standardNewContactNoDays)
    );
    //format the new contact date
    newPeriodicity.nextContactDate = moment(unformattedNewContactDate).format(
      Config.momentUSDateFormat
    );
    const unformattedNextTransportDate = new Date(
      newPeriodicity.nextContactDate
    );
    if (lastElement.day.value === 5 || lastElement.day.value === "5") {
      newPeriodicity.nextTransportDate = moment(
        unformattedNextTransportDate.setDate(
          unformattedNextTransportDate.getDate() + 3
        )
      ).format(Config.momentUSDateFormat);
    } else {
      newPeriodicity.nextTransportDate = moment(
        unformattedNextTransportDate.setDate(
          unformattedNextTransportDate.getDate() + 1
        )
      ).format(Config.momentUSDateFormat);
    }
    //if periodicity name is not set, set it to periodic
    newPeriodicity.name =
      lastElement.name || lastElement.name !== ""
        ? lastElement.name
        : Vocabulary.periodic;
    //set noDays
    newPeriodicity.noDays = lastElement.noDays
      ? lastElement.noDays
      : Config.standardNewContactNoDays;
    //add the new element to the periodicity array
    oldPeriodicity.push(newPeriodicity);
    setValue("periodicity", oldPeriodicity);

  };

  /**
   *
   */
  const generateConversation = () => {
    const clientId: any = contactContextValue?.client?._id;
    postData(endpoints.conversation.main, { clientId: clientId }).then(
      (res: any) => {
        if (res && res.data) {
          setConversationId(res.data._id);
        }
      }
    );
  };

  /**
   *
   */
  const finishContact = () => {
    const url = `${endpoints.clientsEndpoint}/${contactContextValue?.client?._id}`;
    updateData(url, contactContextValue.client).then(
      (res: CreateAndUpdateOrderResponse) => {
        if (res && res.data) {
          //update contact
          const updatedContact = Object.assign({}, contactContextValue) as any;
          updatedContact.status = EContactStatus.FINALIZAT;
          updateContact(updatedContact, () => {
            setOpenFinishDialog(false);
            closeModal();
          });
        }
      }
    );
  };

  return (
    <GenericModal
      open={open}
      onClose={closeModal}
      title={`${Vocabulary.client}: ${contactContextValue.client?.name}`}
      maxWidth={EWidths.LG}
      otherOptions={
        contactContextValue?.client?.notes?.length > 0 && (
          <>
            <Button
              variant="contained"
              color="secondary"
              id="demo-positioned-button"
              aria-controls={
                clientNotesOpen ? "demo-positioned-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={clientNotesOpen ? "true" : undefined}
              onClick={handleClick}
            >
              {Vocabulary.clientNotesHistory}
            </Button>
            <Popover
              open={clientNotesOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List sx={{ maxHeight: "300px" }}>
                {contactContextValue?.client?.notes?.map(
                  (note: any, index: any) => (
                    <ListItem sx={{ padding: "5px" }} key={index}>
                      <ListItemIcon>
                        {IconsForNotes[note.type || ENoteType.OTHERS]?.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={note.text}
                        secondary={
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6.6} lg={6.5}>
                              {" "}
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: "text.primary",
                                  display: "inline",
                                }}
                              >
                                {`${
                                  note?.agent?.firstName ||
                                  note?.client?.firstName ||
                                  ""
                                }
                ${
                  note?.agent?.lastName || note?.client?.lastName || "System"
                }     `}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
                              {moment(note.createdAt).format(
                                Config.momentEUDateTimeFormat
                              )}
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                  )
                )}
              </List>
            </Popover>
          </>
        )
      }
    >
      <ContactCRUDContext.Provider value={contactContextValue}>
        {openFinishDialog ? (
          <Dialog
            classes={{ paper: styles.contactWidgetDialogPaper }}
            style={{ backdropFilter: "blur(5px)" }}
            open={openFinishDialog}
            onClose={handleDialogState}
          >
            <DialogTitle>{Vocabulary.chooseNextContactDate}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Grid container spacing={Config.standardGridSpacing}>
                <Periodicity
                  client={contactContextValue.client}
                  periodicityLength={
                    contactContextValue.client?.periodicity?.length || 0
                  }
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  renderLess={true}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDialogState}
                variant="contained"
                color="secondary"
              >
                {Vocabulary.cancel}
              </Button>
              <Button onClick={finishContact} variant="contained">
                {Vocabulary.approve}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
        <Grid container spacing={1}>
          <div className={styles.container1}>
            {/**CONTACT EMAIL */}
            <Grid
              className={styles.panelGrid}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <CustomPanel title={Vocabulary.email}>
                <ContactEmail
                  disabled={disabled}
                  sendEmailToClient={sendEmailToClient}
                />
              </CustomPanel>
            </Grid>
            {/**CONTACT PHONE */}
            <Grid
              item
              className={styles.panelGrid}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <CustomPanel title={Vocabulary.wasClientCalledOverPhone}>
                <ContactPhone disabled={disabled} handleChange={handleChange} />
              </CustomPanel>
            </Grid>
            {/**CONTACT CONVERSATION */}
            <Grid
              className={styles.panelGrid}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <CustomPanel title={Vocabulary.messages}>
                {conversationId ? (
                  <Chat
                    disabled={disabled}
                    id={conversationId}
                    className={styles.messagesContainerHeight}
                  />
                ) : (
                  <div className={styles.addNewConversationButton}>
                    <Button
                      disabled={disabled}
                      onClick={generateConversation}
                      startIcon={<Message />}
                      variant="contained"
                    >
                      {Vocabulary.addConversation}
                    </Button>
                  </div>
                )}
              </CustomPanel>
            </Grid>

            {/**CONTACT NOTES */}
            <Grid className={styles.panelGrid} item xs={12} sm={12} md={12}>
              <CustomPanel title={Vocabulary.note}>
                <StepperNotes
                  updateNotes={updateContactNotes}
                  notes={contactContextValue.notes}
                  disabled={disabled}
                />
              </CustomPanel>
            </Grid>
          </div>
          <div className={styles.container2}>
            <div className={styles.container2SubContainer}>
              {isMobile() || isTablet() ? (
                <>
                  {/**CONTACT ORDER */}
                  <Grid
                    className={styles.panelGrid}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <ContactOrder
                      disabled={disabled}
                      setContextValue={setContactContextValue}
                    />
                  </Grid>
                  <Grid
                    className={`${styles.panelGrid} ${styles.container2ButtonsGrid}`}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Button
                      onClick={handleDialogState}
                      variant="contained"
                      color="pastelRed"
                      style={{ width: "100%" }}
                      startIcon={<DoneAll />}
                      disabled={disabled}
                    >
                      {Vocabulary.finishContact}
                    </Button>
                    <Button
                      onClick={() => updateContact(contactContextValue)}
                      variant="contained"
                      color="secondary"
                      startIcon={<Save />}
                      style={{ width: "100%" }}
                      disabled={disabled}
                    >
                      {Vocabulary.saveContact}
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    className={`${styles.panelGrid} ${styles.container2ButtonsGrid}`}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Button
                      onClick={handleDialogState}
                      variant="contained"
                      color="pastelRed"
                      style={{ width: "100%" }}
                      startIcon={<DoneAll />}
                      disabled={disabled}
                    >
                      {Vocabulary.finishContact}
                    </Button>
                    <Button
                      onClick={() => updateContact(contactContextValue)}
                      variant="contained"
                      color="secondary"
                      startIcon={<Save />}
                      style={{ width: "100%" }}
                      disabled={disabled}
                    >
                      {Vocabulary.saveContact}
                    </Button>
                  </Grid>
                  {/**CONTACT ORDER */}
                  <Grid
                    className={styles.panelGrid}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <ContactOrder
                      disabled={disabled}
                      setContextValue={setContactContextValue}
                    />
                  </Grid>
                </>
              )}
            </div>
          </div>
        </Grid>
      </ContactCRUDContext.Provider>
    </GenericModal>
  );
}
