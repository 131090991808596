import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/order.module.css";
import { TotalPanelProps } from "../../Utils/Types";

export default function TotalPanel(props: TotalPanelProps) {
  const { disabled, isOrderOffer } = props;
  let totalValueWithoutTVA = 0;
  let totalValue = 0;
  let totalDiscount = 0;

  //Verific pentru prima oara fiecare subtotal, valoare, discount de pe fiecare line_items
  if (!disabled) {
    props.getValues("line_items")?.forEach((element: any, index: any) => {
      totalValueWithoutTVA += parseFloat(
        props.watch(`line_items[${index}].subtotal_tax`) || "0"
      );
      totalValue += parseFloat(
        props.watch(`line_items[${index}].total`) || "0"
      );
      totalDiscount += parseFloat(
        props.watch(`line_items[${index}].discountValue`) || "0"
      );
    });
    totalValue += parseFloat(props.watch("shipping_total")) || 0;
  } else {
    totalValueWithoutTVA = parseFloat(props.getValues("total_tax") || "0");
    totalValue = parseFloat(props.getValues("total") || "0");
  }

  /**
   *Calculeaza la fie scimbare din produs sau transport, noile totaluri (fara TVA si cu TVA)
   */
  useEffect(() => {
    props.setValue(
      "total_tax",
      totalValueWithoutTVA?.toFixed(2).toString() || "0"
    );
    props.setValue("total", totalValue?.toFixed(2).toString() || "0");
    props.setValue(
      "discount_total",
      totalDiscount?.toFixed(2).toString() || "0"
    );
  }, [totalValueWithoutTVA, totalValue]);

  return (
    <div className={styles.mainBox}>
      <Grid container spacing={Config.standardGridSpacing}>
        {props.isOrderOffer ? null : (
          <Grid item xs={12} sm={12} md={6} className={styles.gridContainer}>
            <div className={styles.typography}>
              <Typography>{Vocabulary.totalDiscountWithouTVA}</Typography>
              <Typography>
                {totalDiscount?.toFixed(2)} {Vocabulary.lei.toUpperCase()}
              </Typography>
            </div>
            <div className={styles.typography}>
              <Typography>{Vocabulary.otherCosts}</Typography>
              <Typography>{0}</Typography>
            </div>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className={
            props.isOrderOffer ? styles.totalPanelForClient : styles.totalPanel
          }
        >
          {isOrderOffer ? (
            <>
              <Typography className={styles.typographyTotal}>
                {Vocabulary.totalTVAIncluded}:{totalValue?.toFixed(2)}{" "}
                {Vocabulary.lei.toUpperCase()}
              </Typography>
            </>
          ) : (
            <>
              <Typography className={styles.typographyTotal}>
                {Vocabulary.totalWithoutTVA}: {totalValueWithoutTVA?.toFixed(2)}{" "}
                {Vocabulary.lei.toUpperCase()}
              </Typography>
              <Typography className={styles.typographyTotal}>
                {Vocabulary.total}:{totalValue?.toFixed(2)}{" "}
                {Vocabulary.lei.toUpperCase()}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
